<template>
    <div class="word-search-game">
        <div class="word-search-game__legend sugar-legend">
            <template
                v-for="level in sugar_levels"
                :key="level.val"
            >
                <span
                    :class="'sugar_level_legend_' + level.val"
                    class="sugar-legend__item"
                >
                    {{ level.name }}
                </span>
            </template>
        </div>
        <el-row
            :gutter="15"
            class="word-search-game__words-list"
        >
            <el-col
                v-for="word in words"
                :key="word.value"
                :span="8"
            >
                <template v-if="foundWords.find(w => w.value === word.value)">
                    <i
                        :class="'sugar_level_' + word.sugar"
                        class="fa-solid fa-circle-check"
                    />
                    <s class="word-search-game__words-value">
                        {{ word.value }}
                    </s>
                </template>
                <template v-else>
                    <span class="word-search-game__words-value">
                        {{ word.value }}
                    </span>
                </template>
            </el-col>
        </el-row>
        <div class="matrix word-search-game__matrix">
            <div
                v-for="(row, row_key) in matrix"
                :key="row_key-1"
                class="matrix-row"
            >
                <div
                    v-for="(letter, col_key) in row"
                    :key="`${row_key}_${col_key}`"
                    :class="letterTileClasses(col_key, row_key)"
                    class="matrix-cell"
                >
                    <div
                        :data-x="col_key"
                        :data-y="row_key"
                        class="cell"
                        @mousedown.prevent="wordSelectStart"
                        @mouseup="wordSelectStop"
                        @mouseenter="debounceActiveCell = `${col_key}_${row_key}`"
                        @touchstart.prevent="wordSelectStart"
                        @touchend="wordSelectStop"
                        @touchmove="wordSelectUpdate"
                    >
                        <svg
                            width="100%"
                            height="100%"
                            viewBox="0 0 18 18"
                        >
                            <text
                                x="50%"
                                y="13"
                                text-anchor="middle"
                            >
                                {{ letter }}
                            </text>
                        </svg>
                    </div>
                    <div
                        v-for="(wordLineData, i) in wordLinesForTile(col_key, row_key)"
                        :key="`${row_key}_${col_key}_${i}`"
                        :class="wordLineClasses(wordLineData)"
                    />
                </div>
            </div>
        </div>
        <div class="word-search-game__text">
            <p class="default-text">
                {{ bottomText }}
            </p>
        </div>
        <div class="word-search-game__button">
            <RobotDialog
                v-if="showRobot"
                :messages="robotMessage"
                robot-image="/images/robots/robot_hands_up.svg"
            />
            <div class="word-search-game__button-wrap">
                <el-button
                    :disabled="!done"
                    type="primary"
                    round
                    class="continue-button"
                    @click="clickDone"
                >
                    Done
                </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {LEVELS} from '~/modules/games/sugarLevel.constants'
import pmoApi from '~/modules/pmo/pmoApi'
import RobotDialog from '~/modules/quiz/components/RobotDialog'
import store from '~/store'

export default {
    name: 'WordSearchGame',
    components: {RobotDialog},
    props: {
        matrix: {
            type: Array,
            required: true,
        },
        words: {
            type: Array,
            required: true,
        },
        bottomText: {
            type: String,
            default: '',
        },
        robotMessage: {
            type: Array,
            default: () => {return []}
        }
    },
    data() {
        return {
            sugar_levels: LEVELS,
            debounceActiveCell: '',
            selectedFrom: null,
            selectedTo: null,
            dragging: false,
            foundWords: [],
            foundCells: [],
            showRobot: false,
        }
    },
    computed: {
        done() {
            return this.foundWords.length === this.words.length
        },
        selectedCells() {
            let cells = []
            if (this.selectedFrom && this.selectedTo) {
                if (this.selectedFrom.x === this.selectedTo.x) {
                    // horizontal direction (-)
                    let from = _.min([this.selectedFrom.y, this.selectedTo.y])
                    let to = _.max([this.selectedFrom.y, this.selectedTo.y])
                    for (let i = from; i <= to; i++) {
                        cells.push({x: this.selectedFrom.x, y: i})
                    }
                } else if (this.selectedFrom.y === this.selectedTo.y) {
                    // vertical direction (|)
                    let from = _.min([this.selectedFrom.x, this.selectedTo.x])
                    let to = _.max([this.selectedFrom.x, this.selectedTo.x])
                    for (let i = from; i <= to; i++) {
                        cells.push({x: i, y: this.selectedFrom.y})
                    }
                } else if (this.selectedFrom.x - this.selectedTo.x === this.selectedFrom.y - this.selectedTo.y) {
                    // right-down direction (\)
                    let x_from = _.min([this.selectedFrom.x, this.selectedTo.x])
                    let x_to = _.max([this.selectedFrom.x, this.selectedTo.x])
                    let y_from = _.min([this.selectedFrom.y, this.selectedTo.y])
                    for (let i = x_from; i <= x_to; i++) {
                        cells.push({x: i, y: y_from})
                        y_from++
                    }
                } else if (this.selectedFrom.x - this.selectedTo.x === this.selectedTo.y - this.selectedFrom.y) {
                    // right-up direction (/)
                    let x_from = _.min([this.selectedFrom.x, this.selectedTo.x])
                    let x_to = _.max([this.selectedFrom.x, this.selectedTo.x])
                    let y_to = _.max([this.selectedFrom.y, this.selectedTo.y])
                    for (let i = x_from; i <= x_to; i++) {
                        cells.push({x: i, y: y_to})
                        y_to--
                    }
                }
            }
            return cells
        }
    },
    watch: {
        debounceActiveCell: function () {
            this.debounceSetActiveCell()
        },
    },
    mounted() {
        this.debounceSetActiveCell = _.debounce(this.wordSelectUpdate, 100)
    },
    methods: {
        letterTileClasses(x, y) {
            if (_.find(this.selectedCells, {x:x, y:y})) {
                return 'selected'
            }
            if (this.done && !_.find(this.foundCells, {x:x, y:y})) {
                return 'done'
            }
        },
        wordSelectStart(event) {
            this.dragging = true
            const touchedElement = event.target.closest('div.cell')
            if (touchedElement && touchedElement.dataset && touchedElement.dataset.x) {
                const {x, y} = touchedElement.dataset
                this.selectedFrom = {
                    x: parseInt(x, 10),
                    y: parseInt(y, 10),
                }
                return true
            }
            return false
        },
        wordSelectStop(event) {
            this.dragging = false
            let selected = []
            this.selectedCells.forEach(coordinate => {
                selected.push(this.matrix[coordinate.y][coordinate.x])
            })

            if (this.selectedCells.length < 2) {
                this.selectedFrom = null
                this.selectedTo = null
                return
            }

            let word = _.find(this.words, ['value', selected.join('')])
            let x_start = this.selectedCells[0]?.x
            let y_start = this.selectedCells[0]?.y
            let x_end = this.selectedCells[this.selectedCells.length - 1].x
            let y_end = this.selectedCells[this.selectedCells.length - 1].y
            if (!word) {
                word = _.find(this.words, ['value', selected.reverse().join('')])
                x_end = this.selectedCells[0]?.x
                y_end = this.selectedCells[0]?.y
                x_start = this.selectedCells[this.selectedCells.length - 1].x
                y_start = this.selectedCells[this.selectedCells.length - 1].y
            }
            if (word) {
                this.selectedCells.forEach(coordinate => {
                    this.foundCells.push({x: coordinate.x, y:coordinate.y})
                })
                this.foundWords.push({
                    value: word.value,
                    sugar: word.sugar,
                    x_start: x_start,
                    y_start: y_start,
                    direction: this.wordDirection(x_start, y_start, x_end, y_end),
                    length: word.value.length
                })
                this.sugar_levels.find(o => o.val === word.sugar).show = true
            }
            this.selectedFrom = null
            this.selectedTo = null
        },
        wordSelectUpdate(event = null) {
            if (! this.dragging) return

            let x, y

            // console.log(event)
            if (event) {
                let touch = event
                if (event.type.indexOf('touch') === 0) {
                    touch = event.changedTouches.item(0)
                }

                const touchedElement = document.elementFromPoint(touch.clientX, touch.clientY).closest('div.cell')

                if (touchedElement && touchedElement.dataset && touchedElement.dataset.x) {
                    x = parseInt(touchedElement.dataset.x, 10)
                    y = parseInt(touchedElement.dataset.y, 10)
                }
            } else {
                [x, y] = this.debounceActiveCell.split('_')
            }
            this.selectedTo = {x: parseInt(x), y: parseInt(y)}
        },

        wordDirection(x_start, y_start, x_end, y_end) {
            if (x_start === x_end && y_start > y_end) {
                // up
                return 0
            } else if (x_start < x_end && y_start > y_end) {
                // up-right
                return 1
            } else if (x_start < x_end && y_start === y_end) {
                // right
                return 2
            } else if (x_start < x_end && y_start < y_end) {
                // down-right
                return 3
            } else if (x_start === x_end && y_start < y_end) {
                // down
                return 4
            } else if (x_start > x_end && y_start < y_end) {
                // down-left
                return 5
            } else if (x_start > x_end && y_start === y_end) {
                // left
                return 6
            } else if (x_start > x_end && y_start > y_end) {
                // up-left
                return 7
            }
        },
        wordLinesForTile(x, y) {
            return this.foundWords.filter(w => (w.x_start === x) && (w.y_start === y))
        },
        wordLineClasses(wordLine) {
            const classes = [
                'word-strike',
                'word-strike-direction-' + wordLine.direction,
                'word-strike-length-' + wordLine.length,
                'sugar_level_legend_' + wordLine.sugar
            ]
            // Odd directions are diagonal
            if (wordLine.direction % 2 === 1) {
                classes.push('word-strike-diagonal')
            }
            return classes
        },
        async clickDone() {
            const game_slug = this.$route.name.split('.')[1]
            const pantry = _.toArray(this.$config.pmoTypes).find(o => o.game_slug === game_slug).id
            await pmoApi.gameFinished(game_slug)
            const {data} = await pmoApi.categoryFinished({game_slug: game_slug})
            store.commit('auth/addFirstExperience', 'game_finished_' + game_slug)
            this.showRobot = true
            setTimeout(() => {
                if (data.canSeeSummary) {
                    this.$router.push({name: 'pmo.summary', params: {pantry}})
                } else {
                    this.$router.push({name: 'pmo'})
                }
            }, 6500)
            setTimeout(() => {
                document.querySelector('.word-search-game__button-wrap').scrollIntoView({ behavior: 'smooth' })
            },750)
        },
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
$local-green: #32cd99;
$local-light-green: #99e6cc;
$local-light-yellow: #ffe6a1;
$local-pink: #ffb6c1;
$local-light-pink: #ffdae1;
.word-search-game {
    .matrix-row {
        width: 100%;
        margin: 0;
        display:flex;
        flex-wrap:wrap;
        justify-content:space-around;
        position: relative;
        .matrix-cell {
            width: 10%;
            height: 10%;
            margin: 0;
            position: relative;
            &.selected {
                background-color: #ccc;
            }
            &.done {
                transition: transform 3s;
                transform: rotate(360deg) scale(.01, .01);
            }
            .cell {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                user-select: none;
                position: relative;
                z-index: 2011;
                padding: 100% 0 0;
                svg {
                    position: absolute;
                    left: 0;
                    top: 0;
                    line-height: 0;
                    width: 100%;
                    font-size: 0.7rem;
                }
            }
        }
    }
    i.sugar_level {
        &_1, &_2, &_3 {
            margin-right: 5px;
        }
    }
    .sugar_level_1 {
        color: $local-light-green;
    }
    .sugar_level_2 {
        color: $local-light-yellow;
    }
    .sugar_level_3 {
        color: $local-light-pink;
    }
    .sugar_level_legend {
        &_1, &_2, &_3 {
            margin: 3px;
            padding: 3px;
        }
    }
    .sugar_level_legend_1 {
        background-color: $local-green;
    }
    .sugar_level_legend_2 {
        background-color: $yellow;
    }
    .sugar_level_legend_3 {
        background-color: $local-pink;
    }
    .word-strike {
        height: 90%;
        width: 100%;
        position: absolute;
        z-index: 100;
        transform-origin: 0 0;
        opacity: .5;
        border-radius: 20px;

        &.word-strike-direction-0 { margin: 0; }
        &.word-strike-direction-1 { margin: -30% -35%; }
        &.word-strike-direction-2 { margin: -90% 0; }
        &.word-strike-direction-3 { margin: -130% 30%; }
        &.word-strike-direction-4 { margin: -100% 93%; }
        &.word-strike-direction-5 { margin: -60% 120%; }
        &.word-strike-direction-6 { margin: 0 100%; }
        &.word-strike-direction-7 { margin: 30% 65%; }

        @for $i from 0 to 8 {
            &.word-strike-direction-#{$i} {
                transform: rotate((($i * 45deg) - 90deg));
            }
        }
        @for $i from 0 to 20 {
            &:not(.word-strike-diagonal).word-strike-length-#{$i} { width: $i * 100%; }
            &.word-strike-diagonal.word-strike-length-#{$i} { width: $i * 100% * 1.414214; } // 1.414214 is a hypotenuse multiplier
        }
    }

    &__words {
        &-list {
            margin-bottom: 35px;
        }
        &-value {
            font-size: 13px;
            @media all and (min-width: 360px) {
                font-size: 14px;
            }
            @media all and (min-width: $xs) {
                font-size: 16px
            }
        }
    }
    &__matrix {
        margin-bottom: 20px;
    }

    &__text {
        margin-bottom: 15px;
    }
    &__button {
        &-wrap {
            padding: 20px 0;
            text-align: center;
        }
    }
    .sugar-legend {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        min-height: 25px;
        margin-bottom: 10px;
        &__item {
            margin: 0 5px 0 0;
            padding: 2px 5px;
            font-size: 14px;
            &.sugar_level_legend{
                &_1 {
                    background-color: $local-light-green;
                }
                &_2 {
                    background-color: $local-light-yellow;
                }
                &_3 {
                    background-color: $local-light-pink;
                }
            }
        }
    }
}
</style>
