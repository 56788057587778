import pmoApi from '~/modules/pmo/pmoApi'
import store from '~/store'

export function useDoneGame(route, router) {

    async function done() {
        const game_slug = route.name.split('.')[1]
        const pantry = _.toArray(window.config.pmoTypes).find(o => o.game_slug === game_slug).id
        await pmoApi.gameFinished(game_slug)
        const {data} = await pmoApi.categoryFinished({game_slug: game_slug})

        store.commit('auth/addFirstExperience', 'game_finished_' + game_slug)

        if (data.canSeeSummary) {
            router.push({name: 'pmo.summary', params: {pantry}})
        } else {
            await router.push({name: 'pmo'})
        }
    }

    return {
        done
    }
}
