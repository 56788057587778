<template>
    <header class="header">
        <div class="main-header">
            <div class="header__left-side">
                <div
                    v-if="isVisibleBack()"
                    :key="route.name"
                    class="header__menu"
                    @click="goBack"
                >
                    <i class="header__menu-icon fa-solid fa-arrow-left" />
                </div>
                <div
                    v-else-if="!isWizardPage"
                    class="header__menu"
                    @click="toggleMenu"
                >
                    <i class="header__menu-icon fa fa-bars " />
                </div>
            </div>
            <div class="header__center">
                <header-title />
            </div>
            <div class="header__right-side">
                <share-video
                    v-if="shareLink"
                    :video-link="shareLink"
                />
                <StarCount v-if="user.client_level && user.client_level.has_starts_points_system === true" :star-count="user.points" />
            </div>
            <template v-if="!isWizardPage">
                <div
                    class="header__user-menu"
                    :class="{'menu-open': openMenu}"
                >
                    <user-menu
                        @close="close"
                    />
                </div>
                <div
                    class="header__overlay"
                    :class="{'menu-open': openMenu}"
                    @click="toggleMenu"
                />
            </template>
        </div>
    </header>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import store from '~/store'
import bus from '~/includes/Event'
import UserMenu from '~/base/components/user-menu/UserMenu'
import HeaderTitle from '~/base/components/HeaderTitle'
import StarCount from '~/base/components/star-count/StarCount'
import ShareVideo from './ShareVideo'

const route = useRoute()
const router = useRouter()

const user = store.getters['auth/user']

const openMenu = ref(false)
const shareLink = ref('')

const isWizardPage = computed(() => _.includes(route.name, 'quiz.'))

onMounted(() => {
    bus.$on('set-share-link', setShareLink)
})

function toggleMenu() {
    openMenu.value = !openMenu.value
}

function close() {
    openMenu.value = false
}

function setShareLink(link) {
    shareLink.value = link
}

function isVisibleBack() {
    const visibleRoutes = [
        'learning-modules.quiz',
        'learning-modules.lesson'
    ]
    return visibleRoutes.includes(route.name)
}

function goBack() {
    router.back()
}
</script>
