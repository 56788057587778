<template>
    <user-intro
        :robot-messages-all="robotMessages"
        @finishUserIntro="goNext"
    />
    <section class="main">
        <header class="header">
            <div class="header__left-side">
                <div class="header__menu">
                    <i class="header__menu-icon fa fa-bars" />
                </div>
            </div>
            <div class="header__center">
                <h1 class="header__text">
                    Sugar & Health
                </h1>
            </div>
            <div class="header__right-side">
                <div class="header__menu">
                    <div
                        class="share-video"
                    >
                        <span class="share-video__icon">
                            <inline-svg
                                class="share-video__icon-svg"
                                src="/images/svg-icons/share-icon.svg"
                                fill="#ffffff"
                            />
                        </span>
                    </div>
                </div>
            </div>
        </header>
        <div class="main main--stretch-height">
            <div class="main-content main-content--no-offset">
                <div class="lesson">
                    <div class="lesson__video">
                        <div class="embedded-video">
                            <img
                                src="/images/preview-video.png"
                                alt=""
                            >
                        </div>
                    </div>
                    <div class="g-container">
                        <div class="lesson__btn-section">
                            <el-button
                                round
                                type="primary"
                                :disabled="true"
                                class="continue-button"
                            >
                                Complete Section
                            </el-button>
                        </div>
                        <div class="lesson__content">
                            <div class="accordion">
                                <el-collapse
                                    class="el-collapse--lesson"
                                >
                                    <el-collapse-item
                                        title="References"
                                        name="1"
                                    />
                                    <el-collapse-item
                                        title="Transcript"
                                        name="2"
                                    />
                                    <el-collapse-item
                                        title="Tips"
                                        name="3"
                                    />
                                </el-collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import InlineSvg from 'vue-inline-svg'
import UserIntro from './UserIntro'
import {useRoute, useRouter} from 'vue-router'

const router = useRouter()
const route = useRoute()

const props = defineProps({
    nextRoute: {
        type: Object,
        required: true,
    },
})

const robotMessages = [
    {
        robotPosition: 'bottom-left-screen-corner',
        robotImage: '/images/robots/robot_waving.svg',
        text: 'Choose to watch the <b>Video</b> or read the <b>Transcript.</b> References and Tips provide additional assistance.',
    },
]

function goNext() {
    router.push({name: props.nextRoute})
}
</script>

<style lang="scss" scoped>
.share-video {
    $block-name: &;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    &__icon {
        display: block;
        width: 19px;
        height: 19px;
        overflow: hidden;
        &-svg {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
</style>
