import Child from '~/base/components/Child'
import Index from './components/Index'
import store from '~/store'

export const REGISTER_STEP = 1

const beforeEnter = (to, from) => {
    if (! store.getters['auth/user'].coaching) {
        return { name: 'learning-modules' }
    }
}

const meta = {
    auth: true,
    layout: 'Profile',
    step: REGISTER_STEP
}

export default [
    {
        path: 'coaching',
        component: Child,
        beforeEnter,
        children: [
            {
                path: '',
                name: 'coaching',
                component: Index,
                meta,
            },
        ]
    },
]
