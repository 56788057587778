<template>
    <div class="reference-block">
        <a
            :href="reference.url"
            class="reference-block__title"
            target="_blank"
            aria-hidden="true"
        >
            <span class="reference-block__title-text">
                {{ reference.title }}
            </span>
            <span class="reference-block__title-icon">
                <i class="fa fa-external-link" />
            </span>
        </a>
        <div class="reference-block__description">
            {{ reference.description }}
        </div>
        <a
            :href="reference.url"
            class="reference-block__source"
            target="_blank"
        >
            {{ reference.domain }}
        </a>
    </div>
</template>

<script setup>
const name = 'Reference'
const props = defineProps({
    reference: {
        type: Object,
        required: true
    }
})
</script>
