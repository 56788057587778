<template>
    <el-container v-loading="loadingLogo" class="auth-page">
        <el-main>
            <div class="auth-page__top">
                <div class="auth-page__image-wrap">
                    <img
                        v-if="!loginPath && !loadingLogo"
                        class="auth-page__image"
                        src="/images/auth/seekingsimple-logo.svg"
                        alt=""
                    >
                    <img
                        v-else
                        class="auth-page__image companies_logo"
                        :src="loginPath"
                        alt=""
                    >
                </div>
            </div>
            <el-card>
                <login-form
                    :errors="authErrors"
                    :loading="loading"
                    @submit="onSubmit"
                />
            </el-card>
        </el-main>
    </el-container>
</template>

<script setup>
import LoginForm from './LoginForm'
import authApi from '../authApi'
import whiteLabelApi from '../whiteLabelApi'
import { onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import store from '~/store'
import bus from '~/includes/Event'
const route = useRoute()
const router = useRouter()

const authErrors = ref({})
const loading = ref(false)
const loadingLogo = ref(false)
const loginPath = ref(false)
onBeforeMount(() => {
    if (!route.query.client_level) {
        localStorage.removeItem('useClientLevel')
        removeIconLinks()
        addFavicon('./favicon.ico')
        addFaviconApple('./apple_touch_icon.png')
        return false
    }

    const localStorageClientLevelData =  JSON.parse(localStorage.getItem('useClientLevel'))
    if (localStorageClientLevelData?.name === route.query.client_level) {
        loginPath.value = localStorageClientLevelData.logo_path
        fetchWhiteList()
    } else {
        localStorage.removeItem('useClientLevel')
        loadingLogo.value = true
        fetchWhiteList()
    }
})

function removeIconLinks() {
    const faviconLinks = document.querySelectorAll('link[rel="icon"]');
    const faviconShortcutLinks = document.querySelectorAll('link[rel="shortcut icon"]');
    faviconLinks.forEach(link => {
        link.parentNode.removeChild(link);
    });
    faviconShortcutLinks.forEach(link => {
        link.parentNode.removeChild(link);
    });
}

function addFavicon(path, size = false) {
    if (path == null) return false;
    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = path;
    if (size) {
        link.sizes = size
    }
    document.getElementsByTagName('head')[0].appendChild(link);
}

function addFaviconApple(path) {
    if (path == null) return false;
    const link = document.createElement('link');
    link.rel = 'apple-touch-icon';
    link.href = path;
    document.getElementsByTagName('head')[0].appendChild(link);
}
function addFaviconSvg(path) {
    if (path == null) return false;
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/svg+xml';
    link.href = path;
    document.getElementsByTagName('head')[0].appendChild(link);
}

function fetchWhiteList() {

    const payload = {
        client_level_name: route.query.client_level
    }
    whiteLabelApi.getByClientLevelName(payload)
        .then(data => {
            loginPath.value = data.data?.logo_path
            localStorage.setItem('useClientLevel', JSON.stringify(data.data))

            removeIconLinks()
            addFavicon(data.data.favicon.favicon_path)
            addFavicon(data.data.favicon.favicon16x16, '16x16')
            addFavicon(data.data.favicon.favicon32x32, '32x32')
            addFaviconApple(data.data.favicon.apple_touch_icon)
            addFaviconSvg(data.data.favicon.favicon_svg)

            loadingLogo.value = false
        })
        .catch(() => {
            loadingLogo.value = false
        })
}

function onSubmit(loginData) {
    loading.value = true
    authApi.login(loginData).then(response => {
        store.dispatch('auth/saveToken', {
            token: response.data.token,
            remember: loginData.remember
        }).then(() => {
            // this.$router.push({ path: user.last_step?.url ?? '/my-items' })
            router.push({ name: 'Home' })
        })
    }).catch(error => {
        if (error.response?.status === 422) {
            authErrors.value = error.response.data.errors
        } else {
            console.error(error)
        }
    }).finally(() => loading.value = false)
}
</script>
