import { onBeforeMount, ref } from 'vue';
import whiteLabelApi from "~/modules/auth/whiteLabelApi";

export function faviconChanging() {
  onBeforeMount (() => {
    window.onload =  () => {
      const localStorageClientLevelData =  JSON.parse(localStorage.getItem('useClientLevel'))
      if (localStorageClientLevelData && localStorageClientLevelData.name) {
        fetchWhiteList(localStorageClientLevelData.name)
        removeIconLinks()
        addFavicon(localStorageClientLevelData.favicon.favicon_path, '32x32')
        addFavicon(localStorageClientLevelData.favicon.favicon16x16, '16x16')
        addFavicon(localStorageClientLevelData.favicon.favicon32x32, '32x32')
        addFaviconApple(localStorageClientLevelData.favicon.apple_touch_icon)
        addFaviconSvg(localStorageClientLevelData.favicon.favicon_svg)
      }
    }
  })

  function removeIconLinks() {
    const faviconLinks = document.querySelectorAll('link[rel="icon"]');
    const faviconShortcutLinks = document.querySelectorAll('link[rel="shortcut icon"]');
    faviconLinks.forEach(link => {
      link.parentNode.removeChild(link);
    });
    faviconShortcutLinks.forEach(link => {
      link.parentNode.removeChild(link);
    });
  }

  function fetchWhiteList(name) {

    const payload = {
      client_level_name: name
    }
    whiteLabelApi.getByClientLevelName(payload)
      .then(data => {
        localStorage.setItem('useClientLevel', JSON.stringify(data.data))
        removeIconLinks()
        addFavicon(data.data.favicon.favicon_path)
        addFavicon(data.data.favicon.favicon16x16, '16x16')
        addFavicon(data.data.favicon.favicon32x32, '32x32')
        addFaviconApple(data.data.favicon.apple_touch_icon)
        addFaviconSvg(data.data.favicon.favicon_svg)

      })
      .catch((err) => {
        console.log(err.response)
        removeIconLinks()
        addFavicon('./favicon.ico')
        addFaviconApple('./apple_touch_icon.png')
      })
  }

  function addFaviconApple(path) {
    if (!path) return false
    const link = document.createElement('link');
    link.rel = 'apple-touch-icon';
    link.href = path;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  function addFavicon(path, size = false) {
    if (!path) return false
    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = path;
    if (size) {
      link.sizes = size
    }
    document.getElementsByTagName('head')[0].appendChild(link);
  }

  function addFaviconSvg(path) {
    if (!path) return false
    const link = document.createElement('link');
    link.rel = 'icon';
    link.type = 'image/svg+xml';
    link.href = path;
    document.getElementsByTagName('head')[0].appendChild(link);
  }

}
