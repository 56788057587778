import ProfileIndex from './components/Index'
import ProfileInfo from './components/Info'
import ResetPassword from './components/ResetPassword'
import SugarSubstitutes from './components/SugarSubstitutes'
import ProfileFdm from './components/Fdm'
import ChildUsers from './components/ChildUsers'
import NotificationSettings from './components/NotificationSettings'
import GroceryStores from './components/grocery-stores/GroceryStores'
import LoyaltyProgram from './components/grocery-stores/LoyaltyProgram'
import ProfileHousehold from './components/profile-household/ProfileHousehold'
import Child from '~/base/components/Child'
import MeasurementsSettings from './components/MeasurementsSettings'

const meta = {
    auth: true,
    layout: 'Profile',
    step: 0.1
}

export default [
    {
        path: 'profile',
        component: Child,
        children: [
            {
                path: '',
                name: 'profile',
                component: ProfileIndex,
                meta,
            },
            {
                path: 'info',
                name: 'profile.info',
                component: ProfileInfo,
                meta,
            },
            {
                path: 'reset-password',
                name: 'profile.reset-password',
                component: ResetPassword,
                meta,
            },
            {
                path: 'sugar-substitutes',
                name: 'profile.sugar-substitutes',
                component: SugarSubstitutes,
                meta,
            },
            {
                path: 'fdm',
                name: 'profile.fdm',
                component: ProfileFdm,
                meta,
            },
            {
                path: 'child-users',
                name: 'profile.child-users',
                component: ChildUsers,
                meta,
            },
            {
                path: 'notification-settings',
                name: 'profile.notification-settings',
                component: NotificationSettings,
                meta,
            },
            {
                path: 'your-household',
                name: 'profile.your-household',
                component: ProfileHousehold,
                meta,
            },
            {
                path: 'grocery-stores',
                name: 'profile.grocery-stores',
                component: GroceryStores,
                meta,
            },
            {
                path: 'loyalty-program',
                name: 'profile.loyalty-program',
                component: LoyaltyProgram,
                meta,
            },
            {
                path: 'measurements-settings',
                name: 'profile.measurements-settings',
                component: MeasurementsSettings,
                meta,
            },
        ]
    },
]
