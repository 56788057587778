<template>
    <div class="quiz-question__confirm">
        <blockquote
            class="quiz-question__quote default-text"
            v-html="question.additional_info.quote"
        />
        <div class="quiz-question__button-section quiz-question__button-section--fixed-bottom">
            <div class="g-container">
                <div class="quiz-question__button-wrap">
                    <div class="quiz-question__button-col">
                        <el-button
                            type="primary"
                            round
                            class="w-100 continue-button"
                            @click="$emit('set', question._id, 'Nope')"
                        >
                            NOPE
                        </el-button>
                    </div>
                    <div class="quiz-question__button-col">
                        <el-button
                            type="primary"
                            round
                            class="w-100 continue-button"
                            @click="$emit('set', question._id, 'Yes')"
                        >
                            YES, IT'S ME
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormConfirm',
    props: {
        question: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {}
    },
    mounted() {
        this.$store.commit('quiz/hideContinueBtn')
    },
    unmounted() {
        this.$store.commit('quiz/riseContinueBtn')
    },
}
</script>
