<template>
    <div class="nav-menu">
        <div class="nav-menu__list">
            <div
                v-for="item in items"
                :key="item.route.name"
                class="nav-menu__item"
                :class="{
                    'active': routeName === item.route.name,
                    'locked': item.lock,
                }"
                @click="goToTab(item)"
            >
                <span class="nav-menu__label">
                    <span class="nav-menu__label-icon">
                        <i
                            v-if="item.lock"
                            class="nav-menu__label-icon-i fa-solid fa-lock"
                        />
                        <inline-svg
                            v-else
                            class="nav-menu__label-icon-i"
                            :src="item.icon"
                        />
                    </span>
                    <span class="nav-menu__label-text">
                        <span class="nav-menu__label-badge">
                        <el-badge
                            v-if="item.badge && item.badge > 0"
                            :value="item.badge"
                            class="item"
                        >
                        </el-badge>
                    </span>
                        {{ item.title }}
                    </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
import InlineSvg from 'vue-inline-svg'
import {computed, ref} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import store from '~/store'
import {PMO_STEP} from '~/modules/pmo/routes'
import bus from '~/includes/Event'

const router = useRouter()
const route = useRoute()

const user = computed(() => store.getters['auth/user'])
const countUnCompleteLM = computed(() => store.getters['learning-modules/countUnCompleted'])

const routeName = ref(route.name)

const selectedTab = ref(routeName.value)

bus.$on('set-navbar-active-tab', (tab) => {
    routeName.value = tab
})

const allows_my_pantry = computed(() => user.value?.is_fdm && user.value?.last_step.level >= PMO_STEP)

const items = computed(() => [
    {
        title: 'Learn',
        icon: '/images/svg-icons/education-icon.svg',
        route: {name: 'learning-modules'},
        lock: false,
        badge: countUnCompleteLM.value
    },
    {
        title: 'My pantry',
        icon: '/images/svg-icons/pantry-icon.svg',
        route: {name: 'pmo'},
        lock: !allows_my_pantry.value
    },
    {
        title: 'My items',
        icon: '/images/svg-icons/basket-icon.svg',
        route: {name: 'my-items.shop'},
        lock: !user.value?.canSeeMyList
    },
])

const goToTab = item => {
    if (item.lock) return
    router.push(item.route)
}

</script>

<style lang="scss">
@import "resources/sass/_variables.scss";
.nav-menu {
    $block-name: &;
    position: relative;
    z-index: 1;
    background-color: $blue;
    box-shadow: 0 3px 5px rgba(0,0,0, 30%);
    &__list {
        display: flex;
        justify-content: center;
        max-width: 800px;
        margin: 0 auto;
    }
    &__item {
        width: calc(100% / 3);
        cursor: pointer;
        border-bottom: 2px solid transparent;
        &.active {
            border-bottom-color: $white;
            #{$block-name} {
                &__label {
                    color: $white;
                    &-icon-i path {
                        fill: $white;
                    }
                }
            }
        }
        &.locked {
            cursor: auto;
            #{$block-name} {
                &__label {
                    flex-direction: row;
                    justify-content: center;
                    align-items: flex-end;
                    height: 100%;
                    &-icon {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 18px;
                        height: 18px;
                        margin: 0 5px 0 0;
                        &-i {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
    &__label {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 12px 10px 14px;
        color: $light-gray;
        &-icon {
            width: 20px;
            height: 20px;
            margin: 0 0 5px 0;
            overflow: hidden;
            &-i {
                path {
                    fill: $light-gray;
                }
            }
        }
        &-text {
            font-size: 14px;
            line-height: 1.15;
            font-weight: 500;
            text-transform: uppercase;
            position: relative;
        }

        &-badge {
            position: absolute;
            top: -30px;
            right: -15px;
        }
    }
}
</style>
