<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="profile-info">
                    <el-form
                        ref="resetPasswordForm"
                        :model="form"
                        :rules="rules"
                        class="profile-info__form"
                        @submit.prevent="passwordReset"
                    >
                        <div class="profile-info__form-section">
                            <div class="profile-info__form-section-title">
                                Reset Password
                            </div>
                            <el-form-item
                                prop="password"
                                class="profile-info__form-section-item"
                                :error="formErrors.get('password')"
                            >
                                <label class="el-form-item__label--floating-label">
                                    <el-input
                                        v-model="form.password"
                                        type="password"
                                        show-password
                                        placeholder=" "
                                        class="el-input--floating-label"
                                    >
                                        <template #prefix>
                                            New Password
                                        </template>
                                    </el-input>
                                </label>
                            </el-form-item>
                            <el-form-item
                                prop="password"
                                class="profile-info__form-section-item"
                                :error="formErrors.get('password_confirmation')"
                            >
                                <label class="el-form-item__label--floating-label">
                                    <el-input
                                        v-model="form.password_confirmation"
                                        type="password"
                                        show-password
                                        placeholder=" "
                                        class="el-input--floating-label"
                                    >
                                        <template #prefix>
                                            Confirm Password
                                        </template>
                                    </el-input>
                                </label>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
                <div v-if="showSuccessMessage">
                    Your password has been changed successfully.
                </div>
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__buttons-wrap">
                    <div class="main-content__button-col">
                        <el-button
                            type="primary"
                            round
                            class="continue-button"
                            @click.prevent="passwordReset"
                        >
                            Save Password
                        </el-button>
                    </div>
                    <div class="main-content__button-col">
                        <el-button
                            type=""
                            round
                            class="continue-button"
                            @click="onCancel"
                        >
                            Cancel
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed, onMounted} from 'vue'
import axios from 'axios'
import {useRoute, useRouter} from 'vue-router'
import store from '~/store'
import bus from '~/includes/Event'
import {ElMessage} from 'element-plus'
import {Errors} from '~/includes/Errors'

const router = useRouter()
const route = useRoute()
const user = computed(() => store.getters['auth/user'])

onMounted(() => { bus.$emit('set-title', 'Reset Password') })

const form = ref({
    password: '',
    password_confirmation: '',
})

const formErrors = ref(new Errors())
const showSuccessMessage = ref(false)

function passwordReset() {
    formErrors.value.record([])
    axios.post('profile/reset-pass', form.value).then(response => {
        // ElMessage.success(response.data.message)

        form.value.password = ''
        form.value.password_confirmation = ''
        showSuccessMessage.value = true
    }).catch(error => {
        if (error.response.data.errors) {
            formErrors.value.record(error.response.data.errors)
        }
    })
}

function onCancel() {
    router.push('/profile')
}
</script>
