<template>
    <header class="header">
        <div class="main-header">
            <div class="header__left-side">
                <div
                    v-if="isVisible()"
                    :key="route.name"
                    class="header__menu"
                    @click="goBack"
                >
                    <i class="header__menu-icon fa-solid fa-arrow-left" />
                </div>
            </div>
            <div class="header__center">
                <header-title />
            </div>
            <div class="header__right-side">
                <StarCount
                    v-if="showPoints"
                    :star-count="user && user.points"
                />
            </div>
        </div>
    </header>
</template>

<script setup>
import HeaderTitle from '~/base/components/HeaderTitle'
import StarCount from '~/base/components/star-count/StarCount'
import {useRoute, useRouter} from 'vue-router'
import store from '~/store'
import {WIZARD_STEP} from '~/modules/quiz/routes'

const route = useRoute()
const router = useRouter()

const user = store.getters['auth/user']

const showPoints = user?.last_step.level > WIZARD_STEP && !route.name.includes('intro') && user.client_level && user.client_level.has_starts_points_system === true

function isVisible() {
    const hiddenRoutes = [
        'quiz.index',
        'quiz.welcome',
        'learning-modules'
    ]
    return !hiddenRoutes.includes(route.name)
}

function goBack() {
    if (route.name === 'profile') {
        router.push({name: 'my-items.shop'})
    } else {
        router.back()
    }
}
</script>
