<template>
    <div>
        <h1>Register</h1>
        <register-form
            :loading="loading"
            :errors="authErrors"
            @submit="onSubmit"
        />
    </div>
</template>

<script>
import authApi from '../authApi'
import RegisterForm from './RegisterForm'

export default {
    name: 'Register',
    components: {RegisterForm},
    data() {
        return {
            loading: false,
            authErrors: {},
        }
    },
    methods: {
        onSubmit(signUpFormData) {
            authApi.register(signUpFormData).then(response => {
                // this.$message.success('You have been registered')
                this.$store.dispatch('auth/saveToken', {
                    token: response.data.token,
                    remember: false
                }).then(() => {
                    this.$router.push({name: 'Home'})
                })
            }).catch(error => {
                if (error.response.status === 422)
                    this.authErrors = error.response.data.errors
            }).finally(() => this.loading = false)
        },
    }
}
</script>
