<template>
    <div
        v-if="isOriginals"
        class="alternative-products-list"
    >
        <div
            class="alternative-products-list__title"
        >
            {{ products.length }} originally scanned item{{ products.length > 1 ? 's' : '' }}
        </div>
        <div v-if="products.length && canEdit && !products[0].status">
            <div
                class="alternative-products-list__list"
            >
                <a
                    class="alternative-products-list__item"
                    :href="productLink(products[0])"
                >
                    <span class="alternative-products-list__item-image-wrap">
                        <img
                            :src="products[0].image"
                            class="alternative-products-list__item-image"
                            alt=""
                        >
                    </span>
                    <span class="alternative-products-list__item-brand">
                        {{ products[0].brand }}
                    </span>
                </a>
                <div class="total_info">
                    <div class="total_info__block">
                        <span class="total_info__label">Frequency:</span>
                        <div class="total_info__data">
                            {{ frequencyInfo }}
                        </div>
                    </div>
                    <div class="total_info__block">
                        <span class="total_info__label">Quantity:</span>
                        <div class="total_info__data">
                            {{ quantityInfo }}
                        </div>
                    </div>
                </div>
                <div
                    class="adjust_button"
                >
                    <el-button
                        round
                        type="primary"
                        class="w-100"
                        @click.once="onAdjust"
                    >
                        Adjust
                    </el-button>
                </div>
            </div>
            <div class="alternative-products-list__list">
                <a
                    v-for="product in originalProducts"
                    :key="product.upc"
                    class="alternative-products-list__item"
                    :href="productLink(product)"
                >
                    {{ index }}
                    <span class="alternative-products-list__item-image-wrap">
                        <img
                            :src="product.image"
                            class="alternative-products-list__item-image"
                            alt=""
                        >
                    </span>
                    <span class="alternative-products-list__item-brand">
                        {{ product.brand }}
                    </span>
                </a>
            </div>
        </div>
        <div v-else>
            <div class="alternative-products-list__list">
                <a
                    v-for="product in props.products"
                    :key="product.upc"
                    class="alternative-products-list__item"
                    :href="productLink(product)"
                >
                    {{ index }}
                    <span class="alternative-products-list__item-image-wrap">
                        <img
                            :src="product.image"
                            class="alternative-products-list__item-image"
                            alt=""
                        >
                    </span>
                    <span class="alternative-products-list__item-brand">
                        {{ product.brand }}
                    </span>
                </a>
            </div>
        </div>
    </div>
    <div
        v-else
        class="alternative-products-list"
    >
        <div class="alternative-products-list__title">
            {{ products.length }} item alternative{{ products.length > 1 ? 's' : '' }} saved
        </div>
        <div class="alternative-products-list__list">
            <a
                v-for="product in products"
                :key="product.upc"
                class="alternative-products-list__item"
                :href="productLink(product)"
            >
                <span class="alternative-products-list__item-image-wrap">
                    <img
                        :src="product.image"
                        class="alternative-products-list__item-image"
                        alt=""
                    >
                </span>
                <span class="alternative-products-list__item-brand">
                    {{ product.brand }}
                </span>
            </a>
        </div>
    </div>
</template>

<script setup>
import { useRouter, useRoute } from 'vue-router'
import { computed, ref } from 'vue'
import UserMeasurementUnitsConverter from '~/plugins/UserMeasurementUnitsConverter'
import _ from 'lodash'

const router = useRouter()
const route = useRoute()

const name = 'AlternativeProducts'
const pmoEditProducts = localStorage.getItem('pmo_edit_products')

const props = defineProps({
    products: {
        type: Array,
        default: () => {
            return {}
        }
    },
    isOriginals: {
        type: Boolean,
        default: false
    },
    slugCategory: {
        type: String,
        default: ''
    }
})

const canEdit = pmoEditProducts === props.slugCategory
const originalProducts = _.clone(props.products).slice(1)

const frequencyInfo = computed(() => {
    const times = props.products[0].amount_values.frequency < 2 ? 'Time' : 'Times'
    return `${props.products[0].amount_values.frequency} ${times} Per ${props.products[0].amount_values.frequencyUnit}`
})

const quantityInfo = computed(() => {
    const totalQuantity = _.round(props.products[0].amount_values.frequency * props.products[0].amount_values.amount, 2)
    return `${totalQuantity} ${props.products[0].amount_values.amountUnit} Total Per ${props.products[0].amount_values.frequencyUnit}`
})

function getToUom(toUom) {
    return new UserMeasurementUnitsConverter().getUnits(toUom)
}

function convertSizeTotal(value, fromUom, toUom) {
    return new UserMeasurementUnitsConverter().convertSizeTotal(value, fromUom, getToUom(toUom))
}

function productLink(product) {
    return `/products/${product.upc}`
}

const onAdjust = () => {
    router.push({
        name: 'product.frequency',
        params: { upc: props.products[0].gtinFormats.gtin14, pmo_category: props.slugCategory },
        query: { goto: props.slugCategory, pantry: route.params.pantry || 1, edit: true }
    })
        .catch(failure => {
            console.log(failure)
        })
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
    .alternative-products-list {
        .adjust_button {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            margin-left: 20px;
        }
        .total_info {
            align-content: space-evenly;
            display: grid;
            margin-bottom: 20px;
            margin-left: 20px;

            &__block {
                display: flex;
                flex-wrap: wrap;
            }

            &__label {
                font-size: 12px;
                margin-right: 5px;
                color: $gray;
                display: block;
            }

            &__data {
                font-weight: bold;
                font-size: 15px;
            }
        }

        &__title {
            margin-bottom: 10px;
            font-weight: 600;
            color: $dark;
        }
        &__list {
            display: flex;
            overflow-x: auto;
            padding-bottom: 15px;
            &::-webkit-scrollbar {
                display: block;
                height: 8px;
            }
            &::-webkit-scrollbar-thumb {
                height: 8px;
                border-radius: 4px;
                background-color: $gray;
                &:hover {
                    background-color: lighten($gray, 10%);
                }
            }
        }
        &__item {
            text-decoration: none;
            &:not(:last-child) {
                margin-right: 5px;
            }
            &-image {
                max-width: 100%;
                max-height: 100%;
                &-wrap {
                    flex-shrink: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 80px;
                    height: 80px;
                    margin-bottom: 5px;
                    border: 2px solid $light-gray;
                    border-radius: 4px;
                }
            }
            &-brand {
                display: block;
                max-width: 80px;
                overflow: hidden;
                font-size: 12px;
                line-height: 1.3;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: $gray;
            }
        }
    }
</style>
