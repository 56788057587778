import axios from 'axios'
import store from '~/store'

const API_ENDPOINT = 'user_invite'

export default {

    childUsers() {
        return axios.get(API_ENDPOINT)
    },

    reSendInvite(params) {
        return axios.post(API_ENDPOINT + '/re-send-invite', params)
    },

    setMeFdm() {
        return axios.post(API_ENDPOINT + '/set-me-fdm')
    },

    setFdm(params) {
        return axios.post(API_ENDPOINT + '/set-fdm', params)
    },

    myItemsLIst(params) {
        return axios.post(API_ENDPOINT + '/my-items-lists', params)
    },

    pmoList(params) {
        return axios.post(API_ENDPOINT + '/pmo-lists', params)
    },
}
