<template>
    <NotFoundSection
        :title="title"
        :canAddProduct="canAddProduct"
        :text="text"
        :pmo-page="true"
    />
</template>

<script setup>
import {ref} from 'vue'
import NotFoundSection from '~/base/components/not-found-section/NotFoundSection'
import {useRoute} from 'vue-router'
import pmoApi from '~/modules/pmo/pmoApi'

const route = useRoute()

const pmoCategory = ref(null)
const pmoCategories = ref(null)
const canAddProduct = ref(false)

if (route.params.pmo_category) {
    canAddProduct.value = false
    pmoApi.productPmoCategoriesByGtin(route.params.upc).then(response => {
        pmoCategory.value = response.data&&response.data[0]

        pmoCategories.value = response.data
        let responseData = response.data.nielsen

        // title.value = 'Please scan or search for this product in '
        //     + pmoCategory.value.type_name + ', under the '
        //     + pmoCategory.value.title + 'sub-category'

        /*
            OPTION 1 - One to one mapping (the Nielsen category is mapped to a single PMO category):
            "Please scan this product in the XXXX Pantry Makeover Category for better results"

            OPTION 2 - one to many (the Nielsen category is mapped to multiple PMO categories):
            "Please scan this product in the XXXX  or XXXX Pantry Makeover Category, whichever best fits the description for that product, for better results"
        */

        let message
        if (responseData.length === 0) {
            canAddProduct.value = true
        } else if (responseData.length === 1) {
            // Option 1: One-to-one mapping
            const pmoCategory = responseData[0]
            message = `Please scan this product in the <strong>${pmoCategory.type_name}: ${pmoCategory.title}</strong> Pantry Makeover Category for better results.`
        } else {
            // Option 2: One-to-many mapping
            const categoryNames = responseData.map(c => '<strong>' + c.type_name + ': ' + c.title + '</strong>').join(' or ')
            message = `Please scan this product in the ${categoryNames} Pantry Makeover Categories, whichever best fits the description for that product, for better results.`
        }

        title.value = message
    })
}

const title = ref(
    route.params.pmo_category
        ? 'Please look at other Pantry Makeover categories for searching for this specific product.'
        : 'Sorry we can’t find any item with that barcode.')
const text = 'This product could not be found in the SeekingSimple  platform. You may Skip this product or Add this product to SeekingSimple.'
</script>
