<template>
    <div class="user-menu">
        <div class="user-menu__inner">
            <div
                class="user-menu__close-btn"
                @click="closeMenu"
            >
                <i class="user-menu__close-btn-i fa-solid fa-xmark" />
            </div>
            <div
                v-if="check"
                class="user-menu__user-info"
            >
                <div class="user-menu__user-avatar">
                    <img
                        :src="avatarUrl"
                        alt="avatar"
                        @error="imageLoadError"
                    >
                </div>
                <div class="user-menu__user-name">
                    {{ user.first_name }} {{ user.last_name }}
                </div>
                <div
                    v-if="user.fdm"
                    class="user-menu__user-fdm"
                >
                    Food Decision Maker
                </div>
                <div class="user-menu__user-company">
                    {{ getClientLevel() }}
                </div>
            </div>
            <div class="user-menu__menu">
                <div class="user-menu__menu-section">
                    <div class="user-menu__menu-item">
                        <template v-if="allows_my_pantry">
                            <router-link
                                :to="{ name: 'pmo' }"
                                class="user-menu__link"
                            >
                                <span class="user-menu__link-icon">
                                    <inline-svg
                                        class="user-menu__link-icon-i"
                                        src="/images/svg-icons/pantry-icon.svg"
                                    />
                                </span>
                                Pantry Makeover
                            </router-link>
                        </template>
                        <template v-if="check">
                            <router-link
                                :to="{ name: 'notifications' }"
                                class="user-menu__link"
                            >
                               <span class="user-menu__link-icon">
                                    <el-badge
                                        v-if="countUnreadMessages > 0"
                                        :value="countUnreadMessages"
                                        class="item"
                                    >
                                        <inline-svg
                                            class="user-menu__link-icon-i"
                                            src="/images/svg-icons/notification-icon.svg"
                                        />
                                   </el-badge>
                                   <inline-svg
                                       v-else
                                       class="user-menu__link-icon-i"
                                       src="/images/svg-icons/notification-icon.svg"
                                   />
                               </span>
                                Notifications
                            </router-link>
                        </template>
                        <template v-if="!allows_my_pantry && allow_my_items">
                            <router-link
                                :to="{ name: 'my-items.shop' }"
                                class="user-menu__link"
                            >
                                <span class="user-menu__link-icon">
                                    <inline-svg
                                        class="user-menu__link-icon-i"
                                        src="/images/svg-icons/basket-icon.svg"
                                    />
                                </span>
                                My items
                            </router-link>
                        </template>
                        <template v-if="!allows_my_pantry && !allow_my_items">
                            <router-link
                                :to="{ name: 'learning-modules' }"
                                class="user-menu__link"
                            >
                                <span class="user-menu__link-icon">
                                    <inline-svg
                                        class="user-menu__link-icon-i"
                                        src="/images/svg-icons/education-icon.svg"
                                    />
                                </span>
                                Learning modules
                            </router-link>
                        </template>
                    </div>
                    <div
                        v-if="check"
                        class="user-menu__menu-item"
                    >
                        <router-link
                            :to="{ name: 'profile' }"
                            class="user-menu__link"
                        >
                            <span class="user-menu__link-icon">
                                <inline-svg
                                    class="user-menu__link-icon-i"
                                    src="/images/svg-icons/gear-icon.svg"
                                />
                            </span>
                            Profile
                        </router-link>
                    </div>
                    <div class="user-menu__menu-item">
                        <router-link
                            :to="{ name: 'static-pages.terms-of-use' }"
                            class="user-menu__link"
                        >
                            <span class="user-menu__link-icon">
                                <inline-svg
                                    class="user-menu__link-icon-i"
                                    src="/images/svg-icons/translate-icon.svg"
                                />
                            </span>
                            Terms of Use
                        </router-link>
                    </div>
                    <div class="user-menu__menu-item">
                        <router-link
                            :to="{ name: 'static-pages.privacy-policy' }"
                            class="user-menu__link"
                        >
                            <span class="user-menu__link-icon">
                                <inline-svg
                                    class="user-menu__link-icon-i"
                                    src="/images/svg-icons/info-second-icon.svg"
                                />
                            </span>
                            Privacy Policy
                        </router-link>
                    </div>
                    <div class="user-menu__menu-item">
                        <router-link
                            :to="{ name: 'static-pages.help-support' }"
                            class="user-menu__link"
                        >
                            <span class="user-menu__link-icon">
                                <inline-svg
                                    class="user-menu__link-icon-i"
                                    src="/images/svg-icons/info-icon.svg"
                                />
                            </span>
                            Feedback & Support
                        </router-link>
                    </div>
                    <div class="user-menu__menu-item">
                        <router-link
                            :to="{ name: 'static-pages.faq' }"
                            class="user-menu__link"
                        >
                            <span class="user-menu__link-icon">
                                <inline-svg
                                    class="user-menu__link-icon-i"
                                    src="/images/svg-icons/lamp-icon.svg"
                                />
                            </span>
                            FAQ
                        </router-link>
                    </div>
                    <div
                        v-if="user.last_step?.level >= MY_ITEM_STEP"
                        class="user-menu__menu-item"
                    >
                        <router-link
                            :to="{ name: 'aspiration.check-in' }"
                            class="user-menu__link"
                        >
                            <span class="user-menu__link-icon">
                                <inline-svg
                                    class="user-menu__link-icon-i"
                                    src="/images/svg-icons/info-second-icon.svg"
                                />
                            </span>
                            Aspiration Check-In
                        </router-link>
                    </div>
                    <div
                        v-if="showPoints"
                        class="user-menu__menu-item"
                    >
                        <router-link
                            :to="{ name: 'static-pages.points-progression' }"
                            class="user-menu__link"
                        >
                            <span class="user-menu__link-icon">
                                <inline-svg
                                    class="user-menu__link-icon-i"
                                    src="/images/svg-icons/star-icon.svg"
                                />
                            </span>
                            Points & Progression
                        </router-link>
                    </div>
                    <div
                        v-if="user.coaching"
                        class="user-menu__menu-item"
                    >
                        <router-link
                            :to="{ name: 'coaching' }"
                            class="user-menu__link"
                        >
                            <span class="user-menu__link-icon">
                                <inline-svg
                                    class="user-menu__link-icon-i"
                                    src="/images/svg-icons/people-icon.svg"
                                />
                            </span>
                            Coaching
                        </router-link>
                    </div>
                    <div
                        v-if="check"
                        class="user-menu__menu-item"
                    >
                        <div
                            class="user-menu__menu-item"
                        >
                            <router-link
                                :to="{ name: 'sugar-reduction-report' }"
                                class="user-menu__link"
                            >
                                <span class="user-menu__link-icon">
                                    <inline-svg
                                        class="user-menu__link-icon-i"
                                        src="/images/svg-icons/chart-histogram-icon.svg"
                                    />
                                </span>
                                Sugar Report
                            </router-link>
                        </div>
                    </div>
                    <div
                        v-if="check"
                        class="user-menu__menu-item"
                    >
                        <div
                            class="user-menu__menu-item"
                        >
                            <router-link
                                :to="{ name: 'net-carbs-reduction-report' }"
                                class="user-menu__link"
                            >
                                <span class="user-menu__link-icon">
                                    <inline-svg
                                        class="user-menu__link-icon-i"
                                        src="/images/svg-icons/chart-histogram-icon.svg"
                                    />
                                </span>
                                Net Carbs Report
                            </router-link>
                        </div>
                    </div>
                    <div
                        v-if="check"
                        class="user-menu__menu-item"
                    >
                        <div
                            class="user-menu__link"
                            @click="logout"
                        >
                            <span class="user-menu__link-icon">
                                <inline-svg
                                    class="user-menu__link-icon-i"
                                    src="/images/svg-icons/logout-icon.svg"
                                />
                            </span>
                            Log out
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import InlineSvg from 'vue-inline-svg'
import store from '~/store'
import bus from '~/includes/Event'
import { useRouter, useRoute } from 'vue-router'
import {computed, ref} from 'vue'
import {MY_ITEM_STEP} from '~/router'
import {PMO_STEP} from '~/modules/pmo/routes'

const route = useRoute()
const router = useRouter()

const emit = defineEmits()

const user = computed(() => store.getters['auth/user'])
const check = computed(() => store.getters['auth/check'])
const avatarUrl = computed(() => user.value.photo_url || '/images/ava-default.svg')
const showPoints = check.value && user.value.client_level && user.value.client_level.has_starts_points_system === true
const imageLoadError = (e) => {
    e.target.src = '/images/ava-default.svg'
}

function closeMenu() {
    emit('close')
}

async function logout() {
    bus.$emit('logout')
    await store.dispatch('auth/logout')
    await router.push({name: 'Home'})
}

const allows_my_pantry = computed(() => user.value?.is_fdm && user.value?.last_step.level >= PMO_STEP)
const allow_my_items = computed(() => user.value?.canSeeMyList && user.value?.last_step.level >= PMO_STEP)
const countUnreadMessages = computed(() =>  store.getters['base/count_unread_notifications'])

function getClientLevel() {
    const clientLevel = user.value?.client_level;
    const clientLevelParents = user.value?.client_level_parents;

    let cl = clientLevel?.name || '';

    if (clientLevel && clientLevel.level !== '1' && clientLevelParents) {
        const parents = clientLevelParents
            .filter(parent => parent.level !== '0')
            .sort((a, b) => a.level - b.level);

        if (parents.length > 0) {
            const parentsNames = parents.map(parent => parent.name);
            cl = `${parentsNames.join(' > ')} > ${cl}`;
        }
    }

    return cl;
}</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.user-menu__link-icon {
    overflow: unset !important;
}

.user-menu {
    $block-name: &;
    background-color: $white;
    &__close-btn {
        position: absolute;
        z-index: 10;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        cursor: pointer;
        &-i {
            font-size: 18px;
            color: $dark;
            transition: 0.2s color ease-in-out;
        }
        &:hover {
            #{$block-name}__close-btn-i {
                color: $green;
            }
        }
    }
    &__user {
        &-info {
            border-bottom: 1px solid #e0e0e0;
            padding: 40px 15px 12px;
        }
        &-avatar {
            width: 48px;
            height: 48px;
            margin-bottom: 20px;
            border-radius: 50%;
            overflow: hidden;
            &-i {
                max-width: 100%;
                max-height: 100%;
            }
        }
        &-name {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.5;
            letter-spacing: 0.15px;
            color: $dark;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            word-wrap: break-word;
        }
        &-company {
            font-size: 15px;
            font-weight: 400;
            line-height: 1.3;
            letter-spacing: normal;
            color: $dark;
        }
    }

    &__menu {
        height: calc(100vh - 215px);
        overflow-y: auto;
        &-section {
            margin: 0 8px;
            padding: 15px 0 15px;
            &:not(:last-child) {
                border-bottom: 1px solid $light-gray;
            }
        }
        &-item {
            &:not(:last-child) {
                margin-bottom: 5px;
            }
            &-label {
                padding-left: 10px;
                color: $dark;
            }
        }
    }
    &__link {
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 10px;
        font-size: 15px;
        text-decoration: none;
        color: $dark;
        cursor: pointer;
        transition: 0.2s background-color ease-in-out, 0.2s font-weight ease-in-out, 0.2s color ease-in-out;
        &.router-link-exact-active {
            font-weight: bold;
        }
        &:hover {
            font-weight: 600;
            color: $indigo;
            background-color: $light-indigo;
            #{$block-name}__link-icon-i {
                fill: $indigo;
            }
        }
        &-icon {
            width: 20px;
            height: 20px;
            overflow: hidden;
            margin-right: 33px;
            &-i {
                max-width: 100%;
                max-height: 100%;
                fill: $gray;
                transition: 0.2s fill ease-in-out;
            }
        }
    }
}
</style>
