<template>
    <div
        class="user-section"
        :class="{
            'active': status === 'active',
            'done': status === 'done',
        }"
    >
        <div class="user-section__screen">
            <div class="user-section__screen-title">
                {{ info.title }}
            </div>
            <div
                class="user-section__body"
                :class="{
                    'user-section__body--vertical': info.modify === 'vertical',
                    'user-section__body--pmo': info.modify === 'pmo'
                }"
            >
                <div
                    v-for="item in info.items"
                    :key="item"
                    class="user-section__block"
                    :class="{
                        'user-section__block--2': item === 2,
                        'user-section__block--3': item === 3,
                        'user-section__block--5': item === 5,
                        'user-section__block--6': item === 6,
                    }"
                >
                    <div class="user-section__block-icon" />
                    <div class="user-section__block-text" />
                </div>
            </div>
        </div>
        <div
            v-if="showReplayBtn"
            class="user-section__replay"
        >
            <el-button
                type="primary"
                size="small"
                round
            >
                Replay
            </el-button>
        </div>
        <div class="user-section__status">
            <div class="user-section__status-icon-wrap">
                <i class="user-section__status-icon fa-solid fa-circle-check" />
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from 'vue'
import {useRoute} from 'vue-router'

const route = useRoute()

const props = defineProps({
    info: {
        type: Object,
        default: () => {return {}}
    },
    status: {
        type: String,
        default: ''
    }
})

const showReplayBtn = computed(() => (
    props.status === 'active' && [
        'intro.after-profile.lm-preview-done',
        'intro.after-lm.pmo-preview-done',
        'intro.after-pmo.my-list-preview-done',
    ].includes(route.name)
    ||
    route.name === 'intro.full.done-full')
)
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.user-section {
    $block-name: &;
    max-width: 300px;
    height: 100%;
    margin: 0 auto;
    border: 2px solid $light-gray;
    border-radius: 5px;
    overflow: hidden;
    padding: 10px 15px;
    transition: 0.2s border ease-in-out, 0.2s box-shadow ease-in-out;
    pointer-events: none;
    @media all and (min-width: $xs) {
        padding: 15px 30px;
    }
    &__screen {
        width: 100%;
        &-title {
            margin-bottom: 10px;
            font-weight: bold;
            text-align: center;
            color: $dark;
        }
    }
    &__body {
        &--vertical {
            flex-direction: column;
            justify-content: center;
            #{$block-name} {
                &__block {
                    flex-direction: column;
                    justify-content: center;
                    margin-bottom: 20px;
                    &-icon {
                        margin-right: 0;
                        margin-bottom: 10px;
                    }
                    &-text {
                        height: 15px;
                        width: 70px;
                    }
                }
            }
        }
        &--pmo {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            #{$block-name} {
                &__block {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    width: 48%;
                    height: 50px;
                    margin-bottom: 5px !important;
                    padding-bottom: 5px;
                    background-color: $light-gray;
                    &-icon {
                        margin-right: 0;
                    }
                    &-text {
                        display: none;
                    }
                }
            }
        }
    }
    &__block {
        display: flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 15px;
        }
        &-icon {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin-right: 10px;
            border-radius: 50%;
            background-color: $indigo;
        }
        &-text {
            height: 10px;
            width: 50px;
            background-color: $light-gray;
        }
        &--2,
        &--5 {
            #{$block-name}__block-text {
                width: 65px;
            }
        }
        &--3,
        &--6 {
            #{$block-name}__block-text {
                width: 80px;
            }
        }
    }
    &__status {
        visibility: hidden;
        opacity: 0;
    }
    &__replay {
        visibility: hidden;
        opacity: 0;
        text-align: center;
    }
    &.active {
        position: relative;
        border: 3px solid $indigo;
        box-shadow: 0 0 15px 5px rgba(0,0,0,0.3);
        #{$block-name} {
            &__replay {
                position: absolute;
                right: auto;
                bottom: 0px;
                left: calc(50% - 44px);
                visibility: visible;
                opacity: 1;
                background-color: white;
                padding: 10px 10px 15px;
            }
        }
    }
    &.done {
        position: relative;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba(0,0,0,0.4);
        }
        #{$block-name} {
            &__replay {
                position: absolute;
                right: auto;
                bottom: 75px;
                left: calc(50% - 34px);
                visibility: visible;
                opacity: 1;
            }
            &__status {
                position: absolute;
                right: auto;
                bottom: 15px;
                left: calc(50% - 25px);
                visibility: visible;
                opacity: 1;
                &-icon {
                    font-size: 35px;
                    color: $green;
                    &-wrap {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        background-color: $white;
                    }
                }
            }
        }
    }
    &--clickable {
        cursor: pointer;
        pointer-events: auto;
        &:hover {
            box-shadow: 0 0 10px 4px rgba(0,0,0,0.3);
        }
    }
}
</style>
