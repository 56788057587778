<template>
    <div class="main-content not-found-section">
        <div class="g-container">
            <div class="not-found-section__image">
                <div
                    class="not-found-section__image-wrap"
                >
                    <img
                        src="/images/robots/robot_oops.svg"
                        class="not-found-section__image-i"
                        alt=""
                    >
                </div>
            </div>
            <div class="not-found-section__section not-found-section__section--centered">
                <div
                    class="not-found-section__title default-text"
                    v-html="title"
                />
                <div
                    v-if="canAddProduct"
                    class="not-found-section__text default-text"
                    v-html="text"
                />
            </div>
            <div class="not-found-section__buttons">
                <div class="not-found-section__buttons-row">
                    <template v-if="pmoPage && canAddProduct">
                        <div
                            v-if="false"
                            class="not-found-section__buttons-col"
                        >
                            <el-button
                                round
                                type="primary"
                                class="w-100"
                                @click="$router.push({ name: 'product.search', params: {pmo_category: $route.params.pmo_category} })"
                            >
                                Search
                            </el-button>
                        </div>
                        <div class="not-found-section__buttons-col">
                            <el-button
                                round
                                class="w-100"
                                @click="$router.push({ name: 'pmo.show', params: {pmo_category: $route.params.pmo_category} })"
                            >
                                Skip
                            </el-button>
                        </div>
                        <div class="not-found-section__buttons-col">
                            <el-button
                                round
                                class="w-100"
                                @click="$router.push({ name: 'product.add', params: {upc: $route.params.upc, pmo_category: $route.params.pmo_category} })"
                            >
                                Add
                            </el-button>
                        </div>
                    </template>
<!--                    <template v-else>-->
<!--                        <div class="not-found-section__buttons-col not-found__buttons-col&#45;&#45;wide">-->
<!--                            <el-button-->
<!--                                round-->
<!--                                type="primary"-->
<!--                                class="w-100"-->
<!--                                @click="$router.push({ name: 'Home' })"-->
<!--                            >-->
<!--                                My-items page-->
<!--                            </el-button>-->
<!--                        </div>-->
<!--                    </template>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const name = 'NotFoundSection'
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    text: {
        type: String,
        default: '',
    },
    pmoPage: {
        type: Boolean,
        default: false
    },
    canAddProduct: {
        type: Boolean,
        default: true
    }
})
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.not-found-section {
    padding-top: 80px;
    &__image {
        &-wrap {
            width: 180px;
            height: 180px;
            overflow: hidden;
            margin: 0 auto 20px;
        }
        &-i {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__section {
        margin-bottom: 40px;
        &--centered {
            text-align: center;
        }
    }
    &__title,
    &__text {
        color: $black;
    }
    &__title {
        margin-bottom: 15px;
        font-weight: 600;
    }
    &__text {
        font-size: 15px;
    }
    &__buttons {
        &-row {
            display: flex;
            justify-content: center;
            margin: 0 -5px;
        }
        &-col {
            width: 50%;
            max-width: 320px;
            padding: 0 5px;
            &--wide {
                width: 100%;
            }
        }
    }
}
</style>
