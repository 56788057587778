import axios from 'axios'
import store from '~/store'

const API_ENDPOINT = 'labelinsight/product'

export default {

    getProductByUpc(upc, params) {
        return axios.get(API_ENDPOINT + '/upc/' + upc, {params: params})
    },

    getProduct(upc, params) {
        return axios.get('labelinsight/show-product/upc/' + upc, {params: params})
    },

    getProductAmount(upc, pmo_category) {
        return axios.get(API_ENDPOINT + '/amount_data/' + upc + '/' + pmo_category)
    },

    getAlternativesByUpc(upc, params) {
        return axios.get(API_ENDPOINT + '/alternatives/' + upc, {params: params})
    },

    search(params) {
        return axios.get('/labelinsight/product/', {params})
    },

    firstScan() {
        if (! this.isFirstScan()) {
            store.commit('auth/addFirstExperience', 'first_scan')
            return axios.post(API_ENDPOINT + '/first-scan')
        } else {
            return Promise.resolve('Already set')
        }
    },

    isFirstScan() {
        const user = store.getters['auth/user']
        return user.first_experience && (user.first_experience.first_scan ?? false)
    },
    addProduct(data) {
        return axios.post(API_ENDPOINT + '/add-product', data)
    }
}
