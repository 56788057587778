<template>
    <section id="dialog2">
        <RobotDialog
            :messages="['Do one of these seem like you, it may not match you exactly but pick the one that matches the closest.',]"
            robot-image="/images/robots/robot_have_answer.svg"
        />
    </section>
</template>

<script>
import RobotDialog from '../RobotDialog'
export default {
    name: 'Dialog2',
    components: {RobotDialog},
}
</script>
