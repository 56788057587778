<template>
    <div class="star-count">
        <div
            v-if="plusSymbol"
            class="star-count__plus"
        >
            +
        </div>
        <div class="star-count__star">
            {{ starCount }}
        </div>
        <div class="star-count__star-icon">
            <i class="fa-solid fa-star" />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    starCount: {
        type: Number,
        required: true,
    },
    plusSymbol: {
        type: Boolean,
        default: false
    }
})
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
    .star-count {
        $block-name: &;
        display: inline-flex;
        align-items: center;
        height: 30px;
        border: 2px solid $yellow;
        border-radius: 15px;
        padding: 1px 5px 0;
        color: $yellow;
        background-color: $blue;
        user-select: none;
        &__plus,
        &__star {
            font-size: 18px;
            font-weight: 700;
        }
        &__star {
            margin-right: 3px;
            &-icon {
                font-size: 16px;
            }
        }
    }
</style>
