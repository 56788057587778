export const state = {
    aspiration: null,
    status: null,
}

const robotOptions = {
    sleep: {
        good: {
            image: '/images/robots/robot_waving.svg',
            messages: [
                'That’s a <strong>great improvement</strong> in your sleep!',
                'Let\'s see this keep getting better as you continue progress through your pantry, finding and using healthier alternatives.'
            ],
        },
        no_change: {
            image: '/images/robots/robot_sleep.png',
            messages: [
                '<strong>No change</strong> in your sleep, let\'s keep trying some new alternatives.',
                'As you continue to progress through your pantry we will keep checking in.'
            ],
        },
        bad: {
            image: '/images/robots/robot_sleep.png',
            messages: [
                'Hmm, <strong>let\'s keep going</strong> and see if adding new alternatives can help make improvements.'
            ],
        },
    },
    weight: {
        good: {
            image: '/images/robots/robot_waving.svg',
            messages: [
                'Glad your weight is <strong>improving</strong>!',
                'Let\'s see this keep getting better as you continue progress through your pantry, finding and using healthier alternatives.'
            ],
        },
        no_change: {
            image: '/images/robots/overweight_upset.png',
            messages: [
                '<strong>No change</strong> in your weight, let\'s keep trying some new alternatives.',
                'As you continue to progress through your pantry we will keep checking in.'
            ],
        },
        bad: {
            image: '/images/robots/overweight_upset.png',
            messages: [
                'Hmm, <strong>let\'s keep going</strong> and see if adding new alternatives can help make improvements.',
                'As you continue to progress through your pantry we will keep checking in.'
            ],
        },
    },
    energy: {
        good: {
            image: '/images/robots/robot_waving.svg',
            messages: [
                'That’s a <string>great improvement</string> in your energy!',
                'Let\'s see this keep getting better as you continue progress through your pantry, finding and using new alternatives.'
            ],
        },
        no_change: {
            image: '/images/robots/robot_distracted.png',
            messages: [
                '<strong>No change</strong> in your healthier choices, let\'s keep trying some new alternatives.',
                'As you continue to progress through your pantry we will keep checking in.'
            ],
        },
        bad: {
            image: '/images/robots/robot_distracted.png',
            messages: [
                'Hmmm, <strong>let\'s keep going</strong>! As you work through your pantry more information on choosing healthier alternatives will be provided.',
                'We will keep <strong>checking in</strong> to track your progress.'
            ],
        },
    },
    health: {
        good: {
            image: '/images/robots/robot_waving.svg',
            messages: [
                'Glad that you’re now making <strong>healthier choices</strong>.',
                'Let\'s see this keep getting better as you continue progress through your pantry, finding and using healthier alternatives.'
            ],
        },
        no_change: {
            image: '/images/robots/robot_search.svg',
            messages: [
                '<strong>No change</strong> in your energy, let\'s keep trying some new alternatives.',
                'As you continue to progress through your pantry we will keep checking in.'
            ],
        },
        bad: {
            image: '/images/robots/robot_unhealthy.png',
            messages: [
                'Hmm, <strong>let\'s keep going</strong> and see if adding new alternatives can help make improvements.',
                'As you continue to progress through your pantry we will keep checking in.'
            ],
        },
    },
    stress: {
        good: {
            image: '/images/robots/robot_waving.svg',
            messages: [
                'That’s a <strong>great improvement</strong> in your stress!',
                'Let\'s see this keep getting better as you continue progress through your pantry, finding and using healthier alternatives.'
            ],
        },
        mixed: {
            image: '/images/robots/robot_stressed.png',
            messages: [
                'Looks like a mix of better and worse stress symptoms. Let\'s keep trying some new alternatives.',
                'As you continue to progress through your pantry we will keep checking in.'
            ],
        },
        no_change: {
            image: '/images/robots/robot_search.svg',
            messages: [
                'No change in your stress, let\'s keep trying some new alternatives.',
                'As you continue to progress through your pantry we will keep checking in.'
            ],
        },
        bad: {
            image: '/images/robots/robot_stressed.png',
            messages: [
                'Hmm, <strong>let\'s keep going</strong> and see if adding new alternatives can help make improvements.',
                'As you continue to progress through your pantry we will keep checking in.'
            ],
        },
    },
}


// getters
export const getters = {
    robotMessages: state => state.aspiration ? robotOptions[state.aspiration][state.status].messages : null,
    robotImage: state => state.aspiration ? robotOptions[state.aspiration][state.status].image : null,
}

// mutations
export const mutations = {
    setDialog (state, {aspiration, status}) {
        state.aspiration = aspiration
        state.status = status
    },
    clear (state) {
        state.aspiration = null
        state.status = null
    },
}
