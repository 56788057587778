<template>
    <div class="main-content main-content--base-layout main-content--offset-bottom">
        <div class="main-content__top-content-section" />
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__robot">
                    <RobotDialog
                        v-if="globalMessages.length === 0"
                        :messages="[finishMessage]"
                        robot-image="/images/robots/robot_smile.svg"
                        :show-bricks-animation="true"
                        :button-callback="handleClick"
                        :button-cansel-callback="handleCancelClick"
                        button-text="Sure!"
                        button-cansel-text="Not now"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import RobotDialog from '~/modules/quiz/components/RobotDialog'
import {useRoute, useRouter} from 'vue-router'
import pmoApi from '~/modules/pmo/pmoApi'
import { computed, getCurrentInstance } from 'vue'
import store from '~/store'
import userApi from '~/modules/profile/userApi'

const name = 'WelcomeGame'

const { proxy } = getCurrentInstance()

const router = useRouter()
const route = useRoute()
const user = computed(() => store.getters['auth/user'])

const globalMessages = computed(() => store.getters['global-robot/messages'])

const finishMessage = computed(() => {
    let message = `Fantastic job completing ${gameName(route.params.game_slug)}. `

    if (!userApi.hasFirstExperience('pmo_' + route.params.game_slug + '_earned') && user.value.client_level && user.value.client_level.has_starts_points_system === true) {
        message += 'You just earned <span class="stars-yellow">60<i class="fa-solid fa-star"></i></span>! '
    }
    message += 'Ready for a game?'

    return message
})

async function handleClick() {
    await userApi.setFirstExperience('pmo_' + route.params.game_slug + '_earned')
    await router.push({name: 'games.' + route.params.game_slug})
}
async function handleCancelClick() {
    await userApi.setFirstExperience('pmo_' + route.params.game_slug + '_earned')
    const {data} = await pmoApi.categoryFinished({game_slug: route.params.game_slug})

    if (data.canSeeSummary) {
        const pantry = _.toArray(proxy.$config.pmoTypes).find(o => o.game_slug === route.params.game_slug).id
        await router.push({name: 'pmo.summary', params: {pantry}})
    } else {
        await router.push({name: 'pmo'})
    }
}

function gameName(name) {
    if (!name) return name
    const newName = name.split('-').join(' ')
    return _.capitalize(newName)
}
</script>
