<template>
    <el-container class="auth-page">
        <el-main>
            <div class="auth-page__top">
                <div class="auth-page__image-wrap">
                    <img
                        class="auth-page__image"
                        src="/images/auth/seekingsimple-logo.svg"
                        alt=""
                    >
                </div>
            </div>
            <div
                v-if="title"
                class="auth-page__title"
            >
                {{ title }}
            </div>
            <el-card>
                <login-form
                    :errors="authErrors"
                    :loading="loading"
                    :forgot-link="false"
                    @submit="onSubmit"
                />
            </el-card>
            <div
                v-if="errorMessage"
                class="auth-page__error-message"
                :class="{'auth-page__error-message--show': isShowMessage }"
            >
                {{ errorMessage }}
            </div>
        </el-main>
    </el-container>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import LoginForm from '~/modules/auth/components/LoginForm'

const router = useRouter()

const authErrors = ref({})
const loading = ref(false)
const title = 'Partner Sign in'
const errorMessage = 'This Username and Password combination does not exist'
const isShowMessage = ref(false)

function onSubmit() {
    authErrors.value = {
        email: [ ' ' ],
        password: [' ']
    }
    isShowMessage.value = true
    setTimeout(() => {
        isShowMessage.value = false
    }, 5000)
}
</script>
