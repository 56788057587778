<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="main-content__text">
                    To suggest the best alternatives for your pantry, we need to know what types of sugar substitutes you prefer.
                </div>
                <el-form :model="form">
                    <el-radio-group
                        v-model="form.acceptSugarSubstitutes"
                        class="el-radio-group--column"
                    >
                        <div class="el-radio-group__item el-radio-group__item--inner-content">
                            <el-radio label="accept">
                                <strong>Yes, I will accept sugar substitutes.</strong>
                                <div
                                    v-if="formErrors.get('sugarSubstitutesOptions')"
                                    class="el-form-item__error"
                                >
                                    {{ formErrors.get('sugarSubstitutesOptions') }}
                                </div>
                            </el-radio>
                            <div class="el-radio-group__item-checkbox-group">
                                <el-checkbox-group
                                    v-model="form.sugarSubstitutesOptions"
                                    class="el-checkbox-group--column el-checkbox-group--column-middle-offset-bottom"
                                >
                                    <template
                                        v-for="option in options"
                                        :key="option.label"
                                    >
                                        <el-checkbox
                                            v-if="option.parent === 'accept'"
                                            :label="option.label"
                                            :disabled="disableCheckboxes"
                                        >
                                            <strong>{{ option.label }}</strong>
                                            <div class="el-checkbox__label-sub-text">
                                                {{ option.text }} <span
                                                    class="el-checkbox__label-sub-icon"
                                                    @click.prevent.stop="showDialog(option)"
                                                ><inline-svg
                                                    class="el-checkbox__label-sub-icon-svg"
                                                    src="/images/svg-icons/question-circle-icon.svg"
                                                    fill="#757575"
                                                /></span>
                                            </div>
                                        </el-checkbox>
                                    </template>
                                </el-checkbox-group>
                            </div>
                        </div>

                        <div class="el-radio-group__item">
                            <el-radio
                                label="not_accept"
                                class="el-radio--align-top"
                            >
                                <strong>No thanks!</strong>
                                <div class="el-radio__label-sub-text">
                                    Sugar, honey, fructose... only <span
                                        class="el-radio__label-sub-icon"
                                        @click.prevent.stop="showDialog(getOptionByLabel('No thanks!'))"
                                    ><inline-svg
                                        class="el-radio__label-sub-icon-svg"
                                        src="/images/svg-icons/question-circle-icon.svg"
                                        fill="#757575"
                                    /></span>
                                </div>
                            </el-radio>
                        </div>
                    </el-radio-group>
                </el-form>
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__button-wrap">
                    <el-button
                        type="primary"
                        round
                        class="continue-button"
                        @click="onSubmit"
                    >
                        Save Preference
                    </el-button>
                </div>
            </div>
        </div>
        <el-dialog
            v-model="dialogVisible"
            :show-close="false"
            custom-class="el-dialog--indigo-bg el-dialog--without-header"
        >
            <div
                v-if="clickedOption"
                class="el-dialog__body-text default-text"
                v-html="clickedOption.description"
            />
            <template #footer>
                <span class="dialog-footer">
                    <el-button
                        class="el-button--transparent el-button--transparent-invert-color"
                        @click="dialogVisible = false"
                    >
                        GOT IT
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import {ref, reactive, computed, onMounted, watch} from 'vue'
import axios from 'axios'
import {useRoute, useRouter} from 'vue-router'
import store from '~/store'
import bus from '~/includes/Event'
import {ElMessage} from 'element-plus'
import InlineSvg from 'vue-inline-svg'
import {Errors} from '~/includes/Errors'

const router = useRouter()
const route = useRoute()
const user = computed(() => store.getters['auth/user'])

const options = [
    {
        'label': 'Herbal extracts',
        'text': 'Stevia',
        'description': '<p class="default-text default-text--offset-bottom default-text--white"><b>Herbal extracts</b> are derived from plants. They are extracted from the natural constituents of the leaves, stems or roots of the herbs. They are also known as intense sweeteners because they are many times sweeter than sugar.</p>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>CHINESE BLACKBERRY</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Chinese Sweet Tea</li><li>Rubus suavissimus</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>GLYCYRRHIZIN</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Licorice</li></ul>' +
            '<p class="default-text--offset-left default-text--bordered-bottom-white"><b>STEVIA</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Stevia rebaudiana Bertoni</li><li>Truvia</li><li>PureVia</li><li>Sweet Leaf</li><li>Enliten</li></ul>',
        'parent': 'accept'
    },
    {
        'label': 'Plant based sweeteners',
        'text': 'Monkfruit...',
        'description': '<p class="default-text default-text--offset-bottom default-text--white"><b>Plant based sweeteners</b> are sugar substitutes that are often promoted as healthier alternatives to sugar or other sugar substitutes. These sweeteners often undergo processing. They are natural plant sweeteners that have less impact on blood sugar and insulin levels.</p>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>MONKFRUIT</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Luo Han Guo</li><li>SGFE</li><li>Siraitia grosvenori</li><li>Swingle</li><li>Nectresse</li><li>PureLo</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>YACON SYRUP</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Peruvian ground apple</li><li>Poire de terre</li><li>Yacon strawberry</li><li>Bolivian sunroot</li></ul>',
        'parent': 'accept'
    },
    {
        'label': 'Sugar alcohols from plants',
        'text': 'Xylitol, erythritol, ...',
        'description': '<p class="default-text default-text--offset-bottom default-text--white"><b>Sugar Alcohols</b> are processed sweeteners that have reduced calories compared to regular sugar. They do create some blood glucose and insulin reactions. They occur naturally in certain fruits and vegetables, but some are man-made and are added to processed foods.</p>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>ERYTHRITOL</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Sugar alcohol</li><li>Zerose</li><li>ZSweet</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>GLYCEROL</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Glycerin</li><li>Glycerine</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>HYDROGENATED STARCH HYDROLYSATE (HSH)</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Sugar alcohol</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>ISOMALT</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Sugar alcohol</li><li>ClearCut Isomalt</li><li>Decomalt</li><li>DiabetiSweet (also contains Acesulfame-K)</li><li>Hydrogenated Isomaltulose</li><li>Isomaltitol</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>LACTITOL</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Sugar alcohol</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>MALTITOL</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Sugar alcohol</li><li>Maltitol Syrup</li><li>Maltitol Powder</li><li>Hydrogenated High Maltose Content Glucose Syrup</li><li>Hydrogenated Maltose</li><li>Lesys</li><li>MaltiSweet (hard to find online to buy)</li><li>SweetPearl</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>MANNITOL</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Sugar alcohol</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>POLYDEXTROSE</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Sugar alcohol</li><li>(Derived from glucose and sorbitol)</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>SORBITOL</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Sugar alcohol</li><li>D-glucitol</li><li>D-glucitol syrup</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>XYLITOL</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Sugar alcohol</li><li>Smart Sweet</li><li>Xylipure</li><li>Xylosweet</li></ul>',
        'parent': 'accept'
    },
    {
        'label': 'Synthetic sweeteners',
        'text': 'Aspartame, ...',
        'description': '<p class="default-text default-text--offset-bottom default-text--white"><b>Synthetic</b> or commonly called artificial sweeteners are created through chemical synthesis and are not found in nature. Most of the sweeteners in this category require additional chemicals, like chlorine, to create their low-calorie nature, which may pose health risks.</p>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>ACESULFAME POTASSIUM</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>ACK</li><li>Ace K</li><li>Equal Spoonful (also +aspartame)</li><li>Sweet One</li><li>Sunett</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left default-text--offset-bottom"><b>ADVANTAME</b></p><br>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>ASPARTAME</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>APM</li><li>AminoSweet (but not in US)</li><li>Aspartyl-phenylalanine-1-methyl ester</li><li>Canderel (not in US)</li><li>Equal Classic</li><li>NatraTaste Blue</li><li>NutraSweet</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>ASPARTAME-ACESULFAME SALT</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>TwinSweet (Europe only)</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>CYCLAMATE</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>(Not in US as per FDA)</li><li>Calcium cyclamate</li><li>Cologran = cyclamate and saccharin(not in US)</li><li>Sucaryl</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left default-text--offset-bottom"><b>NEOTAME</b></p><br>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>SACCHARIN</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Acid saccharin</li><li>Equal Saccharin</li><li>Necta Sweet</li><li>Sodium Saccharin</li><li>Sweet N Low</li><li>Sweet Twin</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>SUCRALOSE</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>1\',4,6\'-Trichlorogalactosucrose</li><li>Trichlorosucrose</li><li>Equal Sucralose</li><li>NatraTaste Gold</li><li>Splenda</li></ul>' +
            '<p class="default-text--bordered-bottom-white default-text--offset-left"><b>TAGATOSE</b></p>' +
            '<ul class="default-list default-list--offset-left"><li>Natrulose</li></ul>',
        'parent': 'accept'
    },
    {
        'label': 'No thanks!',
        'text': 'Sugar, honey, fructose... only',
        'description': 'These sugars are refined. They may be from a natural source like fruit and plants, but they have been processed so only the sugar remains, like granulated sugar from sugar cane, corn syrup from corn and agave syrup from agave.',
        'parent': 'not_accept'
    }
]


const dialogVisible = ref(false)
const clickedOption = ref('')
const next_slug = ref(route.query.next_slug||'')
const disableCheckboxes = ref(false)

const form = reactive({
    acceptSugarSubstitutes: user.value.acceptSugarSubstitutes||'accept',
    sugarSubstitutesOptions: user.value.sugarSubstitutesOptions||options.map(option => option.label),
})

const formErrors = ref(new Errors())

onMounted(() => {
    bus.$emit('set-title', 'Sugar Substitutes')
    checkAcceptSugarSubstitutes(form)
    //save default values of sugar substitutes form
    if (user.value.acceptSugarSubstitutes == undefined || user.value.acceptSugarSubstitutes == null) {
        axios.post( '/profile/save-sugar-substitutes/', form).then(response => {
            store.dispatch('auth/fetchUser')
        })
    }
})

function checkAcceptSugarSubstitutes(newForm) {
    disableCheckboxes.value = newForm.acceptSugarSubstitutes !== 'accept'
}

watch(form, (newForm) => {
    checkAcceptSugarSubstitutes(newForm)
})

const onSubmit = () => {
    formErrors.value.record([])
    axios.post( '/profile/save-sugar-substitutes', form).then(response => {
        // console.log(response)
        // ElMessage.success('Preferences saved!')
        store.dispatch('auth/fetchUser')
        if (next_slug.value) {
            router.push({ name: 'pmo.show', params: {pmo_category: next_slug.value} })
        }
    }).catch(error => {
        // console.log(error)
        if (error.response.data.errors) {
            formErrors.value.record(error.response.data.errors)
        }
    })
}

function showDialog(opt) {
    dialogVisible.value = true
    clickedOption.value = opt
}

function getOptionByLabel(label) {
    return options.find(option => option.label == label)
}

</script>
