<template>
    <el-form>
        <el-form-item>
            <div class="questions-page__store-search-section">
                <div class="questions-page__store-search">
                    <div
                        v-if="showTopText"
                        class="questions-page__store-search-text default-text"
                        v-html="topText"
                    />
                    <label class="el-form-item__label--floating-label">
                        <el-input
                            v-model="zip_code"
                            class="questions-page__store-search-input el-input--floating-label"
                            placeholder=" "
                            :disabled="!user.is_fdm"
                        >
                            <template #prefix>
                                Zip code
                            </template>
                        </el-input>
                    </label>
                </div>
                <div>
                    <RobotDialog
                        v-if="showRobotValue"
                        :messages="['Enter your zip to see participating stores near you.',]"
                        robot-image="/images/robots/robot_smile.svg"
                        :timeout-value="3000"
                        :show-bricks-animation="false"
                        @finish="buttonCallback"
                    />
                </div>
                <div class="questions-page__button-wrap">
                    <el-button
                        v-if="user.is_fdm"
                        :disabled="zip_code.length < 5 || loading"
                        :loading="loading"
                        type="primary"
                        round
                        class="continue-button"
                        @click="handleSearch"
                    >
                        Search
                    </el-button>
                </div>
                
            </div>
            <div
                v-if="stores.length > 0"
                class="questions-page__store-list-section"
            >
                <el-checkbox-group
                    v-model="selected_stores_id"
                    name="selected_stores_id"
                    class="store-list"
                    @change="selectStores"
                    :disabled="!user.is_fdm"
                >
                    <el-checkbox
                        v-for="store in stores"
                        :key="store.upc"
                        :value="store._id"
                        :label="store._id"
                        class="store-list__item"
                    >
                        <StoreListItem
                            :store="store"
                            :is-primary="store._id === primary_store"
                            show-link
                        />
                    </el-checkbox>
                </el-checkbox-group>
            </div>
        </el-form-item>
        <div 
            class="main-content__bottom"
            v-if="selected_stores_id.length > 0"
        >
            <div class="g-container">
                <div class="main-content__bottom-wrap lesson__btn-section">
                    <el-button
                        type="primary"
                        class="continue-button"
                        round
                        @click.prevent.stop="emit('handlePrimaryDialog')"
                    >
                        Set default store
                    </el-button>
                </div>
            </div>
        </div>
        <!-- <div
            v-if="selected_stores_id.length > 0"
            class="lesson__btn-section"
        >
            
        </div> -->
        <el-dialog
            v-model="showDialog"
            :show-close="false"
            :close-on-click-modal="false"
            title="Select primary store"
            custom-class="el-dialog--stores-list"
            append-to-body
        >
            <div class="el-dialog__body-text default-text">
                Which store is your top choice?
            </div>
            <el-radio-group
                v-model="primary_store"
                class="el-radio-group--separated"
            >
                <el-radio
                    v-for="store in selectedStores"
                    :key="store._id"
                    :label="store._id"
                >
                    <StoreListItem :store="store" />
                </el-radio>
            </el-radio-group>
            <template #footer>
                <span class="dialog-footer">
                    <el-button
                        class="el-button--transparent"
                        @click="handleCancel"
                    >
                        Cancel
                    </el-button>
                    <el-button
                        :disabled="!selected_stores_id.includes(primary_store)"
                        class="el-button--transparent"
                        @click="selectStores"
                    >
                        Select
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </el-form>
</template>

<script setup>
import {ref, computed} from 'vue'
import StoreListItem from '~/modules/quiz/components/household/StoreListItem'
import storeApi from '~/modules/stores/storeApi'
import storeVuex from '~/store'
import RobotDialog from '~/modules/quiz/components/RobotDialog'

const user = computed(() => storeVuex.getters['auth/user'])
const zip_code = ref('')
const loading = ref(false)
const stores = ref([])
const selected_stores_id = ref([])
const primary_store = ref(null)
const props = defineProps({
    topText: {
        type: String,
        default: '',
    },
    showPrimaryDialog: {
        type: Boolean,
        default: false,
    },
    autoHandleSearch: {
        type: Boolean,
        default: false,
    },
    showTopText: {
        type: Boolean,
        default: true,
    },
    showRobot: {
        type: Boolean,
        default: false,
    },

})
const emit = defineEmits()

const showDialog = computed({
    get() {
        return props.showPrimaryDialog
    },
    set(value) {
        emit('update:modelValue', value)
    },
})

const showRobotValue = ref(props.showRobot)

if(props.autoHandleSearch && user.value.stores && user.value.stores.length > 0) {
    selected_stores_id.value = user.value.stores.map(store => store._id)
    primary_store.value = user.value.stores.find(store => store.is_primary)?._id
    zip_code.value = getSavedSavedStoresZip()
    if(zip_code.value) {
        handleSearch(false)
    }
}

function getSavedSavedStoresZip() {
    if (user.value.stores||user.value.stores.length > 0) {
        return  user.value.stores.find(store => store.is_primary)?.zip
    }
    return ''
}

const selectedStores = computed(() => {
    return stores.value.filter(o => selected_stores_id.value.includes(o._id))
})

async function handleSearch(resetSelectedStores = true) {
    loading.value = true
    const {data} = await storeApi.getByZip(zip_code.value)
    stores.value = data.stores
    if (resetSelectedStores) selected_stores_id.value = []
    loading.value = false
    selectStores()
}

function handleCancel() {
    emit('handleCancel')
}

function selectStores() {
    emit('selectStores', {
        stores: stores.value,
        selectedStores: selectedStores.value,
        selected_stores_id: selected_stores_id.value,
        primary_store: primary_store.value,
    })
    emit('selectPrimaryStore')
}

function buttonCallback() {
    setTimeout(() => {
        showRobotValue.value = false
    }, 3000)
}

</script>
