<template>
    <section class="main">
        <Header v-if="check" />
        <HeaderWithBack v-else />
        <Notifications />
        <Child />
    </section>
</template>

<script setup>
import Child from '~/base/components/Child'
import Header from '~/base/components/Header'
import HeaderWithBack from '~/base/components/HeaderWithBack'
import Notifications from '~/base/components/notifications/NotificationsDeprecated'
import store from '~/store'

import {useRoute, useRouter} from 'vue-router'

const route = useRoute()
const router = useRouter()

// show header with back button if user not logged in or if user on static pages
const check = store.getters['auth/check']&&!['static-pages.faq','static-pages.terms-of-use','static-pages.help-support','static-pages.privacy-policy'].includes(route.name)
</script>
