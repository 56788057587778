<template>
    <div
        class="shop-list"
        :class="{ 'shop-list--open' : showShopList }"
    >
        <div class="shop-list__inner">
            <div
                class="shop-list__current"
                @click="toggleShopList"
            >
                <div class="shop-list__current-shop">
                    <div
                        v-if="activeShop"
                        class="shop-list__current-shop-wrap"
                    >
                        <span class="shop-list__current-shop-banner">{{ activeShop.banner }}</span>
                        <span class="shop-list__current-shop-address">{{ activeShop.address }}, </span>
                        <span class="shop-list__current-shop-name">{{ activeShop.name }}</span>
                    </div>
                    <div class="shop-list__current-arrow">
                        <i class="shop-list__current-arrow-icon fa-solid fa-caret-down" />
                    </div>
                </div>
            </div>
            <div class="shop-list__count">
                <div class="shop-list__count-value">
                    {{ products && products.items && products.items.length }}
                </div>
            </div>
        </div>
        <div
            v-if="showShopList"
            class="shop-list__dropdown"
        >
            <div class="shop-list__list">
                <div
                    v-for="shop in shops"
                    :key="shop._id"
                    class="shop-list__list-item"
                >
                    <div
                        class="shop-list__shop"
                        @click="selectShop(shop)"
                    >
                        <div class="shop-list__shop-content">
                            <div class="shop-list__shop-icon">
                                <template v-if="shop._id === activeShop._id">
                                    <i class="shop-list__shop-icon-check fa-solid fa-check" />
                                </template>
                            </div>
                            <div class="shop-list__shop-name">
                                <div class="shop-list__shop-name-top">
                                    <span class="shop-list__shop-name-banner">
                                        {{ shop.banner }}
                                    </span>
                                </div>
                                <div class="shop-list__shop-name-bottom">
                                    <span class="shop-list__shop-name-name">
                                        {{ shop.name }}
                                    </span>,
                                    <span class="shop-list__shop-name-address">
                                        {{ shop.address }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="shop-list__shop-count">
                            <div class="shop-list__shop-count-value">
                                {{ shop.productsCount }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import store from '~/store'
import storeApi from '~/modules/stores/storeApi'
import axios from 'axios'

const showShopList = ref(false)
const activeShop = ref(null)
const shops = ref([])
const user = computed(() => store.getters['auth/user'])
const products = computed(() => store.getters['my-items/shop'])
const shopProducts = ref({})

function toggleShopList() {
    showShopList.value = !showShopList.value
    if (showShopList.value) {
        productsInStores()
    }
}

productsInStores()

function getShops() {
    shops.value = []
    user.value.stores?.forEach((store) => {
        if(store.is_active) {
            activeShop.value = store
        }
        if(shopProducts.value[store.uid]) {
            store.productsCount = shopProducts.value[store.uid]
        }
        shops.value.push(store)
    })
    if (!activeShop.value?.uid || !user.value.is_fdm) {
        activeShop.value = user.value.stores?.find(o => o.is_primary)
    }

    getShopItems()
}

function getShopItems() {
    store.dispatch('my-items/fetchShop', activeShop.value.uid)
}

function selectShop(shop) {
    activeShop.value = shop
    showShopList.value = false

    user.value.stores.forEach((store) => {
        store.is_active = store._id === shop._id
    })

    storeApi.saveStores(user.value.stores).then(
        (response) => {
            if (response.data && response.data.status === 'ok') {
                store.dispatch('auth/fetchUser')
                getShopItems()
            }
        }
    )
}

function productsInStores() {
    axios.post('my-items/products-in-stores')
        .then(response => {
            shopProducts.value = response.data.productsCount
            getShops()
        })
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.shop-list {
    $block-name: &;
    position: relative;
    user-select: none;
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__current {
        max-width: 85%;
        &-shop {
            display: flex;
            align-items: center;
            cursor: pointer;
            &-wrap {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &-wrap,
            &-banner,
            &-address,
            &-name {
                transition: 0.2s color ease-in-out;
            }
            &-banner {
                margin-right: 5px;
                font-size: 20px;
                font-weight: 600;
                line-height: 1.4;
                letter-spacing: 0.15px;
                color: $dark;
            }
            &-address,
            &-name {
                font-size: 15px;
                font-weight: 400;
                color: $gray;
            }
        }
        &-arrow {
            flex-shrink: 0;
            margin-left: 10px;
            &-icon {
                color: $gray;
                transform-origin: center;
                transition: 0.2s color ease-in-out, 0.3s transform ease-in-out;
            }
        }
    }
    &__count {
        flex-shrink: 0;
        margin-left: 10px;
        &-value {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width:17px;
            height: 17px;
            border: 1px solid $white;
            border-radius: 8px;
            padding: 2px 4px;
            font-size: 11px;
            font-weight: 500;
            color: $white;
            background-color: $indigo;
        }
    }
    &__dropdown {
        position: absolute;
        z-index: 5;
        top: 100%;
        right: 0;
        left: 0;
        border-radius: 5px;
        background-color: $white;
        box-shadow: 0 3px 14px rgba(0,0,0, 20%);
    }
    &__list {
        &-item {
            padding: 10px 15px 10px;
            cursor: pointer;
            &:not(:first-child) {
                border-top: 1px solid $light-gray;
            }
        }
    }
    &__shop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-content {
            display: flex;
            align-items: center;
        }
        &-icon {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            margin-right: 10px;
            &-check {
                font-size: 17px;
                color: $gray;
            }
        }
        &-count {
            flex-shrink: 0;
            margin-left: 10px;
            &-value {
                color: $gray;
            }
        }
        &-name {
            &-banner {
                font-weight: 600;
                color: $dark;
            }
            &-name,
            &-address {
                color: $gray;
            }
        }
    }
    &--open {
        #{$block-name} {
            &__current-arrow-icon {
                transform: rotate(-180deg);
            }
        }
    }
}
</style>
