<template>
    <div
        v-if="deferredPrompt && isShow"
        class="pwa-modal"
    >
        <div class="left">
            <div class="logo">
                <img src="apple_touch_icon.png" alt="logo"/>
            </div>
            <div class="text">
                <div class="text__title">SeekingSimple</div>
                <div class="text__description">Do you want to add a shortcut to your device?</div>
            </div>
        </div>
        <div class="buttons">
            <el-button
                class="buttons__btn"
                type="primary"
                round
                size="small"
                @click="installPwa"
            >
                Install
            </el-button>
            <el-button
                class="buttons__btn"
                type=""
                round
                size="small"
                @click="cancel"
            >Cancel</el-button>
        </div>
    </div>
</template>

<script setup>
import {onBeforeMount, ref} from 'vue'

const deferredPrompt = ref(null)
const isShow = ref(true)

onBeforeMount(() => {
    const pwaData = JSON.parse(localStorage.getItem('pwa_shortcut'))
    if (pwaData && pwaData.cancel) {
        isShow.value = !pwaData.cancel
    }

    window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        deferredPrompt.value = e;
    });
})

async function installPwa() {
    deferredPrompt.value.prompt();
    isShow.value = false
}

function cancel() {
    localStorage.setItem('pwa_shortcut', JSON.stringify({cancel: true}));
    isShow.value = false
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.pwa-modal {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100px;
    margin: auto;
    background: white;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media all and (max-width: $s) {
        flex-wrap: wrap;
        padding: 20px 0;
        justify-content: center;
        height: 150px;
    }

    .left {
        display: flex;
        @media all and (max-width: $s) {
           padding: 0 20px 20px 20px;
        }
    }

    .logo {
        height: 100%;
        display: flex;
        margin-left: 50px;
        align-items: center;
        @media all and (max-width: $s) {
            margin-left: 0;
        }

        img {
            width: 50px;
            height: 50px;
        }
    }

    .text {
        margin-left: 20px;

        &__title {
            font-size: 16px;
            font-weight: bold;
        }

        &__description {
            font-size: 14px !important;
            display: block;
        }
    }

    .buttons {
        margin-right: 50px;
        @media all and (max-width: $s) {
            margin-right: 0;
        }

        &__btn {
            width: 100px;
        }
    }
}
</style>