// pmo

export const state = {
    current_category_slug: null,
}

// getters
export const getters = {
    current_category_slug: state => state.current_category_slug,
}

// mutations
export const mutations = {
    setCurrentCategory (state, slug) {
        state.current_category_slug = slug
    },
}
