import store from '~/store'
import MeasurementUnitsConverter from './MeasurementUnitsConverter'

export default class UserMeasurementUnitsConverter {
    constructor() {
        this.user = store.getters['auth/user']
    }

    getPerUnitOfMeasure(uom, short = false) {
        let perUnitOfMeasure = short?'per 100 g':'per 100 grams'
        if (this.isVolumeUom(uom)) {
            if (this.getUserMeasurements() === 'metric') {
                perUnitOfMeasure = 'per 100 ml'
            } else {
                perUnitOfMeasure = 'per fl oz'
            }
        } else {
            if (this.getUserMeasurements() === 'metric') {
                perUnitOfMeasure = short?'per 100 g':'per 100 grams'
            } else {
                perUnitOfMeasure = 'per oz'
            }
        }

        return perUnitOfMeasure
    }
    isVolumeUom(uom) {
        let volumeUoms = ['fl oz', 'ml', 'l', 'gal', 'bottle', 'tbsp', 'tsp', 'pint', 'quart', 'gallon', 'qt', 'pt',]
        return volumeUoms.includes(uom)
    }
    isMetricUom(uom) {
        let metricUoms = ['ml', 'l', 'g', 'kg']
        return metricUoms.includes(uom)
    }
    getUserMeasurements() {
        // let user = store.getters['auth/user']
        return this.user ? this.user.measurements : 'imperial'
    }
    getUnits(toUom) {
        if (!toUom) {
            return toUom
        }

        if (this.isVolumeUom(toUom)) {
            if (this.getUserMeasurements() === 'metric') {
                return 'ml'
            } else {
                return 'fl oz'
            }
        } else {
            if (this.getUserMeasurements() === 'metric') {
                return 'g'
            } else {
                return 'oz'
            }
        }
    }
    convertSize(value, fromUom, toUom) {
        return this.convertSizeFunc(value, fromUom, toUom)
    }
    convertSizeFunc(value, fromUom, toUom) {
        let newValue = value

        if (this.getUserMeasurements() === 'metric') {
            newValue = MeasurementUnitsConverter.toGramms(value, toUom) * 100
            // round to 0 decimal places
            // newValue = Math.round(newValue)
        } else {
            newValue = MeasurementUnitsConverter.toGramms(value, toUom)
            // round to 2 decimal places
            // newValue = Math.round(newValue * 100) / 100
        }

        return newValue
    }
    convertSizeTotalToUser(value, fromUom) {
        let newValue = value

        if (this.getUserMeasurements() === 'metric') {
            if ( fromUom === 'g') {
                newValue = value
            } else {
                newValue = MeasurementUnitsConverter.toGramms(value, fromUom)
            }
            // round to 0 decimal places
            // newValue = Math.round(newValue)
        } else {
            if (fromUom === 'fl oz' || fromUom === 'oz') {
                newValue = value
            } else {
                newValue = MeasurementUnitsConverter.toOunces(value, fromUom)
            }
            // round to 2 decimal places
            // newValue = Math.round(newValue * 100) / 100
        }

        return newValue
    }
    convertSizeTotal(value, fromUom, toUom) {
        let newValue = value

        if (this.isMetricUom(toUom)) {
            newValue = MeasurementUnitsConverter.toGramms(value, fromUom)
            // round to 0 decimal places
            // newValue = Math.round(newValue)
        } else {
            newValue = MeasurementUnitsConverter.toOunces(value, fromUom)
            // round to 2 decimal places
            // newValue = Math.round(newValue * 100) / 100
        }

        return newValue
    }
    convertGramsToTeaspoons(value) {
        return MeasurementUnitsConverter.toTeaspoons(value, 'g')
    }

}

