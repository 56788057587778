<template>
    <div class="main-content main-content--button-bottom-fixed">
        <div class="main-content__main">
            <div class="g-container">
                <div class="pmo-learn">
                    <Transition
                        name="slide"
                        mode="out-in"
                    >
                        <div
                            class="robot-notification__track pmo-learn__track"
                            :class="'pmo-learn__track--' + robotPosition"
                        >
                            <div class="robot-notification__image pmo-learn__image">
                                <img
                                    :src="robotImage"
                                    class="robot-notification__image-i pmo-learn__image-i"
                                    alt=""
                                >
                            </div>
                        </div>
                    </Transition>
                    <TransitionGroup
                        name="message"
                        tag="ul"
                        mode="out-in"
                        class="robot-notification__list-message pmo-learn__list-message"
                    >
                        <template
                            v-for="(message, key) in robotMessages"
                            :key="key"
                        >
                            <li
                                v-if="isVisible[key]"
                                class="robot-notification__message pmo-learn__message"
                            >
                                <div v-html="message.text" />
                            </li>
                        </template>
                    </TransitionGroup>
                </div>
            </div>
        </div>
        <div
            v-if="showContinueButton"
            class="main-content__bottom"
        >
            <div class="g-container">
                <div class="main-content__bottom-wrap">
                    <el-button
                        type="primary"
                        round
                        class="continue-button"
                        @click="onContinue"
                    >
                        Continue
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import bus from '~/includes/Event'
import userApi from '~/modules/profile/userApi'

const router = useRouter()

const robotMessagesAll = [
    {
        robotPosition: 'left',
        text: 'Pantry makeover has three important parts. First, we help you see how our target ingredients, which are sugar and simple carbs, are affecting the body. ',
        tab: 'learning-modules',
    },
    {
        robotPosition: 'center',
        text: 'Second, we show you which categories of foods typically found in households are most laden with these ingredients.',
        tab: 'pmo',
    },
    {
        robotPosition: 'right',
        text: 'Third, we provide you with an easy to use list where you can find great alternatives in your grocery store to help you reduce the density of the offending ingredients.',
        tab: 'my-items.shop',
    },
    // {
    //     robotPosition: 'right',
    //     text: 'You’ll earn stars along the way, which VersaCorp will convert into dollars in your HSA every month!',
    //     tab: 'my-items.shop',
    // },
    // {
    //     robotPosition: 'right',
    //     text: 'Here’s your first 10 stars for your milestone achivements so far.',
    //     tab: 'my-items.shop',
    // }
]
const robotMessages = ref(robotMessagesAll)
const robotImage = '/images/robots/robot_skate.svg'
const robotPosition = ref('right')
const isVisible = ref([])
const currentSlide = ref(0)
const showContinueButton = ref(false)

function setTimer() {
    setTimeout(() => {
        showCurrentSlide()
        if (currentSlide.value < robotMessagesAll.length) {
            setTimer()
        } else {
            showContinueButton.value = true
        }
    }, 3000)
}

isVisible.value = robotMessagesAll.map(() => false)
showCurrentSlide()
setTimer()

function showCurrentSlide() {
    isVisible.value[currentSlide.value] = true

    setRobotPosition(robotMessages.value[currentSlide.value].robotPosition)
    bus.$emit('set-navbar-active-tab', robotMessages.value[currentSlide.value].tab)
    currentSlide.value++
}

function setRobotPosition(position) {
    robotPosition.value = position
}

async function onContinue() {
    await userApi.setFirstExperience('pmo_learn_slide')
    await router.push({name: 'learning-modules'})
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.pmo-learn {
    overflow: hidden;
    &__track {
        width: 100%;
        margin-bottom: 15px;
        transform: translateX(0);
        transition: 0.8s transform linear;
        &--center {
            transform: translateX(calc(50% - 90px));
        }
        &--right {
            transform: translateX(calc(100% - 180px));
        }
    }
    &__list {
        &-message {
            display: flex;
            flex-direction: column-reverse;
        }
    }
    &__message {
        overflow: hidden;
    }
}

.message-enter-from {
    height: 0;
    opacity: 0;
    padding: 0 20px;
}
.message-enter-active {
    transition: 0.8s opacity ease-in, 0.8s height ease-in, 0.5s padding ease-in;
}
.message-enter-to {
    height: 70px;
    opacity: 1;
    padding: 10px 20px;
}

.message-leave-from {
    height: 0;
    opacity: 1;
}
.message-leave-active {
    transition: 0.1s opacity linear, 0.1s height linear;
}
.message-leave-to {
    height: auto;
    opacity: 0;
}
</style>
