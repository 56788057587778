import axios from 'axios'

export default {
    getByZip(zip) {
        return axios.get('stores/get-by-zip/'+zip)
    },
    neatest() {
        return axios.get('stores/nearest')
    },
    saveStores(stores) {
        return axios.post('settings/profile/save-store-list', {store_list: stores})
    },
}
