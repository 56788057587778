import {mapGetters} from 'vuex'
import quizApi from '~/modules/quiz/quizApi'
import Dialog1 from '~/modules/quiz/components/motivations_dialog/Dialog1'
import Dialog2 from '~/modules/quiz/components/motivations_dialog/Dialog2'
import Dialog3 from '~/modules/quiz/components/motivations_dialog/Dialog3'
import Dialog4 from '~/modules/quiz/components/motivations_dialog/Dialog4'
import Dialog5 from "~/modules/quiz/components/motivations_dialog/Dialog5"

export default {
    components: {
        // Dialog1,
        Dialog2,
        // Dialog3,
        // Dialog4,
    },
    data() {
        return {
            section_2_page: '7',
        }
    },
    computed: {
        ...mapGetters('quiz_motivations', [
            'A',
            'B',
            'C',
            'next_type',
        ]),
        ...mapGetters('quiz', [
            'wasNope',
        ])
    },
    watch:{
        $route (to, from) {
            if (this.page == this.section_2_page && from.query.page == 10 && to.query.page == this.section_2_page) {
                this.localQuestions = this.localQuestions.map(o => {
                    delete o.answer
                    return o
                })
            }
            this.handleDialog()
        }
    },
    mounted() {
        this.handleDialog()
        this.showQuestionOnBack()
    },
    methods: {
        calculateRate(question_id, variant) {
            this.handleType(question_id, variant)
            this.handleConfirmInput(question_id, variant)
        },
        handleType(question_id, variant) {
            let question = _.find(this.localQuestions, {_id: question_id})

            if ([1, 4].includes(question.additional_info?.section) && variant.addition) {
                this.$store.commit('quiz_motivations/setType', {
                    question_id: question_id,
                    type: variant.addition,
                    score: question.additional_info.score,
                })

                let max_type_score = _.max([this.A, this.B, this.C])
                let max_type_name = this.A === max_type_score ? 'A' : this.B === max_type_score ? 'B' : 'C'
                if (this.$route.query?.page == this.section_2_page - 1 && question.additional_info?.section === 1) {
                    this.setSubQuestionsByType(max_type_name)
                }

                if (Number(this.$route.query?.page) === 10) { // finish additional questions
                    console.log(123123123)
                    console.log('this.$route.params.slug', this.$route.params.slug)
                    console.log('page: this.section_2_page',this.section_2_page)
                    this.setSubQuestionsByTypeAfter4Section(max_type_name)
                    this.$store.commit('quiz/setCustomContinueBtnUrl', this.$router.resolve({name: 'quiz.show', params: {slug: this.$route.params.slug}, query: {page: this.section_2_page}}))
                }
            }
        },
        async handleConfirmInput(question_id, variant) {
            if (variant === 'Yes') {
                this.loading = true
                await this.$bus.$emit('saveAnswers')
                let selected_type = _.find(this.$parent.localQuestions[0].variants, ['_id', this.localQuestions[0].variant_id])?.addition
                await quizApi.saveType(selected_type)
                this.$store.commit('auth/updateUserPersonalType', selected_type)
                this.loading = false
                await this.$router.push({name: 'quiz.health'})
            } else if (variant === 'Nope') {
                this.$store.commit('quiz/riseWasNope')
                if (this.doneAdditionalQuestions()) {
                    let selected_type = _.find(this.$parent.localQuestions[0].variants, ['_id', this.localQuestions[0].variant_id])?.addition
                    this.$store.dispatch('quiz_motivations/markTypeAsDone', selected_type).then(() => {
                        if (this.next_type.length === 2) { // is subtype
                            let parent_question_id = this.$parent.localQuestions[0]._id
                            let parent_variant_id = _.find(this.$parent.localQuestions[0].variants, ['addition', this.next_type])._id
                            this.$parent.setSubQuestions(parent_variant_id, parent_question_id)
                            this.$router.push({name: 'quiz.show', params: {slug: this.$route.params.slug}, query: {page: this.section_2_page}})
                        } else if (this.next_type.length === 1) { // is type
                            this.$parent.$parent.setSubQuestionsByType(this.next_type)
                            this.$router.push({name: 'quiz.show', params: {slug: this.$route.params.slug}, query: {page: this.section_2_page}})
                        }
                    })
                } else {
                    let currentPage = Number(this.$route.query?.page) || 1
                    await this.$router.push({
                        name: 'quiz.show',
                        params: {slug: this.$route.params.slug},
                        query: {page: currentPage + 1}
                    })
                }
            }
        },
        doneAdditionalQuestions() {
            return _.sum([this.A, this.B, this.C]) >= 2
        },
        setSubQuestionsByType(type) {
            this.subQuestions = this.questions.filter(q => q.additional_info?.type === type)
        },
        setSubQuestionsByTypeAfter4Section(type) {
            this.$parent.$parent.$parent.$parent.subQuestions = this.$parent.$parent.$parent.$parent.questions.filter(q => q.additional_info?.type === type)
        },
        handleDialog() {
            if (this.$route.params.slug === this.$config.motivationQuizSlug) {
                this._handleMotivationsDialog()
            }
        },
        _handleMotivationsDialog() {
            this.$store.commit('quiz/hideDialog')
            let currentPage = Number(this.$route.query?.page) || 1
            // if (currentPage === 1) {
            //     this.$store.commit('quiz/setDialogComponent', Dialog1)
            //     this.$store.commit('quiz/hideQuestions')
            //     this.$store.commit('quiz/riseDialog')
            // }
            if (currentPage === 7) {
                this.$store.commit('quiz/setDialogComponent', Dialog2)
                this.$store.commit('quiz/riseDialog')
            }
            // if (currentPage === 7) {
            //     this.$store.commit('quiz/setDialogComponent', Dialog3)
            //     this.$store.commit('quiz/hideQuestions')
            //     this.$store.commit('quiz/riseDialog')
            // }
            // if (currentPage === 8 && !this.wasNope) {
            //     this.$store.commit('quiz/setDialogComponent', Dialog4)
            //     this.$store.commit('quiz/hideQuestions')
            //     this.$store.commit('quiz/riseDialog')
            // }
            // if (currentPage === 8 && this.wasNope) {
            //     this.$store.commit('quiz/setDialogComponent', Dialog5)
            //     this.$store.commit('quiz/hideQuestions')
            //     this.$store.commit('quiz/riseDialog')
            // }
            this.$store.commit('quiz/setCustomContinueBtnUrl', '')
        },
        showQuestionOnBack() {
            window.onpopstate = () => {
                if (this.$route.name === 'quiz.show') {
                    this.$store.commit('quiz/hideDialog')
                    this.$store.commit('quiz/riseQuestions')
                    this.$emit('done', true)
                }
            }
        }
    },
}
