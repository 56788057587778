import { ref } from 'vue';
import axios from "axios";

export function useLowInternetConnection() {
  const isLowConnect = ref(false)
  const requestQueries = ref([])
  const arrayApiQueries = {
    s30: [
      'pmo/categories',
      'labelinsight/product',
      'dislike',
    ],
    s60: [
      'pmo/categories/product-pmo-categories-by-gtin',
      'coach'
    ]
  }

  axios.interceptors.request.use(config => {
    config.id = _.uniqueId();
    let maxRequestTime = 15 * 1000

    if (arrayApiQueries.s30.some(substring => config.url.includes(substring))) {
      maxRequestTime = 30 * 1000

    }if (arrayApiQueries.s60.some(substring => config.url.includes(substring))) {
      maxRequestTime = 60 * 1000
    }

    requestQueries.value.push(config.id)
    setTimeout(() =>{
      const index = requestQueries.value.indexOf(config.id);

      if (index > -1) {
        isLowConnect.value = true
        requestQueries.value.splice(index, 1);
      } else {
        isLowConnect.value = false
      }
    }, maxRequestTime)

    return config;
  }, error => {
    return Promise.reject(error);
  });

  axios.interceptors.response.use(response => {
    isLowConnect.value = false;
    const id = response.config.id;

    const index = requestQueries.value.indexOf(id);

    if (index > -1) {
      requestQueries.value.splice(index, 1);
    }

    return response;
  }, error => {
    const id = error?.response?.config?.id;
    if(id) {
      const index = requestQueries.value.indexOf(id);

      if (index > -1) {
        requestQueries.value.splice(index, 1);
      }
    }

    return Promise.reject(error);
  });


  return { isLowConnect };
}
