<template>
    <section
        class="main-content main-content--button-bottom-fixed questions-page"
    >
        <div class="main-content__main">
            <div class="g-container">
                <div>
                    <el-image
                        :src="bannerUrl"
                        fit="contain"
                        alt="banner logo will be here"
                    />
                </div>
                <el-input
                    v-model="loyaltyNumber"
                    :placeholder="loyaltyProgramName+ ' number or phone number'"
                    class="loyalty-number-input"
                />
                <div>
                    <p>
                        Please enter your {{ loyaltyProgramName }} number or phone number
                    </p>
                </div>
                <div
                    v-if="showSuccessMessage"
                    class="default-text"
                >
                    {{ successMessage }}
                </div>
                <div>
                    <el-button
                        v-if="loyaltyNumber.length === 0"
                        type="primary"
                        round
                        class="continue-button no-loyalty-number"
                        @click="noNmuberSaveData"
                    >
                        No Loyalty Number
                    </el-button>
                </div>
            </div>
        </div>
        <div class="main-content__bottom">
            <div class="g-container">
                <div class="main-content__bottom-wrap">
                    <el-button
                        v-if="loyaltyNumber"
                        :disabled="loyaltyNumber.length === 0 || loading"
                        :loading="loading"
                        type="primary"
                        round
                        class="continue-button"
                        @click="saveData"
                    >
                        Continue
                    </el-button>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>

import {ref, computed, onMounted} from 'vue'
import quizApi from '~/modules/quiz/quizApi'
import userApi from '~/modules/profile/userApi'
import router from '~/router'
import bus from '~/includes/Event'
import storeVuex from '~/store'

const loading = ref(false)
const questionModuleImage = ref('/images/quiz/household.png')
const questionModuleName = ref('About your household')

const user = computed(() => storeVuex.getters['auth/user'])
const loyaltyNumber = ref((user.value.loyalty_number)||'')
const bannerUrl = ref('')
const bannerName = ref('')
const loyaltyProgramName = ref('')

onMounted(() => {
    bus.$emit('set-title', 'About your household')
    setPrimaryStoreData()
})

async function saveData() {
    loading.value = true
    await userApi.saveLoyaltyNumber(loyaltyNumber.value)
    await quizApi.setHouseholdDone()
    await router.push({name: 'quiz.index'})
    loading.value = false
}

async function noNmuberSaveData() {
    loading.value = true
    await quizApi.setHouseholdDone()
    await router.push({name: 'quiz.index'})
    loading.value = false
}

function setPrimaryStoreData() {
    if (user.value && user.value.stores) {
        user.value.stores.forEach(store => {
            if(store.is_primary) {
                bannerUrl.value = store.store_image_url
                bannerName.value = store.banner
                loyaltyProgramName.value = store.loyalty_program_name||'Albertsons for U'
            }
        })
    }
}

</script>

<style scoped>
.no-loyalty-number {
    margin-top: 50px;
}
</style>
