import axios from 'axios'
import store from '~/store'

const API_ENDPOINT = 'quiz'

export default {

    getQuizzes() {
        return axios.get(API_ENDPOINT)
    },

    getSubQuiz(slug) {
        return axios.get(`${API_ENDPOINT}/${slug}`)
    },

    saveAnswers(quiz) {
        return axios.post(API_ENDPOINT + '/save-answers', quiz)
    },

    setDone(quiz_id) {
        return axios.post(API_ENDPOINT + '/set-done/' + quiz_id)
    },

    setMotivationsDone() {
        return axios.post(API_ENDPOINT + '/set-motivations-done')
    },

    setHouseholdDone() {
        return axios.post(API_ENDPOINT + '/set-household-done')
    },

    setFDM(data) {
        return axios.post(API_ENDPOINT + '/set-fdm', data)
    },

    saveType(type) {
        return axios.post('settings/profile/save-type', {personal_type: type})
    },

    saveAspiration(value) {
        return axios.post('settings/profile/save-aspiration', {aspiration: value})
    },

    isQuizStarted(slug) {
        const user = store.getters['auth/user']
        return user.first_experience && (user.first_experience['quiz_' + slug + '_starter'] ?? false)
    },
}
