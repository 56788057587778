<template>
    <section class="main-content main-content--base-layout">
        <div v-if="!user.is_fdm && (!user.stores || user.stores.length < 1)">
            <div class="text-center">
                Loyalty program is not available until FDM selects the store
            </div>
        </div>
        <div v-else>
            <div class="main-content__top-content-section">
                <div class="g-container">
                    <div>
                        <el-image
                            v-if="bannerUrl"
                            :src="bannerUrl"
                            fit="contain"
                            alt="banner logo will be here"
                        />
                    </div>
                    <el-input
                        v-model="loyaltyNumber"
                        :placeholder="loyaltyProgramName + ' number or phone number'"
                        class="loyalty-number-input"
                        :disabled="!user.is_fdm"
                    />
                    <div>
                        <p>
                            Please enter your {{ loyaltyProgramName }} number or phone number
                        </p>
                    </div>
                    <div
                        v-if="showSuccessMessage"
                        class="default-text"
                    >
                        {{ successMessage }}
                    </div>
                </div>
            </div>
            <div
                class="main-content__bottom-content-section"
                v-if="user.is_fdm"
            >
                <div class="g-container">
                    <div class="main-content__buttons-wrap">
                        <div class="main-content__button-col">
                            <el-button
                                type="primary"
                                round
                                class="continue-button"
                                @click="onSubmit"
                            >
                                Save Profile
                            </el-button>
                        </div>
                        <div class="main-content__button-col">
                            <el-button
                                type=""
                                round
                                class="continue-button"
                                @click="onCancel"
                            >
                                Cancel
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script setup>
import {ref, computed, onMounted, nextTick} from 'vue'
// import storeApi from '~/modules/stores/storeApi'
import userApi from '~/modules/profile/userApi'
import router from '~/router'
import bus from '~/includes/Event'
import storeVuex from '~/store'

const user = computed(() => storeVuex.getters['auth/user'])
const loading = ref(false)
const showErrorMessage = ref(false)

const loyaltyNumber = ref((user.value.loyalty_number)||'')

const showSuccessMessage = ref(false)
const successMessage = ref('Loyalty Number has been saved')

const bannerUrl = ref('')
const bannerName = ref('')
const loyaltyProgramName = ref('')

onMounted(() => {
    bus.$emit('set-title', 'Loyaty Program')
    setPrimaryStoreData()
})

async function saveData() {
    loading.value = true
    await userApi.saveLoyaltyNumber(loyaltyNumber.value)
    await storeVuex.dispatch('auth/fetchUser')
    await nextTick()
    showSuccessMessage.value = true
    loading.value = false
}

function onSubmit() {
    showErrorMessage.value = false

    saveData()

}

function onCancel() {
    router.back()
}

function setPrimaryStoreData() {
    if (user.value && user.value.stores) {
        user.value.stores.forEach(store => {
            if(store.is_primary) {
                bannerUrl.value = store.store_image_url
                bannerName.value = store.banner
                loyaltyProgramName.value = store.loyalty_program_name||'Albertsons for U'
            }
        })
    }
}

</script>

<style lang="scss" scoped>
.error-message {
    position: relative;
}
</style>
