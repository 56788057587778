<template>
    <div
        class="main-content main-content--base-layout"
    >
        <div class="main-content__top-content-section">
            <div class="g-container">
                <quiz-question-rate
                    :question-text="questionText"
                    :range-list="range_list"
                    :range-value="range_value"
                    @changeRangeValue="getRangeValue"
                />
                <div v-if="!user.phone_number">
                    Please add phone number on your profile page.
                </div>
                <div class="notifications-switchers">
                    <div>
                        <label>
                            SMS Notifications
                        </label>
                        <el-switch
                            v-model="sms_notifications"
                        />
                    </div>
                    <div>
                        <label>
                            Email Notifications
                        </label>
                        <el-switch
                            v-model="email_notifications"
                        />
                    </div>
                </div>
                <div v-if="showSuccessMessage">
                    Your preferences has been changed successfully.
                </div>
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__button-wrap">
                    <el-button
                        :disabled="loading"
                        :loading="loading"
                        type="primary"
                        round
                        class="continue-button"
                        @click="submit"
                    >
                        Save
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import userApi from '~/modules/profile/userApi'
import QuizQuestionRate from '~/base/components/quiz-question-range/QuizQuestionRange'

export default {
    name: 'QuizRocShow',
    components: {QuizQuestionRate},
    data() {
        return {
            done: false,
            loading: false,
            roc: '60 days',
            showSuccessMessage: false,
            showErrorMessage: false,
            sms_notifications: true,
            email_notifications: true,
            range_value: 1,
            range_list: [
                {
                    value: '90 days',
                    img: '/images/robots/robot_profile.svg',
                    text: 'Awesome! In 90 days you will have completed your Pantry Makeover. <br/>' +
                        'In order to support this progress I will reach out <b>one time a week</b> with a text message or email.',
                    class: '',
                },
                {
                    value: '60 days',
                    img: '/images/robots/robot_skate.svg',
                    text: 'Super! In 60 days you will have completed your Pantry Makeover. <br/>' +
                        'In order to support this progress I will reach out <b>twice a week</b> with a text message or email.',
                    class: 'active',
                },
                {
                    value: '30 days',
                    img: '/images/robots/robot_rocket.svg',
                    text: 'Great! In under 30 days you will have completed your Pantry Makeover. <br/>' +
                        'In order to support this progress I will reach out <b>daily</b> with a text message or email.',
                    class: '',
                },
            ],
            questionText: 'The actual time to complete your Pantry Makeover will take anywhere from 1 - 6 hours depending on the number of products you choose to replace. We recommend you do this over time so you have an opportunity to integrate healthier products all while we support your participation. We have divided this process up into prioritized bite sized pieces. Over which time frame would you like to complete you Pantry Makeover and move toward your improved aspiration and health status?',
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
    },
    mounted() {
        let range_index = this.range_list.indexOf(_.find(this.range_list, ['value', this.user.roc]))
        this.range_value = range_index==-1?1:range_index
        this.roc = this.user.roc??'60 days'
        this.sms_notifications = Boolean(this.user.sms_notifications)
        this.email_notifications = Boolean(this.user.email_notifications)
    },
    methods: {
        async submit() {
            this.loading = true
            userApi.saveRoc({
                roc: this.roc,
                sms_notifications: this.sms_notifications,
                email_notifications: this.email_notifications,
            }).then(() => {
                this.showSuccessMessage = true
                this.user.roc = this.roc
                this.user.sms_notifications = this.sms_notifications
                this.user.email_notifications = this.email_notifications
            }).catch(error => {
                if (error.response.data.message) {
                    this.$message.error(error.response.data.message)
                }
            }).finally(() => this.loading = false)
        },
        getRangeValue(value){
            this.roc = value
        }
    }
}
</script>
