import Child from '~/base/components/Child'
import Index from './components/Index'
import store from '~/store'
import NetCarbsIndex from "./components/NetCarbsIndex.vue";

export const REGISTER_STEP = 1

const meta = {
    auth: true,
    layout: 'Profile',
    step: REGISTER_STEP
}

export default [
    {
        path: 'sugar-reduction-report',
        component: Child,
        children: [
            {
                path: '',
                name: 'sugar-reduction-report',
                component: Index,
                meta,
            },
        ]
    },
    {
        path: 'net-carbs-reduction-report',
        component: Child,
        children: [
            {
                path: '',
                name: 'net-carbs-reduction-report',
                component: NetCarbsIndex,
                meta,
            },
        ]
    },
]
