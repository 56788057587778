<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="profile-info__form-section-title">
                    {{ formSectionTitle }}
                </div>
                <child-list :key="child_list_key" />
                <div class="profile-info">
                    <el-form
                        :model="form"
                        class="personal-info__form"
                        @submit.prevent.stop="onSubmit"
                    >
                        <div class="personal-info__form-section">
                            <el-form-item
                                class="profile-info__form-section-item"
                                :error="formErrors.get('email')"
                            >
                                <label class="el-form-item__label--floating-label">
                                    <el-input
                                        v-model="form.email"
                                        type="email"
                                        name="email"
                                        placeholder=" "
                                        class="el-input--floating-label"
                                    >
                                        <template #prefix>
                                            Email
                                        </template>
                                    </el-input>
                                </label>
                            </el-form-item>
                        </div>
                        <input
                            type="hidden"
                            name="dummy"
                            value="dummy"
                        >
                    </el-form>
                </div>
            </div>
            <div class="g-container">
                <div class="main-content__buttons-wrap">
                    <div class="main-content__button-col">
                        <el-button
                            type="primary"
                            round
                            class="continue-button"
                            :loading="loading"
                            @click="onSubmit"
                        >
                            Send Invite
                        </el-button>
                    </div>
                    <div class="main-content__button-col">
                        <el-button
                            type=""
                            round
                            class="continue-button"
                            @click="onCancel"
                        >
                            Cancel
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed, onMounted,} from 'vue'
import axios from 'axios'
import {useRoute, useRouter} from 'vue-router'
import store from '~/store'
import {Errors} from '~/includes/Errors'
import ChildList from './child_user/ChildList'
import bus from '~/includes/Event'

const router = useRouter()
const route = useRoute()
const user = computed(() => store.getters['auth/user'])
const loading = ref(false)

const form = ref({
    email: '',
    invite_link: ''
})

const child_list_key = ref(1)

const formErrors = ref(new Errors())

const formSectionTitle = ref('Additional Users')

onMounted(() => bus.$emit('set-title', formSectionTitle.value))

const onSubmit = (event) => {
    event.preventDefault()
    formErrors.value.record([])
    if(form.value.email) {
        loading.value = true
        axios.post('user_invite/send-invite', form.value)
            .then((response) => {
                // ElMessage.success({
                //     message: 'Invitation sent successfully',
                //     duration: 3000,
                // })
                form.value.invite_link = response.data.invite_link
                child_list_key.value++
                form.value.email = ''
            })
            .catch(err => {
                console.log(err)
                formErrors.value.record(err.response.data.errors)
            }).finally(() => {
                loading.value = false
            })
    } else {
        formErrors.value.record({'email':['The email must be a valid email address.']})
    }
}

function onCancel() {
    router.push('/profile')
}

async function copyInviteLink() {
    // await invite_link.focus()
    await form.value.invite_link.$el.querySelector('input').focus()
    await form.value.invite_link.$el.querySelector('input').select()
    document.execCommand('copy')
}
</script>

<style lang="scss" scoped>
.invite-link {
    &__input {
        margin-bottom: 10px;
    }
}
</style>
