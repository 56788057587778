<template>
    <header class="header">
        <div class="main-header">
            <div class="header__left-side">
                <div
                    v-if="!isWizardPage"
                    class="header__menu"
                    @click="toggleMenu"
                >
                    <i
                        v-if="route.query.goto === 'summary'"
                        class="header__menu-icon fa-solid fa-arrow-left"
                    />
                    <i
                        v-else
                        class="header__menu-icon fa fa-bars"
                    />
                </div>
            </div>
            <div class="header__center">
                <header-title />
            </div>
            <div class="header__right-side">
                <StarCount
                    v-if="showPoints"
                    :star-count="user.points"
                />
            </div>
            <template v-if="!isWizardPage">
                <div
                    class="header__user-menu"
                    :class="{'menu-open': openMenu}"
                >
                    <user-menu
                        @close="close"
                    />
                </div>
                <div
                    class="header__overlay"
                    :class="{'menu-open': openMenu}"
                    @click="toggleMenu"
                />
            </template>
        </div>
    </header>
</template>

<script setup>
import {ref, computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import store from '~/store'
import UserMenu from '~/base/components/user-menu/UserMenu'
import HeaderTitle from '~/base/components/HeaderTitle'
import StarCount from '~/base/components/star-count/StarCount'

const router = useRouter()
const route = useRoute()

const user = store.getters['auth/user']

const showPoints = user.points > 0 && !route.name.includes('intro') && user.client_level && user.client_level.has_starts_points_system === true

const isWizardPage = computed(() => _.includes(route.name, 'quiz.'))

const openMenu = ref(false)

function toggleMenu() {
    if (route.query.goto === 'summary') {
        router.push({
            name: 'pmo.summary',
            params: {pantry: route.query.pantry}
        })
    } else {
        openMenu.value = !openMenu.value
    }
}

function close() {
    openMenu.value = false
}
</script>
