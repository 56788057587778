import axios from 'axios'

const API_ENDPOINT = 'view-notifications'

export default {

    getNotify() {
        return axios.get(API_ENDPOINT)
    },
    readingMessages(payload) {
        return axios.post(API_ENDPOINT + '/reading', payload)
    },
    getCountUnreadNotifications() {
        return axios.get(API_ENDPOINT + '/count-unread-messages')
    }
}
