<template>
    <div
        class="main-content main-content--base-layout"
    >
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div>
                    Please, select your preferred measurement system.
                </div>
                <el-radio-group
                    v-model="measurements"
                    class="ml-4"
                >
                    <el-row>
                        <el-radio
                            label="imperial"
                            size="large"
                        >
                            Imperial
                        </el-radio>
                    </el-row>
                    <el-row>
                        <el-radio
                            label="metric"
                            size="large"
                        >
                            Metric
                        </el-radio>
                    </el-row>
                </el-radio-group>
                <div v-if="showSuccessMessage">
                    Your preferences has been changed successfully.
                </div>
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__buttons-wrap">
                    <div class="main-content__button-col">
                        <el-button
                            type="primary"
                            round
                            class="continue-button"
                            @click="onSubmit"
                        >
                            Save
                        </el-button>
                    </div>
                    <div class="main-content__button-col">
                        <el-button
                            type=""
                            round
                            class="continue-button"
                            @click="onCancel"
                        >
                            Cancel
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed, onMounted, nextTick} from 'vue'
// import storeApi from '~/modules/stores/storeApi'
import userApi from '~/modules/profile/userApi'
import router from '~/router'
import bus from '~/includes/Event'
import storeVuex from '~/store'
const user = computed(() => storeVuex.getters['auth/user'])
const loading = ref(false)
const showErrorMessage = ref(false)
const measurements = ref((user.value.measurements)||'imperial')
const showSuccessMessage = ref(false)

onMounted(() => {
    bus.$emit('set-title', 'Measurement Settings')
})
async function saveData() {
    loading.value = true
    await userApi.saveMeasurements(measurements.value)
    await storeVuex.dispatch('auth/fetchUser')
    await nextTick()
    showSuccessMessage.value = true
    loading.value = false
}
function onSubmit() {
    showErrorMessage.value = false
    saveData()
}
function onCancel() {
    router.back()
}
</script>

<style scoped>
.el-radio-group {
    display: block;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

</style>
