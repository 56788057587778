<template>
    <invite-dialog
        :user-invite="userInvite"
        :dialog-show="showModalInvite"
        @close="handleClose"
    />
</template>

<script setup>
import axios from 'axios'
import { ref } from 'vue'
import InviteDialog from '~/modules/quiz/components/household/InviteDialog'
import store from '~/store'

import { h } from 'vue'
import { ElMessage, ElNotification } from 'element-plus'

const POLL_INTERVAL_SECONDS = 60*5

const userInvite = ref({})

const showModalInvite = ref(false)

checkForNotification()

function checkForNotification() {
    if (! store.getters['auth/check']) return

    axios.get('/profile/get-notifications')
        .then(response => {
            if (response.data.notifications) {
                _.forEach(response.data.notifications, (n, key) => {
                    if (store.getters['base/invite_notifications'].includes(n.email)) return
                    store.commit('base/addInviteNotifications', n.email)
                    let notify_data
                    if (key === 'user_invite') {
                        if(isNotificationLoaded(userInvite.value.id)) {
                            return
                        }

                        userInvite.value = n
                        const link = h('a', {href: '#', onClick: handleOpen, class: 'el-notification__content-link'}, 'Invite')
                        const text = h('span', { class: 'el-notification__content-text' }, ' your grocery decision-maker to use SeekingSimple.')
                        notify_data = {
                            dangerouslyUseHTMLString: true,
                            message: h('div', { class: 'el-notification__content-wrap' }, [link, text]),
                            duration: 10 * 1000,
                            type: 'success',
                            customClass: 'el-notification--green',
                        }

                        saveNotificationId(userInvite.value.id)
                    } else {
                        notify_data = {
                            message: n.message,
                            type: n.type,
                            duration: 10 * 1000,
                            dangerouslyUseHTMLString: true,
                            customClass: 'el-notification--green',
                        }
                    }
                    setTimeout(() => { ElMessage(notify_data) }, 3)
                })
            }
        })
        .catch(error => {
            console.log(error)
        })
        .finally(() => {
            setTimeout(checkForNotification, 1000 * POLL_INTERVAL_SECONDS)
        })
}

function handleClose() {
    showModalInvite.value = false
}

function handleOpen() {
    ElNotification.closeAll()
    showModalInvite.value = true
    axios.post('/profile/mark-invite-notifications', {id: userInvite.value.id}).then(response => {
        store.commit('base/clearInviteNotifications')
        //currently do nothing
    }).catch(errors => {
        console.error(errors)
        //currently do nothing
    })
}

function saveNotificationId(notificationId) {
    let notifications = getNotificationsUserInvite()
    // check if notification already exists
    if (!isNotificationLoaded(notificationId)) {
        if(!Array.isArray(notifications)) {
            notifications = []
        }
        notifications.push(notificationId)
        localStorage.setItem('notifications', JSON.stringify(notifications))
    }
}

function isNotificationLoaded(notificationId) {
    let notifications = getNotificationsUserInvite()
    // check if notification already exists
    return Array.isArray(notifications) && notifications.find( notification => { return  notification == notificationId})
}

function getNotificationsUserInvite() {
    return JSON.parse(localStorage.getItem('notifications'))
}
</script>
