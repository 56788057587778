<template>
    <el-select
        v-model="netWeightUnit"
        :placeholder="placeholder"
        class="add-product__input__unit w-100"
    >
        <el-option
            v-for="item in validUnits"
            :key="item"
            :label="item.label"
            :value="item.value"
        />
    </el-select>
    <el-input
        v-if="netWeightUnit === 'custom'"
        v-model="customUnit"
        placeholder="Choose a custom unit"
        class="add-product__input"
    />
</template>

<script setup>
import {onMounted, ref, computed, watch} from 'vue'
const validUnits = [
    {
        value: 'ml',
        label: 'Milliliters',
    },
    {
        value: 'l',
        label: 'Liters',
    },
    {
        value: 'tsp',
        label: 'Teaspoons',
    },
    {
        value: 'tbsp',
        label: 'Tablespoons',
    },
    {
        value: 'fl oz',
        label: 'Fluid Ounces',
    },
    {
        value: 'cup',
        label: 'Cups',
    },
    {
        value: 'pint',
        label: 'Pints',
    },
    {
        value: 'qt',
        label: 'Quarts',
    },
    {
        value: 'gal',
        label: 'Gallons',
    },
    {
        value: 'mg',
        label: 'Milligrams',
    },
    {
        value: 'g',
        label: 'Grams',
    },
    {
        value: 'kg',
        label: 'Kilograms',
    },
    {
        value: 'oz',
        label: 'Ounces',
    },
    {
        value: 'lb',
        label: 'Pounds',
    },
    {
        value: 'custom',
        label: 'Other',
    },

]

const props = defineProps({
    modelValue: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: 'Measurement',
    }
})

const emit = defineEmits()

const customUnit = ref('')
const netWeightUnit = ref('')
const newNetWeightUnit = ref('')

onMounted(() => {
    netWeightUnit.value = props.modelValue
})

watch(customUnit, (value) => {
    if (value && netWeightUnit.value == 'custom') {
        newNetWeightUnit.value = customUnit.value
        emit('update:modelValue', value)
    }
})

watch(netWeightUnit, (value) => {
    if (value && value !== 'custom') {
        newNetWeightUnit.value = value
        emit('update:modelValue', value)
    }
})



</script>


<style lang="scss" scoped>
.add-product {
  &__input {
    &__unit {
      margin-left: 10px;

      @media all and (max-width: 768px) {
        margin-left: 0;
      }
    }
  }
}
</style>