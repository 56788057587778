<template>
    <section class="main">
        <ClearHeader />
        <Child />
    </section>
</template>

<script setup>
import Child from '~/base/components/Child'
import ClearHeader from '~/base/components/ClearHeader'
</script>
