<template>
    <div class="amount">
        <div class="amount__label">
            <span>{{ title }}</span>
            <el-tooltip
                v-if="!disabled"
                class="box-item"
                content="How Frequently do you consume this?"
                placement="top-end"
                effect="light"
                trigger="click"
            >
                <i class="fa-solid fa-info-circle" />
            </el-tooltip>
        </div>
        <el-form
            :model="form"
            :rules="rules"
        >
            <el-row
                :gutter="10"
                class="main-content__inputs"
            >
                <el-col :span="12">
                    <el-form-item prop="frequency">
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model.number="form.frequency"
                                placeholder=" "
                                class="el-input--floating-label"
                                :disabled="disabled"
                            >
                                <template #prefix>
                                    Times
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="frequency_unit">
                        <label class="el-form-item__label--floating-label">
                            <el-select
                                v-model="form.frequency_unit"
                                placeholder=" "
                                class="el-input--floating-label w-100"
                                :disabled="disabled"
                            >
                                <el-option
                                    v-for="unit in frequency_units_array"
                                    :key="unit"
                                    :label="`times per ${unit.toLowerCase()}`"
                                    :value="unit"
                                />
                                <template #prefix>
                                    Interval
                                </template>
                            </el-select>
                        </label>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script setup>
import {computed, ref} from 'vue'
import {useModelWrapper} from '~/includes/composables/modelWrapper'

const emit = defineEmits()

const props = defineProps({
    frequency: {
        type: Number,
        required: true
    },
    frequencyUnit: {
        type: String,
        required: true
    },
    defaultFrequencyUnit: {
        type: String,
        required: false,
        default: 'Day'
    },
    title: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    max: {
        type: Number,
        default: 20
    },
})

const default_frequency_units_array = [
    'Day',
    'Week',
    // 'Month',
    // 'Year',
]

const form = ref({})

const frequency_units_array = computed(() => {
    let result = []
    const timeIndex = default_frequency_units_array.indexOf(props['defaultFrequencyUnit'])
    default_frequency_units_array.forEach(function (time, index) {
        if (timeIndex <= index) {
            result.push(time)
        }
    })

    return result
})
const rules = computed(() => ({
    frequency: [
        {
            required: true,
        },
        {
            type: 'number',
            max: props.max,
        },
    ]
}))

form.value.frequency = useModelWrapper(props, emit, 'frequency')
form.value.frequency_unit = useModelWrapper(props, emit, 'frequencyUnit')
</script>
