<template>
    <robot-response>
        <h4>Do you feel like the Pantry Makeover is helping improve how often you are experiencing:</h4>
        <div v-if="sub_questions">
            <div
                v-for="question in sub_questions"
                :key="question._id"
                class="quiz-question"
            >
                <div
                    class="default-text quiz-question__text"
                    v-html="question.text"
                />
                <div
                    v-if="question.additional_info && question.additional_info.top_image"
                    class="quiz-question__image quiz-question__image--centered"
                >
                    <div class="quiz-question__image-wrap">
                        <img
                            :src="question.additional_info.top_image"
                            class="quiz-question__image-i"
                            alt=""
                        >
                    </div>
                </div>
                <form-rate
                    :question="question"
                    class="el-radio-group-bordered"
                    @set="handleChange"
                />
            </div>
            <div class="main-content__button-wrap">
                <el-button
                    :loading="loading"
                    type="primary"
                    round
                    class="continue-button"
                    @click="handleContinue"
                >
                    Continue
                </el-button>
            </div>
        </div>
    </robot-response>
</template>

<script setup>
import {ref, onMounted, getCurrentInstance} from 'vue'
import quizApi from '~/modules/quiz/quizApi'
import userApi from '~/modules/profile/userApi'
import FormRate from '../inputs/FormRate'
import {useRouter} from 'vue-router'
import store from '~/store'
import RobotResponse from './RobotResponse'

const { proxy } = getCurrentInstance()

const router = useRouter()
const user = store.getters['auth/user']

onMounted(() => fetchQuestion())

const aspiration = ref(null)
const sub_questions = ref(null)
const old_choice = ref({})
const new_choice = ref({})
const loading = ref(false)

async function fetchQuestion() {
    const {data} = await quizApi.getSubQuiz(proxy.$config.aspirationQuizSlug)

    if (!!data) {
        aspiration.value = _.find(data.questions[0].variants, {addition: user.aspiration})
        setAspirationStress()
    }
}

function setAspirationStress() {
    const answers = _.toArray(aspiration.value.questions[0].answer)
    let variants = aspiration.value.questions[0].variants.filter(o => answers.includes(o.value))

    if (variants.length === 0) {
        variants = [_.sample(aspiration.value.questions[0].variants)]
        aspiration.value.questions[0].answer = variants.map(o => o._id)
    }

    const var_types = variants.map(o => o.addition)
    sub_questions.value = aspiration.value.questions.filter(q => var_types.includes(q.additional_info?.type))

    let values = {}
    sub_questions.value.forEach(o => {
        if (o.answer) {
            values[o.additional_info.type] = _.find(o.variants, {_id: o.answer}).value
        }
    })
    old_choice.value = _.clone(values)
    new_choice.value = _.clone(values)
}

function handleChange(question_id, answer) {
    sub_questions.value.forEach(o => {
        const new_answer = (o._id === question_id) ? answer : o.answer
        new_choice.value[o.additional_info.type] = _.find(o.variants, {_id: new_answer}).value
    })
}

function handleContinue() {
    loading.value = true
    let status = 'no_change'

    let at_least_one_better = false
    let at_least_one_worse = false
    _.forEach(old_choice.value, (value, key) => {
        const more_is_better = ['tired'].includes(key)

        if (more_is_better) {
            if (new_choice.value[key] > value) at_least_one_better = at_least_one_better || true
            if (new_choice.value[key] < value) at_least_one_worse = at_least_one_worse || true
        } else {
            if (new_choice.value[key] < value) at_least_one_better = at_least_one_better || true
            if (new_choice.value[key] > value) at_least_one_worse = at_least_one_worse || true
        }
    })

    if (at_least_one_better && at_least_one_worse) status = 'mixed'
    else if (at_least_one_better && !at_least_one_worse) status = 'good'
    else if (!at_least_one_better && at_least_one_worse) status = 'bad'

    console.log('at_least_one_better', at_least_one_better)
    console.log('at_least_one_worse', at_least_one_worse)
    console.log(status)

    // let questionText = sub_questions.value.questions[0].text
    // let answer = new_choice.value.value
    // userApi.saveUserLog({
    //     name: questionText,
    //     value: answer,
    //     reason: proxy.$config.aspirationQuizSlug
    // })

    quizApi.saveAnswers(aspiration.value)

    store.commit('check-in-response/setDialog', {aspiration: 'stress', status})
}
</script>
