<template>
    <div class="how-it-works">
        <div class="container-holder">
            <h2 class="container__title">
                How it Works
            </h2>
            <Carousel
                :settings="settings"
                :breakpoints="breakpoints"
            >
                <Slide
                    v-for="slide in items"
                    :key="slide.text"
                >
                    <div class="carousel__item">
                        <p class="carousel__text">
                            {{ slide.text }}
                        </p>
                        <div class="carousel__image">
                            <img
                                :src="'/images/landing/' + slide.image"
                                alt=""
                            >
                        </div>
                    </div>
                </Slide>
                <template #addons>
                    <Navigation />
                </template>
            </Carousel>
        </div>
    </div>
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel'

export default {
    name: 'LandingCarousel',
    components: {
        Carousel,
        Slide,
        Navigation
    },

    data() {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                570: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                748: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                1024: {
                    itemsToShow: 4,
                    snapAlign: 'start',
                },
                1440: {
                    itemsToShow: 5,
                    snapAlign: 'start',
                }
            },
            items: [
                {
                    text: 'Proprietary and innovative methods to engage, to create efficacy and to sustain results for the individual and household members.',
                    image: 'slider-01.png'
                },
                {
                    text: 'Focus on household food supply, engage the food decision maker, understand food culture and target ingredient changes in the pantry where it will be least disruptive.',
                    image: 'slider-02.png'
                },
                {
                    text: 'Aggregate key stakeholders (employers, food suppliers, insurers, pharmas, and other health network entities) in a target geography; curate their participation and collaboration; and orchestrate their execution.',
                    image: 'slider-03.png'
                },
                {
                    text: 'Establish the adoption of the SeekingSimple Digital Pantry Makeover in the household and with the food supplier as the epicenter.',
                    image: 'slider-04.png'
                },
                {
                    text: 'Track and maintain progress toward healthier eating.',
                    image: 'slider-05.png'
                },
            ]
        }
    }
}
</script>
