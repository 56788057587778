<template>
    <transition-group
        name="order-game"
        tag="div"
        class="order-game"
    >
        <div
            v-for="product in newProducts"
            :id="`cell_${product.id}`"
            :key="product.id"
            class="order-game__cell"
        >
            <template v-if="product.id === 0">
                <div
                    id="plate"
                    ref="plateEl"
                    class="product-target"
                    @drop="drop"
                    @dragover="allowDrop"
                    @dragenter="overPlate"
                    @dragleave="outPlate"
                >
                    <div class="product-target__text">
                        {{ targetText }}
                    </div>
                </div>
            </template>
            <template v-else>
                <div
                    :id="product.id"
                    class="product"
                    :class="{
                        'product--correct': product.correct === true
                    }"
                    :draggable="!product.correct"
                    @dragstart="dragStart"
                    @touchstart="handleTouchStart"
                    @touchmove="handleTouchMove"
                    @touchend="handleTouchEnd"
                >
                    <div class="product__inner">
                        <div class="product__image">
                            <img
                                :src="product.image"
                                :alt="product.name"
                                class="product__image-i"
                            >
                        </div>
                        <div class="product__status">
                            <i
                                class="product__status-icon fa-solid"
                                :class="{
                                    'fa-circle-check': product.correct === true,
                                    'fa-circle-xmark': product.correct === false
                                }"
                            />
                            <div class="product__status-value">
                                {{ product.value }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </transition-group>
</template>

<script setup>
import {computed, ref} from 'vue'
const name = 'OrderGame'
const targetText = 'Drop here'

const ON_CELL_SECONDS = 3
const emit = defineEmits(['done'])
const plateEl = ref(null)
const productOnPlate = ref(null)

const props = defineProps({
    products: {
        type: Array,
        required: true,
    },
})

const localProducts = ref(props.products.map(o => ({...o, correct: false})))
const correctProducts = ref([])
const dropHereItem = {
    id: 0,
    value: 0,
    name: 'Drop here',
    image: '',
}

const newProducts = computed(() => [...correctProducts.value, dropHereItem, ...localProducts.value])

const setProduct = (product) => {
    if (product.correct) return
    if (product.value === _.minBy(localProducts.value, 'value').value) {
        correctProducts.value.push({...product, correct: true})
        _.remove(localProducts.value, o => o.id === product.id)
        if (localProducts.value.length === 0) emit('done')
    }
    _.delay(() => removeFromPlate(product.id), 1000 * ON_CELL_SECONDS)
}

function dragStart(event) {
    event.dataTransfer.setData('text', event.target.id)
}

function allowDrop(event) {
    event.preventDefault()
}

function overPlate(event) {
    plateEl.value[0].classList.add('over')
}

function outPlate(event) {
    plateEl.value[0].classList.remove('has-product')
}

function drop(event) {
    event.preventDefault()
    let id = event.dataTransfer.getData('text')
    let product = localProducts.value.find(o => o.id == id)
    handleOnPlate(product.id)
    plateEl.value[0].classList.add('has-product')
    const dragElems = document.querySelectorAll('.product[draggable="true"]:not(.product--correct)')
    dragElems.forEach((item) => {
        item.setAttribute('draggable', 'false')
    })
}

function removeFromPlate(id) {
    if (productOnPlate.value?.id === id) productOnPlate.value = null
    document.querySelector(`#cell_${id}`).appendChild(document.getElementById(id))
    const dragElems = document.querySelectorAll('.product[draggable="false"]:not(.product--correct)')
    dragElems.forEach((item) => {
        item.setAttribute('draggable', 'true')
    })
}

function handleOnPlate(id) {
    if (productOnPlate.value) {
        removeFromPlate(productOnPlate.value.id)
    }
    plateEl.value[0].appendChild(document.getElementById(id))
    let product = localProducts.value.find(o => o.id == id)
    setProduct(product)
    productOnPlate.value = product
}

// On mobile
let droppedIn = ref(false)
const activeEvent = ref('')

function handleTouchStart(event) {
    event.preventDefault()
    activeEvent.value = 'start'
    overPlate()
}

function handleTouchMove(event) {
    let path = event.composedPath()
    if (!! localProducts.value.find(o => o.id == path[0].id)?.correct) {
        return
    }
    let touchLocation = event.targetTouches[0]

    let pageX = (touchLocation.pageX - event.target.clientWidth/2) + 'px'
    let pageY = (touchLocation.pageY - event.target.clientHeight/2) + 'px'
    path[0].classList.add('dragging')
    path[0].style.position = 'absolute'
    path[0].style.left = pageX
    path[0].style.top = pageY
    activeEvent.value = 'move'
}

function handleTouchEnd(event) {
    let path = event.composedPath()
    event.preventDefault()
    if (activeEvent.value === 'move') {
        let {pageX, pageY} = event.changedTouches[0]
        if (detectDropOnPlate(pageX, pageY)) {
            handleOnPlate(path[0].id)
            droppedIn = true
            plateEl.value[0].classList.add('has-product')
        }
        path[0].style.removeProperty('position')
        path[0].style.removeProperty('left')
        path[0].style.removeProperty('top')
        path[0].classList.remove('dragging')
    }
}

function detectDropOnPlate(pageX, pageY) {
    return document.elementFromPoint(pageX, pageY).id === plateEl.value[0].id
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.order-game {
    $block-name: &;
    display: flex;
    flex-wrap: wrap;
    margin: 10px -5px 0;
    &__cell {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: calc(100% / 3);
        height: calc(100% / 3);
        margin-bottom: 10px;
        padding: 0 5px;
        background-color: $white;
    }
    &-move {
        transition: 0.75s transform 3s linear;
    }
    .dragging {
        z-index: 2012;
        max-width: 110px;
        max-height: 110px;
        opacity: 0.7;
    }
}

.product {
    $block-name: &;
    width: 100%;
    height: 100%;
    border: 2px solid $gray;
    border-radius: 5px;
    background-color: $white;
    transition: 0.3s max-width ease-in-out, 0.3s max-height ease-in-out, 0.1s top linear, 0.1s left linear;
    &__inner {
        position: relative;
        z-index: 2011;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 98% 0 0;
        user-select: none;
        pointer-events: none;
    }
    &-target {
        position: relative;
        z-index: 2015;
        width: 100%;
        height: 100%;
        padding: 97% 0 0;
        user-select: none;
        &__text {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
            font-size: 15px;
            color: $light-gray;
            border: 2px solid $light-gray;
            border-radius: 5px;
            transition: 0.2s border ease-in-out;
        }
        &.has-product {
            border-color: transparent;
            .product {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-color: $maroon;
                #{$block-name} {
                    &__status {
                        visibility: visible;
                        opacity: 1;
                        &-icon {
                            color: $maroon;
                        }
                        &-value {
                            color: $maroon;
                            font-weight: 700;
                            -webkit-text-stroke: 1px $white;
                        }
                    }
                }
                &--correct {
                    border-color: $indigo;
                    #{$block-name} {
                        &__status {
                            visibility: visible;
                            opacity: 1;
                            &-icon {
                                color: $blue;
                            }
                            &-value {
                                color: $blue;
                                font-weight: 700;
                                -webkit-text-stroke: 1px $white;
                            }
                        }
                    }
                }
            }
        }
    }
    &__image {
        position: absolute;
        left: 0;
        top: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80%;
        pointer-events: none;
        &-i {
            max-width: 100%;
            max-height: 100%;
            pointer-events: none;
        }
    }
    &__status {
        position: absolute;
        top: 10px;
        right: 10px;
        visibility: hidden;
        opacity: 0;
        transition: 1.2s visibility ease-in-out, 1.2s opacity ease-in-out;
        pointer-events: none;
        &-icon {
            border-radius: 10px;
            overflow: hidden;
            font-size: 20px;
            background-color: $white;
        }
        &-value {
            font-size: 26px;
            font-weight: 600;
            text-align: center;
        }
    }
    &--correct {
        border-color: $indigo;
        #{$block-name} {
            &__status {
                visibility: visible;
                opacity: 1;
                &-icon {
                    color: $blue;
                }
                &-value {
                    color: $blue;
                    font-weight: 700;
                    -webkit-text-stroke: 1px $white;
                }
            }
        }
    }
}
</style>
