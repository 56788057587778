<template>
    <div class="question-module">
        <div class="question-module__image">
            <div class="question-module__image-wrap">
                <img
                    :src="image"
                    class="question-module__image-i"
                    alt=""
                >
            </div>
        </div>
        <div class="question-module__title">
            {{ name }}
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    image: {
        type: String,
        default: '',
    },
    name: {
        type: String,
        default: '',
    }
})
</script>
