<template>
    <div class="function-buttons__item">
        <el-button
            type="info"
            round
            bg
            @click="clickShareBtn"
        >
            <span class="el-button__icon el-button__icon--no-offset">
                <inline-svg
                    class="el-button__icon-svg"
                    src="/images/svg-icons/share-icon.svg"
                    fill="#757575"
                />
            </span>
        </el-button>
    </div>

    <el-dialog
        v-model="showShareDialog"
        title="Share the video?"
        custom-class="share-shopping-list__dialog el-dialog--share-shopping-list"
    >
        <ul class="share-shopping-list__dialog-list">
            <li class="share-shopping-list__dialog-list-item">
                <a
                    :href="smsLink"
                    class="share-shopping-list__dialog-link"
                    @click="shareAction"
                >
                    SMS
                </a>
            </li>
            <li class="share-shopping-list__dialog-list-item">
                <a
                    :href="mailToLink"
                    class="share-shopping-list__dialog-link"
                    @click="shareAction"
                >
                    Email
                </a>
            </li>
        </ul>
    </el-dialog>
</template>

<script setup>
import { ref, computed } from 'vue'
import InlineSvg from 'vue-inline-svg'
import pmoApi from '~/modules/pmo/pmoApi'

const props = defineProps({
    'videoLink': {
        type: [ String ],
        required: true,
        default: null
    }
})

const showShareDialog = ref(false)
const smsLink = ref('')
const mailToLink = ref('')


function clickShareBtn() {
    let bodyText = encodeURIComponent(props.videoLink)

    if (isIOSDevice()) {
        smsLink.value = 'sms:;body=' + bodyText
    } else {
        smsLink.value = 'sms:?body=' + bodyText
    }
    mailToLink.value = 'mailto:?body=' + bodyText

    showShareDialog.value = true

}

function isIOSDevice(){
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
}

function shareAction () {
    pmoApi.firstSharedPmo()
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.share-shopping-list {
    $local-light-gray: #f0f0f0;
    &__btn {
        border-color: $blue;
        color: $blue;
        background-color: $local-light-gray;
        transition: 0.2s box-shadow ease-in-out;
        &:hover {
            box-shadow: 0 0 6px rgb(0 0 0 / 20%);
        }
    }
    &__dialog {
        &-list {
            display: flex;
            align-items: center;
            list-style: none;
            margin: 0 -10px;
            padding: 0;
            &-item {
                padding: 0 10px;
            }
        }
        &-link {
            border: 1px solid $indigo;
            border-radius: 18px;
            padding: 6px 15px;
            font-size: 14px;
            font-weight: 600;
            text-decoration: none;
            color: $indigo;
            transition: 0.2s box-shadow ease-in-out;
            &:hover,
            &:active,
            &:focus {
                box-shadow: 0 0 6px rgba(0,0,0, 20%);
            }
        }
    }
}
</style>
