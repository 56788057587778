import axios from 'axios'
import store from '~/store'

const API_ENDPOINT = 'iqpay'

export default {
    firstIqPayOrder() {
        if (! this.isFirstIqPayOrder()) {
            store.commit('auth/addFirstExperienceToParent', 'first_iqpay_order')
            return axios.post(API_ENDPOINT + '/first-iqpay-order')
        }
    },
    isFirstIqPayOrder() {
        const user = store.getters['auth/user']
        return user.parent_user.first_experience && (user.parent_user.first_experience.first_iqpay_order ?? false)
    }
}
