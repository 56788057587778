export const LEVELS = [
    {
        val: 1,
        name: 'Low Sugar'
    },
    {
        val: 2,
        name: 'Moderate Sugar'
    },
    {
        val: 3,
        name: 'High Sugar'
    },
]

export const LOW = 1
export const MODERATE = 2
export const HIGH = 3
