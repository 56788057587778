<template>
    <div
        v-if="dialogStatus !== 'hidden'"
        class="full-screen-message"
    >
        <RobotDialog
            :messages="robotMessages"
            :robot-image="robotImage"
            :show-bricks-animation="false"
            :button-text="buttonText"
            :button-cansel-text="buttonCanselText"
            :button-callback="buttonCallback"
            :button-cansel-callback="buttonCanselCallback"
        />
    </div>
</template>

<script setup>
import {computed} from 'vue'

import store from '~/store'
import RobotDialog from '~/modules/quiz/components/RobotDialog'

const dialogStatus = computed(() => store.getters['shortcut_quiz/robotDialogStatus'])
const robotMessages = computed(() => store.getters['shortcut_quiz/robotMessages'])
const robotImage = computed(() => store.getters['shortcut_quiz/robotImage'])
const buttonText = computed(() => store.getters['shortcut_quiz/buttonText'] || 'Got it')
const buttonCanselText = computed(() => store.getters['shortcut_quiz/buttonCanselText'] || null)


const emit = defineEmits()

function buttonCallback() {
    if (
        dialogStatus.value === 'final'
        || dialogStatus.value === 'mandatory_finish'
    ) emit('showFinalQuestion')

    store.commit('shortcut_quiz/resetRobotDialog')
}

function buttonCanselCallback() {
    store.commit('shortcut_quiz/resetRobotDialog')
}

</script>

<style lang="scss" scoped>
    .full-screen-message {
        position: fixed;
        z-index: 100;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: flex-end;
        padding: 15px;
        background-color: rgba(255,255,255, 65%);
    }
</style>
