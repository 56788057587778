<template>
    <div class="quiz-block">
        <div class="quiz-block__question default-text">
            <p v-if="quiz.type == 'boolean'">
                True or False?
            </p>
            <p v-html="quiz.question" />
        </div>
        <div
            class="quiz-block__buttons"
            :class="[quiz.type, answered ? 'quiz-block__answered' : '']"
        >
            <div
                v-for="variant in quiz.variants"
                :key="variant"
                class="quiz-block__button-col"
            >
                <el-button
                    round
                    type="primary"
                    :class="[
                        checkedVariants[variant] ? 'quiz-block__answer--checked' : '',
                        isCorrect(variant) ? 'quiz-block__answer--correct' : 'quiz-block__answer--incorrect'
                    ]"
                    class="quiz-block__button"
                    @click="clickAnswer(variant)"
                >
                    <template v-if="isCorrect(variant)">
                        <i class="quiz-block__button-answer-correct fa-solid fa-check" />
                    </template>
                    {{ variant }}
                </el-button>
            </div>
        </div>
        <div
            v-if="showExplanation"
            class="quiz-block__answer default-text"
        >
            <p v-html="quiz.explanation" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
const props = defineProps({
    'quiz': {
        type: [ Object ],
        required: true,
        default: null
    }
})
const emit = defineEmits(['clickAnswer:answer'])

const checkedVariants = ref([])
const showExplanation = ref(false)
const key = ref(0)
const answered = ref(false)

props.quiz.variants.forEach((el) => {
    checkedVariants.value[el] = false
})

function clickAnswer(answer) {
    checkedVariants.value[answer] = !checkedVariants.value[answer]
    answered.value = true
    if (isCorrect(answer)) {
        emit('answer', 'correct')
    } else {
        showExplanation.value = true
        emit('answer', 'incorrect')
    }
    key.value++
}

function isCorrect(answer) {
    return props.quiz.answer == answer
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.quiz-block {
   $block-name: &;
    margin-bottom: 15px;
    &:not(:first-child) {
        border-top: 1px solid $light-gray;
        padding-top: 15px;
    }
    &__question {
        margin-bottom: 15px;
        p {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
        b {
            font-weight: 600;
        }
    }
    &__buttons {
        &.boolean {
            display: flex;
            margin: 0 -5px 20px;
            #{$block-name}__button-col {
                width: 50%;
                padding: 0 5px;
                text-align: center;
            }
        }
        &.multiple {
            margin: 0 0 20px;
            #{$block-name}__button-col {
                text-align: center;
                &:not(:last-child) {
                    margin-bottom: 20px;
                }
            }
        }
    }
    &__button {
        max-width: 350px;
        width: 100%;
        transition: 0.2s border-color ease-in-out, 0.2s background-color ease-in-out;
        &-answer-correct {
            display: none;
            margin-right: 5px;
            color: $green;
        }
    }
    &__answered {
        #{$block-name}__button {
            border-color: $light-blue;
            background-color: $light-blue;
            pointer-events: none;
            &#{$block-name}__answer--checked {
                &#{$block-name} {
                    &__answer--incorrect {
                        border-color: $maroon;
                        background-color: $maroon;
                    }
                    &__answer--correct {
                        border-color: $blue;
                        background-color: $blue;
                        #{$block-name}__button-answer-correct {
                            display: none;
                        }
                    }
                }
            }
        }
        #{$block-name}__answer--correct {
            #{$block-name}__button-answer-correct {
                display: inline-block;
            }
        }
    }
}
</style>
