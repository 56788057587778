<template>
    <section class="main-content word-game">
        <div class="g-container">
            <h3>Find these {{ words.length }} popular snacks</h3>
            <WordSearchGame
                :matrix="matrix"
                :words="words"
                :bottom-text="bottomText"
                :robot-message="robotMessage"
                class="word-game__game"
            />
        </div>
    </section>
</template>

<script>
import WordSearchGame from './WordSearchGame'
import {LOW, MODERATE, HIGH} from '~/modules/games/sugarLevel.constants'

export default {
    name: 'SneakySnacks',
    components: {WordSearchGame},
    data() {
        return {
            matrix: [
                ['A', 'M', 'C', 'N', 'T', 'S', 'K', 'B', 'A', 'N'],
                ['D', 'O', 'A', 'H', 'T', 'O', 'T', 'A', 'L', 'S'],
                ['A', 'J', 'N', 'X', 'E', 'V', 'R', 'U', 'O', 'R'],
                ['P', 'C', 'D', 'U', 'P', 'E', 'F', 'R', 'N', 'E'],
                ['R', 'B', 'Y', 'R', 'S', 'R', 'S', 'V', 'A', 'K'],
                ['E', 'Y', 'O', 'G', 'U', 'R', 'T', 'E', 'R', 'C'],
                ['T', 'L', 'N', 'I', 'B', 'A', 'R', 'S', 'G', 'A'],
                ['Z', 'K', 'T', 'G', 'I', 'I', 'J', 'X', 'W', 'R'],
                ['E', 'I', 'L', 'R', 'S', 'S', 'P', 'I', 'H', 'C'],
                ['L', 'S', 'E', 'I', 'K', 'O', 'O', 'C', 'U', 'F'],
            ],
            words: [
                {value: 'BARS', sugar: MODERATE},
                {value: 'CANDY', sugar: HIGH},
                {value: 'CARROT', sugar: LOW},
                {value: 'CHEESE', sugar: LOW},
                {value: 'CHIPS', sugar: LOW},
                {value: 'COOKIES', sugar: HIGH},
                {value: 'CRACKERS', sugar: LOW},
                {value: 'FRUIT', sugar: MODERATE},
                {value: 'GRANOLA', sugar: HIGH},
                {value: 'NUTS', sugar: LOW},
                {value: 'PRETZEL', sugar: LOW},
                {value: 'YOGURT', sugar: HIGH},
            ],
            bottomText: 'All snacks are not created equal, which do you think would help you improve your "aspiration"?',
            robotMessage: ['Cheese, nuts and raw vegetables will give the most benefits.'],
        }
    },
}
</script>
