import QuizIndex from './components/QuizIndex'
import QuizHouseholdShow from './components/household/QuizHouseholdShow'
import QuizStoreSelector from './components/household/QuizStoreSelector'
import QuizHealthShow from './components/health/QuizHealthShow'
import QuizRocShow from './components/roc/QuizRocShow'
import QuizShow from './components/QuizShow'
import QuizLoyaltyProgram from './components/household/QuizLoyaltyProgram'
import PantryWelcome from './components/WelcomeToQuizzesDialog'
import FinishMotivationDialog from './components/FinishMotivationDialog'
import SleepCheckIn from './components/aspiration_check_in/SleepCheckIn'
import WeightCheckIn from './components/aspiration_check_in/WeightCheckIn'
import EnergyCheckIn from './components/aspiration_check_in/EnergyCheckIn'
import StressCheckIn from './components/aspiration_check_in/StressCheckIn'
import HealthCheckIn from './components/aspiration_check_in/HealthCheckIn'
import RobotResponse from './components/aspiration_check_in/RobotResponse'
import ChildPath from '~/base/components/ChildPath'
import store from '~/store'
import {MY_ITEM_STEP} from '~/router'

export const REGISTER_STEP = 1
export const WIZARD_STEP = 2

const beforeEnter = (to, from) => {
    if (to.name === 'quiz.roc') {
        if (store.getters['auth/user'].last_step?.level > MY_ITEM_STEP) {
            return {name: 'Home'}
        }
    }
    
    if (store.getters['auth/user'].last_step?.level > WIZARD_STEP) {
        return {name: 'Home'}
    }
}

const meta = {
    auth: true,
    layout: 'Wizard',
    step: WIZARD_STEP
}

const check_in_meta = {
    auth: true,
    layout: 'Wizard',
    step: MY_ITEM_STEP
}

export default [
    {
        path: 'welcome',
        name: 'quiz.welcome',
        component: PantryWelcome,
        meta: {
            auth: true,
            layout: 'Wizard',
            step: REGISTER_STEP
        },
        beforeEnter() {
            if (store.getters['auth/user'].last_step?.url !== '/welcome') {
                return {name: 'Home'}
            }
        }
    },
    {
        path: 'aspiration/check-in',
        name: 'aspiration.check-in',
        component: ChildPath,
        mate: check_in_meta,
        redirect: {name: 'aspiration.check-in.sleep'},
        children: [
            {
                path: 'sleep',
                name: 'aspiration.check-in.sleep',
                component: SleepCheckIn,
                meta: check_in_meta,
                beforeEnter() {
                    const aspiration = store.getters['auth/user'].aspiration
                    if (aspiration !== 'sleep') {
                        return {name: 'aspiration.check-in.' + aspiration}
                    }
                }
            },
            {
                path: 'weight',
                name: 'aspiration.check-in.weight',
                component: WeightCheckIn,
                meta: check_in_meta,
                beforeEnter() {
                    const aspiration = store.getters['auth/user'].aspiration
                    if (aspiration !== 'weight') {
                        return {name: 'aspiration.check-in.' + aspiration}
                    }
                }
            },
            {
                path: 'energy',
                name: 'aspiration.check-in.energy',
                component: EnergyCheckIn,
                meta: check_in_meta,
                beforeEnter() {
                    const aspiration = store.getters['auth/user'].aspiration
                    if (aspiration !== 'energy') {
                        return {name: 'aspiration.check-in.' + aspiration}
                    }
                }
            },
            {
                path: 'stress',
                name: 'aspiration.check-in.stress',
                component: StressCheckIn,
                meta: check_in_meta,
                beforeEnter() {
                    const aspiration = store.getters['auth/user'].aspiration
                    if (aspiration !== 'stress') {
                        return {name: 'aspiration.check-in.' + aspiration}
                    }
                }
            },
            {
                path: 'health',
                name: 'aspiration.check-in.health',
                component: HealthCheckIn,
                meta: check_in_meta,
                beforeEnter() {
                    const aspiration = store.getters['auth/user'].aspiration
                    if (aspiration !== 'health') {
                        return {name: 'aspiration.check-in.' + aspiration}
                    }
                }
            },
            {
                path: 'robot-response',
                name: 'aspiration.check-in.robot-response',
                component: RobotResponse,
                meta: check_in_meta,
            },
        ]
    },
    {
        path: 'quiz',
        component: ChildPath,
        beforeEnter,
        children: [
            {
                path: '',
                name: 'quiz.index',
                component: QuizIndex,
                meta,
            },
            {
                path: 'about-your-household',
                name: 'quiz.household',
                component: QuizHouseholdShow,
                meta,
            },
            {
                path: 'store-selector',
                name: 'quiz.store-selector',
                component: QuizStoreSelector,
                meta,
            },
            {
                path: 'health',
                name: 'quiz.health',
                component: QuizHealthShow,
                meta,
            },
            {
                path: 'roc',
                name: 'quiz.roc',
                component: QuizRocShow,
                meta,
            },
            {
                path: ':slug',
                name: 'quiz.show',
                component: QuizShow,
                meta,
            },
            // {
            //     path: 'finish-motivation',
            //     name: 'quiz.finish-motivation',
            //     component: FinishMotivationDialog,
            //     meta,
            // },
            {
                path: 'loyalty-program',
                name: 'quiz.loaylty-program',
                component: QuizLoyaltyProgram,
                meta,
            },
        ]
    },
]
