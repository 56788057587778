import Index from './components/Index'
import Child from '~/base/components/Child'

const meta = {
    auth: true,
    layout: 'Profile',
    step: 0.1
}

export default [
    {
        path: 'notifications',
        component: Child,
        children: [
            {
                path: '',
                name: 'notifications',
                component: Index,
                meta,
            },
        ]
    },
]
