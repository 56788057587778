<template>
    <div class="quiz-question quiz-question--range">
        <div class="default-text quiz-question__text">
            {{ questionText }}
        </div>
        <div class="quiz-question__range">
            <div class="quiz-question__range-image">
                <div
                    v-for="(img, i) in rangeList"
                    :key="img.value"
                    :class="[img.class, i === 2 ? 'quiz-question__range-image-wrap--custom' : '']"
                    class="quiz-question__range-image-wrap"
                >
                    <img
                        :src="img.img"
                        class="quiz-question__range-image-i"
                        alt=""
                    >
                </div>
            </div>
            <div class="quiz-question__range-input-wrap">
                <el-slider
                    v-model="rangeValue"
                    :min="0"
                    :max="2"
                    :show-tooltip="false"
                    :step="1"
                    show-stops
                    class="quiz-question__range-input"
                />
            </div>
            <div class="quiz-question__range-values">
                <div
                    v-for="item in rangeList"
                    :key="item.value"
                    :class="item.class"
                    class="quiz-question__range-value"
                >
                    <div class="quiz-question__range-value-number">
                        {{ item.value }}
                    </div>
                </div>
            </div>
            <div
                class="default-text quiz-question__range-explanation"
                v-html="baseText"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'QuizQuestionRate',
    props: {
        questionText: {
            type: String,
            default: '',
        },
        rangeList: {
            type: Array,
            default: () => {return []}
        },
        rangeValue: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        baseText() {
            return this.rangeList[Number(this.rangeValue)].text
        },
    },
    watch: {
        rangeValue(new_val, old_val) {
            this.$emit('changeRangeValue', this.rangeList[Number(new_val)].value)
            this.rangeList[Number(old_val)].class = ''
            this.rangeList[Number(new_val)].class = 'active'
        },
    },
}
</script>
