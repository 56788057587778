import LandingIndex from './components/LandingIndex'
import Contacts from '~/modules/landing/components/Contacts'
import PartnerLogin from '~/modules/landing/components/PartnerLogin'

const meta = {
    auth: undefined,
    layout: 'DefaultLanding'
}

export default [
    {
        path: '/',
        name: 'Landing',
        component: LandingIndex,
        meta: meta,
    },
    {
        path: 'contacts',
        name: 'contacts',
        component: Contacts,
        meta: meta,
    },
    {
        path: 'partner-login',
        name: 'partner-login',
        component: PartnerLogin,
        meta: {
            auth: undefined,
            layout: 'Auth'
        }
    },
]
