<template>
    <div class="math-section">
        <div class="math-section__col">
            <div
                v-for="product in localProducts"
                :key="product.name"
                class="math-section__item"
            >
                <div
                    :id="'product_'+product.id"
                    class="math-section__product"
                >
                    <img
                        :id="product.id"
                        :draggable="!product.status"
                        :src="product.image"
                        :alt="product.name"
                        class="math-section__product-image"
                        @dragstart="dragStart"
                        @touchstart="handleTouchStart"
                        @touchmove="handleTouchMove"
                        @touchend="handleTouchEnd"
                    >
                </div>
            </div>
        </div>
        <div class="math-section__col">
            <div
                v-for="(spoon, index) in spoons"
                :key="index"
                class="math-section__item"
                :class="cssClass(spoon)"
            >
                <div
                    :id="'spoon_'+spoon"
                    :ref="setItemRef"
                    class="math-section__product math-section__sugar"
                    draggable="false"
                    @drop="drop"
                    @dragover="allowDrop"
                    @dragenter="overPlate"
                    @dragleave="outPlate"
                >
                    <span class="math-section__product-sugar">{{ spoon }}</span>
                    <i class="math-section__product-icon fa-solid fa-circle-check" />
                    <i class="math-section__product-icon fa-solid fa-circle-xmark" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed} from 'vue'

const name = 'MatchTwoColumns'

const ON_CELL_SECONDS = 3
const STATUS_CORRECT = 'correct'

const props = defineProps({
    products: {
        type: Array,
        required: true,
    },
    spoons: {
        type: Array,
        required: true,
    },
})
const productOnPlate = ref(null)
const itemRefs = ref([])
const localProducts = computed(() => props.products)
let localSpoons = computed(() => props.spoons)
const status = ref('')

const done = computed(() => {
    const correctAnswers = localProducts.value.filter(o => o.status === STATUS_CORRECT)
    return correctAnswers.length === localProducts.value.length
})

const emit = defineEmits(['done'])

function cssClass(value) {
    return `math-section__item--sugar-${value}`
}
function setItemRef(el) {
    if (el) {
        itemRefs.value.push(el)
    }
}

function dragStart(event) {
    event.dataTransfer.setData('text', event.target.id)
}

function allowDrop(event) {
    event.preventDefault()
}

function overPlate(event) {
    event.target.classList.add('over')
}

function outPlate(event) {
    event.target.classList.remove('over')
}

function drop(event) {
    let path = event.composedPath()
    event.preventDefault()
    let id = event.dataTransfer.getData('text')
    let cell = path[0]
    if (event.target.classList.contains('math-section__product--correct') || event.target.classList.contains('math-section__product--wrong')) {
        removeFromCell(id)
        return
    }
    handleOnCell(id, cell)
    if(done.value) {
        emit('done')
    }
}

function handleOnCell(id, cell) {
    cell.appendChild(document.getElementById(id))
    let product = localProducts.value.find(o => o.id == id)
    let spoonValue = cell.firstChild.innerText
    const status = product.value === +spoonValue ? STATUS_CORRECT : 'wrong'
    if (status === 'wrong') {
        cell.classList.add('math-section__product--wrong')
        _.delay(() => {
            removeFromCell(id)
            cell.classList.remove('math-section__product--wrong')
        }, 1000 * ON_CELL_SECONDS)
    }
    if (status === 'correct') {
        product.status = status
        cell.classList.add('math-section__product--correct')
    }
}

function removeFromCell(id) {
    if (productOnPlate.value?.id === id) productOnPlate.value = null
    document.querySelector(`#product_${id}`).appendChild(document.getElementById(id))
}

/**
 * On mobile
 */

let droppedIn = ref(false)
const activeEvent = ref('')
let activeCell

function handleTouchStart(event) {
    event.preventDefault()
    activeEvent.value = 'start'
}

function handleTouchMove(event) {
    let path = event.composedPath()
    if (!! localProducts.value.find(o => o.id == path[0].id)?.status) {
        return
    }
    let touchLocation = event.targetTouches[0]
    let pageX = (touchLocation.pageX - event.target.clientWidth/2) + 'px'
    let pageY = (touchLocation.pageY - event.target.clientHeight/2) + 'px'

    path[0].style.position = 'absolute'
    path[0].style.left = pageX
    path[0].style.top = pageY

    activeEvent.value = 'move'
    path[0].classList.add('dragging')
}

function handleTouchEnd(event) {
    let path = event.composedPath()
    event.preventDefault()
    if (activeEvent.value === 'move') {
        let {pageX, pageY} = event.changedTouches[0]
        if (detectDropOnCell(pageX, pageY)) {
            let prodId = path[0].id
            handleOnCell(prodId, activeCell)
            droppedIn = true
        }
        path[0].style.position = 'initial'
        path[0].classList.remove('dragging')
    }
    if(done.value) {
        emit('done')
    }
}

function detectDropOnCell(pageX, pageY) {
    let target = document.elementFromPoint(pageX, pageY)
    const activeCellItem = itemRefs.value.filter(o => o.id === target.id)

    if (target.classList.contains('math-section__product--correct') || target.classList.contains('math-section__product--wrong')) {
        return false
    }
    if (activeCellItem.length === 0) {
        return false
    }
    activeCell = activeCellItem[0]
    return target.id === activeCellItem[0].id
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.math-section {
    $block-name: &;
    display: flex;
    &--offset-bottom {
        margin-bottom: 20px;
    }
    &__col {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
    }
    &__item {
        &:not(:last-child){
            margin-bottom: 10px;
        }
        &--sugar-1 {
            #{$block-name}__product-sugar {
                opacity: 0.7;
                background-image: url("/images/games/spoons/sugar-spoon_1.jpeg");
            }
        }
        &--sugar-2 {
            #{$block-name}__product-sugar {
                opacity: 0.7;
                background-image: url("/images/games/spoons/sugar-spoon_2.jpeg");
            }
        }
        &--sugar-3 {
            #{$block-name}__product-sugar {
                opacity: 0.7;
                background-image: url("/images/games/spoons/sugar-spoon_3.jpeg");
            }
        }
        &--sugar-4 {
            #{$block-name}__product-sugar {
                opacity: 0.7;
                background-image: url("/images/games/spoons/sugar-spoon_4.jpeg");
            }
        }
        &--sugar-5 {
            #{$block-name}__product-sugar {
                opacity: 0.7;
                background-image: url("/images/games/spoons/sugar-spoon_5.jpeg");
            }
        }
        &--sugar-6 {
            #{$block-name}__product-sugar {
                opacity: 0.7;
                background-image: url("/images/games/spoons/sugar-spoon_6.jpeg");
            }
        }
        &--sugar-7 {
            #{$block-name}__product-sugar {
                opacity: 0.7;
                background-image: url("/images/games/spoons/sugar-spoon_7.jpeg");
            }
        }
        &--sugar-8,
        &--sugar-9,
        &--sugar-10,
        &--sugar-11,
        &--sugar-12 {
            #{$block-name}__product-sugar {
                opacity: 0.7;
                background-image: url("/images/games/spoons/sugar-spoon_8.jpeg");
            }
        }
    }
    &__product {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100px;
        height: 100px;
        border: 2px solid $gray;
        border-radius: 5px;
        overflow: hidden;
        &-image {
            max-width: 100%;
            max-height: 100%;
            user-select: none;
        }
        &-icon {
            position: absolute;
            z-index: 10;
            top: 8px;
            right: 8px;
            border-radius: 10px;
            overflow: hidden;
            font-size: 20px;
            background-color: $white;
            visibility: hidden;
            opacity: 0;
            transition: 0.2s visibility ease-in-out, 0.2s opacity ease-in-out;
        }
        &-sugar {
            position: absolute;
            z-index: 10;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 34px;
            font-weight: 600;
            color: $gray;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            pointer-events: none;
            transition: 0.2s color ease-in-out, 0.2s font-size ease-in-out;
            user-select: none;
        }
        &--correct,
        &--wrong {
            position: relative;
            background-image: unset !important;
            #{$block-name} {
                &__product {
                    &-sugar {
                        top: 26px;
                        left: 60px;
                        align-items: flex-start;
                        font-size: 26px;
                        font-weight: 700;
                        -webkit-text-stroke: 1px $white;
                        background-image: unset;
                        opacity: unset;
                    }
                    &-image {
                        pointer-events: none;
                    }
                }
            }
        }
        &--correct {
            border-color: $indigo;
            #{$block-name} {
                &__product{
                    &-icon,
                    &-sugar {
                        color: $blue;
                    }
                }
            }
            .fa-circle-check {
                visibility: visible;
                opacity: 1;
            }
        }
        &--wrong {
            border-color: $maroon;
            #{$block-name} {
                &__product {
                    &-icon,
                    &-sugar {
                        color: $maroon;
                    }
                }
                &__sugar-image {
                    display: none;
                }
            }
            .fa-circle-xmark {
                visibility: visible;
                opacity: 1;
            }
        }
    }
    &__sugar {
        position: relative;
    }
    .dragging {
        max-width: 90px;
        width: auto;
        height: auto;
    }
}
</style>
