// my-items
import axios from 'axios'
import * as types from '../mutation-types'
import baseApi from '~/base/baseApi'
import store from '~/store'
import {MY_ITEM_STEP} from '~/router'
import {ElLoading} from 'element-plus'
import {SET_PMO_SLUG} from '../mutation-types'


export const state = {
    shop: {},
    categories: [],
    rated: [],
    pmo_temp_category_slug: null,

}

// getters
export const getters = {
    shop: state => state.shop,
    replaced: state => state.replaced||[],
    placed: state => state.placed||[],
    categories: state => state.categories,
    rated: state => state.rated||[],
    pmo_temp_category_slug: state => state.pmo_temp_category_slug,
}

// mutations
export const mutations = {
    [types.UPDATE_SHOP] (state, {shop}) {
        state.shop = shop

        let categories = []
        let placed = []
        let replaced = []
        shop.items.forEach(product => {
            if (product.bought) {
                replaced.push(product)
                return
            }
            placed.push(product)
            if (categories.find(category => category.name === product.categorizationNielsenIq.category) === undefined) {
                categories.push({
                    name: product.categorizationNielsenIq.category,
                    items: [product]
                })
            } else {
                categories.find(category => category.name === product.categorizationNielsenIq.category).items.push(product)
            }
        })
        state.categories = categories
        state.replaced = replaced
        state.placed = placed

        state.rated = {
            notRated: shop.items.filter(product => (product.liked == undefined) || product.liked == 0),
            liked: shop.items.filter(product => product.liked && (product.liked == 1)),
            disliked: shop.items.filter(product => product.liked && (product.liked == -1)),
        }
    },
    [types.FETCH_SHOP_FAILURE] (state) {
        console.log('fail to load shop data')
    },
    [types.SET_PMO_SLUG]  (state, {slug}) {
        state.pmo_temp_category_slug = slug
    },
    clearPmoCatSlug (state) {
        state.pmo_temp_category_slug = null
    },
}

// actions
export const actions = {
    async fetchShop( { commit }, shopId) {
        const loadingInstance = ElLoading.service({ fullscreen: true, target: '.main-content' })
        try {
            const {data} = await axios.get('my-items/' + shopId)
            commit(types.UPDATE_SHOP, {shop: data})
        } catch (e) {
            commit(types.FETCH_SHOP_FAILURE)
        }
        loadingInstance.close()
    },
    async addItemToList( { commit }, payload) {
        const loadingInstance = ElLoading.service({ fullscreen: true, target: '.main-content' })
        try {
            const {data} = await axios.post('my-items/add', {gtins: payload.gtins, original_gtin: payload.original_gtin})
            commit(types.UPDATE_SHOP, {shop: data})

            if (store.getters['auth/user'].last_step?.level < MY_ITEM_STEP) {
                await baseApi.newUserStep(MY_ITEM_STEP)
                await store.dispatch('auth/fetchUser')
            }
        } catch (e) {
            console.log(e)
        }
        loadingInstance.close()
    },
    async addItemToCart( { commit }, gtin) {
        const loadingInstance = ElLoading.service({ fullscreen: true, target: '.main-content' })
        try {
            const {data} = await axios.post('my-items/to-cart', {gtin: gtin})
            commit(types.UPDATE_SHOP, {shop: data})
        } catch (e) {
            console.log(e)
        }
        loadingInstance.close()
    },
    async likeProduct( { commit }, gtin) {
        let gtins = [gtin]
        const loadingInstance = ElLoading.service({ fullscreen: true, target: '.main-content' })
        try {
            const {data} = await axios.post('my-items/like', {gtins: gtins})
            commit(types.UPDATE_SHOP, {shop: data})
        } catch (e) {
            console.log(e)
        }
        loadingInstance.close()
    },
    async dislikeProduct( { commit }, payload) {
        let gtins = [payload.gtin]
        let answers = payload.answers

        const loadingInstance = ElLoading.service({ fullscreen: true, target: '.main-content' })
        try {
            const {data} = await axios.post('my-items/dislike', {gtins: gtins, answers: answers})
            commit(types.SET_PMO_SLUG, {slug: data.pmo_category_slug})
            commit(types.UPDATE_SHOP, {shop: data})
        } catch (e) {
            console.log(e)
        }
        loadingInstance.close()
    },
    async removeFromList( { commit }, gtin) {
        const loadingInstance = ElLoading.service({ fullscreen: true, target: '.main-content' })
        try {
            const {data} = await axios.post('my-items/remove', {gtin: gtin})
            commit(types.UPDATE_SHOP, {shop: data})
        } catch (e) {
            console.log(e)
        }
        loadingInstance.close()
    },

}
