<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section" />
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__robot">
                    <RobotDialog
                        :messages="['Your profile is almost complete!']"
                        robot-image="/images/robots/robot_rocket.svg"
                        @finish="isActiveBtn = true"
                    />
                </div>
                <div class="main-content__button-wrap">
                    <div class="main-content__btn">
                        <el-button
                            :disabled="!isActiveBtn"
                            type="primary"
                            round
                            class="continue-button"
                            @click="$router.push({name: 'quiz.health'})"
                        >
                            Continue
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import RobotDialog from './RobotDialog'
import bus from '~/includes/Event'
const name = 'FinishMotivationDialog'
let isActiveBtn = ref(false)

onMounted(() => {
    bus.$emit('set-title', 'Motivations')
})
</script>
