<template>
    <div
        v-if="quiz"
        class="main-content main-content--base-layout"
    >
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="quiz-question">
                    <p
                        class="default-text quiz-question__text"
                        v-html="quiz.text"
                    />
                </div>
                <QuizHealthQuestions
                    v-if="quiz.questions"
                    ref="QuizHealthQuestions"
                    :questions="quiz.questions"
                    @index="setCurrent"
                    @done="toggleContinue"
                />
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__button-wrap">
                    <el-button
                        :disabled="!done || loading"
                        :loading="loading"
                        type="primary"
                        round
                        class="continue-button"
                        @click="finishQuiz"
                    >
                        Continue {{ currentIndex }} of {{ totalQuestions }}
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import QuizHealthQuestions from './QuizHealthQuestions'
import quizApi from '~/modules/quiz/quizApi'
import bus from '~/includes/Event'

export default {
    name: 'QuizHealthShow',
    components: {
        QuizHealthQuestions,
    },
    data() {
        return {
            quiz: null,
            done: false,
            loading: false,
            currentIndex: 1,
        }
    },
    computed: {
        ...mapGetters({
            user: 'auth/user'
        }),
        totalQuestions() {
            return this.quiz.questions.filter(o => o.type !== 'text').length
        }
    },
    created() {
        this.fetchSubQuiz()
    },
    mounted() {
        bus.$emit('set-title', 'Motivations')
    },
    methods: {
        toggleContinue(done) {
            this.done = done
        },
        async fetchSubQuiz() {
            const {data} = await quizApi.getSubQuiz(this.user.personal_type.toLowerCase() + '-health')
            if (!!data) this.quiz = data
        },
        setCurrent(index) {
            this.currentIndex = index
        },
        async finishQuiz() {
            this.loading = true
            await quizApi.saveAnswers(this.quiz)
            await quizApi.setMotivationsDone()
            this.loading = false
            await this.$router.push({name: 'quiz.index'})
        },
    }
}
</script>
