import Product from './components/Product'
import Index from './components/Index'
import MoreChoices from './components/MoreChoices/MoreChoices'
import ChoiceOriginal from './components/MoreChoices/ChoiceOriginal'
import AddProduct from './components/AddProduct/AddProduct'
import ShowProduct from "./components/ShowProduct.vue";

const meta = {
    auth: true,
    layout: 'Pmo'
}

export default [
    {
        path: 'product/:upc',
        name: 'show.product',
        component: ShowProduct,
        meta: {
            layout: 'WithoutAuth'
        },
    },
    {
        path: 'products/search/:pmo_category?/:searchType?',
        name: 'product.search',
        component: Index,
        meta: meta,
    },
    {
        path: 'products/:upc/:pmo_category?/:mapped?',
        name: 'product.show',
        component: Product,
        meta: {
            auth: true,
            layout: 'Pmo' //'ProductLayout'
        },
    },
    {
        path: 'products/more-choices/:upc/:pmo_category?',
        name: 'product.more-choices',
        component: MoreChoices,
        meta: meta,
    },
    {
        path: 'products/more-choices-frequency/:upc/:pmo_category?',
        name: 'product.frequency',
        component: ChoiceOriginal,
        meta: meta,
    },
    {
        path: 'products/add/:upc/:pmo_category?',
        name: 'product.add',
        component: AddProduct,
        meta: meta,
    },
]
