<template>
    <el-form>
        <el-form-item
            v-model="question.answer"
            :name="question.id"
            class="person-quantity"
        >
            <div class="person-quantity__inner">
                <h3 class="person-quantity__title">
                    {{ question.text }}
                </h3>
                <div class="person-quantity__switches">
                    <div class="person-quantity__reduce">
                        <el-button
                            :disabled="answers[question.additional_info.slug] === 0"
                            class="person-quantity__reduce-btn"
                            @click="decreaseValue(question.additional_info.slug)"
                        >
                            —
                        </el-button>
                    </div>
                    <div
                        class="person-quantity__image"
                        :class="{ 'person-quantity__image--select': answers[question.additional_info.slug] > 0 }"
                    >
                        <div class="quiz-household-img-container person-quantity__image-wrap">
                            <img
                                :src="question.additional_info.image"
                                class="person-quantity__image-i"
                                alt=""
                            >
                        </div>
                    </div>
                    <div class="person-quantity__increase">
                        <el-button
                            :disabled="step_one_completed"
                            class="person-quantity__increase-btn"
                            @click="increaseValue(question.additional_info.slug)"
                        >
                            +
                        </el-button>
                    </div>
                </div>
                <h3 class="person-quantity__counter">
                    {{ answers[question.additional_info.slug] }}
                </h3>
            </div>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    name: 'PeopleType',
    props: {
        answers: {
            type: Object,
            required: true,
        },
        question: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {}
    },
    computed: {
        step_one_completed() {
            let total = this.answers.household_adults_count + this.answers.household_teenagers_count + this.answers.household_pre_teens_count + this.answers.household_kids_toddlers_count
            return total >= this.answers.household_people_count
        },
    },
    methods: {
        increaseValue(slug) {
            this.answers[slug]++
            this.changeAnswers()
        },
        decreaseValue(slug) {
            if (this.answers[slug] <= 0) {
                return
            }
            this.answers[slug]--
            this.changeAnswers()
        },
        changeAnswers() {
            this.$emit('change', this.answers)
            this.question.answer = this.answers[this.question.additional_info.slug]
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "resources/sass/_variables.scss";
    .person-quantity {
        $block-name: &;
        $local-light-indigo: #b5b7df;
        margin-bottom: 0;
        &__inner {
            width: 100%;
        }
        &__title {
            margin-bottom: 3px;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.4;
            letter-spacing: 0.15px;
            text-align: center;
            color: $dark;
        }
        &__switches {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__reduce,
        &__increase {
            &-btn {
                width: 36px;
                height: 36px;
                border-radius: 4px;
                font-size: 24px;
                color: $white;
                background-color: $indigo;
                &.is-disabled {
                    color: $white;
                    background-color: $local-light-indigo;
                }
            }
        }
        &__image {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: 84px;
            height: 84px;
            margin: 0 24px;
            border-radius: 50%;
            border: 4px solid $light-gray;
            background-color: $white;
            &:before,
            &:after {
                content: "";
                position: absolute;
                border-radius: 50%;
            }
            &:before {
                z-index: 2;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 76px;
                height: 76px;
                background-color: $white;
            }
            &:after {
                z-index: 3;
                top: 5px;
                right: 5px;
                bottom: 5px;
                left: 5px;
                width: 66px;
                height: 66px;
                background-color: $green;
                transition: 0.2s background-color ease-in-out;
            }

            &-wrap {
                position: relative;
                z-index: 4;
                width: 54px;
                height: 54px;
            }
            &-i {
                max-width: 100%;
                max-height: 100%;
            }
            &--select {
                &:after {
                    background-color: $indigo;
                }
            }
        }
        &__counter {
            font-size: 34px;
            font-weight: 600;
            line-height: 1.4;
            letter-spacing: 0.25px;
            text-align: center;
        }
    }
</style>
