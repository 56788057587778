<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="default-text default-text--offset-bottom">
                    What 3 items when added to your breakfast plate have the right/best amount of added sugar for your health?
                </div>
                <div class="default-text default-text--offset-bottom">
                    The Broke Fast game reinforces improved breakfast choices to help you child start their day in a healthy way.
                </div>
                <DragGame
                    :products="products"
                    @done="handleDone"
                />
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__button-wrap">
                    <el-button
                        :disabled="!done"
                        type="primary"
                        round
                        class="continue-button"
                        @click="clickDone"
                    >
                        Done
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import DragGame from './DragGame'
import {provide} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import pmoApi from '~/modules/pmo/pmoApi'
import userApi from '~/modules/profile/userApi'

const name = 'BrokeFastKidsCamp'
const router = useRouter()
const route = useRoute()
const done = ref(false)

provide('range', _.range(0, 22, 2))
provide('limit', 6)

const products = ref([
    {
        id: 1,
        value: 18,
        name: 'Toast with strawberry jam: 18g added sugar',
        image:'/images/games/toast-with-strawberry-jam@2x.png',
    },
    {
        id: 2,
        value: 0.5,
        name: '1 egg with 2 slices of bacon: 0.5g added sugar',
        image:'/images/games/egg-slice-becon@2x.png',
    },
    {
        id: 3,
        value: 26,
        name: 'Blueberry muffin: 26g added sugar',
        image:'/images/games/muffin@2x.png',
    },
    {
        id: 4,
        value: 2,
        name: 'O\'s cereal with milk, blueberries: 2g added sugar',
        image:'/images/games/cereal-with-milk@2x.png',
    },
    {
        id: 5,
        value: 21,
        name: 'Orange juice: 21g sugar',
        image:'/images/games/orange-juice@2x.png',
    },
    {
        id: 6,
        value: 1.5,
        name: 'Egg, ham and cheese sandwich: 1.5g added sugar',
        image:'/images/games/egg-ham-cheese-sandwich@2x.png',
    },
])

function handleDone() {
    userApi.setFirstExperience('pmo_sub_' + route.name.split('.')[1] + '_finished')
    done.value = true
    scrollTo()
}

function clickDone() {
    pmoApi.gameFinished(route.name.split('.')[1])
    // router.push({name: 'pmo'})
    router.push({name: 'pmo.welcome-game', params: {game_slug: "broke-fast"}})
}

function scrollTo() {
    document.querySelector('.continue-button').scrollIntoView({ behavior: 'smooth' })
}
</script>
