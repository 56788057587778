import axios from 'axios'
import store from '~/store'

const API_ENDPOINT = 'profile'

export default {

    setFirstExperience(value) {
        if (! this.hasFirstExperience(value)) {
            store.commit('auth/addFirstExperience', value)
            return axios.post(API_ENDPOINT + '/set-first-experience/' + value)
        } else {
            return Promise.resolve('Already set')
        }
    },

    hasFirstExperience(value) {
        const user = store.getters['auth/user']
        return Boolean(user.first_experience && user.first_experience[value])
    },

    saveRoc(data) {
        return axios.post('settings/profile/save-roc', data)
    },

    savePhoneNumber(phoneNumber, from_quiz = false) {
        return axios.post('/settings/profile/save-phone-number', {phone_number: phoneNumber, from_quiz: from_quiz})
    },

    saveUserLog(data) {
        return axios.post(API_ENDPOINT + '/save-user-log', data)
    },

    saveLoyaltyNumber(loyaltyNumber) {
        return axios.post('/settings/profile/save-loyalty-number', {loyalty_number: loyaltyNumber})
    },

    saveMeasurements(measurements) {
        return axios.post('/settings/profile/save-measurements', {measurements: measurements})
    },

    sendUserNote(data) {
        return axios.post('/settings/profile/send-user-note', {data})
    },
}
