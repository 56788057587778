<template>
    <div class="fullscreen-robot-message">
        <div class="fullscreen-robot-message__robot">
            <RobotDialog
                :messages="messages"
                :robot-image="robotImage"
                :show-bricks-animation="showBricksAnimation"
                :button-text="buttonText"
                :button-callback="buttonCallback"
                :button-cansel-text="buttonCanselText"
                :button-cansel-callback="buttonCanselCallback"
                :helpful-btn-white="helpfulBtnWhite"
                @finish="emit('finish')"
            />
        </div>
    </div>
</template>

<script setup>
import RobotDialog from '~/modules/quiz/components/RobotDialog'

const props = defineProps({
    messages: {
        type: Array,
        required: true,
    },
    robotImage: {
        type: String,
        default: '/images/robots/robot_hand_up.svg',
    },
    showBricksAnimation: {
        type: Boolean,
        default: true,
    },
    buttonText: {
        type: String,
        default: null
    },
    buttonCanselText: {
        type: String,
        default: null
    },
    helpfulBtnWhite: {
        type: Boolean,
        default: false
    },
})
const emit = defineEmits()

function buttonCallback() {
    emit('buttonCallback')
}

function buttonCanselCallback() {
    emit('buttonCanselCallback')
}
</script>

<style lang="scss" scoped>
.fullscreen-robot-message {
    position: fixed;
    z-index: 2200;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    padding: 15px;
    background-color: rgba(255,255,255, 65%);
    user-select: none;
}
</style>
