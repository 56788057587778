<template>
    <robot-response>
        <h4>How is your Pantry Makeover?</h4>
        <div>
            <p v-html="question.text" />
            <form-rate
                :question="question"
                class="el-radio-group-bordered"
            />
            <div class="main-content__button-wrap">
                <el-button
                    :disabled="!question.answer"
                    :loading="loading"
                    type="primary"
                    round
                    class="continue-button"
                    @click="handleContinue"
                >
                    Continue
                </el-button>
            </div>
        </div>
    </robot-response>
</template>

<script setup>
import {ref, getCurrentInstance} from 'vue'
import FormRate from '../inputs/FormRate'
import {useRouter} from 'vue-router'
import store from '~/store'
import RobotResponse from './RobotResponse'
import userApi from '~/modules/profile/userApi'

const { proxy } = getCurrentInstance()

const router = useRouter()

const loading = ref(false)

const question = ref({
    text: 'Do you feel like the Pantry Makeover is helping you make <strong>healthier choices?</strong>',
    variants: [
        {_id: 1, value: 'Yes'},
        {_id: 2, value: 'Not Yet'},
        {_id: 3, value: 'No'},
    ]
})

function handleContinue() {
    let status
    switch (question.value.answer) {
    case 1: status = 'good'
        break
    case 2: status = 'no_change'
        break
    case 3: status = 'bad'
        break
    }

    const answer = _.find(question.value.variants, {_id: question.value.answer}).value

    userApi.saveUserLog({
        name: question.value.text,
        value: answer,
        reason: proxy.$config.aspirationQuizSlug
    })

    store.commit('check-in-response/setDialog', {aspiration: 'health', status})
}
</script>
