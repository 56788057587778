<template>
    <div class="play-sound-container" v-if="isSoundPlay">
        <div class="play-sound">
            <i class="fa-solid fa-volume-high"></i>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import store from '~/store'
import userApi from '~/modules/profile/userApi'
import {Howl, Howler} from 'howler'

const props = defineProps({
    'src': {
        type: String,
        // required: true,
        default: null,
    },
    'autoplay': {
        type: Boolean,
        default: true
    }
})

const emit = defineEmits()

const sound = ref(null)
const isSoundPlay = ref(false)

onMounted(() => {
    if (false && props.src && !userApi.hasFirstExperience(slugged(props.src))) {
        // sound.play()

        isSoundPlay.value = true
        sound.value = new Howl({
            src: [props.src],
            loop: false,
            autoplay: props.autoplay,
            onend: function() {
                // userApi.setFirstExperience(slugged(props.src))

                endSound()
            }
        });

        if (sound.value.play()) {
            setTimeout(() => {
                userApi.setFirstExperience(slugged(props.src))
            }, 10000)
        }
    } else {
        endSound()
    
    }
})

function endSound() {
    if (sound.value != null) {
        sound.value.stop()
    }
    isSoundPlay.value = false
    emit('onSoundEnd')
}

function slugged(str) {
    return str.toLowerCase().replace(/ /g, '-').replace(/[^a-zA-Z0-9-]/g, '')
}

</script>