<template>
    <div class="main-content main-content--offset-bottom main-content--robot-fullscreen-message">
        <div class="g-container">
            <div class="main-content__text">
                Improve the ingredients in your pantry using <strong>Pantry Makeover</strong>. Find excellent replacement items at local stores.
            </div>
            <div class="main-content__pantry">
                <div
                    id="pantry"
                    v-loading="loading"
                    class="pantry"
                >
                    <div class="pantry__inner">
                        <div
                            v-for="(type, key) in types"
                            :key="key"
                            class="pantry__row"
                            :class="{
                                'pantry__row--first': key === 0,
                                'pantry__row--second': key === 1,
                                'pantry__row--third': key === 2
                            }"
                        >
                            <div
                                v-for="item in type"
                                :key="item.name"
                                class="pantry__cell-wrap"
                            >
                                <div
                                    :id="`pantry-${item.id}`"
                                    class="pantry__cell"
                                >
                                    <div class="pantry__cell-header">
                                        {{ item.name }}
                                    </div>
                                    <template v-if="item.status === 'active'">
                                        <el-button
                                            class="pantry__cell-btn"
                                            round
                                            type="primary"
                                            @click="goToPmoCategory(item.id)"
                                        >
                                            Go
                                        </el-button>
                                        <div class="pantry__cell-img pantry__cell-img--align-end">
                                            <img
                                                :src="`/images/pmo/pmo-cell-${item.slug}-icon.svg`"
                                                class="pantry__cell-img-i"
                                                alt=""
                                            >
                                        </div>
                                    </template>
                                    <template v-else-if="item.status === 'completed'">
                                        <el-button
                                            class="pantry__cell-btn"
                                            round
                                            type="primary"
                                            @click="goToPmoSummary(item.id)"
                                        >
                                            Revisit
                                        </el-button>
                                        <div class="pantry__cell-img">
                                            <img
                                                :src="item.icon"
                                                class="pantry__cell-img-i"
                                                alt=""
                                            >
                                        </div>
                                    </template>
                                    <template v-else>
                                        <el-button
                                            round
                                            type="primary"
                                            class="pantry__cell-btn"
                                            disabled
                                        >
                                            Go
                                        </el-button>
                                        <div class="pantry__cell-img pantry__cell-img--align-end">
                                            <img
                                                :src="`/images/pmo/pmo-cell-${item.slug}-icon.svg`"
                                                class="pantry__cell-img-i"
                                                alt=""
                                            >
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        <template v-if="showRobotMessage">-->
        <!--            <fullscreen-robot-message-->
        <!--                :messages="robotMessages"-->
        <!--                :robot-image="robotImage"-->
        <!--                :show-bricks-animation="false"-->
        <!--                :button-text="buttonText"-->
        <!--                @buttonCallback="handleClick"-->
        <!--            />-->
        <!--        </template>-->
    </div>
</template>

<script setup>
import pmoApi from '~/modules/pmo/pmoApi'
import {ref, onMounted} from 'vue'
import {useRouter} from 'vue-router'
import store from '~/store'
import bus from '~/includes/Event'
import baseApi from '~/base/baseApi'
import {MY_ITEM_STEP} from '~/router'
import userApi from '~/modules/profile/userApi'

const user = store.getters['auth/user']
const router = useRouter()
const types = ref()
const allCompleted = ref(false)
const loading = ref(true)


onMounted(async () => {
    bus.$emit('set-title', 'Pantry Makeover')
    await fetchTypes()
    if (!userApi.hasFirstExperience('showed_my_items_intro') && allCompleted.value) {
        await baseApi.newUserStep(MY_ITEM_STEP)
        await store.dispatch('auth/fetchUser')
        await router.push({name: 'my-items.shop'})
    }
})

async function fetchTypes() {
    const {data} = await pmoApi.getTypes()
    types.value = _.chunk(_.toArray(data), 2)
    allCompleted.value = !_.some(_.toArray(data), ['status', 'active'])
    loading.value = false
}

async function goToPmoCategory(type) {
    const {data} = await pmoApi.findByType(type)

    if (!user.acceptSugarSubstitutes) {
        await router.push({name: 'pmo.sugar_substitutes', query: {next_slug: data.slug}})
    } else {
        await router.push({name: 'pmo.show', params: {pmo_category: data.slug}})
    }
}

function goToPmoSummary(type) {
    router.push({name: 'pmo.summary', params: {pantry: type}})
}

// const robotImage = '/images/robots/robot_smile.svg'
// const buttonText = 'Got it'
// const showRobotMessage = computed(() => ! userApi.hasFirstExperience('showed_pmo_welcome_dialog'))
// const handleClick = () => userApi.setFirstExperience('showed_pmo_welcome_dialog')
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.pantry {
    $block-name: &;
    $local-blue: #5564A6;
    $local-blue-second: #6B95B4;
    $local-dark-blue: #1A3459;
    $local-light-blue: #E3EEFA;
    $local-light-blue-second: #86ADCD;
    $local-light-blue-third: #AAC8E2;
    &__inner {
        min-height: 325px;
        border: 1px solid $local-blue;
        padding: 3px 0 3px;
        background-color: $local-light-blue;
        @media all and (min-width: $xs) {
            min-height: 475px;
        }
    }
    &__row {
        display: flex;
        &--first {
            #{$block-name} {
                &__cell {
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 89px;
                        left: 0;
                        background-color: $local-light-blue-second;
                        @media all and (min-width: $xs) {
                            bottom: 139px;
                        }
                    }
                    &:after {
                        top: 80px;
                        bottom: 0;
                        @media all and (min-width: $xs) {
                            top: 130px;
                        }
                    }
                    &-wrap {
                        &:nth-child(odd) {
                            &:before {
                                border-width: 9px 50px 18px 39px;
                            }
                        }
                        &:nth-child(even) {
                            &:before {
                                border-width: 9px 39px 18px 50px;
                            }
                        }
                    }
                }
            }
        }
        &--second {
            #{$block-name} {
                &__cell {
                    &:after {
                        top: 70px;
                        @media all and (min-width: $xs) {
                            top: 120px;
                        }
                    }
                    &-wrap {
                        &:nth-child(odd) {
                            &:before {
                                border-width: 0 50px 28px 39px;
                            }
                        }
                        &:nth-child(even) {
                            &:before {
                                border-width: 0 39px 28px 50px;
                            }
                        }
                    }
                }
            }
        }
        &--third {
            #{$block-name} {
                &__cell {
                    &:after {
                        top: 55px;
                        @media all and (min-width: $xs) {
                            top: 105px;
                        }
                    }
                    &-wrap {
                        &:nth-child(odd) {
                            &:before {
                                border-width: 0 50px 43px 39px;
                            }
                        }
                        &:nth-child(even) {
                            &:before {
                                border-width: 0 39px 43px 50px;

                            }
                        }
                    }
                }
            }
        }
    }
    &__cell {
        position: relative;
        height: 100px;
        border: 1px solid $local-blue;
        background-color: $local-dark-blue;
        @media all and (min-width: $xs) {
            height: 150px;
        }
        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $local-light-blue-second;
        }

        &-wrap {
            position: relative;
            width: 50%;
            background-color: $local-light-blue;
            &:nth-child(odd),
            &:nth-child(even) {
                &:before {
                    content: "";
                    position: absolute;
                    z-index: 10;
                    top: 4px;
                    bottom: 4px;
                    display: block;
                }
            }
            &:nth-child(odd) {
                padding: 3px 3px 3px 6px;
                &:before {
                    left: 7px;
                    border-style: none none solid solid;
                    border-color: transparent transparent transparent $local-blue-second;
                }
            }
            &:nth-child(even) {
                padding: 3px 6px 3px 3px;
                &:before {
                    right: 7px;
                    border-style: solid solid solid none;
                    border-color: transparent $local-light-blue-third transparent transparent;
                }
            }
        }
        &-header {
            position: relative;
            z-index: 11;
            width: 80%;
            margin: -1px auto 10px;
            font-size: 12px;
            text-align: center;
            line-height: 1.3;
            color: $black;
            background-color: $local-light-blue;
            border: solid $local-blue;
            border-radius: 0 0 10px 10px;
            border-width: 0 1px 1px 1px;
        }
        &-btn {
            position: relative;
            z-index: 1100;
            display: block;
            margin: 0 auto auto;
        }
        &-img {
            position: absolute;
            z-index: 1099;
            max-height: 90%;
            max-width: 90%;
            top: 15px;
            bottom: 5px;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            @media all and (min-width: $xs) {
                top: 20px;
            }
            &-i {
                width: 100%;
                height: 100%;
            }
            &--align-end {
                align-items: flex-end;
                #{$block-name}__cell-img-i {
                    max-width: 65%;
                    max-height: 65%;
                    width: unset;
                    height: unset;
                }
            }
        }
    }
}
</style>
