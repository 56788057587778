import axios from 'axios'
import store from '~/store'

const API_ENDPOINT = 'my-items'

export default {
    firstSharedList() {
        if (! this.isFirstShared()) {
            store.commit('auth/addFirstExperience', 'first_shared_list')
            return axios.post(API_ENDPOINT + '/first-shared')
        } else {
            return Promise.resolve('Already set')
        }
    },

    isFirstShared() {
        const user = store.getters['auth/user']
        return user.first_experience && (user.first_experience.first_shared_list ?? false)
    },
    getSugarReductionReport() {
        return axios.get(API_ENDPOINT + '/sugar-reduction-report')
    },
    getNetCarbsReductionReport() {
        return axios.get(API_ENDPOINT + '/net-carbs-reduction-report')
    }
}
