<template>
    <el-form v-model="form">
        <el-alert
            v-if="appointmentError"
            title=""
            type="error"
            show-icon
            :closable="false"
        >
            <div class="appointment-alert">
                <h4 class="appointment-alert__title">
                    This time is busy with another user. Please, click reload button then you can see that.
                </h4>
                <el-button
                    class="el-button--transparent"
                    @click.prevent="reloadTimeSteps"
                >
                    reload
                </el-button>
            </div>
        </el-alert>
        <el-row
            :gutter="20"
        >
            <el-col
                :md="13"
                :sm="24"
            >
                <el-calendar
                    v-if="!loading"
                    ref="calendar"
                    v-model="form.date"
                    class="w-100"
                    :min-date="DateTime.now()"
                    cell-class-name="cell-class-name"
                >
                    <template #header="{ date }">
                        <span class="el-calendar__title">{{ date }}</span>
                        <el-button-group>
                            <el-button
                                v-if="hidePreviousMonth"
                                size="small"
                                @click="selectDate('prev-month')"
                            >
                                Previous Month
                            </el-button>
                            <el-button
                                size="small"
                                @click="selectDate('today')"
                            >
                                Today
                            </el-button>
                            <el-button
                                size="small"
                                @click="selectDate('next-month')"
                            >
                                Next Month
                            </el-button>
                        </el-button-group>
                    </template>
                    <template #dateCell="{ data }">
                        <el-row>
                            <el-col>
                                <p
                                    :class="isWeekend(data.day) ? 'disabled-text' : ''"
                                    class="default-text--align-right"
                                >
                                    {{ data.day.split('-')[2] }}
                                </p>
                            </el-col>
                        </el-row>

                        <div
                            v-for="(daySlot, day) in month_times_slots"
                            :key="day"
                            class="table_time_step"
                        >
                            <div v-if="data.day.trim() == day">
                                <div
                                    v-for="(timeSlot, index) in daySlot.availableSlots"
                                    :key="index"
                                >
                                    <div
                                        v-if="timeSlot.isUserMeeting"
                                        :class="isWeekend(data.day) ? 'table_time_step__past_data' : 'table_time_step__data'"
                                    >
                                        <h6 style="margin: 0">
                                            {{ user.coach.name.slice(0, 6) }}...
                                        </h6>
                                        <h6 style="margin: 0">
                                            {{ timeSlot.startTime.slice(0, -3) }}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </el-calendar>
                <el-skeleton
                    v-else
                    :rows="5"
                    animated
                />
            </el-col>
            <el-col
                :md="11"
                :sm="24"
            >
                <div v-if="loading">
                    <el-skeleton
                        animated
                        :rows="5"
                    />
                </div>
                <div v-else-if="!loading && times_slots.length">
                    <div class="time_slot_date">
                        {{ currentDate }}
                    </div>
                    <div>Select Time:</div>

                    <div class="time_slots">
                        <div
                            v-for="time_slot in times_slots"
                            :key="time_slot"
                            class="time_slot"
                        >
                            <el-button
                                :disabled="!time_slot.available"
                                class="w-100"
                                :class="timeSlotClasses(time_slot)"
                                @click="saveTime(time_slot)"
                            >
                                {{ formatSlotTime(time_slot.time) }}
                            </el-button>
                        </div>
                    </div>
                    <div>Add Comment:</div>
                    <el-input
                        v-model="form.text"
                        :rows="4"
                        type="textarea"
                    />
                </div>
                <div
                    v-else-if="times_slots.length === 0"
                >
                    <el-alert
                        title="Your coach isn't available on this date. Please choose another date."
                        type="warning"
                        :closable="false"
                    />
                </div>

                <div class="dialog-footer mt-2">
                    <el-button
                        @click.prevent="onClose"
                    >
                        Cancel
                    </el-button>
                    <el-button
                        :disabled="!form.time || loading"
                        class="is-active"
                        @click="onCreate"
                    >
                        Create
                    </el-button>
                </div>
            </el-col>
        </el-row>
    </el-form>
</template>

<script setup>
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import store from '~/store'
import coachApi from '~/modules/coach/coachApi'
import _ from 'lodash'
import { DateTime } from 'luxon'

/**
 * Data
 */

const emits = defineEmits(['close'])
const user = store.getters['auth/user']
const form = ref({
    date: DateTime.now(),
    time: null,
    endTime: null,
    text: ''
})
const loading = ref(true)
const calendar = ref()
const appointmentError = ref(false)
let month_times_slots = ref([])
let times_slots = ref([])
const user_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

/**
 * Computed props
 */
const hidePreviousMonth = computed(() => {
    return DateTime.fromJSDate(new Date(form.value.date)).minus({ month: 1}).month >= DateTime.now().month
})

const currentDate = computed(() => {
    return DateTime.fromJSDate(new Date(form.value.date)).toFormat('yyyy LLLL d, cccc')
})

/**
 * Live circles
 */
onMounted(async () => {
    loading.value = true
    await fetchTimelineByMonth()
    setTimesSlots(true)
    loading.value = false
})

/**
 * Fetches
 */

const fetchTimelineByMonth = async () => {
    let day = DateTime.fromJSDate(new Date(form.value.date))
    const params = {
        month: day.toFormat('LL'),
        year: day.year,
        timezone: user_timezone
    }
    const { data } = await coachApi.getTimeSlotsByMonth(params)
        .catch((err) => {
            console.log(err.response)
            loading.value = false
        })
    appointmentError.value = false
    month_times_slots.value = data
}
const fetchCreateAppointment = () => {
    const startDate = dataFormat(DateTime.fromJSDate(new Date(form.value.date)))
    const startDateTime = DateTime.fromJSDate(new Date(startDate + 'T' + form.value.time))
    let endDateTime = DateTime.fromJSDate(new Date(startDate + 'T' + form.value.endTime))

    if (startDateTime > endDateTime) {
        endDateTime = endDateTime.plus({ day: 1 })
    }

    const payload = {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        notes: form.value.text,
        timezone: user_timezone
    }

    coachApi.saveAppointment(payload)
        .then(async () => {
            appointmentError.value = false
            form.value.time = null
            form.value.text = ''
            await fetchTimelineByMonth()
            await setTimesSlots()
            loading.value = false
        })
        .catch(err => {
            appointmentError.value = true
            console.log(err.response)
            loading.value = false
        })
}

/**
 * Methods
 */

const isWeekend = (date) => {
    return Object.values(month_times_slots.value).some((data) => {
        return data.date === date && !(data.availableSlots.length > 0 && data.availableSlots.some(time => time.available))
    }) || DateTime.now().minus({ day: 1}) > DateTime.fromJSDate(new Date(date))
}

const selectDate = (val) => {
    calendar.value.selectDate(val)
}

const reloadTimeSteps = async () => {
    loading.value = true
    appointmentError.value = false
    await fetchTimelineByMonth()
    setTimesSlots(true)
    loading.value = false
}

const toNextDayIfWeekend = (date) => {
    let day = DateTime.fromJSDate(new Date(date),{ zone: 'UTC', setZone: true }).setZone(user_timezone, { keepLocalTime: true })
    let nextDay = day.plus({ day: 1 })
    if (isWeekend(dataFormat(day)) && day > DateTime.now()) {
        toNextDayIfWeekend(dataFormat(nextDay))
    } else {
        form.value.date = day
    }
}

const formatSlotTime = (val) => {
    const timeArray = val.split(':')
    return `${ timeArray[0] }:${ timeArray[1] }`
}

const setTimesSlots = (today = false) => {
    let date = DateTime.fromJSDate(new Date(form.value.date))
    const daySlots = _.cloneDeep(month_times_slots.value)[dataFormat(date)]
    if (today) {
        times_slots.value = daySlots.availableSlots.map(date => {
            const slotDate = DateTime.fromJSDate(new Date(form.value.date)).toFormat('yyyy-LL-dd')
            if (DateTime.now().plus({ hours: 1}) < DateTime.fromJSDate(new Date(slotDate + 'T' + date.startTime))) {
                return {
                    time: date.startTime,
                    endTime: date.endTime,
                    available: date.available,
                    amIHasMeeting: date.isUserMeeting
                }
            } else {
                return null
            }
        }).filter(item => !!item)
    } else {
        times_slots.value = daySlots.availableSlots.map(date => {
            return {
                time: date.startTime,
                endTime: date.endTime,
                available: date.available,
                amIHasMeeting: date.isUserMeeting
            }
        })
    }
}

const dataFormat = (date) => {
    return date.toFormat('yyyy-LL-dd')
}

const timeSlotClasses = (time_slot) => {
    if (time_slot.amIHasMeeting) return 'changed'
    if (time_slot.time === form.value.time) return 'is-active'
    return ''
}

/**
 * Buttons
 */
const saveTime = (val) => {
    form.value.time = val.time
    form.value.endTime = val.endTime
}

const onCreate = async () => {
    loading.value = true
    await fetchCreateAppointment()
}

const onClose = () => {
    emits('close')
}

/**
 * Watches
 */
watch(() => form.value.date, async (newValue, lastValue) => {
    form.value.time = null
    const newMonthValue = DateTime.fromJSDate(new Date(newValue)).month
    const newYearValue =  DateTime.fromJSDate(new Date(newValue)).year

    const lastMonthValue =  DateTime.fromJSDate(new Date(lastValue)).month
    const lastYearValue = DateTime.fromJSDate(new Date(lastValue)).year

    if (newMonthValue !== lastMonthValue || lastYearValue !== newYearValue) {
        loading.value = true
        times_slots.value = []
        await fetchTimelineByMonth()
        toNextDayIfWeekend(dataFormat(DateTime.fromJSDate(new Date(newValue))))
        loading.value = false

    } else if (DateTime.now() > form.value.date) {
        setTimesSlots(true)
    } else {
        setTimesSlots()
    }
})

</script>

<style lang="scss" scoped>
.time_slots {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(6, 50px);
    gap: 10px;

   .changed {
       background: #484baf;
       color: white;
       border-color: #484baf;
   }
}

.disabled-text {
    color: #E6E8EB;
}

.cell-class-name {
    height: 80px !important;
    width: 50px !important;
    overflow: hidden;
}

.table_time_step {
    overflow: auto;
    max-height: 50px;

    &::-webkit-scrollbar {
        display: none;
    }

    &__data {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 2px 0;
        background: #e1f2ff;
        border-radius: 6px;
        padding: 0 3px;
        text-decoration: none;
        color: #1b1b1b;

        &:hover {
            background: #7fc6f8;
        }
    }
    &__past_data {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 2px 0;
        background: #efefef;
        border-radius: 6px;
        padding: 0 3px;
        text-decoration: none;
        color: #1b1b1b;
    }
}

.time_slot_date {
    padding: 20px 0;
    color: black;
}

.appointment-alert {
    display: flex!important;
    align-items: center;

    &__title {
        font-weight: 500 !important;
        margin-right: 10px;
    }
}
</style>
