<template>
    <div class="amount">
        <div class="amount__label">
            <span>{{ title }}</span>
            <el-tooltip
                v-if="!disabled"
                class="box-item"
                content="How Much of this do you consume?"
                placement="top-end"
                effect="light"
                trigger="click"
            >
                <i class="fa-solid fa-info-circle" />
            </el-tooltip>
        </div>
        <el-form
            :model="form"
            :rules="rules"
            ref="formRef"
        >
            <el-row
                :gutter="10"
                class="main-content__inputs"
            >
                <el-col :span="12">
                    <el-form-item prop="amount">
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model.decimal="form.amount"
                                placeholder=" "
                                class="el-input--floating-label"
                                :disabled="disabled"
                                @input="onAmountInput"
                            >
                                <template #prefix>
                                    Your Serving
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item prop="amount_unit">
                        <el-select
                            v-model="form.amount_unit"
                            placeholder=" "
                            class="el-input--floating-label w-100"
                            :disabled="disabled"
                            @change="formValidate(formRef)"
                        >
                            <el-option
                                v-for="unit in amount_units_array"
                                :key="unit"
                                :label="unit"
                                :value="unit"
                            />
                            <template #prefix>
                                Unit
                            </template>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>

<script setup>
import {ref, computed} from 'vue'
import {useModelWrapper} from '~/includes/composables/modelWrapper'

const emit = defineEmits()

const props = defineProps({
    amount: {
        type: Number,
        required: true
    },
    amountUnit: {
        type: String,
        required: true
    },
    title: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    max: {
        type: Number,
        default: 1000
    },
})

const amount_units_array = [
    'fl oz',
    'oz',
    'g',
    'ml',
]

const form = ref({})
const formRef = ref({})

const rules = computed(() => ({
    amount: [
        { validator: checkDecimal, trigger: 'change' }
    ]
}))

const formValidate = (form) => {
  setTimeout(() => {
    form.validateField('amount')
  }, 0)

}

const onAmountInput = (value) => {
  if (value.charAt(0) == 0 && value.charAt(1) != '.' && value.charAt(1)) {
    form.value.amount = value.substring(1)
  }
}

function checkDecimal(rule, value, callback) {
    if (!value) {
        return callback(new Error('Amount is required'))
    }

    const regex = new RegExp(/^\d*\.?\d*$/)

    if (!regex.test(value)) {
        callback(new Error('Amount should be decimal'))
    }

    if (parseFloat(value) > props.max) {
        callback(new Error('Amount should be less than ' + props.max))
    } else {
        callback()
    }
}

form.value.amount = useModelWrapper(props, emit, 'amount')
form.value.amount_unit = useModelWrapper(props, emit, 'amountUnit')
</script>
