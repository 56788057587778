<template>
    <div
        class="main-content main-content--offset-bottom"
    >
        <div
            class="g-container"
            style="text-align: center"
        >
            <br>
            <el-button
                class="main-content--new-appointment"
                @click="showFAppointment = true"
            >
                Schedule a coaching session
            </el-button>
            <br><br><br>
            <h2>Your Appointments</h2>
            <el-calendar
                v-if="!loading"
                v-model="calendar"
                class="w-100"
            >
                <template #dateCell="{ data }">
                    <el-row>
                        <el-col>
                            <p
                                :class="isWeekend(data.day) ? 'disabled-text' : ''"
                                class="default-text--align-right"
                            >
                                {{ data.day.split('-')[2] }}
                            </p>
                        </el-col>
                    </el-row>

                    <div
                        v-for="(daySlot, day) in month_times_slots"
                        :key="day"
                        class="table_time_step"
                    >
                        <div v-if="data.day.trim() == day">
                            <div
                                v-for="(timeSlot, index) in daySlot.availableSlots"
                                :key="index"
                            >
                                <divisWeekend
                                    v-if="timeSlot.isUserMeeting"
                                    :class="isWeekend(data.day) ? 'table_time_step__past_data' : 'table_time_step__data'"
                                >
                                    <h6 style="margin: 0">
                                        {{ user.coach.name.slice(0, 6) }}...
                                    </h6>
                                    <h6 style="margin: 0">
                                        {{ timeSlot.startTime.slice(0, -3) }}
                                    </h6>
                                </divisWeekend>
                            </div>
                        </div>
                    </div>
                </template>
            </el-calendar>
            <el-skeleton
                v-else
                :rows="5"
                animated
            />
        </div>
        <el-dialog
            v-model="showFAppointment"
            title="Create appointment"
            :close-on-click-modal="false"
            width="80%"
        >
            <AppointmentForm
                @close="showFAppointment = false"
            />
        </el-dialog>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import bus from '~/includes/Event'
import AppointmentForm from './AppointmentForm'
import coachApi from '~/modules/coach/coachApi'
import store from '~/store'

onMounted( async () => {
    bus.$emit('set-title', 'Coaching')
    loading.value = true
    await fetchTimelineByMonth()
    loading.value = false
})

const showFAppointment = ref(false)
const calendar = ref(new Date())
const user = store.getters['auth/user']
let month_times_slots = ref([])
const loading = ref(true)

const isWeekend = (date) => {
    return Object.values(month_times_slots.value).some((data) => {
        return data.date === date && !(data.availableSlots.length > 0 || data.availableSlots.some(time => time.available))
    }) || new Date() > new Date(date)
}

const fetchTimelineByMonth = async () => {
    let day = new Date(calendar.value)
    const params = {
        month: day.toISOString().split('-')[1],
        year: day.toISOString().split('-')[0],
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    const { data } = await coachApi.getTimeSlotsByMonth(params)
        .catch((err) => {
            console.log(err)
            loading.value = false
        })
    month_times_slots.value = data
}

watch(() => calendar.value, async (newValue, lastValue) => {
    const newMonthValue = newValue.getMonth()
    const newYearValue = newValue.getFullYear()

    const lastMonthValue = lastValue.getMonth()
    const lastYearValue = lastValue.getFullYear()
    if (newMonthValue !== lastMonthValue || lastYearValue !== newYearValue) {
        loading.value = true
        await fetchTimelineByMonth()
        loading.value = false
    }
})
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.main-content {
    &--new-appointment {
        @media all and (max-width: $s) {
            font-size: 15px;
        }
    }
}

.disabled-text {
    color: #E6E8EB;
}

.table_time_step {
    overflow: auto;
    max-height: 50px;

    &::-webkit-scrollbar {
        display: none;
    }

    &__data {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 2px 0;
        background: #e1f2ff;
        border-radius: 6px;
        padding: 0 3px;
        text-decoration: none;
        color: #1b1b1b;

        &:hover {
            background: #7fc6f8;
        }
    }

    &__past_data {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 2px 0;
        background: #efefef;
        border-radius: 6px;
        padding: 0 3px;
        text-decoration: none;
        color: #1b1b1b;
    }
}
</style>
