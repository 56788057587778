<template>
    <Roc />
</template>

<script setup>
import {onMounted} from 'vue'
import bus from '~/includes/Event'
import Roc from './Roc'

onMounted(() => bus.$emit('set-title', 'Notifications'))

</script>
