<template>
    <header class="header">
        <div class="main-header">
            <div class="header__center">
                <header-title />
            </div>
        </div>
    </header>
</template>

<script setup>
import HeaderTitle from '~/base/components/HeaderTitle'

</script>

<style lang="scss" scoped>
.main-header {
    justify-content: center;
}
</style>
