<template>
    <div class="drag-game">
        <div class="drag-game__sugar-progress">
            <SugarProgress
                :value="progressValue"
                :title="progressTitle"
            />
        </div>
        <div class="drag-game__plate">
            <div class="drag-game__forge" />
            <div
                id="plate"
                ref="plateEl"
                class="drag-game__plate-target"
                :class="{'drag-game__plate-target--dish-big': bigDishImage}"
                @drop="drop"
                @dragover="allowDrop"
                @dragenter="overPlate"
                @dragleave="outPlate"
            />
            <div class="drag-game__spoon-knife" />
        </div>
        <div class="drag-game__products">
            <div
                ref="productsEl"
                class="products dishes-list"
            >
                <div
                    v-for="product in localProducts"
                    :id="'product_'+product.id"
                    :key="product.id"
                    class="dishes-list__item"
                >
                    <div
                        class="dishes-list__item-cell"
                        :class="{
                            'dishes-list__item-cell--wrong': product.status === 'wrong',
                            'dishes-list__item-cell--correct': product.status === 'correct'
                        }"
                    >
                        <div class="dishes-list__dish">
                            <img
                                :id="product.id"
                                :draggable="!product.status"
                                :src="product.image"
                                :alt="product.name"
                                :title="product.name"
                                class="dishes-list__dish-image"
                                @dragstart="dragStart"
                                @touchstart="handleTouchStart"
                                @touchmove="handleTouchMove"
                                @touchend="handleTouchEnd"
                            >
                        </div>
                        <i
                            v-if="!!product.status"
                            class="dishes-list__dish-icon fa-solid fa-circle-check"
                            :class="{
                                'fa-circle-check': product.status === 'correct',
                                'fa-circle-xmark': product.status === 'wrong'
                            }"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, ref, inject} from 'vue'
import SugarProgress from '~/modules/games/components/drag/SugarProgress'
const name = 'DragGame'

const ON_PLATE_SECONDS = 3
const STATUS_CORRECT = 'correct'

const props = defineProps({
    products: {
        type: Array,
        required: true,
    },
    bigDishImage: {
        type: Boolean,
        default: false,
        description: 'a modifier that makes the image on the plate bigger'
    }
})

const localProducts = computed(() => props.products)

const limit = inject('limit')

const plateEl = ref(null)
const productsEl = ref(null)
const productOnPlate = ref(null)

const progressValue = computed(() => productOnPlate.value?.value)
const progressTitle = computed(() => productOnPlate.value?.name)

const done = computed(() => {
    const correctVariants = localProducts.value.filter(o => o.value <= limit)
    const correctAnswers = localProducts.value.filter(o => o.status === STATUS_CORRECT)

    return correctVariants.length === correctAnswers.length
})
const emit = defineEmits(['done'])

function dragStart(event) {
    event.dataTransfer.setData('text', event.target.id)
}

function allowDrop(event) {
    event.preventDefault()
}

function overPlate(event) {
    plateEl.value.classList.add('over')
}

function outPlate(event) {
    plateEl.value.classList.remove('over')
}

function drop(event) {
    event.preventDefault()
    let id = event.dataTransfer.getData('text')
    handleOnPlate(id)
    if(done.value) {
        emit('done')
    }
}

function removeFromPlate(id) {
    if (productOnPlate.value?.id === id) productOnPlate.value = null
    document.querySelector(`#product_${id} .dishes-list__item-cell .dishes-list__dish`).appendChild(document.getElementById(id))
}

function handleOnPlate(id) {
    if (productOnPlate.value) {
        removeFromPlate(productOnPlate.value.id)
    }
    plateEl.value.classList.remove('over')
    plateEl.value.appendChild(document.getElementById(id))
    let product = localProducts.value.find(o => o.id == id)
    product.status = product.value <= limit ? STATUS_CORRECT : 'wrong'
    productOnPlate.value = product
    _.delay(() => removeFromPlate(id), 1000 * ON_PLATE_SECONDS)
}

/**
 * On mobile
 */

let droppedIn = ref(false)
const activeEvent = ref('')

function handleTouchStart(event) {
    event.preventDefault()
    activeEvent.value = 'start'
    overPlate()
}

function handleTouchMove(event) {
    let path = event.composedPath()
    if (!! localProducts.value.find(o => o.id == path[0].id)?.status) {
        return
    }
    let touchLocation = event.targetTouches[0]
    let pageX = (touchLocation.pageX - event.target.clientWidth/2) + 'px'
    let pageY = (touchLocation.pageY - event.target.clientHeight/2) + 'px'

    path[0].style.position = 'absolute'
    path[0].style.left = pageX
    path[0].style.top = pageY

    activeEvent.value = 'move'
    path[0].classList.add('dragging')
}

function handleTouchEnd(event) {
    let path = event.composedPath()
    event.preventDefault()
    if (activeEvent.value === 'move') {
        let {pageX, pageY} = event.changedTouches[0]
        if (detectDropOnPlate(pageX, pageY)) {
            handleOnPlate(path[0].id)
            droppedIn = true
        }
        path[0].style.position = 'initial'
        path[0].classList.remove('dragging')
    }
    if(done.value) {
        emit('done')
    }
}

function detectDropOnPlate(pageX, pageY) {
    return document.elementFromPoint(pageX, pageY).id === plateEl.value.id
}

</script>

<style scoped lang="scss">
@import "resources/sass/_variables.scss";
.drag-game {
    $block-name: &;
    @media all and (min-width: $s) {
    padding-top: 40px;
}
    &__sugar-progress {
        margin-bottom: 10px;
        overflow: hidden;
        @media all and (min-width: $s) {
            margin-bottom: 20px;
        }
    }
    &__plate {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 0 20px;
        @media all and (min-width: $s) {
            margin: 0 0 40px;
        }
        &-target {
            z-index: 3033;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 170px;
            height: 170px;
            border-radius: 50%;
            background-image: url(/images/games/plate@2x.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 172px;
            transition: 0.2s box-shadow ease-in-out, 0.2s opacity ease-in-out;
            &.over {
                box-shadow: 0 0 8px rgba(0,0,0, 30%);
                opacity: 0.3;
            }
            .dishes-list__dish-image {
                width: 100px;
                height: 100px;
                pointer-events: none;
            }
            &--dish-big {
                .dishes-list__dish-image {
                    width: 170px;
                    height: 170px;
                }
            }
        }
    }
    &__forge,
    &__spoon-knife {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &__forge {
        width: 25px;
        height: 125px;
        margin-right: 10px;
        background-image: url(/images/games/forge@2x.png);
    }
    &__spoon-knife {
        width: 48px;
        height: 126px;
        margin-left: 10px;
        background-image: url(/images/games/knife-spoon@2x.png);
    }
    &__button {
        text-align: center;
    }
    .dragging {
        width: 75px;
        height: 75px;
    }
}

.dishes-list {
    $block-name: &;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    &__item {
        width: 33.3333%;
        margin-bottom: 15px;
        padding: 0 8px;
        @media all and (min-width: $xs) {
            width: 26%;
        }
        &-cell {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            max-width: 150px;
            height: 100%;
            max-height: 150px;
            margin: 0 auto;
            border: 2px solid $gray;
            border-radius: 5px;
            transition: 0.2s border-color ease-in-out;
            &:empty {
                border-color: $light-gray;
            }
            &--correct,
            &--wrong {
                position: relative;
                #{$block-name} {
                    &__dish {
                        cursor: default;
                    }
                }
            }
            &--correct {
                border-color: $indigo;
                #{$block-name} {
                    &__dish-icon {
                        color: $blue;
                    }
                }
            }
            &--wrong {
                border-color: $maroon;
                #{$block-name} {
                    &__dish-icon {
                        color: $maroon;
                    }
                }
            }
        }
    }
    &__dish {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 3px;
        cursor: grab;
        &:active {
            cursor: grabbing;
        }
        &-icon {
            position: absolute;
            z-index: 10;
            top: 8px;
            right: 8px;
            border-radius: 10px;
            overflow: hidden;
            font-size: 20px;
            background-color: $white;
        }
        &-image {
            max-width: 100%;
            max-height: 100%;
            user-select: none;
        }
    }
}
</style>
