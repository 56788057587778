<template>
    <div class="pmo-page-slider">
        <div
            class="pmo-page-slider__prev"
            @click="goPrev"
        >
            <i class="pmo-page-slider__prev-btn fa-solid fa-angle-left" />
        </div>

        <div class="pmo-page-slider__progress">
            <div class="pmo-page-slider__progress-info">
                <span class="pmo-page-slider__progress-info-page">
                    {{ page }}
                </span>
                of
                <span class="pmo-page-slider__progress-info-total">
                    {{ total }}
                </span>
            </div>
            <div class="pmo-page-slider__progress-bar">
                <el-progress
                    :percentage="percent"
                    :show-text="false"
                    :stroke-width="10"
                    color="#c8c9e7"
                    class="el-progress--pmo"
                />
            </div>
        </div>
        <div
            v-if="!disableNext"
            class="pmo-page-slider__next"
            @click="goNext"
        >
            <i class="pmo-page-slider__next-btn fa-solid fa-angle-right" />
        </div>
    </div>
</template>

<script setup>
import {computed, getCurrentInstance} from 'vue'
import {useRoute, useRouter} from 'vue-router'

const { proxy } = getCurrentInstance()
const router = useRouter()
const route = useRoute()

const emit = defineEmits()

const props = defineProps({
    page: {
        type: Number,
        required: true,
    },
    total: {
        type: Number,
        required: true,
    },
    prevSlug: {
        type: String,
        default: null,
    },
    nextSlug: {
        type: String,
        default: null,
    },
    disableNext: {
        type: Boolean,
        default: false,
    },
    subCategory: {
        type: Object,
        default: null,
    },
})

const percent = computed(() => props.page / props.total * 100)

function goPrev() {
    localStorage.removeItem('pmo_edit_products')

    if (props.prevSlug) {
        router.push({name: 'pmo.show', params: {pmo_category: props.prevSlug} })
    } else {
        goBack()
    }
}
function goNext() {
    emit('goNext')
}

function goBack() {
    if (route.params.pmo_category) {
        const route_name = route.name === 'pmo.show' ? 'pmo' : 'pmo.show'

        router.push({
            name: route_name,
            params: {
                pmo_category: route.params.pmo_category,
            },
        })
    } else {
        router.push({name: 'my-items.shop'})
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
    .pmo-page-slider {
        $block-name: &;
        display: flex;
        justify-content: center;
        align-items: center;
        &__prev,
        &__next {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            cursor: pointer;
            &:hover {
                #{$block-name}__prev-btn,
                #{$block-name}__next-btn {
                    color: $green;
                }
            }
            &-btn {
                font-size: 12px;
                color: $indigo;
                transition: 0.2s color ease-in-out;
            }
        }
        &__progress {
            width: 110px;
            margin: 0 5px;
            &-info {
                margin-bottom: 3px;
                font-size: 12px;
                line-height: 1.35;
                text-align: center;
                color: $indigo;
                user-select: none;
            }
        }
    }
</style>
