<template>
    <div class="store">
        <el-image
            v-if="store.store_logo_url"
            :src="store.store_logo_url"
            fit="contain"
            alt="banner logo will be here"
            class="store__content-logo"
        />
        <div class="store__content">
            <p class="store__content-name">
                {{ store.banner }}
                <el-tag v-if="isPrimary">
                    Primary
                </el-tag>
            </p>
            <p class="store__content-address">
                {{ store.address }}
            </p>
        </div>
        <div class="store__location">
            <a
                v-if="showLink"
                :href="externalUrl"
                class="store__location-link"
                target="_blank"
            >
                <i class="fa fa-location-dot" />
            </a>
        </div>
    </div>
</template>

<script>

export default {
    name: 'StoreListItem',
    props: {
        store: {
            type: Object,
            default: {},
        },
        isPrimary: {
            type: Boolean,
            default: false
        },
        showLink: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        externalUrl() {
            if (!this.store.geoLocation?.coordinates) return '#'

            const params = _.map([
                this.store.address,
                this.store.city,
                this.store.state + '+' + this.store.zip
            ], _.trim).join(',+')

            return 'https://www.google.com/maps/place/' + params
        }
    }
}
</script>

<style lang="scss" scoped>
    @import "resources/sass/_variables.scss";
    .store {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__content {
            flex-grow: 1;
            &-name,
            &-address  {
                line-height: 1.5;
            }
            &-name {
                font-weight: 600;
                color: $dark;
            }
            &-address {
                font-size: 14px;
                color: $gray;
            }
        }
        &__location {
            flex-shrink: 0;
            margin-left: 10px;
            &-link {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                text-decoration: none;
                color: $gray;
                transition: 0.2s color ease-in-out;
                &:hover {
                    color: $green;
                }
            }
        }
        &__content-logo {
            width: 48px;
            height: 48px;
            margin-right: 10px;
        }
    }
</style>
