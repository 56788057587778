<template>
    <section class="main">
        <user-intro
            :robot-messages-all="robotMessagesAllComputed"
            @finishUserIntro="goNext"
        />
        <header class="header">
            <div class="header__left-side">
                <div class="header__menu" />
            </div>
            <div class="header__center">
                <h1 class="header__text">
                    Profile Builder
                </h1>
            </div>
            <div class="header__right-side">
                <div class="header__menu" />
            </div>
        </header>
        <div class="main-content main-content--base-layout">
            <div class="main-content__top-content-section">
                <div class="g-container">
                    <div class="main-content__text">
                        Establishing a profile takes about 10 minutes and helps us understand what success might look like for you and how to best interact.
                    </div>
                    <div class="main-content__questions-group">
                        <div class="questions-group">
                            <div
                                v-for="quiz in quizzesSectionsComputed"
                                :id="`quiz-page-${quiz.slug}`"
                                :key="quiz.slug"
                                class="questions-group__item"
                            >
                                <div
                                    class="question-module"
                                    :class="quiz.status"
                                >
                                    <div class="question-module__image">
                                        <div class="question-module__image-wrap">
                                            <img
                                                :src="quiz.image"
                                                class="question-module__image-i"
                                                alt=""
                                            >
                                        </div>
                                    </div>
                                    <div class="question-module__title">
                                        {{ quiz.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                id="bottom-content-section"
                class="main-content__bottom-content-section"
            >
                <div class="g-container">
                    <div class="main-content__button-wrap">
                        <el-button
                            type="primary"
                            round
                            class="continue-button"
                        >
                            Continue
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { computed } from 'vue'
import UserIntro from './UserIntro'
import {useRoute, useRouter} from 'vue-router'
import store from '~/store'

const router = useRouter()
const route = useRoute()

const props = defineProps({
    nextRoute: {
        type: Object,
        required: true,
    },
})

const user = store.getters['auth/user']

const quizzes = [
    {
        slug: 'aspirations',
        name: 'Aspirations',
        image: '/images/quiz/aspirations.png',
        status: 'active',
    },
    {
        slug: 'household',
        name: 'About your household',
        image: '/images/quiz/household.png',
        status: 'active',
        hide: !user.is_fdm
    },
    {
        slug: 'motivations',
        name: 'Learning & interaction style',
        image: '/images/quiz/motivations.png',
        status: 'active',
    },
]

const robotMessagesAll = [
    {
        robotPosition: 'bottom-right-screen-corner',
        robotImage: '/images/robots/robot_smile_reflect.svg',
        robotAlign: 'right',
        rightAlignOnPage: true,
        text: 'The following three profile areas play a vital role in customizing your <b>journey</b> and shaping your <b>experience</b>.',
    },
    {
        robotPosition: '#quiz-page-aspirations',
        robotImage: '/images/robots/robot_smile_reflect.svg',
        robotAlign: 'right',
        rightAlignOnPage: true,
        text: 'Setting an Aspiration will help align your actions and behaviors as you move through your Pantry Makeover.',
        bordered: true,
    },
    {
        robotPosition: '#quiz-page-household',
        robotImage: '/images/robots/robot_smile_reflect.svg',
        robotAlign: 'right',
        rightAlignOnPage: true,
        text: 'Defining who will be benefiting from the Pantry Makeover is done in Household',
        bordered: true,
        hide: !user.is_fdm
    },
    {
        robotPosition: '#quiz-page-motivations',
        robotImage: '/images/robots/robot_smile_reflect.svg',
        robotAlign: 'right',
        rightAlignOnPage: true,
        text: 'Customizing your journey continues with identifying your Learning & interaction style.',
        bordered: true,
    },
]

const robotMessagesAllComputed = computed(() => robotMessagesAll.filter(o => !o.hide))
const quizzesSectionsComputed = computed(() => quizzes.filter(o => !o.hide))

function goNext() {
    if (route.query.back === 'yes') {
        router.back()
    } else {
        router.push({name: props.nextRoute})
    }
}
</script>
