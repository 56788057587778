<template>
    <section class="main">
        <HeaderWithBack />
        <child />
    </section>
</template>

<script setup>
import Child from '~/base/components/Child'
import HeaderWithBack from '~/base/components/HeaderWithBack'
const name = 'Profile'
</script>
