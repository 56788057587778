<template>
    <div class="product-item">
        <div class="product-item__product">
            <ProductPreviewBlock
                :key="product.upc"
                :product="product"
                no-border
                :class="{'product-preview-block--cross-out-text': product.liked === -1}"
            />
        </div>
        <div class="product-item__btn-wrap">
            <div class="product-item__btn">
                <div
                    class="functional-button"
                    :class="{'functional-button--like': product.liked === 1}"
                    @click="clickLike(product)"
                >
                    <i class="functional-button__icon fa-solid fa-thumbs-up" />
                </div>
            </div>
            <div class="product-item__btn">
                <div
                    class="functional-button functional-button--align-bottom"
                    :class="{'functional-button--dislike': product.liked === -1}"
                    @click="clickDislike(product)"
                >
                    <i class="functional-button__icon fa-solid fa-thumbs-down" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import ProductPreviewBlock from '~/modules/labelinsight/components/ProductPreviewBlock'

import store from '~/store'

const props = defineProps({
    'product': {
        type: [ Object ],
        required: true,
        default: null
    }
})

const emit = defineEmits(['clickProduct:product'])



function clickLike(product) {
    store.dispatch('my-items/likeProduct', product.gtinFormats.gtin14)
}

function clickDislike(product) {
    store.dispatch('my-items/dislikeProduct', product.gtinFormats.gtin14)
}

</script>

<style lang="scss" scoped>
.product-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__product {

    }
    &__btn {
        &:not(:last-child) {
            margin-right: 10px;
        }
        &-wrap {
            display: flex;
            align-items: center;
            margin-left: 10px;
        }
    }
}
</style>
