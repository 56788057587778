<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="g-container">
                <el-form
                    id="contact-form"
                    ref="contactForm"
                    :model="form"
                    :rules="rules"
                >
                    <el-form-item
                        prop="first_name"
                        :error="formErrors.get('first_name')"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model="form.first_name"
                                placeholder=" "
                                class="el-input--floating-label"
                                type="text"
                                name="first_name"
                            >
                                <template #prefix>
                                    First Name
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        prop="last_name"
                        :error="formErrors.get('last_name')"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model="form.last_name"
                                placeholder=" "
                                class="el-input--floating-label"
                                type="text"
                                name="last_name"
                            >
                                <template #prefix>
                                    Last Name
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        prop="client_level"
                        :error="formErrors.get('client_level')"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model="form.clientLevel"
                                placeholder=" "
                                class="el-input--floating-label"
                                type="text"
                                name="client_level"
                            >
                                <template #prefix>
                                    Client Level
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        prop="email"
                        :error="formErrors.get('email')"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model="form.email"
                                placeholder=" "
                                class="el-input--floating-label"
                                type="email"
                                name="email"
                            >
                                <template #prefix>
                                    Email Address
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        prop="phone"
                        :error="formErrors.get('phone')"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model="form.phone"
                                placeholder="(###)###-####"
                                type="text"
                                pattern="([0-9]{3})[0-9]{3}-[0-9]{4}"
                                class="el-input--input-mask"
                                @input="phoneMaskFormatter"
                            >
                                <template #prefix>
                                    Phone Number
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        prop="title"
                        :error="formErrors.get('title')"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-select
                                v-model="form.title"
                                placeholder=" "
                                class="el-select--floating-label w-100"
                            >
                                <el-option
                                    v-for="item in sections"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                />
                                <template #prefix>
                                    Title
                                </template>
                            </el-select>
                        </label>
                    </el-form-item>
                    <el-form-item
                        prop="text"
                        :error="formErrors.get('text')"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model="form.text"
                                type="textarea"
                                :autosize="{ minRows: 3 }"
                                name="text"
                                placeholder="Please ask your questions here or briefly describe the issue you are encountering"
                            />
                        </label>
                    </el-form-item>
                    <div class="main-content__button-wrap">
                        <el-button
                            type="primary"
                            round
                            class="continue-button"
                            @click="onSubmit"
                        >
                            Send
                        </el-button>
                    </div>
                </el-form>
                <div
                    v-if="showSuccessSent"
                    class="default-text"
                >
                    {{ successMessage }}
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import {Errors} from '~/includes/Errors'
import axios from 'axios'
import bus from '~/includes/Event'
import store from '~/store'

const user = ref(store.getters['auth/user'])

const sections = [
    'Profile Builder',
    'Learn',
    'PMO',
    'My Items',
    'Menu',
    'Sugar Report',
    'Other',
]

const init_form = {
    first_name: '',
    last_name: '',
    email: '',
    text: '',
    phone: '',
    company: '',
    title: '',
}

const form = ref({})

const rules = ref({
    email: [{required: true, message: 'Please enter your email', trigger: 'blur'}],
})

const showSuccessSent = ref(false)
const successMessage = ref('Request has sent!')

const formErrors = ref(new Errors())

const contactForm = ref()

onMounted(() => {
    bus.$emit('set-title', 'Feedback & Support')

    form.value = Object.assign({}, init_form)

    if (!user.value) {
        if (store.getters['auth/token']) {
            axios.get('user').then(r => {
                user.value = r.data
                setUserData()
            })
        } else {
            console.log('There is no authenticated user')
        }

    } else {
        setUserData()
    }
})

const setUserData = () => {
    form.value.first_name = user.value.first_name
    form.value.last_name = user.value.last_name
    form.value.email = user.value.email
    form.value.phone = user.value.phone_number
    form.value.clientLevel = user.value.client_level?.name
    form.value.client_level_id = user.value.client_level._id
}

const onSubmit = () => {
    contactForm.value.validate((valid) => {
        if (valid) {
            axios.post('contact', form.value)
                .then(() => {
                    clearForm()
                    setUserData()
                    showSuccessSent.value = true
                }).catch(errors => {
                    formErrors.value.record(errors.response.data.errors)
                })
        }
    })
}

const clearForm = () => {
    formErrors.value.clear()
    form.value = Object.assign({}, init_form)
}

function phoneMaskFormatter(value) {
    form.value.phone = form.value.phone.replace(/[\D]/g, '')
    if (form.value.phone.length > 5) {
        form.value.phone = form.value.phone.replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)$2-$3').substring(0,13)
    } else if (form.value.phone.length > 3) {
        form.value.phone = form.value.phone.replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)$2').substring(0,13)
    } else if (form.value.phone.length > 0) {
        form.value.phone = form.value.phone.replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)').substring(0,13)
    } else {
        // form.value.phone_number = '(###)###-####'
    }
}
</script>
