<template>
    <div class="quiz-question__quiz-rate">
        <el-radio-group
            v-model="question.answer"
            class="quiz-rate"
            @change="$emit('set', question._id, question.answer)"
        >
            <el-radio-button
                v-for="variant in question.variants"
                :key="variant._id"
                :label="variant._id"
            >
                <span v-html="variant.value" />
            </el-radio-button>
        </el-radio-group>
    </div>
    <div
        v-if="question.additional_info"
        class="quiz-question__rate-hint"
    >
        <div
            class="rate-hint"
        >
            <div
                v-if="question.additional_info.min_image"
                class="float-left rate-hint__image-wrap"
            >
                <img
                    :src="question.additional_info.min_image"
                    class="rate-hint__image"
                    alt=""
                >
            </div>
            <div
                v-if="question.additional_info.max_image"
                class="float-right rate-hint__image-wrap"
            >
                <img
                    :src="question.additional_info.max_image"
                    class="rate-hint__image"
                    alt=""
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormRadio',
    props: {
        question: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {}
    },
    methods: {}
}
</script>

<style lang="scss" scoped>
.quiz-rate {
    display: flex;
    flex-wrap: nowrap;
    .d-inline-block {
        width: 100%;
        margin: 2px;
        label {
            width: 100%;
            padding: 3px;
            font-size: 13px;
            background: #ccc;
            border: none;
        }
    }
}
.rate-hint {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img_text_centered {
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(255, 255, 255, 0.5);
        font-weight: bold;
        width: 70px;
    }
    &__image-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 120px;
    }
    &__image {
        max-width: 100%;
        max-height: 100%;
    }
}
</style>
