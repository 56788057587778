import PmoCategories from '~/modules/pmo/components/PmoCategories'
import PmoSummary from '~/modules/pmo/components/PmoSummary'
import BarcodeScanner from '~/base/components/BarcodeScannerWrapper'
import ProductNotFound from '~/modules/pmo/components/ProductNotFound'
import PmoShow from '~/modules/pmo/components/PmoShow'
import SugarSubstitutes from '~/modules/pmo/components/SugarSubstitutes'
import PmoWelcomeGameDialog from '~/modules/pmo/components/PmoWelcomeGameDialog'
import Child from '~/base/components/Child'

export const PMO_STEP = 4
export const MY_ITEMS = 5

import store from '~/store'

const beforeEnter = (to, from) => {
    if (! store.getters['auth/user'].is_fdm) {
        return { name: 'learning-modules' }
    }
}

export default [
    {
        path: 'pmo',
        component: Child,
        beforeEnter,
        meta: {
            auth: true,
            layout: 'Pmo',
            step: PMO_STEP
        },
        children: [
            {
                path: '',
                name: 'pmo',
                component: PmoCategories,
                meta: {
                    auth: true,
                    layout: 'NavigationLayout',
                    step: PMO_STEP,
                },
            },
            {
                path: 'summary/:pantry?',
                name: 'pmo.summary',
                component: PmoSummary,
            },
            {
                path: 'barcode-scanner/:pmo_category?',
                name: 'pmo.barcode-scanner',
                component: BarcodeScanner,
                meta: {
                    auth: true,
                    layout: 'Empty'
                },
            },
            {
                path: 'product-not-found/:pmo_category?/:upc?',
                name: 'pmo.product-not-found',
                component: ProductNotFound,
            },
            {
                path: ':pmo_category',
                name: 'pmo.show',
                component: PmoShow,
            },
            {
                path: 'sugar_substitutes',
                name: 'pmo.sugar_substitutes',
                component: SugarSubstitutes,
                meta: {
                    auth: true,
                    layout: 'Profile',
                    step: PMO_STEP,
                },
            },
            {
                path: 'welcome-game/:game_slug',
                name: 'pmo.welcome-game',
                component: PmoWelcomeGameDialog,
            },
        ]
    }
]
