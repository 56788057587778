<template>
    <div
        v-if="childUsers.length > 0"
        class="child-list"
    >
        <div
            v-for="childUser in childUsers"
            :key="childUser._id"
            class="child-list__item"
        >
            <div class="child-list__user">
                <div class="child-list__content">
                    <div class="child-list__content-image">
                        <template v-if="childUser.child_user">
                            <img
                                :src="avatarUrl(childUser)"
                                class="child-list__content-image-i"
                                alt="avatar"
                                @error="imageLoadError"
                            >
                        </template>
                        <template v-if="childUser.child_user === null && ['accepted', 'notified'].includes(childUser.status)">
                            <img
                                src="/images/ava-default.svg"
                                class="child-list__content-image-i"
                                alt=""
                            >
                        </template>
                        <div
                            v-if="! ['accepted', 'notified'].includes(childUser.status)"
                            class="child-list__content-image-icon"
                        >
                            <inline-svg
                                class="child-list__content-image-icon-svg"
                                src="/images/svg-icons/envelop-icon.svg"
                                fill="#757575"
                            />
                        </div>
                    </div>

                    <div class="child-list__content-user">
                        <div class="child-list__content-user-name">
                            {{ childUser.email }}
                        </div>
                        <div class="child-list__content-user-status">
                            <template v-if="! ['accepted', 'notified'].includes(childUser.status)">
                                Invitation sent
                            </template>
                            <template v-if="childUser.child_user">
                                <span
                                    v-if="childUser.child_user.is_fdm"
                                    class="child-list__content-user-status-text"
                                >
                                    Food decision maker
                                </span>
                            </template>
                        </div>
                    </div>
                </div>
                <div
                    class="child-list__toggle-menu"
                    @click="onClickActiveChildUser(childUser)"
                >
                    <i class="child-list__toggle-menu-icon fa-solid fa-ellipsis-vertical" />
                </div>
                <div
                    v-if="childUser.active"
                    :key="childUser._id + childUser.active"
                    class="menu-container child-list__user-menu"
                >
                    <div class="child-list__user-menu-inner">
                        <div
                            class="close-container child-list__user-menu-close"
                            @click="onClickActiveChildUser(childUser)"
                        >
                            <i class="child-list__user-menu-close-i fa-solid fa-xmark" />
                        </div>
                        <template v-if="! ['accepted', 'notified'].includes(childUser.status)">
                            <div
                                v-if="childUser.invite_code && false"
                                class="child-list__user-menu-row"
                            >
                                <router-link
                                    :to="{
                                        name: 'registerUser',
                                        params: {
                                            invite_code: childUser.invite_code,
                                            email: childUser.email
                                        }
                                    }"
                                    class="child-list__user-menu-invite-link"
                                >
                                    {{ childUser.invite_link }}
                                </router-link>
                            </div>
                            <div class="child-list__user-menu-row">
                                <el-button
                                    class="el-button el-button--small"
                                    round
                                    @click.prevent.stop="reSendInvite(childUser._id)"
                                >
                                    Resend Invite
                                </el-button>
                            </div>
                        </template>
                        <template v-if="['accepted', 'notified'].includes(childUser.status)">
                            <div class="child-list__user-menu-row">
                                <user-visited-list
                                    :user-data="childUser"
                                />
                            </div>
                            <div
                                v-if="childUser.child_user == null || childUser.child_user.is_fdm !== true"
                                class="child-list__user-menu-row"
                            >
                                <el-button
                                    class="el-button el-button--small"
                                    round
                                    @click.prevent.stop="setFdm(childUser)"
                                >
                                    Assign Food Decision Maker role
                                </el-button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import inviteApi from '~/modules/profile/inviteApi'
import {mapGetters} from 'vuex'
import store from '~/store'
import UserVisitedList from '~/modules/profile/components/child_user/UserVisitedList'

export default {
    components: {
        UserVisitedList,
        InlineSvg,
    },
    data() {
        return {
            childUsers : [],
            showListModal: false,
            showPmoListModal: false,
            my_items: [],
            pmo_items: [],
            child_user_id: '',
            currentChildUser: {},
        }
    },
    computed: {
        ...mapGetters('auth', ['user']),
    },
    mounted() {
        this.getData()
    },
    methods: {
        avatarUrl(childUser) {
            return childUser.child_user.photo_url || '/images/ava-default.svg'
        },
        imageLoadError(e) {
            e.target.src = '/images/ava-default.svg'
        },
        getData() {
            inviteApi.childUsers().then(response => {
                this.childUsers = response.data.data
            })
        },
        reSendInvite(userInviteId) {

            let params = {
                user_invite_id: userInviteId
            }
            inviteApi.reSendInvite(params).then(response => {
                this.getData()
            })
        },
        setFdm(userInvite) {
            let params = {
                user_invite_id: userInvite._id
            }

            this.currentChildUser = userInvite
            inviteApi.setFdm(params).then(response => {
                this.getData()
            })
        },
        onClickActiveChildUser(childUser) {
            let currentStatus = childUser.active
            this.childUsers.forEach(user => {
                user.active = false

                if (user._id === childUser._id) {
                    user.active = !currentStatus
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.child-list {
    $block-name: &;
    margin-bottom: 40px;
    &__item {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__user {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-menu {
            position: absolute;
            z-index: 10;
            top: 0;
            right: 0;
            left: 40px;
            border-radius: 5px;
            background-color: $white;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 3px 4px rgba(0, 0, 0, 0.12), 0 1px 5px rgba(0, 0, 0, 0.2);
            &-inner {
                position: relative;
                padding: 15px 35px 15px 10px;
            }
            &-close {
                position: absolute;
                z-index: 11;
                top: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                cursor: pointer;
                &:hover {
                    #{$block-name}__user-menu-close-i {
                        color: $green;
                    }
                }
                &-i {
                    font-size: 19px;
                    color: $gray;
                    transition: 0.2s color ease-in-out;
                }
            }
            &-row {
                &:not(:last-child) {
                    margin-bottom: 15px;
                }
            }
            &-invite-link {
                word-break: break-all;
                color: $gray;
                transition: 0.2s color ease-in-out;
                &:hover {
                    color: $blue
                }
            }
        }
    }
    &__content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        overflow: hidden;
        &-image {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            margin-right: 10px;
            overflow: hidden;
            border-radius: 50%;
            background-color: $light-gray;
            &-i {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            &-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                &-svg {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
        &-user {
            overflow: hidden;
            text-overflow: ellipsis;
            &-name {
                overflow: hidden;
                text-overflow: ellipsis;
                color: $dark;
            }
            &-status {
                font-size: 12px;
                line-height: 1.0;
                color: $gray;
                &-text {
                    font-weight: 600;
                    color: $dark;
                }
            }
        }
    }
    &__toggle {
        &-menu {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            cursor: pointer;
            &:hover {
                #{$block-name} {
                    &__toggle-menu-icon {
                        color: $green;
                    }
                }
            }
            &-icon {
                font-size: 15px;
                color: $gray;
                transition: 0.2s color ease-in-out;
            }
        }
    }
}
</style>
