<template>
    <div class="header">
        <div class="container-holder">
            <div class="header__inner">
                <a
                    class="header__logo"
                    href="/"
                    tabindex="1"
                >
                    <img
                        class="header__logo-i"
                        src="/images/landing/logo-color.png"
                        alt="SeekingSimple"
                    >
                </a>
                <div class="header__nav">
                    <nav class="navbar">
                        <a
                            href="/contacts"
                            class="navbar__item"
                        >Contact Us</a>
                        <a
                            href="/partner-login"
                            class="navbar__item"
                        >Partner Resources</a>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
const retailerURL = window.config.retailerURL
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.header {
    height: 70px;
    padding: 0;
    background-color: $white;
    @media all and (min-width: $s) {
        height: 100px;
    }
    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px 0 19px;
        @media all and (min-width: $s) {
            padding: 23px 0 24px;
        }
    }
    &__logo {
        display: inline-block;
        width: 150px;
        height: 30px;
        @media all and (min-width: $xs) {
            width: 450px;
            height: 90px;
        }
        &-i {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__nav {
        padding-top: 5px;
    }
    .container-holder {
        width: 100%;
    }
}
</style>
