<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="default-text default-text--offset-bottom default-text--bold">
                    Dessert vs Sugar Match
                </div>
                <div class="default-text default-text--offset-bottom">
                    Drag the dessert on to the correct number of teaspoons of added sugar per serving.
                </div>
                <MatchTwoColumns
                    :products="products"
                    :spoons="[6,12,0,5]"
                    @done="handleDone"
                />
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__button-wrap">
                    <el-button
                        :disabled="!done"
                        type="primary"
                        round
                        class="continue-button"
                        @click="clickDone"
                    >
                        Done
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import MatchTwoColumns from './MatchTwoColumns'
import {useRoute, useRouter} from 'vue-router'
import {useDoneGame} from '~/modules/games/composables/done'

const router = useRouter()
const route = useRoute()
const done = ref(false)

const products = ref([
    {
        id: 1,
        value: 0,
        name: 'Strawberries',
        image: '/images/games/strawberries.png',
    },
    {
        id: 2,
        value: 12,
        name: 'Cake',
        image: '/images/games/cake.png',
    },
    {
        id: 3,
        value: 5,
        name: 'Ice cream',
        image: '/images/games/ice-cream.png',
    },
    {
        id: 4,
        value: 6,
        name: 'Cookies chip',
        image: '/images/games/cookies-chip.png',
    },
])

function handleDone() {
    done.value = true
    scrollTo()
}

function clickDone() {
    useDoneGame(route, router).done()
}

function scrollTo() {
    document.querySelector('.continue-button').scrollIntoView({ behavior: 'smooth' })
}
</script>
