<template>
    <el-container class="auth-page">
        <el-main>
            <div class="auth-page__top">
                <div class="auth-page__image-wrap">
                    <img
                        v-if="!loginPath"
                        class="auth-page__image"
                        src="/images/auth/seekingsimple-logo.svg"
                        alt=""
                    >
                    <img
                        v-else
                        class="auth-page__image companies_logo"
                        :src="loginPath"
                        alt=""
                    >
                </div>
            </div>
            <el-card>
                <PasswordResetForm
                    :token="token"
                    :email="email"
                    button="Reset"
                />
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
import PasswordResetForm from './PasswordResetForm'

export default {
    name: 'PasswordReset',
    components: { PasswordResetForm },
    props: {
        token: {
            type: String,
            default: '',
            loginPath: '',
        },
        email: {
            type: String,
            default: ''
        },
    },
    created() {
        const localStorageCompanyData =  JSON.parse(localStorage.getItem('useClientLevel'))
        if (localStorageCompanyData?.logo_path) {
            this.loginPath = localStorageCompanyData.logo_path
        }
    }
}
</script>
