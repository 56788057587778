<template>
    <div class="shop-product-block">
        <div class="shop-product-block__checkbox">
            <el-checkbox
                v-model="productChecked"
                class="el-checkbox--shop-product-block"
                @change="clickProduct"
            />
        </div>
        <div class="shop-product-block__product shop-product-block__product--inline">
            <ProductPreviewBlock
                :key="product.upc"
                :product="product"
                no-border
                no-margin-bottom
                :shop-product-block="true"
            />
            <template v-if="false&&product.liked && product.liked === 1">
                <div class="shop-product-block__product-mark">
                    <i class="shop-product-block__product-mark-icon fa-solid fa-thumbs-up" />
                </div>
            </template>
            <template v-if="false&&product.liked && product.liked === -1">
                <div class="shop-product-block__product-mark shop-product-block__product-mark--dislike">
                    <i class="shop-product-block__product-mark-icon fa-solid fa-thumbs-down" />
                </div>
            </template>
            <RateThumbs :product="product" v-if="false"/>
        </div>
    </div>
</template>

<script setup>
import ProductPreviewBlock from '~/modules/labelinsight/components/ProductPreviewBlock'
// import RateThumbs from './rate-thumbs'
import store from '~/store'
import { ref } from 'vue'

const productChecked = ref(false)

const props = defineProps({
    'product': {
        type: [ Object ],
        required: true,
        default: null
    }
})

async function clickProduct(val) {
    if (val) {
        await store.dispatch('my-items/addItemToCart', props.product.gtinFormats.gtin14)
        productChecked.value = false
    }
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.shop-product-block {
    $block-name: &;
    display: flex;
    align-items: center;
    &__checkbox {
        flex-shrink: 0;
    }
    &__product {
        position: relative;
        flex-grow: 1;
        &-mark {
            position: absolute;
            z-index: 2;
            top: -5px;
            left: 54px;
            &-icon {
                font-size: 19px;
                color: $indigo;
            }
            &--dislike {
                top: -1px;
            }
        }
        &--inline {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}
</style>
