<template>
    <div>
        <div class="function-buttons">
            <div class="function-buttons__item">
                <el-button
                    :class="{active: type === 'references'}"
                    type="info"
                    round
                    bg
                    @click="showContent('references')"
                >
                    <span class="el-button__icon">
                        <inline-svg
                            class="el-button__icon-svg"
                            src="/images/svg-icons/info-icon.svg"
                            fill="#757575"
                        />
                    </span>
                    References
                </el-button>
            </div>
            <div class="function-buttons__item">
                <el-button
                    :class="{active: type === 'transcript'}"
                    type="info"
                    round
                    bg
                    @click="showContent('transcript')"
                >
                    <span class="el-button__icon">
                        <inline-svg
                            class="el-button__icon-svg"
                            src="/images/svg-icons/translate-icon.svg"
                            fill="#757575"
                        />
                    </span>
                    <template v-if="isKidsCamp">
                        Story
                    </template>
                    <template v-else>
                        Transcript
                    </template>
                </el-button>
            </div>
            <div class="function-buttons__item">
                <el-button
                    :class="{active: type === 'tips'}"
                    type="info"
                    round
                    bg
                    @click="showContent('tips')"
                >
                    <span class="el-button__icon">
                        <inline-svg
                            class="el-button__icon-svg"
                            src="/images/svg-icons/lamp-icon.svg"
                            fill="#757575"
                        />
                    </span>
                    Tips
                </el-button>
            </div>
            <ShareVideo :video-link="props.videoLink" />
        </div>
        <div class="function-buttons__content-info">
            <div v-if="type === 'references'">
                <Reference
                    v-for="reference in subCategory.references"
                    :key="reference.id"
                    :reference="reference"
                />
            </div>
            <div
                v-if="type === 'transcript'"
                v-html="subCategory.transcript"
            />
            <div v-if="type === 'tips'">
                <span v-html="subCategory.tips" />
                <HelpfulOrNot
                    model-type="PmoCategory"
                    :model-id="subCategory._id.toString()"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed} from 'vue'
import InlineSvg from 'vue-inline-svg'
import HelpfulOrNot from '~/base/components/HelpfulOrNot'
import pmoApi from '~/modules/pmo/pmoApi'
import ShareVideo from './ShareVideo'
import Reference from '~/modules/learning-modules/components/Reference'

const name = 'InfoTabs'

const props = defineProps({
    subCategory: {
        type: Object,
        required: true
    },
    'videoLink': {
        type: [ String ],
        required: true,
        default: null
    }
})

const type = ref('')

const showContent = val => {
    type.value = (type.value !== val) ? val : ''
    if (val === 'transcript') {
        pmoApi.learnSubcategory(props.subCategory.slug)
    }
}

const isKidsCamp = computed(() => !!props.subCategory.game_slug)
</script>
