<template>
    <div
        v-if="loaded"
        class="main-content main-content--offset-bottom"
    >
        <div
            v-if="removed_sugar_num_of_items || reduceData.removed_sugar_num_of_items || removeData.removed_sugar_num_of_items"
            class="g-container"
        >
            <div
                class="default-text default-text--offset-bottom-middle default-text--middle-font default-text--blue-color"
                v-html="congratulationText"
            />

            <div v-if="removed_sugar_num_of_items" class="sugar-report-info">
                <div class="sugar-report-info__item">
                    <div class="sugar-report-info__item-icon-wrap">
                        <div class="sugar-report-info__item-icon">
                            <inline-svg
                                class="sugar-report-info__item-icon-svg"
                                src="/images/svg-icons/rhombus-icon.svg"
                            />
                        </div>
                    </div>
                    <div
                        class="sugar-report-info__item-text default-text"
                        v-html="replacedText"
                    />
                </div>
            </div>
            <div v-if="reduceData.removed_sugar_num_of_items" class="sugar-report-info__item">
                <div class="sugar-report-info__item-icon-wrap">
                    <div class="sugar-report-info__item-icon">
                        <inline-svg
                            class="sugar-report-info__item-icon-svg"
                            src="/images/svg-icons/rhombus-icon.svg"
                        />
                    </div>
                </div>
                <div
                    class="sugar-report-info__item-text default-text"
                    v-html="reducedText"
                />
            </div>
            <div
                v-if="removeData.removed_sugar_num_of_items"
                class="sugar-report-info__item"
            >
                <div class="sugar-report-info__item-icon-wrap">
                    <div class="sugar-report-info__item-icon">
                        <inline-svg
                            class="sugar-report-info__item-icon-svg"
                            src="/images/svg-icons/rhombus-icon.svg"
                        />
                    </div>
                </div>
                <div
                    class="sugar-report-info__item-text default-text"
                    v-html="removedText"
                />
            </div>
            <div
                v-if="total_num_of_my_items"
                class="sugar-report-info__item"
            >
                <div class="sugar-report-info__item-icon-wrap">
                    <div class="sugar-report-info__item-icon">
                        <inline-svg
                            class="sugar-report-info__item-icon-svg"
                            src="/images/svg-icons/rectangle-icon.svg"
                        />
                    </div>
                </div>
                <div class="sugar-report-info__item-text">
                    If you purchase and rate the
                    <router-link
                        :to="{ name: 'my-items.shop' }"
                        class="sugar-report-info__item-link"
                    >
                        {{ total_num_of_my_items }} other items
                    </router-link>
                    on your "My Items" list, you would reduce your sugar by an additional <b>{{
                        total_sugar_of_my_items
                    }} grams</b>.
                </div>
            </div>
            <div
                v-if="total_removed_sugar_grams<300"
                class="sugar-report-info__item"
            >
                <div class="sugar-report-info__item-icon-wrap">
                    <div
                        v-if="not_enough"
                        class="sugar-report-info__item-icon"
                    >
                        <inline-svg
                            class="sugar-report-info__item-icon-svg"
                            src="/images/svg-icons/dots-line-icon.svg"
                        />
                    </div>
                </div>
                <div class="sugar-report-info__item-text">
                    <template v-if="not_enough">
                        Keep trying and rating items to remove <b>{{ new_sugar_goal }} grams</b> or more of sugar during
                        the Pantry Makeover process.
                    </template>
                    <template v-else>
                        Keep trying and rating items to continue the great reduction in sugar you are achieving in your
                        Pantry Makeover process.
                    </template>
                </div>
            </div>

            <!--            <div class="achievement-block">-->
<!--                <div class="achievement-block__info-section">-->
<!--                    <div class="achievement-block__info">-->
<!--                        <div-->
<!--                            class="achievement-block__info-text default-text"-->
<!--                            v-html="achievementText"-->
<!--                        />-->
<!--                        <div class="achievement-block__info-star-section">-->
<!--                            <div class="achievement-block__info-star">-->
<!--                                {{ reward_points }}-->
<!--                            </div>-->
<!--                            <div class="achievement-block__info-star-icon">-->
<!--                                <i class="fa-solid fa-star" />-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="achievement-block__image-section">-->
<!--                    <div class="achievement-block__image-wrap">-->
<!--                        <img-->
<!--                            src="/images/robots/robot_hands_up.svg"-->
<!--                            class="achievement-block__image"-->
<!--                            alt="Saavy hands up"-->
<!--                        >-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div
            v-else
            class="g-container"
        >
            <div
                class="default-text default-text--offset-bottom-middle default-text--middle-font default-text--blue-color"
                v-html="emptyReportText"
            />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed} from 'vue'
import bus from '~/includes/Event'
import myItemApi from '~/modules/my-items/myItemApi.js'
import userApi from '~/modules/profile/userApi'
import store from '~/store'
import InlineSvg from 'vue-inline-svg'

const emit = defineEmits()

const user = store.getters['auth/user']

const loaded = ref(false)
const removed_sugar_grams = ref(0)
const total_removed_sugar_grams = ref(0)
const removed_sugar_num_of_items = ref(0)
const total_num_of_my_items = ref(0)
const total_sugar_of_my_items = ref(0)
const reward_points = ref(0)
const not_enough = ref(0)
const removed_sugar_grams_goal = ref(0)
const new_sugar_goal = ref(0)
const reduceData = ref([])
const removeData = ref([])
const congratulationText = ref('Congratulations!')
const achievementText = ref('<b>Congratulations!</b></br> You have also earned rewards.')
// const emptyReportText = ref('You have not made any improvements to your sugar consumption yet. <a href="/my-items">Start rating items</a> to see your progress.')
const emptyReportText = ref('To activate please shop your My Items list - once items are purchased and rated this report becomes activated.')

const replacedText = computed(() => {
    return `You’ve removed <b>${removed_sugar_grams.value}</b> grams of sugar with the <b>${removed_sugar_num_of_items.value}</b> item${removed_sugar_num_of_items.value > 1 ? 's': ''} you have <b>Replaced</b> in your pantry.`
})

const reducedText = computed(() => {
    return `You have removed <b>${reduceData.value.removed_sugar_grams}</b> grams of sugar by <b>Reducing</b> the consumption of <b>${reduceData.value.removed_sugar_num_of_items}</b> item${reduceData.value.removed_sugar_num_of_items > 1 ? 's': ''}.`
})

const removedText = computed(() => {
    return `You have removed <b>${removeData.value.removed_sugar_grams}</b> grams of sugar with the <b>${removeData.value.removed_sugar_num_of_items}</b>  item${removeData.value.removed_sugar_num_of_items > 1 ? 's': ''} you have <b>Removed</b> from your pantry.`
})

onMounted(() => {
    bus.$emit('set-title', 'Improvements & Rewards')

    getRemovedSugar()
})

function getRemovedSugar() {
    myItemApi.getSugarReductionReport().then(response => {
        if (response.data) {
            removed_sugar_grams.value = response.data.removed_sugar_grams
            total_removed_sugar_grams.value = response.data.total_removed_sugar_grams
            removed_sugar_num_of_items.value = response.data.removed_sugar_num_of_items
            total_num_of_my_items.value = response.data.total_num_of_my_items
            total_sugar_of_my_items.value = response.data.total_sugar_of_my_items
            reward_points.value = response.data.reward_points
            not_enough.value = response.data.not_enough
            new_sugar_goal.value = response.data.new_sugar_goal
            loaded.value = true
            removed_sugar_grams_goal.value = response.data.removed_sugar_grams_goal
            reduceData.value = response.data.reduced
            removeData.value = response.data.removed
        }
    })
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.sugar-report-info {
    margin-bottom: 20px;
    &__item {
        display: flex;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &-icon {
            width: 16px;
            height: 16px;
            &-wrap {
                flex-shrink: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 16px;
                height: 24px;
                margin-right: 10px;
            }
        }
        &-link {
            text-decoration: underline;
            color: $indigo;
            cursor: pointer;
            transition: 0.2s color ease-in-out;
            &:hover {
                color: $green;
            }
        }
    }
}

.achievement-block {
    display: flex;
    align-items: center;
    &__info {
        height: 100%;
        border: 1px solid $light-gray;
        border-radius: 15px;
        padding: 10px 20px 15px;
        background-color: lighten($light-gray, 10%);
        &-star {
            font-size: 34px;
            line-height: 1.4;
            color: $indigo;
            &-section {
                display: flex;
                align-items: baseline;
                padding-top: 10px;
            }
            &-icon {
                margin-left: 10px;
                font-size: 34px;
                color: $yellow;
            }
        }
    }
    &__image {
        max-width: 100%;
        max-height: 100%;
        &-section {
            flex-shrink: 0;
            margin-left: 30px;
        }
        &-wrap {
            width: 105px;
            height: 135px;
        }
    }
}
</style>
