import NotFound     from '~/base/components/NotFound'
import Base         from '~/base/components/Base'
import store from '~/store'

const requireContext = require.context('../modules', true, /routes\.js$/)

const modules = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)]
    )

let moduleRoutes = []

for(let i in modules) {
    moduleRoutes = moduleRoutes.concat(modules[i][1].default)
}

export const routes = [
    {
        path: '/',
        component: Base,
        children: [
            {
                path: 'home',
                name: 'Home',
                meta: {auth: true},
                beforeEnter() {
                    const user = store.getters['auth/user']
                    return { path: user.last_step?.url ?? redirect_path }
                }
            },
            ...moduleRoutes,
            {
                path: ':pathMatch(.*)*',
                component: NotFound,
                name: '404'
            }
        ],
    },
]

