import { createApp } from 'vue'
import App from './base/App'
import ElementPlus from 'element-plus'
import dayjs from './plugins/day'
import router from './router'
import store from './store'
import globalMixin from './includes/mixins/globalMixin'
import VueAxios from 'vue-axios'
import axios from './plugins/axios-interceptor'
import filters from './includes/filters'
import $bus from './includes/Event'
import vueVimeoPlayer from 'vue-vimeo-player'

const app = createApp(App)

app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.use(ElementPlus)
app.mixin(globalMixin)
app.use(vueVimeoPlayer)

app.config.globalProperties.$config = window.config
app.config.globalProperties.$dayjs = dayjs
app.config.globalProperties.$filters = filters
app.config.globalProperties.$bus = $bus

// Registration Service Worker
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js')
      .then(registration => {
        console.log('Service Worker is started!');
      })
      .catch(error => {
        console.error('Service Worker error:', error);
      });
  });
}

app.mount('#app')

export default app
