<template>
    <div class="main-content main-content--offset-bottom">
        <div class="g-container">
            <div class="privacy-policy default-content">
                <h1 class="text-center">
                    SeekingSimple Privacy Policy
                </h1>
                <p>This Privacy Policy describes how SeekingSimple, P.B.C. (“<strong>SeekingSimple</strong>”, “<strong>we</strong>” or “<strong>us</strong>”) collects, uses, and discloses information about you. This Privacy Policy applies to information we collect when you access or use our websites, web application, and other online products and services that link to this Privacy Policy (collectively, our “Services”) or when you otherwise interact with us, such as through our customer support channels.</p>

                <h2>Contents</h2>
                <ul>
                    <li>
                        <a
                            @click="scrollTo('collectionOfInformation')"
                        >
                            Collection of Information
                        </a>
                    </li>
                    <li>
                        <a
                            @click="scrollTo('useOfInformation')"
                        >
                            Use of Information
                        </a>
                    </li>
                    <li>
                        <a
                            @click="scrollTo('sharingOfInformation')"
                        >
                            Sharing of Information
                        </a>
                    </li>
                    <li>
                        <a
                            @click="scrollTo('yourChoices')"
                        >
                            Your Choices
                        </a>
                    </li>
                    <li>
                        <a
                            @click="scrollTo('linkToOtherWebsite')"
                        >
                            Links to Other Websites
                        </a>
                    </li>
                    <li>
                        <a
                            @click="scrollTo('changesPrivacyPolicy')"
                        >
                            Changes to This Privacy Policy
                        </a>
                    </li>
                    <li>
                        <a
                            @click="scrollTo('contactUs')"
                        >
                            Contact Us
                        </a>
                    </li>
                </ul>
                <p><strong>A Note About Children.</strong> Our Services are not intended for users under the age of 13 and we do not intentionally collect personal information about users who are under the age of 13.</p>

                <h2 :ref="el => { anchors['collectionOfInformation'] = el }">
                    1. COLLECTION OF INFORMATION
                </h2>
                <p><strong>Information You Provide to Us.</strong> We collect information you provide directly to us. For example, when you contact us via email, we will collect your name and email address, as well as any other personal information that may be included in the email. When you register for the Services, we collect your name, email, and phone number. When you set up your profile in the Services, we collect your zip code; information about your household, including the number of children and their age ranges; your dietary and well-being goals, such as whether you want to lose weight, improve sleep, or make healthier choices; your dietary preferences, such as what type of sugar you wish to avoid; and any additional information you wish to share with us as part of your experience with the Services.</p>
                <p><strong>Information We Collect Automatically.</strong> We automatically collect certain information about your interactions with us or our Services, including:</p>
                <ul>
                    <li><strong>Activity Information:</strong> We collect information about your activity on our Services, such as which sections of the pantry makeover you have completed and which educational articles you have read.</li>
                    <li><strong>Device and Usage Information:</strong> We collect information about how you access our Services, including data about the device and network you use, such as your hardware model, operating system version, mobile network, IP address, unique device identifiers, browser type, and app version. We also collect information about your activity on our Services, such as access times, pages viewed, links clicked, and the page you visited before navigating to our Services. </li>
                    <li><strong>Location Information:</strong> In accordance with your device permissions, we may collect information about the precise location of your device. You may stop the collection of precise location information at any time (see Section 4, <a @click="scrollTo('yourChoices')">Your Choices</a>, for details).</li>
                    <li><strong>Information Collected by Cookies and Similar Tracking Technologies:</strong> We (and those who perform work for us) use tracking technologies, such as cookies and web beacons, to collect information about your interactions with the Services. Cookies are small data files stored on your hard drive or in device memory that help us improve our Services and your experience, see which areas and features of our Services are popular, and count visits. Web beacons (also known as “pixel tags” or “clear GIFs”) are electronic images that we use on our Services and in our emails to help deliver cookies, count visits, and understand usage and campaign effectiveness. For more information about cookies and how to disable them, see Section 4, <a @click="scrollTo('yourChoices')">Your Choices</a>.</li>
                </ul>
                <p><strong>Information We Collect From Other Sources.</strong> We obtain information from other sources. For example, we may collect information from your employer. This information includes your name, email address, and employee ID. We may also receive your information from another user of the Services who invites you to join that user’s household within the Services. This information includes your email address or phone number.</p>

                <h2 :ref="el => { anchors['useOfInformation'] = el }">
                    2. USE OF INFORMATION
                </h2>
                <p>We use the information we collect to provide, maintain, improve, and develop new Services. We also use the information we collect to:</p>
                <ul>
                    <li>Debug and repair errors in our Services;</li>
                    <li>Personalize your experience with us;</li>
                    <li>Send you technical notices, security alerts, support messages, coaching messages, and other relationship messages;</li>
                    <li>Communicate with you about products, services, and events offered by SeekingSimple and others and provide news and information that we think will interest you (see Section 4, <a @click="scrollTo('yourChoices')">Your Choices</a>, for information about how to opt out of these communications at any time);</li>
                    <li>Monitor and analyze trends, usage, and activities in connection with our products and services;</li>
                    <li>Facilitate contests, sweepstakes, and promotions and process and deliver entries and rewards;</li>
                    <li>Detect, investigate, and help prevent security incidents and other malicious, deceptive, fraudulent, or illegal activity and help protect the rights and property of SeekingSimple and others;</li>
                    <li>Comply with our legal and financial obligations;</li>
                    <li>Create de-identified, anonymized, or aggregated information; and</li>
                    <li>Carry out any other purpose described to you at the time the information was collected.</li>
                </ul>

                <h2 :ref="el => { anchors['sharingOfInformation'] = el }">
                    3. SHARING OF INFORMATION
                </h2>
                <p><strong>Employer.</strong> We share personal information with your employer so that they can track participation in the Services and, if applicable, provide you with rewards in exchange for points you have collected through the Services.</p>
                <p><strong>Vendors.</strong> We share personal information with vendors that perform work for us, such as companies that assist us with web hosting, fraud prevention, customer service, and marketing and advertising.</p>
                <p><strong>Household Sharing.</strong> If you invite another user to join your household on the Services, we share your pantry information and grocery list with the other user.</p>
                <p><strong>Legal Purposes.</strong> We may disclose personal information if we believe that disclosure is in accordance with, or required by, law or legal process, including lawful requests by public authorities to meet national security or law enforcement requirements. We may share personal information if we believe that your actions are inconsistent with our user agreements or policies, that you have violated the law, or that disclosure is necessary to protect the rights, property, and safety of SeekingSimple, our users, or others.</p>
                <p><strong>Advisors.</strong> We share personal information with our lawyers and other professional advisors where necessary to obtain advice or otherwise protect and manage our business interests.</p>
                <p><strong>Corporate Reorganization.</strong> We may share personal information in connection with, or during negotiations concerning, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company.</p>
                <p><strong>With Your Consent.</strong> We share personal information with your consent or at your direction.</p>
                <p><strong>Aggregate or De-Identified Information.</strong> We share aggregated or de-identified demographic and usage information that cannot reasonably be used to identify you with our business partners, such as product data providers, food manufacturers, and retail stores.</p>

                <h2 :ref="el => { anchors['yourChoices'] = el }">
                    4. YOUR CHOICES
                </h2>
                <p><strong>Account Information.</strong> You may update or correct certain account information at any time by making the change in the settings page of the web application. If you wish to deactivate your account, you can do so at any time by making the change in the settings page of the web application, but note that we may retain certain information as required by law or for our legitimate business purposes.</p>
                <p><strong>Cookies.</strong> Most web browsers are set to accept cookies by default. If you prefer, you can usually adjust your browser settings to remove or reject browser cookies. Please note that removing or rejecting cookies could affect the availability and functionality of our Services.</p>
                <p><strong>Communication Preferences.</strong> You may opt out of receiving promotional emails from SeekingSimple by following the unsubscribe instructions provided in the email you receive. If you opt out, we may still send you non promotional emails, such as those about your account or our ongoing business relations. You may also update the frequency of the coaching-related emails you receive from us in the settings section of your account.</p>
                <p><strong>Mobile Push Notifications.</strong> With your permission, we may send push notifications to your mobile device. You can deactivate these messages at any time by changing the notification settings on your mobile device.</p>

                <h2 :ref="el => { anchors['linkToOtherWebsite'] = el }">
                    5. LINKS TO OTHER WEBSITES
                </h2>
                <p>We may include links to various third-party websites within the Services. If you click on a link to a third-party website, you will leave the website you are visiting and go to the website you selected. We have no control over these linked websites, each of which has separate privacy and data collection practices independent from us. As such, we have no responsibility or liability for these independent policies or actions and are not responsible for the privacy practices or the content of any such websites. We encourage you to review the privacy policies of any such third parties from whom you request services.</p>

                <h2 :ref="el => { anchors['changesPrivacyPolicy'] = el }">
                    6. CHANGES TO THIS PRIVACY POLICY
                </h2>
                <p>We may change this Privacy Policy from time to time. We encourage you to review it regularly to stay informed about our information practices and the choices available to you. If we make material changes, we will notify you by revising the date at the top of this Privacy Policy. We may also provide you with additional notice (such as by email or by adding a statement to the Services).</p>

                <h2 :ref="el => { anchors['contactUs'] = el }">
                    7. CONTACT US
                </h2>
                <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@seekingsimple.com">support@seekingsimple.com</a>.</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import bus from '~/includes/Event'

const anchors = ref([])

onMounted(() => { bus.$emit('set-title', 'Privacy Policy')})

function scrollTo(ref) {
    anchors.value[ref]?.scrollIntoView({ behavior: 'smooth' })
}
</script>
