<template>
    <div class="main-content" v-loading="loading">
        <div class="g-container">
            <div
                v-if="Object.keys(notifications).length > 0"
                v-for="(messages, day) in notifications"
            >
                <div class="notify-title">
                    <hi class="notify-title__text">{{ day }}</hi>
                </div>

                <div
                    v-for="message in messages"
                    :key="message._id"
                >
                    <el-card
                        class="notify-card"
                        :class="!message.is_read ? 'not_read' : ''"
                    >
                        <h4>{{ getTitle(message) }}</h4>
                        <div v-if="message.invite_email">
                            <a href="#" @click="handleOpen(message.invite_email)">Invite</a>
                            {{ message.message }}
                        </div>
                        <div v-else v-html="message.message"></div>
                    </el-card>
                </div>
            </div>
            <div
                v-else
                class="have-not-notifies"
            >
                <div class="bell-image w-100">
                    <img
                        src="/images/notification-icon.svg"
                        alt="notification"
                    >
                </div>
                <div class="w-100 texts">
                    <h2>No notifications yet</h2>
                    <h4>When you get notifications, they'll show up here</h4>
                </div>
                <div
                    class="w-100 mt-3"
                >
                    <el-button
                        round
                        type="primary"
                        @click="notificationsFetch"
                    >
                        Refresh
                    </el-button>
                </div>
            </div>
        </div>

        <invite-dialog
            :user-invite="inviteUser"
            :dialog-show="showModalInvite"
            @close="handleClose"
        />
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import bus from '~/includes/Event'
import store from '~/store'
import notifyApi from "~/modules/notifications/notifyApi";
import InviteDialog from "~/modules/quiz/components/household/InviteDialog";

onMounted(async () => {
    bus.$emit('set-title', 'Notifications')
    await notificationsFetch()
})

const user = store.getters['auth/user']
const notifications = ref({})
const loading = ref(false)
const showModalInvite = ref(false)
const inviteUser = ref({})

const getTitle = (message) => {
    switch (message.title) {
        case 'Etc':
            return 'System notification'
        case 'Nudge':
            return 'Coach Invites to meetings'
        case 'Feedback':
            return ' Coach\'s advice/feedback'
        default:
            return message.title
    }
}

const notificationsFetch = () => {
    loading.value = true
    notifyApi.getNotify()
        .then(response => {
            notifications.value = response.data
            loading.value = false
            if (Object.keys(notifications.value).length > 0) {
                toReadNotifyFetch()
            }
        })
        .catch(err => {
            console.log('Error getNotify', err.response)
            loading.value = false
        })
}

const handleClose = () => {
    inviteUser.value = {}
    showModalInvite.value = false
}

const handleOpen = (email) => {
    inviteUser.value.email = email
    showModalInvite.value = true
}

const toReadNotifyFetch = () => {
    const unreadMessages = []
    store.commit('base/count_unread_notifications', 0)
    Object.entries(notifications.value).forEach(([days, groupMessages]) => {
        for (let message of groupMessages) {
            if (!message.id_read) {
                unreadMessages.push(message._id)
            }
        }
    });

    notifyApi.readingMessages({ ids: unreadMessages })
        .catch(err => {
            console.log('Error readingMessages', err.response)
        })
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.invite-modal {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}

.notify-title {
    display: flex;
    justify-content: center;

    &__text {
        font-size: large;
        font-weight: bold;
        margin: 5px;
    }
}

.not_read {
    background: #5d2bff !important;
    color: white !important;
}

.notify-card {
    border-radius: 10px !important;
    margin: 5px 0;
    background: #e0e0e0;
    box-shadow: unset !important;
}

.bell-image {
    display: flex;
    justify-content: center;
}

.bell-image img {
    width: 20% !important;
}

.have-not-notifies {
    margin: 20px;
    height: 90vh;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.texts {
    color: grey;
}

</style>
