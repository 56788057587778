<template>
    <section class="quiz-section">
        <div
            v-for="(question, i) in questionsList"
            :key="question._id"
            :ref="'q_'+i"
            class="quiz-question quiz-question--bordered"
        >
            <div class="default-text quiz-question__text">
                <strong>{{ question.text }}</strong>
            </div>
            <FormRadioInline
                :question="question"
                @change="handleChange"
            />
        </div>
    </section>
</template>

<script>
import FormRadioInline from '../inputs/FormRadioInline'

export default {
    name: 'QuizHealthQuestions',
    components: {
        FormRadioInline,
    },
    props: {
        questions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            index: 1,
        }
    },
    computed: {
        questionsList: ({ questions, index }) => questions.slice(0, index),
        countAnswered() {
            let i = 0
            _.forEach(this.questions, o => {
                if (o.answer) i++
            })

            return i
        },
        isAllAnswered() {
            let flag = true
            _.forEach(this.questions, o => {
                if (!o.answer) flag = false
            })

            return flag
        }
    },
    mounted() {
        if (this.countAnswered > 1) {
            this.index = this.countAnswered
        }
    },
    methods: {
        handleChange() {
            if (this.countAnswered === this.questionsList.length) {
                this.index = Math.min(this.index + 1, this.questions.length)
                this.$emit('index', this.index)
                this.$emit('done', this.isAllAnswered)
            }

            this.$nextTick(() => {
                this.$refs['q_' + Number(this.index-1)][0].scrollIntoView({ behavior: 'smooth' })
            })
        },
    }
}
</script>
