<template>
    <div class="contact-us">
        <div class="container-holder">
            <h2 class="lp__title">
                Contact Us
            </h2>
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
            >
                <el-row :gutter="40">
                    <el-col
                        :xs="24"
                        :md="12"
                    >
                        <el-form-item
                            prop="first_name"
                            :error="formErrors.get('first_name')"
                            class="input__holder"
                        >
                            <label class="el-form-item__label--floating-label">
                                <el-input
                                    v-model="form.first_name"
                                    name="first_name"
                                    placeholder=" "
                                    class="el-input--floating-label"
                                >
                                    <template #prefix>
                                        First Name
                                    </template>
                                </el-input>
                            </label>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :xs="24"
                        :md="12"
                    >
                        <el-form-item
                            prop="last_name"
                            :error="formErrors.get('last_name')"
                            class="input__holder"
                        >
                            <label class="el-form-item__label--floating-label">
                                <el-input
                                    v-model="form.last_name"
                                    name="last_name"
                                    placeholder=" "
                                    class="el-input--floating-label"
                                >
                                    <template #prefix>
                                        Last Name
                                    </template>
                                </el-input>
                            </label>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="40">
                    <el-col
                        :xs="24"
                        :md="12"
                    >
                        <el-form-item
                            prop="email"
                            :error="formErrors.get('email')"
                        >
                            <label class="el-form-item__label--floating-label">
                                <el-input
                                    v-model="form.email"
                                    name="email"
                                    type="email"
                                    placeholder=" "
                                    class="el-input--floating-label"
                                >
                                    <template #prefix>
                                        Email Address
                                    </template>
                                </el-input>
                            </label>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :xs="24"
                        :md="12"
                    >
                        <el-form-item
                            prop="phone"
                            :error="formErrors.get('phone')"
                        >
                            <label class="el-form-item__label--floating-label">
                                <el-input
                                    v-model="form.phone"
                                    name="phone"
                                    placeholder=" "
                                    class="el-input--floating-label"
                                >
                                    <template #prefix>
                                        Phone Number
                                    </template>
                                </el-input>
                            </label>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="40">
                    <el-col
                        :xs="24"
                        :md="12"
                    >
                        <el-form-item
                            prop="title"
                            :error="formErrors.get('title')"
                        >
                            <label class="el-form-item__label--floating-label">
                                <el-input
                                    v-model="form.title"
                                    name="title"
                                    placeholder=" "
                                    class="el-input--floating-label"
                                >
                                    <template #prefix>
                                        Title
                                    </template>
                                </el-input>
                            </label>
                        </el-form-item>
                    </el-col>
                    <el-col
                        :xs="24"
                        :md="12"
                    >
                        <el-form-item
                            prop="company"
                            :error="formErrors.get('company')"
                        >SetDefaultCompany
                            <label class="el-form-item__label--floating-label">
                                <el-input
                                    v-model="form.company"
                                    name="company"
                                    placeholder=" "
                                    class="el-input--floating-label"
                                >
                                    <template #prefix>
                                        Company
                                    </template>
                                </el-input>
                            </label>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="40">
                    <el-col :span="24">
                        <el-form-item
                            prop="text"
                            type="textarea"
                            :error="formErrors.get('text')"
                            class="input__holder"
                        >
                            <el-input
                                v-model="form.text"
                                type="textarea"
                                :autosize="{ minRows: 11 }"
                                name="text"
                                placeholder="What questions do you have for us?"
                            />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row
                    justify="center"
                    class="button-holder"
                >
                    <el-form-item>
                        <el-button
                            :loading="loading"
                            class="lp__button"
                            @click.prevent="onSubmit"
                        >
                            Send
                        </el-button>
                    </el-form-item>
                </el-row>
            </el-form>
        </div>
    </div>
</template>

<script>
import {Errors} from '~/includes/Errors'
import landingApi from '~/modules/landing/landingApi'

export default {
    name: 'Contact',
    data: () => ({
        loading: false,
        form: {
            first_name: '',
            last_name: '',
            email: '',
            text: '',
            phone: '',
            company: '',
            title: '',
        },
        rules: {
            email: [
                {required: true, message: 'Email is required', trigger: 'blur'},
                {type: 'email', message: 'Invalid email', trigger: 'blur'}
            ],
            first_name: [
                {required: true, trigger: 'blur'},
                {trigger: 'blur', min: 3, max: 255}
            ],
            last_name: [
                {required: true, trigger: 'blur'},
                {trigger: 'blur', min: 3, max: 255}
            ],
            text: [
                {required: true, trigger: 'blur'},
                {trigger: 'blur', min: 3, max: 255}
            ],
        },
        formErrors: new Errors()
    }),
    methods: {
        onSubmit() {
            this.loading = true
            landingApi.sendContactForm(this.form)
                .then(() => {
                    this.clearForm()
                    this.$message.success('Message sent!')
                }).catch(errors => {
                    this.formErrors.record(errors.response.data.errors)
                }).finally(() => this.loading = false)
        },
        clearForm() {
            this.formErrors.clear()
            if (this.$refs.form)
                this.$refs.form.resetFields()
        },
    }
}
</script>
