<template>
    <div class="content-section">
        <div class="content-section__title-section">
            <div class="content-section__title">
                {{ title }}
            </div>
            <div
                v-if="starsNumber && authUser.client_level && authUser.client_level.has_starts_points_system === true"
                class="content-section__stars"
            >
                <StarCount
                    :star-count="starsNumber"
                    plus-symbol
                />
            </div>
        </div>
        <div class="content-section__text-section">
            <div
                class="content-section__text default-text"
                v-html="text"
            />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'
import StarCount from '~/base/components/star-count/StarCount'
import store from "~/store";
const authUser = computed(() => store.getters['auth/user'])
const name = 'ContentSection'
const props = defineProps({
    title: {
        type: String,
        default: ''
    },
    text: {
        type: String,
        default: ''
    },
    starsNumber: {
        type: Number,
        default: null
    }
})
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.content-section {
    $block-name: &;
    &__title {
        font-size: 16px;
        font-weight: 600;
        color: $dark;
        &-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
        }
    }
    &__stars {
        margin-left: 10px;
        &:empty {
            display: none;
        }
    }
}
</style>
