<template>
    <div class="main-layout landing-holder">
        <LandingHeader />
        <child />
        <LandingFooter />
    </div>
</template>

<script setup>
import Child from '~/base/components/Child'
import LandingHeader from '~/modules/landing/components/LandingHeader'
import LandingFooter from '~/modules/landing/components/LandingFooter'
</script>
