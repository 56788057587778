<template>
    <template v-if="status === 'active'">
        <span
            v-if="completedChapters > 0"
            class="learning-module__check-wrap learning-module__check-wrap--active module-block__check-wrap"
        >
            {{ completedChapters }}
        </span>
    </template>
    <span
        v-else-if="status === 'done'"
        class="learning-module__check-wrap module-block__check-wrap"
    >
        <i class="fa-solid fa-circle-check learning-module__check module-block__check" />
    </span>
    <span
        v-else
        class="learning-module__check-wrap learning-module__check-wrap--locked module-block__check-wrap"
    >
        <i class="fa-solid fa-lock learning-module__check module-block__check" />
    </span>
</template>

<script setup>
const name = 'Badge'
const props = defineProps({
    status: {
        type: String,
        required: true,
    },
    completedChapters: {
        type: Number,
        default: 1,
    },
})
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.learning-module {
    $local-light-gray: #e7e7e7;
    $block-name: &;
    &__check {
        &-wrap {
            &--locked {
                line-height: 1.8;
                background-color: $local-light-gray;
                #{$block-name}{
                    &__check {
                        font-size: 14px;
                        color: $white-second;
                    }
                }
            }
            &--active {
                font-size: 18px;
                font-weight: 500;
                line-height: 1.4;
                color: $white;
                background-color: $green;
            }
        }
    }
}
</style>
