<template>
    <el-form
        ref="forgotPasswordForm"
        :model="form"
        :rules="rules"
        class="auth-form"
        @submit.prevent="passwordReset"
    >
        <input
            type="hidden"
            name="token"
            :value="token"
        >
        <input
            type="hidden"
            name="email"
            :value="email"
        >
        <el-form-item>
            <div class="auth-form__title">
                Reset Password
            </div>
        </el-form-item>
        <el-form-item
            prop="password"
            :error="formErrors.get('password')"
        >
            <label class="el-form-item__label--floating-label">
                <el-input
                    v-model="form.password"
                    name="password"
                    type="password"
                    placeholder=" "
                    show-password
                    class="el-input--floating-label"
                >
                    <template #prefix>
                        Password
                    </template>
                </el-input>
            </label>
        </el-form-item>
        <el-form-item
            prop="password_confirmation"
            :error="formErrors.get('password_confirmation')"
        >
            <label class="el-form-item__label--floating-label">
                <el-input
                    v-model="form.password_confirmation"
                    name="password_confirmation"
                    type="password"
                    placeholder=" "
                    show-password
                    class="el-input--floating-label"
                >
                    <template #prefix>
                        Confirm password
                    </template>
                </el-input>
            </label>
        </el-form-item>
        <el-form-item>
            <el-button
                :loading="loading"
                round
                type="primary"
                class="w-100"
                @click.prevent="passwordReset"
            >
                Reset Password
            </el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import {Errors} from '~/includes/Errors'
import authApi from '~/modules/auth/authApi'
import validatePassword from '~/includes/mixins/validatePassword'

export default {
    name: 'PasswordResetForm',
    mixins: [ validatePassword ],
    props: {
        token: {
            type: String,
            default: ''
        },
        email: {
            type: String,
            default: ''
        },
        button: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            loading: false,
            form: {
                token: this.token,
                email: this.email,
                password: '',
                password_confirmation: ''
            },
            rules: {
                password: [
                    { required: true, message: 'Please enter your password', trigger: 'blur' },
                    { validator: this.checkPasswordComplicated, trigger: ['change', 'blur'] }
                ],
                password_confirmation : [
                    { required: true, message: 'Please confirm password', trigger: 'blur' },
                    { validator: this.checkPassIdentical, trigger: ['change', 'blur'] }
                ]
            },
            formErrors: new Errors(),
        }
    },
    methods: {
        passwordReset() {
            this.loading = true
            authApi.resetPassword(this.form).then(response => {
                // this.$message.success(response.data.message)

                this.$store.dispatch('auth/saveToken', {
                    token: response.data.token,
                    remember: false
                }).then(() => {
                    this.$router.push({name: 'Home'})
                })
            }).catch(error => {
                if (error.response.data.errors) {
                    this.formErrors.record(error.response.data.errors)
                    if (error.response.data.errors.email) {
                        let link = ''
                        if (error.response.data.errors.email[0].split(' ').includes('token')) {
                            link = ' <a href="/forgot/password">Forgot password</a>'
                        }
                        this.$message({
                            type: 'error',
                            dangerouslyUseHTMLString: true,
                            duration: 30000,
                            message: error.response.data.errors.email[0] + link,
                        })
                    }
                }
            }).finally(() => this.loading = false)
        },
    },
}
</script>
