<template>
    <user-intro
        :robot-messages-all="computedRobotMessages"
        @finishUserIntro="goNext"
        @callbackBtn="showVideo"
    />
    <header class="header">
        <div class="header__left-side">
            <div class="header__menu">
                <i class="header__menu-icon fa fa-bars" />
            </div>
        </div>
        <div class="header__center">
            <h1 class="header__text">
                Pantry Makeover
            </h1>
        </div>
        <div class="header__right-side">
            <div class="header__menu" />
        </div>
    </header>
    <div class="nav-menu">
        <div class="nav-menu__list">
            <div
                v-for="item in items"
                :key="item.title"
                class="nav-menu__item"
                :class="{
                    'active': item.active,
                }"
            >
                <span class="nav-menu__label">
                    <span class="nav-menu__label-icon">
                        <inline-svg
                            class="nav-menu__label-icon-i"
                            :src="item.icon"
                        />
                    </span>
                    <span class="nav-menu__label-text">
                        {{ item.title }}
                    </span>
                </span>
            </div>
        </div>
    </div>
    <div class="main-content main-content--offset-bottom main-content--robot-fullscreen-message">
        <div class="g-container">
            <div class="main-content__text">
                Improve the ingredients in your pantry using <strong>Pantry Makeover</strong>. Find excellent replacement items at local stores.
            </div>
            <div class="main-content__pantry">
                <div
                    id="pantry"
                    class="pantry"
                >
                    <div class="pantry__inner">
                        <div
                            v-for="(type, key) in types"
                            :key="key"
                            class="pantry__row"
                            :class="{
                                'pantry__row--first': key === 0,
                                'pantry__row--second': key === 1,
                                'pantry__row--third': key === 2
                            }"
                        >
                            <div
                                v-for="item in type"
                                :key="item.name"
                                class="pantry__cell-wrap"
                            >
                                <div
                                    :id="`pantry-${item.id}`"
                                    class="pantry__cell"
                                >
                                    <div class="pantry__cell-header">
                                        {{ item.name }}
                                    </div>
                                    <div
                                        :id="`pantry-elem-${item.id}`"
                                    />
                                    <el-button
                                        round
                                        type="primary"
                                        class="pantry__cell-btn"
                                        disabled
                                    >
                                        Go
                                    </el-button>
                                    <div class="pantry__cell-img pantry__cell-img--align-end">
                                        <img
                                            :src="`/images/pmo/pmo-cell-${item.slug}-icon.svg`"
                                            class="pantry__cell-img-i"
                                            alt=""
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-dialog
        v-model="showPmoVideo"
        :fullscreen="true"
        custom-class="el-dialog--full-screen-video"
    >
        <div class="embedded-video--vimeo">
            <client-only>
                <vue-vimeo-player
                    ref="player"
                    :video-id="videoId"
                    :autoplay="true"
                    @ended="endVideo"
                />
            </client-only>
        </div>
    </el-dialog>
</template>

<script setup>
import {computed, ref} from 'vue'
import InlineSvg from 'vue-inline-svg'
import UserIntro from './UserIntro'
import store from '~/store'
import {useRoute, useRouter} from 'vue-router'
import {vueVimeoPlayer} from 'vue-vimeo-player'

const router = useRouter()
const route = useRoute()
const videoId = '936853652'

const props = defineProps({
    nextRoute: {
        type: Object,
        required: true,
    },
    extendedPreview: {
        type: Boolean,
        default: false,
    },
    extendedPreviewLast: {
        type: Boolean,
        default: false,
    },
})

const user = store.getters['auth/user']

const items = [
    {
        title: 'Learn',
        icon: '/images/svg-icons/education-icon.svg',
        active: false
    },
    {
        title: 'My pantry',
        icon: '/images/svg-icons/pantry-icon.svg',
        active: true
    },
    {
        title: 'My items',
        icon: '/images/svg-icons/basket-icon.svg',
        active: false
    },
]
const types = [
    [
        {
            id: 1,
            icon: '/images/pmo/pmo-product-1-icon.svg',
            name: 'Liquid Candy',
            status: 'completed',
            slug: 'liquid-candy'
        },
        {
            id: 2,
            icon: '/images/pmo/pmo-product-2-icon.svg',
            name: 'Sneaky Snacks',
            status: 'completed',
            slug: 'sneaky-snacks'
        }
    ],
    [
        {
            id: 3,
            icon: '/images/pmo/pmo-product-3-icon.svg',
            name: 'Broke Fast',
            status: 'completed',
            slug: 'broke-fast'
        },
        {
            id: 4,
            icon: '/images/pmo/pmo-product-4-icon.svg',
            name: 'Conned E Ments',
            status: 'completed',
            slug: 'conned-e-ments'
        }
    ],
    [
        {
            id: 5,
            icon: '/images/pmo/pmo-product-5-icon.svg',
            name: 'Meal Time',
            status: 'completed',
            slug: 'mealtime'
        },
        {
            id: 6,
            icon: '/images/pmo/pmo-product-6-icon.svg',
            name: 'Desserted',
            status: 'completed',
            slug: 'desserted'
        }
    ]
]

const robotMessages = [
    {
        robotPosition: 'bottom-left-screen-corner',
        robotImage: '/images/robots/robot_waving.svg',
        text: 'Step 3 will help you prioritize and find great alternatives for the foods you repeatedly purchase. We walk you through the <b>categories of foods</b> most laden with disease causing ingredients.',
        hide: !user.is_fdm,
    },
]

const robotMessagesExtended = [
    // {
    //     robotPosition: 'bottom-left-screen-corner',
    //     robotImage: '/images/robots/robot_search.svg',
    //     text: 'Together we will work through <b>6 Categories</b> in your pantry to identify where the sugar and carbohydrates reside.',
    //     hide: !user.is_fdm,
    // },
    {
        robotPosition: 'bottom-left-screen-corner',
        robotImage: '/images/robots/robot_waving.svg',
        text: 'Here is a simple <b>2 minute</b> video explaining the entire Pantry Makeover process.',
        hide: !user.is_fdm,
        btnText: 'Watch',
    },
]

const robotMessagesExtendedLast = [
    {
        robotPosition: 'bottom-left-screen-corner',
        robotImage: '/images/robots/robot_rocket.svg',
        text: 'Let\'s Go!',
        hide: !user.is_fdm,
    }
]

const computedRobotMessages = computed(() => {
    if (props.extendedPreview) {
        return robotMessagesExtended
    }
    if (props.extendedPreviewLast) {
        return robotMessagesExtendedLast
    }
    return robotMessages
})

function goNext() {
    if (!props.extendedPreview) {
        router.push({name: props.nextRoute})
    }
}

const showPmoVideo = ref(false)

function showVideo() {
    showPmoVideo.value = true
}

function endVideo(){
    router.push({name: 'intro.after-lm.pmo-overview-last'})
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.main-content {
    background-color: transparent;
}
.pantry {
    $block-name: &;
    $local-blue: #5564A6;
    $local-blue-second: #6B95B4;
    $local-dark-blue: #1A3459;
    $local-light-blue: #E3EEFA;
    $local-light-blue-second: #86ADCD;
    $local-light-blue-third: #AAC8E2;
    &__inner {
        min-height: 325px;
        border: 1px solid $local-blue;
        padding: 3px 0 3px;
        background-color: $local-light-blue;
        @media all and (min-width: $xs) {
            min-height: 475px;
        }
    }
    &__row {
        display: flex;
        &--first {
            #{$block-name} {
                &__cell {
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        bottom: 89px;
                        left: 0;
                        background-color: $local-light-blue-second;
                        @media all and (min-width: $xs) {
                            bottom: 139px;
                        }
                    }
                    &:after {
                        top: 80px;
                        bottom: 0;
                        @media all and (min-width: $xs) {
                            top: 130px;
                        }
                    }
                    &-wrap {
                        &:nth-child(odd) {
                            &:before {
                                border-width: 9px 50px 18px 39px;
                            }
                        }
                        &:nth-child(even) {
                            &:before {
                                border-width: 9px 39px 18px 50px;
                            }
                        }
                    }
                }
            }
        }
        &--second {
            #{$block-name} {
                &__cell {
                    &:after {
                        top: 70px;
                        @media all and (min-width: $xs) {
                            top: 120px;
                        }
                    }
                    &-wrap {
                        &:nth-child(odd) {
                            &:before {
                                border-width: 0 50px 28px 39px;
                            }
                        }
                        &:nth-child(even) {
                            &:before {
                                border-width: 0 39px 28px 50px;
                            }
                        }
                    }
                }
            }
        }
        &--third {
            #{$block-name} {
                &__cell {
                    &:after {
                        top: 55px;
                        @media all and (min-width: $xs) {
                            top: 105px;
                        }
                    }
                    &-wrap {
                        &:nth-child(odd) {
                            &:before {
                                border-width: 0 50px 43px 39px;
                            }
                        }
                        &:nth-child(even) {
                            &:before {
                                border-width: 0 39px 43px 50px;

                            }
                        }
                    }
                }
            }
        }
    }
    &__cell {
        position: relative;
        height: 100px;
        border: 1px solid $local-blue;
        background-color: $local-dark-blue;
        @media all and (min-width: $xs) {
            height: 150px;
        }
        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: $local-light-blue-second;
        }

        &-wrap {
            position: relative;
            width: 50%;
            background-color: $local-light-blue;
            &:nth-child(odd),
            &:nth-child(even) {
                &:before {
                    content: "";
                    position: absolute;
                    z-index: 10;
                    top: 4px;
                    bottom: 4px;
                    display: block;
                }
            }
            &:nth-child(odd) {
                padding: 3px 3px 3px 6px;
                &:before {
                    left: 7px;
                    border-style: none none solid solid;
                    border-color: transparent transparent transparent $local-blue-second;
                }
            }
            &:nth-child(even) {
                padding: 3px 6px 3px 3px;
                &:before {
                    right: 7px;
                    border-style: solid solid solid none;
                    border-color: transparent $local-light-blue-third transparent transparent;
                }
            }
        }
        &-header {
            position: relative;
            z-index: 11;
            width: 80%;
            margin: -1px auto 10px;
            font-size: 12px;
            text-align: center;
            line-height: 1.3;
            color: $black;
            background-color: $local-light-blue;
            border: solid $local-blue;
            border-radius: 0 0 10px 10px;
            border-width: 0 1px 1px 1px;
        }
        &-btn {
            position: relative;
            z-index: 1100;
            display: block;
            margin: 0 auto auto;
        }
        &-img {
            position: absolute;
            z-index: 1099;
            max-height: 90%;
            max-width: 90%;
            top: 15px;
            bottom: 5px;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            @media all and (min-width: $xs) {
                top: 20px;
            }
            &-i {
                width: 100%;
                height: 100%;
            }
            &--align-end {
                align-items: flex-end;
                #{$block-name}__cell-img-i {
                    max-width: 65%;
                    max-height: 65%;
                    width: unset;
                    height: unset;
                }
            }
        }
    }
}
</style>
