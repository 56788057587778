<template>
    <robot-response>
        <h4>Can you tell us how your energy level is now?</h4>
        <div v-if="sub_quiz">
            <p v-html="sub_quiz.value" />
            <div
                v-for="question in sub_quiz.questions"
                :key="question._id"
                class="quiz-question"
            >
                <div
                    class="default-text quiz-question__text"
                    v-html="question.text"
                />
                <div
                    v-if="question.additional_info && question.additional_info.top_image"
                    class="quiz-question__image quiz-question__image--centered"
                >
                    <div class="quiz-question__image-wrap">
                        <img
                            :src="question.additional_info.top_image"
                            class="quiz-question__image-i"
                            alt=""
                        >
                    </div>
                </div>
                <form-rate
                    :question="question"
                    class="el-radio-group-bordered"
                    @set="handleChange"
                />
            </div>
            <div class="main-content__button-wrap">
                <el-button
                    :loading="loading"
                    type="primary"
                    round
                    class="continue-button"
                    @click="handleContinue"
                >
                    Continue
                </el-button>
            </div>
        </div>
    </robot-response>
</template>

<script setup>
import {ref, onMounted, getCurrentInstance} from 'vue'
import quizApi from '~/modules/quiz/quizApi'
import userApi from '~/modules/profile/userApi'
import FormRate from '../inputs/FormRate'
import {useRouter} from 'vue-router'
import store from '~/store'
import RobotResponse from './RobotResponse'

const { proxy } = getCurrentInstance()

const router = useRouter()
const user = store.getters['auth/user']

onMounted(() => fetchQuestion())

const aspiration = ref(null)
const sub_quiz = ref(null)
const old_choice = ref(null)
const new_choice = ref(null)
const loading = ref(false)

async function fetchQuestion() {
    const {data} = await quizApi.getSubQuiz(proxy.$config.aspirationQuizSlug)

    if (!!data) {
        aspiration.value = _.find(data.questions[0].variants, {addition: user.aspiration})

        const sub_variant_id = aspiration.value.questions[0].answer
        sub_quiz.value = _.find(aspiration.value.questions[0].variants, {_id: sub_variant_id})

        if (!sub_quiz.value) {
            sub_quiz.value = _.sample(aspiration.value.questions[0].variants)
            aspiration.value.questions[0].answer = sub_quiz.value._id
        }

        const sub_sub_variant_id = sub_quiz.value.questions[0].answer
        old_choice.value = _.find(sub_quiz.value.questions[0].variants, {_id: sub_sub_variant_id})

        if (!old_choice.value) {
            old_choice.value = _.sample(sub_quiz.value.questions[0].variants)
            sub_quiz.value.questions[0].answer = old_choice.value._id
        }

        new_choice.value = _.clone(old_choice.value)
    }
}

function handleChange(question_id, answer) {
    new_choice.value = _.find(sub_quiz.value.questions[0].variants, {_id: answer})
}

function handleContinue() {
    loading.value = true
    let status = 'no_change'
    if (new_choice.value.value > old_choice.value.value) {
        status = [0, 3].includes(sub_quiz.value.order) ? 'good' : 'bad'
    }
    else if (new_choice.value.value < old_choice.value.value) {
        status = [0, 3].includes(sub_quiz.value.order) ? 'bad' : 'good'
    }

    // let questionText = sub_quiz.value.questions[0].text
    // let answer = new_choice.value.value
    // userApi.saveUserLog({
    //     name: questionText,
    //     value: answer,
    //     reason: proxy.$config.aspirationQuizSlug
    // })

    quizApi.saveAnswers(aspiration.value)

    store.commit('check-in-response/setDialog', {aspiration: 'energy', status})
}
</script>
