<template>
    <section
        id="dialog"
        class="main-content welcome-page"
    >
        <div class="g-container">
            <div class="welcome-page__text">
                <p class="default-text">
                    Welcome to an easy journey to make you and your household healthier.
                </p>
                <p class="default-text">
                    We will help you achieve this by simply lowering a couple of key ingredients in your home food supply.
                </p>
            </div>
            <div class="welcome-page__btn">
                <el-button
                    type="primary"
                    round
                    class="w-100"
                    :disabled="disabledContinueButton"
                    @click="goToQuizzes"
                >
                    Continue
                </el-button>
            </div>
            <div class="welcome-page__robot">
                <img
                    class="welcome-page__robot-image"
                    src="/images/robots/robot_smile.svg"
                    alt=""
                >
            </div>
            <play-sound src="/audio/opening.mp3" @onSoundEnd="handleSoundEnd"/>
        </div>
    </section>

</template>

<script setup>
import {useRouter} from 'vue-router'
import store from '~/store'
import {WIZARD_STEP} from '~/modules/quiz/routes'
import baseApi from '~/base/baseApi'
import bus from '~/includes/Event'
import {ref, onMounted} from 'vue'
import PlaySound from '~/base/components/PlaySound'


const router = useRouter()

const disabledContinueButton = ref(true)

onMounted(() => bus.$emit('set-title', 'SeekingSimple'))

const goToQuizzes = async () => {
    await baseApi.newUserStep(WIZARD_STEP)
    await store.dispatch('auth/updateUserField', {
        last_step: {
            level: WIZARD_STEP,
            name: 'Wizard',
            path: '/quiz',
        }
    })
    await router.push({name: 'intro.full.profile-preview'})
}

const handleSoundEnd = () => {
    disabledContinueButton.value = false
}
</script>

<style lang="scss" scoped>
    .welcome-page {
        &__text {
            margin-bottom: 20px;
            .default-text {
                &:not(:last-child) {
                    margin-bottom: 10px;
                }
            }
        }
        &__btn {
            max-width: 350px;
            margin: 0 auto 40px;
            .el-button {
                height: 40px;
            }
        }
        &__robot {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 185px;
            height: 185px;
            margin: 0 auto;
            &-image {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
</style>
