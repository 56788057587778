<template>
    <user-intro
        v-if="showRobot && !btnShow"
        :robot-messages-all="robotMessagesComputed"
        @finishUserIntro="finishIntro"
    />
    <div class="user-steps-overview">
        <div
            class="user-steps-overview__top"
            :class="{'user-steps-overview__top--with-btn': btnShow}"
        >
            <header class="header">
                <div class="header__left-side">
                    <div class="header__menu" />
                </div>
                <div class="header__center">
                    <h1 class="header__text">
                        SeekingSimple
                    </h1>
                </div>
                <div class="header__right-side">
                    <div class="header__menu" />
                </div>
            </header>
            <div class="user-steps-overview__content">
                <div class="user-steps-overview__item">
                    <user-section
                        id="profile"
                        :info="profileSection"
                        :status="state.profile"
                        :class="{'user-section--clickable': route.name === 'intro.full.done-full'}"
                        @click="goToProfile"
                    />
                </div>
                <div class="user-steps-overview__item">
                    <user-section
                        id="learning"
                        :info="learningSection"
                        :status="state.learning"
                        :class="{'user-section--clickable': state.learning === 'active' || route.name === 'intro.full.done-full'}"
                        @click="goToLM"
                    />
                </div>
                <div
                    v-if="user.is_fdm"
                    class="user-steps-overview__item"
                >
                    <user-section
                        id="pmo"
                        :info="pmoSection"
                        :status="state.pmo"
                        :class="{'user-section--clickable': state.pmo === 'active' || route.name === 'intro.full.done-full'}"
                        @click="goToPMO"
                    />
                </div>
                <div class="user-steps-overview__item">
                    <user-section
                        id="myList"
                        :info="myListSection"
                        :status="state.myList"
                        :class="{'user-section--clickable': state.myList === 'active' || route.name === 'intro.full.done-full'}"
                        @click="goToMyList"
                    />
                </div>
            </div>
        </div>
        <div
            v-if="btnShow"
            class="user-steps-overview__bottom"
        >
            <el-button
                type="primary"
                round
                class="continue-button"
                @click="handleBtn"
                :disabled="disabledContinueButton"
            >
                Continue
            </el-button>
        </div>
    </div>
    <PwaShortcutInstructionModal v-if="btnShow" />
    <PlaySound :src="getAudioSrc()" @onSoundEnd="handleSoundEnd"/>
</template>

<script setup>
import {ref, onMounted, computed, onBeforeUnmount, onBeforeMount} from 'vue'
import UserSection from './UserSection'
import UserIntro from './UserIntro'
import store from '~/store'
import {useRoute, useRouter} from 'vue-router'
import {WIZARD_STEP} from '~/modules/quiz/routes'
import PlaySound from '~/base/components/PlaySound'
import PwaShortcutInstructionModal from "~/base/components/pwaShortcutInstructionModal.vue";

const route = useRoute()
const router = useRouter()

const disabledContinueButton = ref(true)

const handleSoundEnd = () => {
    disabledContinueButton.value = false
}

function getAudioSrc() {
    switch (route.name) {
        // case 'intro.full.done-full':
        //     return '/audio/entering_persona.mp3'
        // case 'intro.after-profile.lm-preview-done':
        //     return '/audio/entering_education.mp3'
        case 'intro.after-lm.pmo-preview-done':
            return '/audio/entering_pmo.mp3'
        default:
            return ''
    }
}

const user = store.getters['auth/user']

const props = defineProps({
    state: {
        type: Object,
        default: () => {},
    },
    nextRoute: {
        type: Object,
        required: true,
    },
    btnShow: {
        type: Boolean,
        default: false,
    },
})

const profileSection = ref({
    title: 'Profile',
    items: user.is_primary ? 3 : 2
})
const learningSection = ref({
    title: 'Learning',
    items: 4,
    modify: 'vertical'
})
const pmoSection = ref({
    title: 'My Pantry',
    items: 6,
    modify: 'pmo'
})
const myListSection = ref({
    title: 'My Items',
    items: 5,
})
const emit = defineEmits()

onBeforeMount(() => {
    // if (route.name === 'intro.after-lm.pmo-preview') {
    //     _.chain(robotMessages)
    //         .find({state: 'pmo'})
    //         .merge(
    //             {text: 'We will work through 6 Categories in order of their highest sugar and carbohydrate density. We will guide you to scan items in your household that are most frequently consumed to address your highest sugar sources first.'}
    //         ).value()
    // }
})

onMounted( () => {
    addScrollLock()
})

onBeforeUnmount(() => {
    removeScrollLock()
})

const showRobot = ref(true)

function finishIntro() {
    showRobot.value = false
    if (!props.btnShow) {
        if (props.nextRoute) router.push({name: props.nextRoute})
        else router.push({name: 'my-items.shop'})
    }
}

function handleBtn() {
    if (props.nextRoute) router.push({name: props.nextRoute})
    else if (user.last_step.level <= WIZARD_STEP) router.push({name: 'quiz.index'})
    else router.push({name: 'my-items.shop'})
}

function addScrollLock() {
    document.body.classList.add('user-steps-overview--scroll-lock')
}

function removeScrollLock() {
    document.body.classList.remove('user-steps-overview--scroll-lock')
}

function goToProfile() {
    router.push({name: 'intro.full.profile-overview-single'})
}

function goToLM() {
    if (route.name === 'intro.full.done-full') {
        router.push({name: 'intro.full.lm-overview-single'})
    } else {
        router.push({name: 'intro.after-profile.lm-overview'})
    }
}

function goToPMO() {
    if (route.name === 'intro.full.done-full') {
        router.push({name: 'intro.full.pmo-overview-single'})
    } else {
        router.push({name: 'intro.after-lm.pmo-overview'})
    }
}

function goToMyList() {
    if (route.name === 'intro.full.done-full') {
        router.push({name: 'intro.full.my-list-overview-single'})
    } else {
        router.push({name: 'intro.after-pmo.my-list-overview'})
    }
}

const robotMessages = [
    {
        state: 'profile',
        robotPosition: 'bottom-left-screen-corner',
        robotImage: '/images/robots/robot_smile.svg',
        text: user.is_fdm
            ? 'Your journey has 4 simple steps:<br>1. Profile Building<br>2. Learning<br>3. Pantry Makeover Process<br>4. My Items - shop, rate, update'
            : 'Your journey has 3 simple steps:<br>1. Profile Building<br>2. Learning<br>3. My Items - shop, rate, update',
    },
    {
        state: 'profile',
        robotPosition: '.user-steps-overview__item #profile .user-section__screen',
        robotImage: '/images/robots/robot_smile.svg',
        text: 'Your first step will be Profile Building which takes approximately 10 minutes to complete',
    },
    //Learning
    {
        state: 'learning',
        robotPosition: '.user-steps-overview #profile',
        robotImage: '/images/robots/robot_smile.svg',
        text: 'The next step is the Learning module which takes approximately 20 minutes to complete.',
    },
    //PMO module
    {
        state: 'pmo',
        robotPosition: '.user-steps-overview__item #profile .user-section__screen-title',
        robotImage: '/images/robots/robot_waving.svg',
        text: 'The next step is your Pantry Makeover which can take anywhere from 1 to 6 hours depending on the number of products with high sugar density that your household frequently consumes.',
        hide: !user.is_fdm,
    },
    //My-items SHOP module
    {
        state: 'myList',
        robotPosition: '#profile .user-section__screen',
        robotImage: '/images/robots/robot_smile.svg',
        text: 'The final step is My Items',
    },

]

const robotMessagesComputed = computed(() => {
    let state = Object.keys(props.state).find(key => props.state[key] === 'active')

    if (props.state.myList === 'done') {
        return [{
            robotPosition: 'bottom-left-screen-corner',
            robotImage: '/images/robots/robot_hands_up.svg',
            text: 'Now Lets Begin!',
        }]
    }

    return robotMessages.filter(o => !o.hide && o.state === state)
})

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.user-steps-overview {
    $block-name: &;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 0 10px 200px rgba(255,255,255,1);
    &__top {
        height: 100%;
        background-color: $white;
        &--with-btn {
            height: calc(100% - 76px);
        }
    }
    &__content {
        height: calc(100% - 42px);
    }
    &__bottom {
        width: 100%;
        padding: 20px;
        text-align: center;
        background-color: $white;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        max-width: 800px;
        margin: 0 auto;
        padding: 0 15px 20px;
    }
    &__item {
        width: 50%;
        height: 50%;
        padding: 20px 10px 0;
    }
    &--bg {
        background-color: $white;
        z-index: 20;
    }
}
</style>
