<template>
    <div class="main-content main-content--offset-bottom-40">
        <div
            v-if="user.client_level && user.client_level.level >= 0 && user.directed_spend && user.parent_user.iq_pay && user.parent_user.iq_pay.activation_code && user.parent_user.iq_pay.activation_code.url"
            class="directed_spend"
        >
            <div class="directed-spend__line">
                <a :href="user.parent_user.iq_pay.activation_code.url" target="_blank">Simply Spend</a>
            </div>
        </div>
        <div class="g-container">
            <div class="shop__functional-section">
                <div
                    class="shop__functional-section-col"
                >
                    <StoreListItem :store="primary_store"/>
                    <switch-sections v-if="false"/>
                </div>
                <div class="shop__functional-section-col">
                    <share-shopping-list :small="true" :shop-items="placed"/>
                </div>
            </div>
            <div
                v-if="false"
                class="shop__shop-list"
            >
                <shop-list/>
            </div>
            <div class="shop__category-list">
                <div
                    v-for="category in categories"
                    :key="category._id"
                    class="shop__category"
                >
                    <shop-category :category="category"/>
                </div>
            </div>
            <div class="shop__cart">
                <shop-category
                    :category="replaced"
                    cart-category
                />
            </div>
        </div>
        <sugar-reduction-report-button/>
    </div>
</template>

<script setup>
import {ref, computed, watch, onMounted} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import store from '~/store'
import SwitchSections from './switch-sections'
import InlineSvg from 'vue-inline-svg'
import ShareShoppingList from './ShareShoppingList'
import ShopCategory from './ShopCategory'
import ShopList from './ShopList'
import StoreListItem from '~/modules/quiz/components/household/StoreListItem'
import bus from '~/includes/Event'
import SugarReductionReportButton from '~/base/components/SugarReductionReportButton'
import iqPayApi from "~/modules/my-items/iqPayApi";
// import DirectedSpendCard from './directed-spend-card.vue'

const router = useRouter()
const route = useRoute()
const activeShop = ref(null)
const user = computed(() => store.getters['auth/user'])
const replaced = computed(() => store.getters['my-items/replaced'])
const placed = computed(() => store.getters['my-items/placed'])
const categories = computed(() => store.getters['my-items/categories'])
const shopStore = computed(() => store.getters['my-items/shop'])

const currentAnimationSlide = ref(null)
const dialogRateVisible = ref(false)

onMounted(() => bus.$emit('set-title', 'My Items'))

onMounted(() => {
    if (user.value.client_level && user.value.client_level.level >= 0 && user.value.directed_spend) {
        iqPayApi.firstIqPayOrder()
    }
})

getActiveShop()

watch(user.value.stores, (stores) => {
    getActiveShop()
})

const primary_store = computed(() => user.value.stores.find(store => store.is_primary))

function getShopItems() {
    store.dispatch('my-items/fetchShop', activeShop.value.uid)
}

function getActiveShop() {
    user.value.stores && user.value.stores.forEach((store) => {
        if (store.is_primary) {
            activeShop.value = store
        }
    })

    getShopItems()
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.directed_spend {
    margin-top: -25px;
    margin-bottom: 25px;
    height: 30px;
    background: $green;
    position: relative;
    min-height: 30px;
    display: flex;
    justify-content: center;

    a {
        font-weight: 700;
        color: white;
        text-decoration: none;
    }

    a:hover {
        font-weight: 200;
    }
}

.shop {
    &__functional-section {
        display: flex;
        align-items: flex-end ;
        margin: 0 -5px 20px;
        &-col {
            padding: 0 5px;
        }
        &--align-right {
            justify-content: flex-end;
        }
    }
    &__shop-list {
        margin: 0 -15px 10px;
        border-bottom: 1px solid $light-gray;
        padding: 0 15px 15px;
        @media all and (min-width: $m) {
            margin: 0 -30px 10px;
            padding: 0 30px 15px;
        }
    }
    &__category {
        margin-bottom: 10px;
    }
}
</style>
