<template>
    <section class="main">
        <PmoHeader />
        <child />
    </section>
</template>

<script setup>
import Child from '~/base/components/Child'
import PmoHeader from '~/modules/pmo/components/PmoHeader'
const name = 'Pmo'
</script>
