<template>
    <inline-svg
        class="add-button"
        src="/images/pmo/add-to-summary.svg"
        @click="clickButton"
    />
</template>

<script setup>
import bus from '~/includes/Event'
import InlineSvg from 'vue-inline-svg'

function clickButton() {
    bus.$emit('clickPmoAddButton')
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.add-button {
    width: 50px;
    height: 50px;
    cursor: pointer;
}

</style>
