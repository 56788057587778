import PageFaq from './components/Faq'
import PageHelp from './components/help-support'
import PrivacyPolicy from './components/PrivacyPolicy'
import TermsOfUse from './components/TermsOfUse'
import Progression from './components/Progression'

const meta = {
    auth: undefined,
}

export default [
    {
        path: 'tips',
        name: 'static-pages.faq',
        component: PageFaq,
        meta,
    },
    {
        path: 'terms-of-use',
        name: 'static-pages.terms-of-use',
        component: TermsOfUse,
        meta,
    },
    {
        path: 'help-support',
        name: 'static-pages.help-support',
        component: PageHelp,
        meta,
    },
    {
        path: 'privacy-policy',
        name: 'static-pages.privacy-policy',
        component: PrivacyPolicy,
        meta,
    },
    {
        path: 'points-progression',
        name: 'static-pages.points-progression',
        component: Progression,
        meta: {
            auth: true,
            step: 0,
            layout: 'Profile',
        }
    },

]
