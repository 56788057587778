// auth.js
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_FIELD = 'UPDATE_USER_FIELD'
//storage.js
export const SET_STORAGE = 'SET_STORAGE'
export const GET_STORAGE = 'GET_STORAGE'

export const TOGGLE_COLLAPSE = 'BASE.TOGGLE_COLLAPSED'

//my-items.js
export const FETCH_SHOP_FAILURE = 'FETCH_SHOP_FAILURE'
export const UPDATE_SHOP = 'UPDATE_SHOP'
export const SET_PMO_SLUG = 'SET_PMO_SLUG'
