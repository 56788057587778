import Shop from './components/shop'
import Rate from './components/rate'
import BarcodeScanner from '~/base/components/BarcodeScannerWrapper'
import {MY_ITEM_STEP} from '~/router'
import store from '~/store'
import Child from '~/base/components/Child'
import userApi from '~/modules/profile/userApi'

const meta = {
    auth: true,
    layout: 'NavigationLayout'
}

const beforeEnter = (to, from) => {
    const user = store.getters['auth/user']
    if (!user.canSeeMyList) {
        return { name: 'learning-modules' }
    }
}

export default [
    {
        path: 'my-items',
        component: Child,
        beforeEnter,
        children: [
            {
                path: '',
                name: 'my-items.shop',
                component: Shop,
                meta,
                beforeEnter: (to, from) => {
                    if (!userApi.hasFirstExperience('showed_my_items_intro')) {
                        userApi.setFirstExperience('showed_my_items_intro')
                        return {name: 'intro.after-pmo.my-list-preview'}
                    }
                },
            },
            {
                path: 'rate',
                name: 'my-items.rate',
                component: Rate,
                meta,
            },
            {
                path: 'barcode-scanner',
                name: 'my-items.barcode-scanner',
                component: BarcodeScanner,
                meta: {
                    auth: true,
                    layout: 'Empty'
                },
            },
        ]
    }
]
