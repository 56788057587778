<template>
    <div
        v-loading="loading"
        class="main-content product-page"
    >
        <div class="g-container">
            <div
                v-if="product"
                class="product-page__inner"
            >
                <div class="product-page__product">
                    <ProductShowInfo
                        :product="product"
                    />
                </div>
                <div class="product-page__product-tabs">
                    <el-tabs
                        v-model="activeTab"
                        class="el-tabs--wide"
                    >
                        <el-tab-pane
                            label="Ingredients"
                            name="Ingredients"
                        >
                            <template v-if="product.ingredients && product.ingredients.declaration">
                                <div class="el-tab-pane__content">
                                    {{ product.ingredients.declaration }}
                                </div>
                            </template>
                        </el-tab-pane>
                        <el-tab-pane
                            label="Allergens"
                            name="Allergens"
                        >
                            <template v-if="product.warning">
                                <div class="el-tab-pane__content">
                                    {{ product.warning }}
                                </div>
                            </template>
                        </el-tab-pane>
                        <el-tab-pane
                            label="Nutrition"
                            name="Nutrition"
                        >
                            <div
                                v-if="productNutritionFacts"
                                class="el-tab-pane__content"
                            >
                                <nutrition-facts :nutrition-facts="productNutritionFacts"/>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane
                            label="Certs"
                            name="Certs"
                        >
                            <div class="el-tab-pane__content">
                                Certs content
                            </div>
                        </el-tab-pane>
                        <el-tab-pane
                            label="Manufacturer"
                            name="Manufacturer"
                        >
                            <div class="el-tab-pane__content">
                                <div
                                    v-for="pData in product.partnerData"
                                    :key="pData.partner"
                                >
                                    <p>{{ pData.partner }}</p>
                                    <div
                                        v-for="item in pData.data"
                                        :key="item.value"
                                    >
                                        {{ item.name }}: {{ item.value }}
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import labelinsightApi from '~/modules/labelinsight/labelinsightApi'
import NutritionFacts from './NutritionFacts'
import ProductShowInfo from "./ProductShowInfo.vue";

export default {
    components: {
        NutritionFacts,
        ProductShowInfo,
    },
    data() {
        return {
            product: null,
            selected: [],
            loading: true,
            activeTab: 'Ingredients',
            productNutritionFacts: null,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        async getData() {
            let params = this.$route.query
            try {
                this.loading = true
                let response = await labelinsightApi.getProduct(this.$route.params.upc, params)
                this.product = response.data.product
                this.loading = false
                this.productNutritionFacts = response.data.product.factPanel.nutrition || response.data.product.factPanel.supplement

                this.getStores()
            } catch (error) {
                this.loading = false
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.product-page {
    flex: 1;
    padding: 0 0 20px;

    &__product {
        margin-bottom: 15px;

        &-info {
            &-warning {
                font-weight: 600;
                color: $maroon;
            }
        }
    }
}
</style>
