<template>
    <section id="dialog4">
        <div class="robot-dialog">
            <div class="robot-dialog__robot">
                <RobotDialog
                    :messages="messages"
                    robot-image="/images/robots/robot_have_answer.svg"
                    @finish="isActiveBtn = true"
                />
            </div>
            <div class="robot-dialog__button">
                <el-button
                    :disabled="!isActiveBtn"
                    type="primary"
                    round
                    class="w-100 continue-button"
                    @click="handleContinue"
                >
                    Continue
                </el-button>
            </div>
        </div>
    </section>
</template>

<script>
import RobotDialog from '../RobotDialog'
export default {
    name: 'Dialog5',
    components: {RobotDialog},
    data() {
        return {
            isActiveBtn: false,
            messages: [
                'I think I\'ve got it now! Is this you?',
            ]
        }
    },
    methods: {
        handleContinue() {
            this.$store.commit('quiz/hideDialog')
            this.$store.commit('quiz/riseQuestions')
        }
    }
}
</script>
