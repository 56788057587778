<template>
    <div class="report-bad-data">
        <el-button
            type="info"
            round
            bg
            class="report-bad-data__button"
            @click.stop="onShowDialog"
        >
            <span class="report-bad-data__button-icon el-button__icon">
                <inline-svg
                    class="el-button__icon-svg"
                    src="/images/svg-icons/info-second-icon.svg"
                    fill="#757575"
                />
            </span>
            <slot>Report Bad Product Data</slot>
        </el-button>
        <!--        <div v-if="showDialogModal">-->
        <!--        </div>-->
        <el-dialog
            v-model="showDialogModal"
            title="Add Comments?"
            class="report-bad-data__modal-window"
        >
            <span>
                <el-input
                    v-model="message"
                    type="textarea"
                    :autosize="{ minRows: 5 }"
                    placeholder="Add notes"
                    aria-label="notes"
                />
            </span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button
                        class="el-button--transparent"
                        @click="onHideDialog"
                    >Cancel</el-button>
                    <el-button
                        v-loading.fullscreen.lock="loadingShow"

                        class="el-button--transparent"
                        @click="handleOk"
                    >Send</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import {useRoute} from 'vue-router'
import InlineSvg from 'vue-inline-svg'
import vuex_store from '~/store'
import userApi from '~/modules/profile/userApi'

const route = useRoute()

const props = defineProps({
    product: {
        type: Object,
        required: true,
    }
})

const store = vuex_store.getters['auth/store']

const showDialogModal = ref(false)
const message = ref('')
const loadingShow = ref(false)

const onShowDialog = () => {
    showDialogModal.value = true
}

const onHideDialog = () => {
    showDialogModal.value = false
}

const handleOk = () => {
    let data = {
        message: message.value,
        store_id: route.query.store_id || store?.id || '',
        search_term: route.query.q || '',
        gtin: props.product.gtin || props.product.upc
    }
    loadingShow.value = true

    onHideDialog()

    userApi.sendUserNote(data)
        .finally(() => loadingShow.value = false)

}
</script>
