<template>
    <div
        v-if="isShow && ((browser === 'Firefox' && !isDesktop) ||  browser === 'Safari')"
        class="pwa-instruction-modal"
    >
        <div
            v-if="!isShowInstruction"
            class="question"
        >
            <div class="left">
                <div class="logo">
                    <img src="/apple_touch_icon.png" alt="logo"/>
                </div>
                <div class="text">
                    <div class="text__title">SeekingSimple</div>
                    <div class="text__description">Do you want to add a shortcut to your device?</div>
                </div>
            </div>
            <div class="buttons">
                <el-button
                    class="buttons__btn"
                    type="primary"
                    round
                    size="small"
                    @click="goToFirstStep"
                >
                    Yes
                </el-button>
                <el-button
                    class="buttons__btn"
                    type=""
                    round
                    size="small"
                    @click="cancel"
                >Cancel
                </el-button>
            </div>
        </div>

        <div
            v-else
            class="instruction"
        >
            <div
                v-if="step === 1"
                class="instruction__step"
            >
                <div>1. Click on the Share button</div>
                <el-image
                    v-if="browser === 'Safari'"
                    src="/images/shortcut/safari-share.png"
                    alt="share"
                />
                <el-image
                    v-else-if="browser === 'Firefox'"
                    src="/images/shortcut/firefox-menu.png"
                    alt="share"
                />
            </div>
            <div
                v-if="step === 2"
                class="instruction__step"
            >
                <div>2. Click on {{ isDesktop ? "'Add to Dock'" : "'Add to Home Screen'"}}</div>
                <el-image
                    v-if="browser === 'Safari'"
                    :src="isDesktop ? '/images/shortcut/safari-desktop-add-to-home-screen.png' : '/images/shortcut/safari-add-to-home-screen.png'"
                    alt="add button"
                />
                <el-image
                    v-else-if="browser === 'Firefox'"
                    src="/images/shortcut/firefox-add-to-home-screen.png"
                    alt="add button"
                />
            </div>
            <div
                v-if="step === 3"
                class="instruction__step"
            >
                <div>3. Click on 'Add'</div>
                <el-image
                    v-if="browser === 'Safari'"
                    src="/images/shortcut/safari-add-button.png"
                    alt="add button"
                />
                <el-image
                    v-else-if="browser === 'Firefox'"
                    src="/images/shortcut/firefox-add-button.png"
                    alt="add button"
                />
            </div>
            <div
                v-if="step === 4"
                class="instruction__step"
            >
                <div>4. Well done, the shortcut should be added to your device</div>
            </div>
            <div class="buttons">
                <el-button
                    v-if="step === 4"
                    class="buttons__btn"
                    type="primary"
                    round
                    size="small"
                    @click="cancel"
                >
                    Done
                </el-button>
                <el-button
                    v-else
                    class="buttons__btn"
                    type="primary"
                    round
                    size="small"
                    @click="nextStep"
                >
                    Next
                </el-button>
                <el-button
                    class="buttons__btn"
                    type=""
                    round
                    size="small"
                    @click="previousStep"
                >
                    Back
                </el-button>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onBeforeMount, ref, computed} from 'vue'

const isShow = ref(true)
const browser = ref(null)
const step = ref(1)
const isShowInstruction = ref(false)

const isDesktop = computed(() => {
    return window.innerWidth >= 1024
})

onBeforeMount(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf("Firefox") > -1) {
        browser.value = 'Firefox'
        isShow.value = true
    } else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") === -1) {
        browser.value = 'Safari'
        isShow.value = true
    }
})

function previousStep() {
    if (step.value <= 1) {
        isShowInstruction.value = false
    } else {
        step.value = --step.value
    }
}

function nextStep() {
    step.value = ++step.value
}

function goToFirstStep() {
    isShowInstruction.value = true
}

function cancel() {
    isShow.value = false
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.pwa-instruction-modal {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100vw;
    height: 100px;
    margin: auto;
    background: white;
    z-index: 999;
    box-shadow: 0 -4px 5px rgba(0, 0, 0, 0.1);
    @media all and (max-width: $s) {
        flex-wrap: wrap;
        padding: 20px 0;
        justify-content: center;
        height: 150px;
    }

    .question {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        @media all and (max-width: $s) {
            flex-wrap: wrap;
            justify-content: center;
        }

        .left {
            display: flex;
            @media all and (max-width: $s) {
                padding: 0 20px 20px 20px;
            }
        }

        .logo {
            height: 100%;
            display: flex;
            margin-left: 50px;
            align-items: center;
            @media all and (max-width: $s) {
                margin-left: 0;
            }

            img {
                width: 50px;
                height: 50px;
                min-width: 50px;
            }
        }

        .text {
            margin-left: 20px;

            &__title {
                font-size: 16px;
                font-weight: bold;
            }

            &__description {
                font-size: 14px !important;
                display: block;
            }
        }
    }

    .buttons {
        margin-right: 50px;
        @media all and (max-width: $s) {
            margin-right: 0;
            display: block;
            margin-bottom: 10px;
            bottom: 0;
            position: fixed;
        }

        &__btn {
            width: 100px;
        }
    }

    .instruction {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media all and (max-width: $s) {
            flex-wrap: wrap;
            justify-content: center;
        }

        &__step {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            text-align: center;
            height: 100%;
            padding: 0 50px;
            @media all and (max-width: $s) {
                height: 70px;
                margin-bottom: 30px;
                font-size: 14px;
                justify-content: center;
                padding: 0 30px;
            }

            .el-image {
                margin-left: 20px;
                border: black solid 1px;

                @media all and (max-width: $s) {
                    margin: 0 50px;
                }
            }
        }
    }
}
</style>