<template>
    <div class="fullscreen-star-message">
        <div class="fullscreen-star-message__star">
            <div class="fullscreen-star-message__star-icon">
                <inline-svg
                    class="fullscreen-star-message__star-icon-svg"
                    src="/images/svg-icons/star-icon.svg"
                    fill="#FECD42"
                />
            </div>
            <div class="fullscreen-star-message__star-value">
                +{{ stars }}
            </div>
        </div>
        <template v-if="type === TYPE_MULTI_STARS">
            <div class="fullscreen-star-message__star fullscreen-star-message__star--satellite-first">
                <div class="fullscreen-star-message__star-icon">
                    <inline-svg
                        class="fullscreen-star-message__star-icon-svg"
                        src="/images/svg-icons/star-icon.svg"
                        fill="#FECD42"
                    />
                </div>
            </div>
            <div class="fullscreen-star-message__star fullscreen-star-message__star--satellite-second">
                <div class="fullscreen-star-message__star-icon">
                    <inline-svg
                        class="fullscreen-star-message__star-icon-svg"
                        src="/images/svg-icons/star-icon.svg"
                        fill="#FECD42"
                    />
                </div>
            </div>
        </template>
        <div class="firework" />
        <div class="firework-green" />
        <div class="firework-blue" />
        <template v-if="type === TYPE_FIREWORKS">
            <div class="firework-yellow" />
            <div class="firework-indigo" />
        </template>
    </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import InlineSvg from 'vue-inline-svg'
import {TYPE_STAR, TYPE_MULTI_STARS, TYPE_FIREWORKS} from '~/base/constants/points.constants'

const props = defineProps({
    stars: {
        type: Number,
        required: true,
        default: '',
    },
    type: {
        type: String,
        default: TYPE_STAR,
    }
})
const emit = defineEmits()

onMounted(() => {
    setTimeout(() => {
        emit('finish')
    },2750)
})

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.fullscreen-star-message {
    position: fixed;
    z-index: 2200;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    background-color: rgba(255,255,255, 25%);
    user-select: none;
    &__star {
        position: absolute;
        left: calc(50% - 32px);
        bottom: -65px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65px;
        height: 65px;
        animation: star-fly 2.75s ease-out;
        &:before {
            content: "";
            position: absolute;
            top: 40%;
            right: 0;
            bottom: -100px;
            left: 0;
            border-radius: 100%;
            background: linear-gradient(to bottom, #ff0, rgba(0,0,0,0));
        }
        &-icon {
            position: absolute;
            z-index: 1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            &-svg {
                width: 100%;
                height: 100%;
            }
        }
        &-value {
            z-index: 5;
            font-size: 16px;
            font-weight: 600;
            color: $white;
        }
        &--satellite-first,
        &--satellite-second {
            width: 25px;
            height: 25px;
            animation: star-fly-satellite 2.9s ease-in-out;
            &:before {
                content: "";
                position: absolute;
                top: 40%;
                right: 0;
                bottom: -100px;
                left: 0;
                border-radius: 100%;
                background: linear-gradient(to bottom, #ff0, rgba(0,0,0,0));
            }
        }
        &--satellite-first {
            left: calc(50% - 80px);
        }
        &--satellite-second {
            left: calc(50% + 50px);
        }
    }

    @keyframes star-fly {
        0% {
            transform: translateY(-65px);
            width: 10px;
            font-size: 2px;
        }
        75% {
            opacity: 1;
            width: 65px;
            font-size: 16px;
            transform: translateY(-400px);
        }
        100% {
            opacity: 0;
            transform: translateY(-400px);
        }
    }
    @keyframes star-fly-satellite {
        0% {
            transform: translateY(-65px);
            width: 10px;
        }
        75% {
            opacity: 1;
            width: 25px;
            transform: translateY(-400px);
        }
        100% {
            opacity: 0;
            transform: translateY(-400px);
        }
    }

    .firework,
    .firework::before,
    .firework::after {
        $block-name: &;
        content: "";
        position: absolute;
        top: 50%;
        left: 51%;
        transform: translate(-50%, -50%);
        width: 0.5vmin;
        opacity: 0;
        aspect-ratio: 1;
        background:
            radial-gradient(circle, #f00 0.2vmin, #0000 0) 50% 00%,
            radial-gradient(circle, #f00 0.3vmin, #0000 0) 00% 50%,
            radial-gradient(circle, #f00 0.5vmin, #0000 0) 50% 99%,
            radial-gradient(circle, #f00 0.2vmin, #0000 0) 99% 50%,
            radial-gradient(circle, #f00 0.3vmin, #0000 0) 80% 90%,
            radial-gradient(circle, #f00 0.5vmin, #0000 0) 95% 90%,
            radial-gradient(circle, #f00 0.5vmin, #0000 0) 10% 60%,
            radial-gradient(circle, #f00 0.2vmin, #0000 0) 31% 80%,
            radial-gradient(circle, #f00 0.3vmin, #0000 0) 80% 10%,
            radial-gradient(circle, #f00 0.2vmin, #0000 0) 90% 23%,
            radial-gradient(circle, #f00 0.3vmin, #0000 0) 45% 20%,
            radial-gradient(circle, #f00 0.5vmin, #0000 0) 13% 24%;
        background-size: 0.5vmin 0.5vmin;
        background-repeat: no-repeat;
        animation: firework 1.90s;
    }
    .firework-green,
    .firework-green::before,
    .firework-green::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0.5vmin;
        aspect-ratio: 1;
        opacity: 0;
        background:
            radial-gradient(circle, #0f0 0.2vmin, #0000 0) 50% 00%,
            radial-gradient(circle, #0f0 0.3vmin, #0000 0) 00% 50%,
            radial-gradient(circle, #0f0 0.5vmin, #0000 0) 50% 99%,
            radial-gradient(circle, #0f0 0.2vmin, #0000 0) 99% 50%,
            radial-gradient(circle, #0f0 0.3vmin, #0000 0) 80% 90%,
            radial-gradient(circle, #0f0 0.5vmin, #0000 0) 95% 90%,
            radial-gradient(circle, #0f0 0.5vmin, #0000 0) 10% 60%,
            radial-gradient(circle, #0f0 0.2vmin, #0000 0) 31% 80%,
            radial-gradient(circle, #0f0 0.3vmin, #0000 0) 80% 10%,
            radial-gradient(circle, #0f0 0.2vmin, #0000 0) 90% 23%,
            radial-gradient(circle, #0f0 0.3vmin, #0000 0) 45% 20%,
            radial-gradient(circle, #0f0 0.5vmin, #0000 0) 13% 24%;
        background-size: 0.5vmin 0.5vmin;
        background-repeat: no-repeat;
        animation: firework 1.95s;
    }

    .firework-blue,
    .firework-blue::before,
    .firework-blue::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 49%;
        transform: translate(-50%, -50%);
        width: 0.5vmin;
        aspect-ratio: 1;
        opacity: 0;
        background:
            radial-gradient(circle, #00f 0.2vmin, #0000 0) 50% 00%,
            radial-gradient(circle, #00f 0.3vmin, #0000 0) 00% 50%,
            radial-gradient(circle, #00f 0.5vmin, #0000 0) 50% 99%,
            radial-gradient(circle, #00f 0.2vmin, #0000 0) 99% 50%,
            radial-gradient(circle, #00f 0.3vmin, #0000 0) 80% 90%,
            radial-gradient(circle, #00f 0.5vmin, #0000 0) 95% 90%,
            radial-gradient(circle, #00f 0.5vmin, #0000 0) 10% 60%,
            radial-gradient(circle, #00f 0.2vmin, #0000 0) 31% 80%,
            radial-gradient(circle, #00f 0.3vmin, #0000 0) 80% 10%,
            radial-gradient(circle, #00f 0.2vmin, #0000 0) 90% 23%,
            radial-gradient(circle, #00f 0.3vmin, #0000 0) 45% 20%,
            radial-gradient(circle, #00f 0.5vmin, #0000 0) 13% 24%;
        background-size: 0.5vmin 0.5vmin;
        background-repeat: no-repeat;
        animation: firework 1.85s;
    }

    .firework::before,
    .firework-green::before,
    .firework-blue::before,
    .firework-yellow::before,
    .firework-indigo::before {
        transform: translate(-50%, -50%) rotate(25deg) !important;
    }

    .firework::after,
    .firework-green::after,
    .firework-yellow::after,
    .firework-indigo::after,
    .firework-blue::after {
        transform: translate(-50%, -50%) rotate(-37deg) !important;
    }

    .firework-yellow,
    .firework-yellow::before,
    .firework-yellow::after {
        $block-name: &;
        content: "";
        position: absolute;
        top: 49%;
        left: 100%;
        transform: translate(-50%, -50%);
        width: 0.5vmin;
        opacity: 0;
        aspect-ratio: 1;
        background:
            radial-gradient(circle, #FECD42 0.2vmin, #0000 0) 50% 00%,
            radial-gradient(circle, #FECD42 0.3vmin, #0000 0) 00% 50%,
            radial-gradient(circle, #FECD42 0.5vmin, #0000 0) 50% 99%,
            radial-gradient(circle, #FECD42 0.2vmin, #0000 0) 99% 50%,
            radial-gradient(circle, #FECD42 0.3vmin, #0000 0) 80% 90%,
            radial-gradient(circle, #FECD42 0.5vmin, #0000 0) 95% 90%,
            radial-gradient(circle, #FECD42 0.5vmin, #0000 0) 10% 60%,
            radial-gradient(circle, #FECD42 0.2vmin, #0000 0) 31% 80%,
            radial-gradient(circle, #FECD42 0.3vmin, #0000 0) 80% 10%,
            radial-gradient(circle, #FECD42 0.2vmin, #0000 0) 90% 23%,
            radial-gradient(circle, #FECD42 0.3vmin, #0000 0) 45% 20%,
            radial-gradient(circle, #FECD42 0.5vmin, #0000 0) 13% 24%;
        background-size: 0.5vmin 0.5vmin;
        background-repeat: no-repeat;
        animation: firework-diagonal-left-to-right 1.90s;
    }
    .firework-indigo,
    .firework-indigo::before,
    .firework-indigo::after {
        $block-name: &;
        content: "";
        position: absolute;
        top: 48%;
        left: 100%;
        transform: translate(-50%, -50%);
        width: 0.5vmin;
        opacity: 0;
        aspect-ratio: 1;
        background:
            radial-gradient(circle, #484BAF 0.2vmin, #0000 0) 50% 00%,
            radial-gradient(circle, #484BAF 0.3vmin, #0000 0) 00% 50%,
            radial-gradient(circle, #484BAF 0.5vmin, #0000 0) 50% 99%,
            radial-gradient(circle, #484BAF 0.2vmin, #0000 0) 99% 50%,
            radial-gradient(circle, #484BAF 0.3vmin, #0000 0) 80% 90%,
            radial-gradient(circle, #484BAF 0.5vmin, #0000 0) 95% 90%,
            radial-gradient(circle, #484BAF 0.5vmin, #0000 0) 10% 60%,
            radial-gradient(circle, #484BAF 0.2vmin, #0000 0) 31% 80%,
            radial-gradient(circle, #484BAF 0.3vmin, #0000 0) 80% 10%,
            radial-gradient(circle, #484BAF 0.2vmin, #0000 0) 90% 23%,
            radial-gradient(circle, #484BAF 0.3vmin, #0000 0) 45% 20%,
            radial-gradient(circle, #484BAF 0.5vmin, #0000 0) 13% 24%;
        background-size: 0.5vmin 0.5vmin;
        background-repeat: no-repeat;
        animation: firework-diagonal-right-to-left 1.70s;
    }

    @keyframes firework {
        0% {
            transform: translate(-50%, 60vh);
            width: 0.5vmin;
            opacity: 1;
        }
        50% {
            width: 0.5vmin;
            opacity: 1;
        }
        100% {
            width: 45vmin;
            opacity: 0;
        }
    }
    @keyframes firework-diagonal-left-to-right {
        0% {
            transform: translate(-50%, 60vh);
            width: 0.5vmin;
            opacity: 1;
            left: 0;
        }
        50% {
            width: 0.5vmin;
            opacity: 1;
        }
        100% {
            width: 45vmin;
            opacity: 1;
            left: 50%;
        }
    }
    @keyframes firework-diagonal-right-to-left {
        0% {
            transform: translate(-50%, 60vh);
            width: 0.5vmin;
            opacity: 1;
            left: 100%;
        }
        50% {
            width: 0.5vmin;
            opacity: 1;
        }
        100% {
            width: 45vmin;
            opacity: 1;
            left: 50%;
        }
    }
}
</style>
