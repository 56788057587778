<template>
    <div
        class="shop-category"
        :class="{ 'shop-category--no-border': cartCategory }"
    >
        <div class="shop-category__title-section">
            <div class="shop-category__title">
                {{ !cartCategory ? category.name : 'Replaced' }}
            </div>
            <div class="shop-category__count">
                <div class="shop-category__count-value">
                    <template v-if="cartCategory">
                        {{ category.length }}
                    </template>
                    <template v-else>
                        {{ category.items.length }}
                    </template>
                </div>
            </div>
        </div>
        <div class="shop-category__product-section">
            <template v-if="cartCategory">
                <div
                    v-for="product in category"
                    :key="product._id"
                    class="shop-category__product"
                >
                    <cart-product-block :product="product" />
                </div>
            </template>
            <template v-else>
                <div
                    v-for="product in category.items"
                    :key="product._id"
                    class="shop-category__product"
                >
                    <shop-product-block
                        :product="product"
                    />
                </div>
            </template>
        </div>
        <div
            v-if="cartCategory"
            class="shop-category__bottom-section"
        >
            <div
                v-if="false"
                class="default-text"
            >
                Check off items above to place them in your cart. Your cart will be reset automatically in 24 hours.
            </div>
        </div>
    </div>
</template>

<script setup>
import ShopProductBlock from './ShopProductBlock'
import CartProductBlock from './CartProductBlock'

const props = defineProps({
    category: {
        type: [Array, Object],
        default: () => {
            return {}
        }
    },
    cartCategory: {
        type: Boolean,
        default: false,
        description: 'flag for "In Cart" section at "/my-item" page'
    }
})
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.shop-category {
    margin: 0 -15px;
    border-bottom: 1px solid $light-gray;
    padding: 0 15px 17px;
    @media all and (min-width: $m) {
        margin: 0 -30px;
        padding: 0 30px 17px;
    }
    &__title {
        font-size: 20px;
        line-height: 1.5;
        font-weight: 600;
        letter-spacing: 0.15px;
        color: $dark;
        &-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
        }
    }
    &__count {
        flex-shrink: 0;
        margin-left: 10px;
        &-value {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 18px;
            height: 18px;
            border: 1px solid $white;
            border-radius: 9px;
            padding: 3px 4px 2px;
            font-size: 11px;
            font-weight: 500;
            color: $white;
            background-color: $indigo;
        }
    }
    &__product {
        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
    &__bottom-section {
        padding-top: 15px;
    }
    &--no-border {
        border-bottom: unset;
    }
}
</style>
