<template>
    <div class="helpful-buttons">
        <el-button
            :loading="loading"
            :type="wasHelpful ?'primary' : 'default'"
            round
            class="helpful-button"
            @click="handleHelpful(true)"
        >
            <i class="fa-solid fa-thumbs-up" />&nbsp; Helpful
        </el-button>
        <el-button
            :loading="loading"
            :type="wasNotHelpful ? 'primary' : 'default'"
            round
            class="helpful-button"
            @click="handleHelpful(false)"
        >
            <i class="fa-solid fa-thumbs-down" />&nbsp; Not Helpful
        </el-button>
    </div>
</template>

<script setup>
import axios from 'axios'
import {computed, ref} from 'vue'

const name = 'HelpfulOrNot'

const props = defineProps({
    modelType: {
        type: String,
        required: true,
    },
    modelId: {
        type: String,
        required: true,
    },
})


const loading = ref(true)
const isHelpful = ref(null)
getData()
async function getData() {
    const {data} = await axios.post('helpful/get', {
        model_type: props.modelType,
        model_id: props.modelId,
    })
    isHelpful.value = data.value
    loading.value = false
}
const wasHelpful = computed(() => isHelpful.value !== null && !!isHelpful.value)
const wasNotHelpful = computed(() => isHelpful.value !== null && !isHelpful.value)


function handleHelpful(val) {
    loading.value = true
    axios.post('helpful/store', {
        model_type: props.modelType,
        model_id: props.modelId,
        is_helpful: val,
    }).then(() => getData())
}
</script>
<style lang="scss">
.helpful{
    &-buttons {
        text-align: center;
        margin-top: 20px;
    }
    &-button {
        &.is-loading {
            i.fa-solid {
                display: none;
            }
            .el-icon,
            .el-icon svg {
                width: 18px;
                height: 18px;
            }
            &.el-button [class*=el-icon] + span {
                margin-left: 0;
            }
        }
    }
}
</style>
