<template>
    <section class="main-content word-game">
        <div class="g-container">
            <h3 class="word-game__title">
                Find these {{ words.length }} popular condiments
            </h3>
            <WordSearchGame
                :matrix="matrix"
                :words="words"
                :bottom-text="bottomText"
                :robot-message="robotMessage"
                class="word-game__game"
            />
        </div>
    </section>
</template>

<script>
import WordSearchGame from './WordSearchGame'
import {LOW, MODERATE, HIGH} from '~/modules/games/sugarLevel.constants'
import store from '~/store'

export default {
    name: 'ConnedEMents',
    components: {WordSearchGame},
    data() {
        return {
            matrix: [
                ['K', 'N', 'H', 'D', 'L', 'R', 'M', 'B', 'W', 'T'],
                ['V', 'E', 'M', 'C', 'E', 'O', 'D', 'A', 'E', 'O'],
                ['M', 'Q', 'T', 'L', 'N', 'R', 'X', 'R', 'B', 'Y'],
                ['K', 'G', 'I', 'C', 'A', 'A', 'I', 'B', 'A', 'A'],
                ['Y', 'S', 'I', 'T', 'H', 'Y', 'R', 'E', 'L', 'M'],
                ['H', 'O', 'S', 'C', 'A', 'U', 'G', 'C', 'S', 'Z'],
                ['J', 'U', 'S', 'K', 'W', 'N', 'P', 'U', 'A', 'A'],
                ['M', 'F', 'I', 'X', 'Q', 'R', 'D', 'E', 'M', 'S'],
                ['A', 'S', 'L', 'A', 'S', 'N', 'U', 'Z', 'I', 'G'],
                ['S', 'R', 'I', 'R', 'A', 'C', 'H', 'A', 'C', 'I'],
            ],
            words: [
                {value: 'BALSAMIC', sugar: MODERATE},
                {value: 'BARBECUE', sugar: HIGH},
                {value: 'GUAC', sugar: LOW},
                {value: 'KETCHUP', sugar: HIGH},
                {value: 'MAYO', sugar: LOW},
                {value: 'MUSTARD', sugar: LOW},
                {value: 'RANCH', sugar: LOW},
                {value: 'RELISH', sugar: HIGH},
                {value: 'SALSA', sugar: LOW},
                {value: 'SOY', sugar: LOW},
                {value: 'SRIRACHA', sugar: MODERATE},
                {value: 'TERIYAKI', sugar: HIGH},
            ],
            bottomText: 'Which are the best condiments to give you lots of flavor without sugar?',
            robotMessage: ['Salsa, mustard and mayo can add lots of flavor without challenging your "aspiration".'],
        }
    },
    computed: {
        userAspiration() {
            return store.getters['auth/user'].aspiration
        },
    },
    mounted() {
        if(this.userAspiration) {
            this.robotMessage = [`Salsa, mustard and mayo can add lots of flavor without challenging your ${this.userAspiration}.`]
        }
    }
}
</script>
