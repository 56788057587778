<template>
    <el-container v-loading="loadingLogo" class="auth-page">
        <el-main>
            <div class="auth-page__top">
                <div class="auth-page__image-wrap">
                    <img
                        v-if="!loginPath && !loadingLogo"
                        class="auth-page__image"
                        src="/images/auth/seekingsimple-logo.svg"
                        alt=""
                    >
                    <img
                        v-else
                        class="auth-page__image companies_logo"
                        :src="loginPath"
                        alt=""
                    >
                </div>
            </div>
            <el-card>
                <el-form
                    ref="registerForm"
                    :model="form"
                    :rules="rules"
                    class="auth-form"
                    @keyup.enter="onSubmit"
                >
                    <el-form-item>
                        <div class="auth-form__title">
                            Create Account
                        </div>
                    </el-form-item>

                    <el-form-item
                        prop="email"
                        :error="formErrors.get('email')"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model="form.email"
                                name="email"
                                type="email"
                                placeholder=" "
                                autocomplete="true"
                                :disabled="true"
                                class="el-input--floating-label"
                            >
                                <template #prefix>
                                    Email
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        prop="password"
                        :error="formErrors.get('password')"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model="form.password"
                                name="password"
                                type="password"
                                show-password
                                placeholder=" "
                                class="el-input--floating-label"
                            >
                                <template #prefix>
                                    Password
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                    <el-form-item
                        prop="password_confirmation"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model="form.password_confirmation"
                                type="password"
                                name="password_confirmation"
                                show-password
                                placeholder=" "
                                class="el-input--floating-label"
                            >
                                <template #prefix>
                                    Confirm password
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                    <el-form-item>
                        <p class="default-text">
                            Feel secure that we <b>never</b> share your personal information with anyone <b><a
                                href="/privacy-policy"
                                class="el-link el-link--dark"
                            >Privacy Policy</a></b>
                        </p>
                    </el-form-item>
                    <el-form-item
                        prop="contact_agree"
                        label=""
                    >
                        <el-checkbox
                            v-model="form.contact_agree"
                            label="I agree to the "
                            name="contact_agree"
                        />
                        <span class="el-link__wrap"><a
                            href="/terms-of-use"
                            class="el-link el-link--dark"
                        >terms of service</a></span>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            :loading="loading"
                            :disabled="!form.contact_agree"
                            type="primary"
                            round
                            class="w-100"
                            @click.prevent="onSubmit"
                        >
                            Accept Invitation
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>
import {Errors} from '~/includes/Errors'
import validatePassword from '~/includes/mixins/validatePassword'
import authApi from '../authApi'
import whiteLabelApi from "~/modules/auth/whiteLabelApi";

export default {
    mixins: [validatePassword],
    layout: 'Auth',
    data() {
        return {
            form: {
                email: '',
                password: '',
                password_confirmation: '',
                loginPath: '',
                contact_agree: false
            },
            loadingLogo: false,
            rules: {
                email: [
                    { required: true, message: 'Please enter your email', trigger: 'blur' },
                    { type: 'email', message: 'Invalid email', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: 'Please enter your password', trigger: 'blur' },
                    { validator: this.checkPasswordComplicated, trigger: ['change', 'blur'] }
                ],
                password_confirmation: [
                    { required: true, message: 'Please confirm password', trigger: 'blur' },
                    { validator: this.checkPassIdentical, trigger: ['change', 'blur'] }
                ],
                contact_agree: [{ validator: this.checkAgree, trigger: ['change', 'blur'] }]
            },
            formErrors: new Errors(),
            loading: false,
        }
    },
    created() {
        const localStorageClientLevelData = JSON.parse(localStorage.getItem('useClientLevel'))
        if (localStorageClientLevelData?.logo_path) {
            this.loginPath = localStorageClientLevelData.logo_path
        }
        this.form.invite_code = this.$route.params.invite_code
        this.form.email = this.$route.params.email
        document.body.removeAttribute('class')
        this.form.help = this.$route.params.help

        authApi.verifyInviteCode({
            invite_code: this.form.invite_code,
            email: this.form.email,
        }).then(response => {
            if (response.data.status !== 'success') {
                this.$router.push('/login')
            }
        }).catch(error => {
            this.$router.push('/login')
        })
    },
    beforeMount() {
        if (!this.$route.query.client_level) {
            localStorage.removeItem('useClientLevel')
            this.removeIconLinks()
            this.addFavicon('./favicon.ico')
            this.addFaviconApple('./apple_touch_icon.png')
            return false
        }

        const localStorageClientLevelData = JSON.parse(localStorage.getItem('useClientLevel'))
        if (localStorageClientLevelData?.name === this.$route.query.client_level) {
            this.loginPath = localStorageClientLevelData.logo_path
            this.fetchWhiteList()
        } else {
            localStorage.removeItem('useClientLevel')
            this.loadingLogo = true
            this.fetchWhiteList()
        }
    },
    methods: {
        onSubmit() {
            this.$refs.registerForm.validate((valid) => {
                if (valid) {
                    this.formErrors.clear()
                    this.registerUser()
                }
            })
        },
        removeIconLinks() {
            const faviconLinks = document.querySelectorAll('link[rel="icon"]');
            const faviconShortcutLinks = document.querySelectorAll('link[rel="shortcut icon"]');
            faviconLinks.forEach(link => {
                link.parentNode.removeChild(link);
            });
            faviconShortcutLinks.forEach(link => {
                link.parentNode.removeChild(link);
            });
        },
        fetchWhiteList () {
            const payload = {
                client_level_name: this.$route.query.client_level
            }
            whiteLabelApi.getByClientLevelName(payload)
                .then(data => {
                    if (data.data.length === 0) {
                        this.removeIconLinks()
                        this.addFavicon('./favicon.ico')
                        this.addFaviconApple('./apple_touch_icon.png')
                    } else {
                    this.loginPath = data.data?.logo_path
                    localStorage.setItem('useClientLevel', JSON.stringify(data.data))

                    const faviconLinks = document.querySelectorAll('link[rel="icon"]');
                    faviconLinks.forEach(link => {
                        link.parentNode.removeChild(link);
                    });
                    this.addFavicon(data.data.favicon.favicon_path)
                    this.addFavicon(data.data.favicon.favicon16x16, '16x16')
                    this.addFavicon(data.data.favicon.favicon32x32, '32x32')
                    this.addFaviconApple(data.data.favicon.apple_touch_icon)
                    this.addFaviconSvg(data.data.favicon.favicon_svg)
                    }
                    this.loadingLogo = false
                })
                .catch(() => {
                    this.removeIconLinks()
                    this.addFavicon('./favicon.ico')
                    this.addFaviconApple('./apple_touch_icon.png')
                    this.loadingLogo = false
                })
        },
        addFavicon (path, size = false) {
            if (path == null) return false;
            const link = document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'icon';
            link.href = path;
            if (size) {
                link.sizes = size
            }
            document.getElementsByTagName('head')[0].appendChild(link);
        },
        addFaviconApple(path) {
            if (path == null) return false;
            const link = document.createElement('link');
            link.rel = 'apple-touch-icon';
            link.href = path;
            document.getElementsByTagName('head')[0].appendChild(link);
        },
        addFaviconSvg(path) {
            if (path == null) return false;
            const link = document.createElement('link');
            link.rel = 'icon';
            link.type = 'image/svg+xml';
            link.href = path;
            document.getElementsByTagName('head')[0].appendChild(link);
        },
        registerUser() {
            authApi.register(this.form).then(response => {
                // this.$message.success('You have been registered')
                authApi.login(this.form).then(response => {
                    this.$store.dispatch('auth/saveToken', {
                        token: response.data.token,
                        remember: true
                    }).then(() => {
                        this.$router.push({ name: 'quiz.welcome' })
                    })
                })
            }).catch(error => {
                if (error.response.status === 422) {
                    this.formErrors.record(error.response.data.errors)
                    if (error.response.data.errors.invite_code) {
                        this.$message.error(error.response.data.errors.invite_code[0])
                    }
                }
            }).finally(() => this.loading = false)
        },
        checkAgree(rule, value, callback) {
            if (!value) {
                callback(new Error('Please agree to term of service'))
            } else {
                callback()
            }
        }
    },
};
</script>
