<template>
    <div class="benefits flex justify-end">
        <div class="container-holder">
            <div class="lp__column-l lp__column-l--width-desktop">
                <div class="lp__image">
                    <img
                        src="/images/landing/benefits-img.png"
                        alt=""
                    >
                </div>
            </div>
            <div class="lp__column-r">
                <div class="lp__content">
                    <h2 class="lp__title">
                        Meet Savvy
                    </h2>
                    <ul class="benefits-list">
                        <li
                            v-for="item in items"
                            :key="item.title"
                            class="benefits-list__item"
                        >
                            <strong class="benefits-list__title">{{ item.title }}</strong>
                            <p class="benefits-list__text lp__text">
                                {{ item.text }}
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            items: [
                {
                    title: 'Instant expertise:',
                    text: 'It’s like having a food expert at your fingertips. SeekingSimple provides recommendations, bite-sized tips, personalized advice, and coaching support so it\'s easy to choose better food and improve the health of the entire household.'
                },
                {
                    title: 'Personalized support:',
                    text: 'SeekingSimple listens and learns all the time and the participants always get the most relevant information, advice and support whenever it\'s needed.'
                },
                {
                    title: 'Pantry Makeover recommendations:',
                    text: 'SeekingSimple maps store-bought foods, containing various levels of harmful ingredients, to diseases with a special emphasis on sugars and highly processed carbohydrates. It then provides Pantry Makeover recommendations the participant can easily find in a local store. All while embracing family food culture and maintaining the time savings, convenience, and enjoyment of packaged foods.'
                },
            ]
        }
    }
}

</script>
