import * as types from '../mutation-types'

let collapsed = false
if (window.innerWidth < 768) {
    collapsed = true
}

export const state = {
    isCollapsed: collapsed,
    invite_notifications: [],
    count_unread_notifications: 0
}
export const getters = {
    baseIsCollapsed: state => state.isCollapsed,
    invite_notifications: state => state.invite_notifications,
    count_unread_notifications: state => state.count_unread_notifications,
}
export const mutations = {
    [types.TOGGLE_COLLAPSE](state) {
        state.isCollapsed = !state.isCollapsed
    },
    addInviteNotifications(state, email) {
        state.invite_notifications.push(email)
    },
    count_unread_notifications(state, count) {
        state.count_unread_notifications = count
    },
    clearInviteNotifications(state) {
        state.invite_notifications = []
    },
}
