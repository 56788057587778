import Child from '~/base/components/Child'
import PreviewScreen from './components/PreviewScreen'
import PseudoQuiz from './components/PseudoQuiz'
import PseudoLearning from './components/PseudoLearning'
import PseudoPmo from './components/PseudoPmo'
import PseudoShop from './components/PseudoShop'
import PseudoLesson from './components/PseudoLesson'
import store from '~/store'

export const REGISTER_STEP = 1

const meta = {
    auth: true,
    layout: 'Empty',
    step: REGISTER_STEP
}

export default [
    //------------ At the Beginning -------------//
    {
        path: 'intro/full',
        component: Child,
        redirect: {name: 'intro.full.profile-preview'},
        children: [
            // Profile
            {
                path: 'profile-preview',
                name: 'intro.full.profile-preview',
                component: PreviewScreen,
                props: {
                    state: {profile: 'active'},
                    nextRoute: 'intro.full.profile-overview'
                },
                meta
            },
            {
                path: 'profile-overview',
                name: 'intro.full.profile-overview',
                component: PseudoQuiz,
                props: {
                    nextRoute: 'intro.full.lm-preview'
                },
                meta
            },
            {
                path: 'profile-overview-single',
                name: 'intro.full.profile-overview-single',
                component: PseudoQuiz,
                props: {
                    nextRoute: 'intro.full.done-full'
                },
                meta
            },

            // Learning Modules
            {
                path: 'lm-preview',
                name: 'intro.full.lm-preview',
                component: PreviewScreen,
                props: {
                    state: {
                        profile: 'done',
                        learning: 'active'
                    },
                    nextRoute: 'intro.full.lm-overview'
                },
                meta
            },
            {
                path: 'lm-overview',
                name: 'intro.full.lm-overview',
                component: PseudoLearning,
                props: {
                    nextRoute: 'intro.full.pmo-preview',
                    robotMessages: [{
                        robotPosition: '#learning-module-1 .learning-module__module-block',
                        robotImage: '/images/robots/robot_waving.svg',
                        text: 'Step 2 will provide information on how our target ingredients, sugar and simple carbs are <b>affecting your body</b>.',
                    }]
                },
                meta
            },
            {
                path: 'lm-overview-single',
                name: 'intro.full.lm-overview-single',
                component: PseudoLearning,
                props: {
                    nextRoute: 'intro.full.done-full',
                    robotMessages: [{
                        robotPosition: '#learning-module-1 .learning-module__module-block',
                        robotImage: '/images/robots/robot_waving.svg',
                        text: 'Step 2 will provide information on how our target ingredients, sugar and simple carbs are <b>affecting your body</b>.',
                    }]
                },
                meta
            },

            // PMO
            {
                path: 'pmo-preview',
                name: 'intro.full.pmo-preview',
                component: PreviewScreen,
                props: {
                    state: {
                        profile: 'done',
                        learning: 'done',
                        pmo:'active'
                    },
                    nextRoute: 'intro.full.pmo-overview'
                },
                beforeEnter: (to, from) => {
                    const user = store.getters['auth/user']
                    if (!user.is_fdm) {
                        return {name: 'intro.full.my-list-preview'}
                    }
                },
                meta
            },
            {
                path: 'pmo-overview',
                name: 'intro.full.pmo-overview',
                component: PseudoPmo,
                props: {
                    nextRoute: 'intro.full.my-list-preview'
                },
                meta
            },
            {
                path: 'pmo-overview-single',
                name: 'intro.full.pmo-overview-single',
                component: PseudoPmo,
                props: {
                    nextRoute: 'intro.full.done-full'
                },
                meta
            },

            // My List
            {
                path: 'my-list-preview',
                name: 'intro.full.my-list-preview',
                component: PreviewScreen,
                props: {
                    state: {
                        profile: 'done',
                        learning: 'done',
                        pmo: 'done',
                        myList: 'active'
                    },
                    nextRoute: 'intro.full.my-list-overview'
                },
                meta
            },
            {
                path: 'my-list-overview',
                name: 'intro.full.my-list-overview',
                component: PseudoShop,
                props: {
                    nextRoute: 'intro.full.done-preview'
                },
                meta
            },
            {
                path: 'my-list-overview-single',
                name: 'intro.full.my-list-overview-single',
                component: PseudoShop,
                props: {
                    nextRoute: 'intro.full.done-full'
                },
                meta
            },
            {
                path: 'done-preview',
                name: 'intro.full.done-preview',
                component: PreviewScreen,
                props: {
                    state: {
                        profile: 'done',
                        learning: 'done',
                        pmo: 'done',
                        myList: 'done'
                    },
                    nextRoute: 'intro.full.done-full',
                },
                meta
            },
            {
                path: 'done-full',
                name: 'intro.full.done-full',
                component: PreviewScreen,
                props: {
                    state: {
                        profile: 'done',
                        learning: 'done',
                        pmo: 'done',
                        myList: 'done'
                    },
                    btnShow: true,
                    nextRoute: 'quiz.index',
                },
                meta
            },
        ]
    },

    //------------ After Profile Builder -------------//
    {
        path: 'intro/after-profile',
        component: Child,
        children: [
            // Learning Modules
            {
                path: 'lm-preview',
                name: 'intro.after-profile.lm-preview',
                component: PreviewScreen,
                props: {
                    state: {
                        profile: 'done',
                        learning: 'active'
                    },
                    nextRoute: 'intro.after-profile.lm-overview'
                },
                meta
            },
            {
                path: 'lm-overview',
                name: 'intro.after-profile.lm-overview',
                component: PseudoLearning,
                props: {
                    nextRoute: 'intro.after-profile.lm-overview-extended',
                    robotMessages: [
                        {
                            robotPosition: 'bottom-left-screen-corner',
                            robotImage: '/images/robots/robot_waving.svg',
                            text: 'There are four modules in Step 2.  Each has great information on how sugar and carbohydrates may be affecting you.',
                        },
                    ]
                },
                meta
            },
            {
                path: 'lm-overview-extended',
                name: 'intro.after-profile.lm-overview-extended',
                component: PseudoLearning,
                props: {
                    nextRoute: 'intro.after-profile.lesson-overview',
                    robotMessages: [
                        {
                            robotPosition: '#learning-module-1 .learning-module__chapter-list',
                            robotImage: '/images/robots/robot_hand_up.svg',
                            text: 'Each module has two chapters that take under <b>3 minutes</b> to complete.',
                            bordered: true,
                            centerAlignOnPage: true,
                        },
                    ],
                    learningModuleChapters: [
                        {
                            title: 'Sweet Danger',
                            completed: false,
                            icon_url: '/images/learning-modules/sweet-danger.png',
                        },
                        {
                            title: 'Study It',
                            completed: false,
                            icon_url: '/images/learning-modules/study-it.png',
                        },
                    ]
                },
                meta
            },
            {
                path: 'lesson-overview',
                name: 'intro.after-profile.lesson-overview',
                component: PseudoLesson,
                props: {
                    nextRoute: 'intro.after-profile.lm-overview-extended-last',
                },
                meta
            },
            {
                path: 'lm-overview-extended-last',
                name: 'intro.after-profile.lm-overview-extended-last',
                component: PseudoLearning,
                props: {
                    nextRoute: 'intro.after-profile.lm-preview-done',
                    robotMessages: [
                        // {
                        //     robotPosition: '#learning-module-1 .el-button',
                        //     robotImage: '/images/robots/robot_hand_up.svg',
                        //     text: 'If you are familiar with the content, you can <b>Skip Ahead</b> and answer a few simple questions for each section.',
                        //     bordered: true,
                        //     centerAlignOnPage: true
                        // },
                        {
                            robotPosition: '#learning-module-1',
                            robotImage: '/images/robots/robot_hands_up.svg',
                            text: 'Let\'s Begin!',
                            robotAlign: 'center',
                            centerAlignOnPage: true,
                        },
                    ],
                    learningModuleChapters: [
                        {
                            title: 'Sweet Danger',
                            completed: false,
                            icon_url: '/images/learning-modules/sweet-danger.png',
                        },
                        {
                            title: 'Study It',
                            completed: false,
                            icon_url: '/images/learning-modules/study-it.png',
                        },
                    ]
                },
                meta
            },
            {
                path: 'lm-preview-done',
                name: 'intro.after-profile.lm-preview-done',
                component: PreviewScreen,
                props: {
                    state: {
                        profile: 'done',
                        learning: 'active'
                    },
                    nextRoute: 'learning-modules',
                    btnShow: true,
                },
                meta
            },
        ]
    },

    //------------ After Learning Modules -------------//
    {
        path: 'intro/after-lm',
        component: Child,
        children: [
            // PMO
            {
                path: 'pmo-preview',
                name: 'intro.after-lm.pmo-preview',
                component: PreviewScreen,
                props: {
                    state: {
                        profile: 'done',
                        learning: 'done',
                        pmo:'active'
                    },
                    nextRoute: 'intro.after-lm.pmo-overview'
                },
                beforeEnter: (to, from) => {
                    const user = store.getters['auth/user']
                    if (!user.is_fdm) {
                        if (user.canSeeMyList) return {name: 'intro.after-pmo.my-list-preview'}
                        else return {name: 'learning-modules'}
                    }
                },
                meta
            },
            {
                path: 'pmo-overview',
                name: 'intro.after-lm.pmo-overview',
                component: PseudoPmo,
                props: {
                    nextRoute: 'intro.after-lm.pmo-overview-last',
                    extendedPreview: true
                },
                beforeEnter: (to, from) => {
                    const user = store.getters['auth/user']
                    if (!user.is_fdm) {
                        if (user.canSeeMyList) return {name: 'intro.after-pmo.my-list-preview'}
                        else return {name: 'learning-modules'}
                    }
                },
                meta
            },
            {
                path: 'pmo-overview-last',
                name: 'intro.after-lm.pmo-overview-last',
                component: PseudoPmo,
                props: {
                    nextRoute: 'intro.after-lm.pmo-preview-done',
                    extendedPreviewLast: true
                },
                beforeEnter: (to, from) => {
                    const user = store.getters['auth/user']
                    if (!user.is_fdm && !user.is_primary) {
                        return {name: 'intro.after-pmo.my-list-preview'}
                    }
                },
                meta
            },
            {
                path: 'pmo-preview-done',
                name: 'intro.after-lm.pmo-preview-done',
                component: PreviewScreen,
                props: {
                    state: {
                        profile: 'done',
                        learning: 'done',
                        pmo:'active'
                    },
                    nextRoute: 'pmo',
                    btnShow: true,
                },
                beforeEnter: (to, from) => {
                    const user = store.getters['auth/user']
                    if (!user.is_fdm) {
                        if (user.canSeeMyList) return {name: 'intro.after-pmo.my-list-preview'}
                        else return {name: 'learning-modules'}
                    }
                },
                meta
            },

        ]
    },

    //------------ After PMO -------------//
    {
        path: 'intro/after-pmo',
        component: Child,
        children: [
            // My List
            {
                path: 'my-list-preview',
                name: 'intro.after-pmo.my-list-preview',
                component: PreviewScreen,
                props: {
                    state: {
                        profile: 'done',
                        learning: 'done',
                        pmo: 'done',
                        myList: 'active'
                    },
                    nextRoute: 'intro.after-pmo.my-list-overview'
                },
                meta
            },
            {
                path: 'my-list-overview',
                name: 'intro.after-pmo.my-list-overview',
                component: PseudoShop,
                props: {
                    nextRoute: 'intro.after-pmo.my-list-preview-done'
                },
                meta
            },
            {
                path: 'my-list-preview-done',
                name: 'intro.after-pmo.my-list-preview-done',
                component: PreviewScreen,
                props: {
                    state: {
                        profile: 'done',
                        learning: 'done',
                        pmo: 'done',
                        myList: 'active'
                    },
                    nextRoute: 'my-items.shop',
                    btnShow: true,
                },
                meta
            },
        ]
    },

]
