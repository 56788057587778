<template>
    <div
        v-if="products&&products.length"
        class="rate-product-list"
    >
        <div class="rate-product-list__title-section">
            <div
                class="rate-product-list__title"
                :class="{'rate-product-list__title--gray': products.length === 0}"
            >
                {{ title }}
            </div>
            <div class="rate-product-list__count">
                <div
                    class="rate-product-list__count-value"
                    :class="{'rate-product-list__count-value--maroon': dislikeSectionDecor && products.length > 0}"
                >
                    {{ products.length }}
                </div>
            </div>
        </div>
        <div class="rate-product-list__list">
            <div
                v-for="item in products"
                :key="item._id"
                class="rate-product-list__list-item"
            >
                <rate-product-block
                    :product="item"
                    :crossed-out-text="dislikeSectionDecor"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import RateProductBlock from '~/modules/my-items/components/rate-product-block'

const props = defineProps({
    'title': {
        type: String,
        default: ''
    },
    'products': {
        type: [ Object, Array ],
        required: true,
        default: null
    },
    'dislikeSectionDecor': {
        type: Boolean,
        default: false,
        description: 'modifier for the component if it is a section of dislikes'
    }
})
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.rate-product-list {
    $local-gray: #999999;
    $local-light-gray: #e0e0e0;
    $block-name: &;
    padding: 15px 0;
    &:not(:last-child) {
        border-bottom: 1px solid $local-light-gray;
    }
    &__title {
        font-size: 20px;
        line-height: 1.4;
        font-weight: 600;
        letter-spacing: 0.15px;
        color: $dark;
        &-section {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &--gray {
            color: $local-gray;
        }
    }
    &__count {
        flex-shrink: 0;
        margin-left: 10px;
        &-value {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width:17px;
            height: 17px;
            border: 1px solid $white;
            border-radius: 8px;
            padding: 2px 4px;
            font-size: 11px;
            font-weight: 500;
            color: $white;
            background-color: $indigo;
            &--maroon {
                background-color: $maroon;
            }
        }
    }
    &__list {
        &:not(:empty){
            padding-top: 10px;
        }
        &-item {
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
}
</style>
