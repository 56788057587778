<template>
    <div
        v-if="quiz"
        class="main-content main-content--base-layout"
    >
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="questions-page__question">
                    <div class="quiz-rate">
                        <p
                            class="default-text quiz-question__text"
                            v-html="quiz.questions[0].text"
                        />
                        <FormRate
                            :question="quiz.questions[0]"
                            @set="setNumberMembers"
                        />
                    </div>
                </div>
                <div class="questions-page__quantity-list questions-page__quantity-list--bordered-top">
                    <div class="quiz-quantity">
                        <p
                            class="default-text quiz-question__text  quiz-question__text--dark"
                            v-html="quiz.questions[1].text"
                        />
                        <template v-if="answers.household_people_count">
                            <div class="quiz-question__list">
                                <PeopleType
                                    v-for="question in quiz.questions.slice(2, 6)"
                                    :key="question.id"
                                    :question="question"
                                    :answers="answers"
                                    class="quiz-question__list-item"
                                    @change="handleAnswers"
                                />
                            </div>
                        </template>
                    </div>
                </div>
                <div v-if="showSuccessMessage">
                    Your household has been changed successfully.
                </div>
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__buttons-wrap">
                    <div class="main-content__button-col">
                        <el-button
                            type="primary"
                            round
                            class="continue-button"
                            @click="onSubmit"
                        >
                            Save Profile
                        </el-button>
                    </div>
                    <div class="main-content__button-col">
                        <el-button
                            type=""
                            round
                            class="continue-button"
                            @click="onCancel"
                        >
                            Cancel
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted, getCurrentInstance } from 'vue'
import {useRoute, useRouter} from 'vue-router'
import PeopleType from '~/modules/quiz/components/household/PeopleType'
import FormRate from '~/modules/quiz/components/inputs/FormRate'
import quizApi from '~/modules/quiz/quizApi'
import bus from '~/includes/Event'
import {ElMessage} from 'element-plus'
import {Errors} from '~/includes/Errors'

const name = 'ProfileHousehold'
const router = useRouter()
const route = useRoute()
const quiz = ref(null)
const answers = ref({
    household_people_count: 0,
    household_adults_count: 0,
    household_teenagers_count: 0,
    household_pre_teens_count: 0,
    household_kids_toddlers_count: 0,
})
const { proxy } = getCurrentInstance()
const step = ref(1)
const formErrors = ref(new Errors())

onMounted(() => { bus.$emit('set-title', 'Your Household') })

fetchSubQuiz()

async function fetchSubQuiz() {
    const {data} = await quizApi.getSubQuiz(proxy.$config.householdQuizSlug)
    if (!!data) {
        quiz.value = data

        quiz.value.questions.forEach(question => {
            let answerValue = 0
            if ( question.answer === null) {
                return
            }
            if (Number.isInteger(question.answer)) {
                answerValue = question.answer||0
            } else {
                let variant = _.find(question.variants, {_id: question.answer})
                answerValue = variant.value
            }
            answers.value[question.additional_info.slug] = answerValue
        })
    }
}

function setNumberMembers(question_id, variant_id) {
    let variant = _.find(quiz.value.questions[0].variants, {_id: variant_id})
    answers.value.household_people_count = variant.value
}

function handleAnswers(answers) {
    answers.value = answers
}

const showSuccessMessage = ref(false)

function onSubmit() {
    formErrors.value.record([])
    quizApi.saveAnswers(quiz.value).then(response => {
        if (response.status === 200) {
            showSuccessMessage.value = true
            // ElMessage.success({
            //     message: 'Your changes have been saved.',
            //     duration: 2000,
            // })
        }
    }).catch(errors => {
        formErrors.value.record(errors.response.data.errors)
    })
}

function onCancel() {
    router.back()
}
</script>
