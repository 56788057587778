<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="default-text default-text--offset-bottom">
                    What 3 items when added to your dinner plate have the right/best amount of net carbs for your health?
                </div>
                <DragGame
                    :products="products"
                    big-dish-image
                    @done="handleDone"
                />
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__button-wrap">
                    <el-button
                        :disabled="!done"
                        type="primary"
                        round
                        class="continue-button"
                        @click="clickDone"
                    >
                        Done
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {provide, ref} from 'vue'
import DragGame from './DragGame'
import {useRoute, useRouter} from 'vue-router'
import {useDoneGame} from '~/modules/games/composables/done'

const name = 'MealTime'
const router = useRouter()
const route = useRoute()
const done = ref(false)

provide('range', _.range(0, 33, 3))
provide('limit', 15)

const products = ref([
    {
        id: 1,
        value: 7,
        name: 'Stuffed chicken with mushrooms',
        image:'/images/games/chicken-stuffed-with-mushrooms@2x.png',
    },
    {
        id: 2,
        value: 44,
        name: 'Spaghetti with meat sauce',
        image:'/images/games/pasta-with-meat-sauce@2x.png',
    },
    {
        id: 3,
        value: 10,
        name: 'Steak salad, avocado, balsamic',
        image:'/images/games/steak-salad-avocado-balsamic@2x.png',
    },
    {
        id: 4,
        value: 13,
        name: 'Salmon, sweet potato, asparagus',
        image:'/images/games/salmon-asparagus@2x.png',
    },
    {
        id: 5,
        value: 62,
        name: 'Chicken burrito',
        image:'/images/games/chicken-burrito@2x.png',
    },
    {
        id: 6,
        value: 46,
        name: 'Chili and rice',
        image:'/images/games/chili-rice@2x.png',
    },
])

function handleDone() {
    done.value = true
    scrollTo()
}

function clickDone() {
    useDoneGame(route, router).done()
}

function scrollTo() {
    document.querySelector('.continue-button').scrollIntoView({ behavior: 'smooth' })
}
</script>
