export default {
    data() {
        return {}
    },
    methods: {
        checkPasswordComplicated(rule, value, callback) {
            if (!value) {
                return callback(new Error(
                    'Password is required'
                ))
            }
            if (! /[a-z]/.test(value)) {
                return callback(new Error(
                    'Password should contain at least one lowercase letter'
                ))
            }
            if (! /[A-Z]/.test(value)) {
                return callback(new Error(
                    'Password should contain at least one uppercase letter'
                ))
            }
            if (! /\d/.test(value)) {
                return callback(new Error(
                    'Password should contain at least one number'
                ))
            }
            if (! /[!@#\$%\^\&*\)\(+=._-]/.test(value)) {
                return callback(new Error(
                    'Password should contain at least one special character'
                ))
            }
            if (value.length < 8) {
                callback(new Error(
                    'Password length must be more then 8 characters'
                ))
            }
            else {
                callback()
            }
        },
        checkPassIdentical(rule, value, callback) {
            if (!value) {
                return callback(new Error(
                    'Password confirmation is required'
                ))
            }

            if (value !== this.form.password) {
                callback(new Error('Mismatch password'))
            } else {
                callback()
            }
        },
    }
}
