<template>
    <user-intro
        :robot-messages-all="robotMessages"
        @finishUserIntro="goNext"
    />
    <header class="header">
        <div class="header__left-side">
            <div class="header__menu">
                <i class="header__menu-icon fa fa-bars" />
            </div>
        </div>
        <div class="header__center">
            <h1 class="header__text">
                Learning Modules
            </h1>
        </div>
        <div class="header__right-side">
            <div class="header__menu" />
        </div>
    </header>
    <div class="nav-menu">
        <div class="nav-menu__list">
            <div
                v-for="item in items"
                :key="item.title"
                class="nav-menu__item"
                :class="{
                    'active': item.active,
                }"
            >
                <span class="nav-menu__label">
                    <span class="nav-menu__label-icon">
                        <inline-svg
                            class="nav-menu__label-icon-i"
                            :src="item.icon"
                        />
                    </span>
                    <span class="nav-menu__label-text">
                        {{ item.title }}
                    </span>
                </span>
            </div>
        </div>
    </div>
    <div class="main-content main-content--offset-bottom">
        <div class="g-container">
            <div class="main-content__text default-text">
                Complete these <b>learning modules</b> as the first step in your
                pantry makeover. Each one just takes a couple of minutes
            </div>
            <div
                id="learning-modules"
                class="main-content__learning-modules"
            >
                <div
                    v-for="(module, index) in learning_modules"
                    :id="`module-${index + 1}`"
                    :key="index"
                    class="main-content__learning-module"
                >
                    <learning-module-item
                        :id="`learning-module-${index + 1}`"
                        :module="module"
                        :is-active="module.isActive"
                        :is-api-request="false"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import InlineSvg from 'vue-inline-svg'
import LearningModuleItem from '~/modules/learning-modules/components/LearningModuleItem'
import UserIntro from './UserIntro'
import store from '~/store'
import {useRoute, useRouter} from 'vue-router'

const router = useRouter()
const route = useRoute()

const props = defineProps({
    nextRoute: {
        type: Object,
        required: true,
    },
    robotMessages: {
        type: Array,
        default: () => {return []}
    },
    learningModuleChapters: {
        type: Array,
        default: () => {return []}
    },
})

const user = store.getters['auth/user']

const items = [
    {
        title: 'Learn',
        icon: '/images/svg-icons/education-icon.svg',
        active: true
    },
    {
        title: 'My pantry',
        icon: '/images/svg-icons/pantry-icon.svg',
        active: false
    },
    {
        title: 'My items',
        icon: '/images/svg-icons/basket-icon.svg',
        active: false
    },
]

const learning_modules = [
    {
        chapters: props.learningModuleChapters,
        completed: false,
        description: 'Explore how sugar and carbs are affecting health.',
        title: 'What\'s Eating you',
        isActive: true,
        icon_url: '/images/learning-modules/what\'s-eating-you.png',
    },
    {
        chapters: [{completed: false}],
        completed: false,
        description: 'Manipulation by marketing messages and our food agencies.',
        title: 'Trust Issue',
        icon_url: '/images/learning-modules/trust-issues.png',
    },
    {
        chapters: [{completed: false}],
        completed: false,
        description: 'Why diets don\'t work and how an ingredient approach can?',
        title: 'Diet Villain',
        icon_url: '/images/learning-modules/diet-villain.png',
    },
    {
        chapters: [{completed: false}],
        completed: false,
        description: 'Where and how sugar and carbs have permeated our food supply?',
        title: 'Ingredient to Success',
        icon_url: '/images/learning-modules/ingredients-to-success.png',
    },
]

function goNext() {
    router.push({name: props.nextRoute})
}
</script>
