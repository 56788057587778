<template>
    <el-dialog
        v-model="dialogShow"
        title="Invite your grocery decision maker"
        :show-close="false"
    >
        <div
            v-if="!showSuccess"
            class="default-content"
        >
            <p>Please help us in achieving your goals by allowing us to <strong>invite</strong> the primary grocery decision maker.</p>
            <p>When it comes to the food that makes it home for all to eat, <strong>little details</strong> about food ingredients matter a lot.</p>
            <p>By providing simple tools and knowledge to those making purchase decisions, your <strong>family can benefit</strong> in so many ways.</p>

            <el-form
                ref="formEmail"
                :model="form"
                :rules="rules"
                hide-required-asterisk
                @submit.prevent
            >
                <el-form-item
                    prop="email"
                    :rules="[{
                        required: true,
                        message: 'Please input email address',
                        trigger: 'blur',
                    },{
                        type: 'email',
                        message: 'Please input correct email address',
                        trigger: ['blur', 'change'],
                    }]"
                    :error="formErrors.get('email')"
                >
                    <el-input
                        v-model="form.email"
                        placeholder="Email"
                    />
                </el-form-item>
            </el-form>
        </div>
        <div v-else>
            <p class="default-text">
                Invitation sent!
            </p>
        </div>
        <template #footer>
            <span class="dialog-footer">
                <el-button
                    v-if="!showSuccess"
                    :disabled="loading"
                    :loading="loading"
                    type=""
                    text
                    class="el-button--transparent"
                    @click="onCancel"
                >
                    Cancel
                </el-button>
                <el-button
                    v-if="!showSuccess"
                    :disabled="!is_valid_email||loading"
                    :loading="loading"
                    text
                    class="el-button--transparent"
                    @click="onInvite"
                >
                    Invite
                </el-button>
                <el-button
                    v-if="showSuccess"
                    :disabled="loading"
                    :loading="loading"
                    type=""
                    text
                    class="el-button--transparent"
                    @click="onCancel"
                >
                    Got it
                </el-button>
            </span>
        </template>
    </el-dialog>
</template>
<script>
import quizApi from '~/modules/quiz/quizApi'
import {Errors} from '~/includes/Errors'

export default {
    name: 'InviteDialog',
    props: {
        userInvite: {
            type: Object,
            default: () => ({}),
        },
        dialogShow: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            form: {
                email: '',
            },
            rules: {
                email: [
                    { required: true, message: 'Please input email address', trigger: ['blur', 'change'] },
                    { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change']}
                ]
            },
            loading: false,
            formErrors: new Errors(),
            is_valid_email: false,
            showSuccess: false,
        }
    },
    watch: {
        'dialogShow'(val) {
            if (val) {
                this.form.email = this.userInvite && this.userInvite.email
                if ( this.form.email ) {
                    this.is_valid_email = true
                }
            }
        },
        'form.email'() {
            this.$refs.formEmail&&this.$refs.formEmail.validate().then(valid => {
                this.is_valid_email = valid
            }).catch(e => {
                this.is_valid_email = false
            })
        }
    },
    methods: {
        async onInvite() {
            this.loading = true
            await quizApi.setFDM({email: this.form.email, reinvite: true}).then(r => {
                this.showSuccess = true
            }).catch(errors => {
                this.formErrors.record(errors.response.data.errors)
            })
            this.loading = false
        },
        onCancel() {
            this.$emit('close')
        },
    }
}
</script>
