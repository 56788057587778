<template>
    <div class="main-content main-content--offset-bottom">
        <div class="g-container">
            <SwitchSections />
            <RateProductList
                :title="'Not Rated'"
                :products="rated.notRated"
            />
            <RateProductList
                :title="'Liked'"
                :products="rated.liked"
            />
            <RateProductList
                :title="'Disliked'"
                :products="rated.disliked"
                dislike-section-decor
            />
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore, mapMutations, mapGetters, mapActions } from 'vuex'
import axios from 'axios'
import store from '~/store'
import rateProductBlock from './rate-product-block'
import SwitchSections from './switch-sections'
import RateProductList from './RateProductList'
import bus from '~/includes/Event'

const notRated = ref([])
const Liked = ref([])
const Disliked = ref([])

const activeShop = ref(null)

const user = computed(() => store.getters['auth/user'])

// const shop = computed(() => {
//     const shop = store.getters['my-items/shop']
//     return shop
// })
const rated = computed(() => store.getters['my-items/rated'])
const shop = computed(() => store.getters['my-items/shop'])
const activeTabRouteName = ref('my-items.shop')

onMounted(() => bus.$emit('set-title', 'My Items'))

getShopItems()

function getShopItems() {
    user.value.stores&&user.value.stores.forEach((store) => {
        if (store.is_primary) {
            activeShop.value = store
        }
    })
    if (activeShop.value) {
        store.dispatch('my-items/fetchShop', activeShop.value.uid)
    }
    bus.$emit('set-navbar-active-tab', activeTabRouteName.value)
}

function isLiked(product) {
    return shop.value.rated&&shop.value.rated.liked.find(item => item === product.gtinFormats.gtin14)
}

function isDisliked(product) {
    return shop.value.rated&&shop.value.rated.disliked.find(item => item === product.gtinFormats.gtin14)
}
</script>
