import { ref, onMounted, onBeforeUnmount } from 'vue';

export function useInternetConnection() {
  const isOnline = ref(navigator.onLine);

  function handleConnectionChange() {
    isOnline.value = navigator.onLine;
  }

  onMounted(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('online', handleConnectionChange);
    window.removeEventListener('offline', handleConnectionChange);
  });

  return { isOnline };
}
