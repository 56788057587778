<template>
    <div class="main-content main-content--offset-bottom">
        <div class="g-container">
            <div class="progression-container">
                <div class="jumbotron">
<!--                    <p>You’ve earned stars that will be converted to VersaCorp rewards.</p>-->
                    <p>Great Job on earning your stars!</p>
                    <div class="star-count__star">
                        {{ user.points }}
                    </div>
                    <div class="star-count__star-icon">
                        <i class="fa-solid fa-star" />
                    </div>
                </div>
                <img
                    src="/images/robots/robot_hands_up.svg"
                    class="progression-robot"
                    alt="saavy hands up"
                >
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted} from 'vue'
import bus from '~/includes/Event'
import store from '~/store'

onMounted(() => { bus.$emit('set-title', 'Points & Progression')})

const user = store.getters['auth/user']
</script>

<style lang="scss" scoped>
.progression-container {
    display: flex;
    .jumbotron {
        width: 50%;
        background: #eee;
        padding: 10px;
        margin-right: 10px;
        border-radius: 10px;
    }
    .progression-robot {
        width: 40%;
    }
}
</style>
