<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="default-text default-text--offset-bottom default-text--bold">
                    Drink vs Sugar Match
                </div>
                <div class="default-text default-text--offset-bottom">
                    Drag the drink on to the correct number of teaspoons of sugar per serving.
                </div>
                <MatchTwoColumns
                    :products="products"
                    :spoons="[3,8,0,10]"
                    @done="handleDone"
                />
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <RobotDialog
                    v-if="showRobot"
                    :messages="[`Drink water instead of sugary beverages to move toward your improved ${userAspiration || ''}`]"
                    robot-image="/images/robots/robot_hands_up.svg"
                    centered
                    black-message-color
                    :show-bricks-animation="false"
                    :timeout-value="300"
                    list-message-centered
                />
                <div class="main-content__button-wrap">
                    <el-button
                        :disabled="!done"
                        type="primary"
                        round
                        class="continue-button"
                        @click="clickDone"
                    >
                        Done
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed} from 'vue'
import MatchTwoColumns from './MatchTwoColumns'
import RobotDialog from '~/modules/quiz/components/RobotDialog'
import store from '~/store'
import {useDoneGame} from '~/modules/games/composables/done'
import {useRoute, useRouter} from 'vue-router'

const router = useRouter()
const route = useRoute()

const done = ref(false)
const showRobot = ref(false)
const userAspiration = computed(() => store.getters['auth/user'].aspiration)

const products = ref([
    {
        id: 1,
        value: 10,
        name: 'Cola',
        image: '/images/games/cola.png',
    },
    {
        id: 2,
        value: 3,
        name: 'Milk',
        image: '/images/games/milk.png',
    },
    {
        id: 3,
        value: 8,
        name: 'Juice',
        image: '/images/games/juice.png',
    },
    {
        id: 4,
        value: 0,
        name: 'Water',
        image: '/images/games/water.png',
    },
])

function handleDone() {
    done.value = true
    showRobot.value = true
    scrollTo()
}

function clickDone() {
    useDoneGame(route, router).done()
}

function scrollTo() {
    document.querySelector('.continue-button').scrollIntoView({ behavior: 'smooth' })
}
</script>
