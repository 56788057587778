<template>
    <NotFoundSection
        :title="title"
        :text="text"
    />
</template>

<script setup>
import NotFoundSection from '~/base/components/not-found-section/NotFoundSection'

const name = 'NotFound'
const title = 'Oops!'
const text = 'Something went wrong.'
</script>
