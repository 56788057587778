<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="default-text default-text--offset-bottom default-text--bold">
                    Guess How Much Sugar?
                </div>
                <div
                    v-if="roundNumber === 1"
                    class="default-text default-text--offset-bottom"
                >
                    Have your child play this game to help them learn about the relationship between fruit, juice and sugar in the common things they drink.
                </div>
                <div class="default-text default-text--offset-bottom">
                    Drag the <b>fruit</b> and <b>juice</b> on to the correct number of teaspoons of sugar per serving.
                </div>
                <MatchTwoColumns
                    :key="roundNumber"
                    :products="products"
                    :spoons="spoons"
                    class="math-section--offset-bottom"
                    @done="handleDone(roundNumber)"
                />
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__button-wrap">
                    <template v-if="roundNumber === data.length">
                        <el-button
                            :disabled="!done"
                            type="primary"
                            round
                            class="continue-button"
                            @click="clickDone"
                        >
                            Done
                        </el-button>
                    </template>
                    <template v-else>
                        <el-button
                            :disabled="!done"
                            type="primary"
                            round
                            class="continue-button"
                            @click="nextRound"
                        >
                            Next {{ round }} of {{ data.length }}
                        </el-button>
                    </template>
                </div>
            </div>
        </div>
        <fullscreen-robot-message
            v-if="showRobot"
            :messages="robotMessage"
            :robot-image="'/images/robots/robot_hand_up.svg'"
        />
    </div>
</template>

<script setup>
import {ref, computed} from 'vue'
import MatchTwoColumns from './MatchTwoColumns'
import {useRoute, useRouter} from 'vue-router'
import pmoApi from '~/modules/pmo/pmoApi'
import FullscreenRobotMessage from '~/base/components/fullscreen-robot-message/FullscreenRobotMessage'
import userApi from '~/modules/profile/userApi'

const router = useRouter()
const route = useRoute()
const done = ref(false)
const round = ref(1)
const data = ref([
    {
        products: [
            {
                id: 1,
                value: 4,
                name: 'Apple',
                image: '/images/games/apple.png',
            },
            {
                id: 2,
                value: 7,
                name: 'Apple juice',
                image: '/images/games/apple-juice.png',
            },
        ],
        spoons: [8,2,7,4]
    },
    {
        products: [
            {
                id: 3,
                value: 3,
                name: 'Orange',
                image: '/images/games/orange.png',
            },
            {
                id: 4,
                value: 5,
                name: 'Orange juice',
                image: '/images/games/orange-juice.png',
            },
        ],
        spoons: [3,1,5,4]
    },
    {
        products: [
            {
                id: 5,
                value: 6,
                name: 'Grape',
                image: '/images/games/grapes.png',
            },
            {
                id: 6,
                value: 8,
                name: 'Grape juice',
                image: '/images/games/grape-juice.png',
            },
        ],
        spoons: [4,2,6,8]
    },
])
const roundNumber = computed(() => round.value)
const products = computed(() => getProducts(round.value))
const spoons = computed(() => getSpoons(round.value))
const showRobot = ref(false)
const robotMessage = ref(['Saucy says "grab an orange and let\'s play orange monster drool."'])

function handleDone() {
    done.value = true
    scrollTo()
}

function clickDone() {
    pmoApi.gameFinished(route.name.split('.')[1])
    userApi.setFirstExperience('pmo_sub_' + route.name.split('.')[1] + '_finished')
    showRobot.value = true
    setTimeout(() => {
        // router.push({name: 'pmo'})
        router.push({name: 'pmo.welcome-game', params: {game_slug: "liquid-candy"}})
        
    }, 7000)
}

function scrollTo() {
    document.querySelector('.continue-button').scrollIntoView({ behavior: 'smooth' })
}

function getProducts(round) {
    return data.value[round - 1].products
}

function getSpoons(round) {
    return data.value[round - 1].spoons
}

function nextRound() {
    round.value = round.value + 1
    done.value = false
    document.querySelector('.main-content--base-layout').scrollIntoView({ behavior: 'smooth' })
}
</script>
