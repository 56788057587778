import axios from 'axios'
import store from '~/store'

const API_ENDPOINT = 'coach'

export default {
    getTimeSlots(date, coachId) {
        let params = {
            date: date,
            coach_id: coachId
        }
        return axios.get(API_ENDPOINT + '/get-time-slots', {params: params})
    },

    getTimeSlotsByMonth(params) {
        return axios.get(API_ENDPOINT + '/get-time-slots-by-month', {params: params})
    },

    getCoaches() {
        return axios.get(API_ENDPOINT + '/get-coaches')
    },

    saveAppointment(data) {
        return axios.post(API_ENDPOINT + '/add-appointment', data)
    },
}
