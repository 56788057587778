<template>
    <el-dialog
        v-model="showDialog"
        center
        :before-close="handleClose"
    >
        <div class="pmo-summary-popup-dialog">
            <h1>The amount you are currently consuming <span class="underline">per week</span>:</h1>
            
            <p>
                <span class="bold-underline">Sugar:</span> {{ total_sugar }} grams equivalent to 
                <span class="red bold">{{ getTeaspoons(total_sugar) }}</span>
            </p>
            <p>
                <span class="bold-underline">Net Carbs:</span> {{ total_net_carbs }} grams equivalent to 
                <span class="red bold">{{ getTeaspoons(total_net_carbs) }}</span>
            </p>
        </div>
    </el-dialog>

</template>

<script setup>

import {onMounted, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import userApi from '~/modules/profile/userApi'
import UserMeasurementUnitsConverter from '~/plugins/UserMeasurementUnitsConverter.js'

const props = defineProps({
    prods_first: {
        type: Array,
        required: true,
    },
    prods_other: {
        type: Array,
        required: true,
    },
    total_sugar: {
        type: Number,
        required: true,
    },
    total_net_carbs: {
        type: Number,
        required: true,
    },
    total_reduced_sugar: {
        type: Number,
        required: true,
    },
    total_reduced_net_carbs: {
        type: Number,
        required: true,
    },
    dialogVisible: {
        type: Boolean,
        required: true,
    },
})

const showDialog = ref(false)

onMounted(() => {
    showDialog.value = props.dialogVisible && !userApi.hasFirstExperience('pmo-summary-popup-dialog')
})

function getTeaspoons(grams) {
    let teaspoons = new UserMeasurementUnitsConverter().convertGramsToTeaspoons(grams)
    return Math.round(teaspoons) + ' teaspoon' + (teaspoons > 1 ? 's' : '')
}

function handleClose(done) {
    userApi.setFirstExperience('pmo-summary-popup-dialog')
    done()
}



</script>

<style lang="scss">
@import "resources/sass/_variables.scss";
.pmo-summary-popup-dialog {
    h1 {
        font-size: 22px;
        margin-bottom: 1em;
        .underline {
            text-decoration: underline;
        }
    }
    .red {
        color: $maroon;
    }
    .bold-underline {
        font-weight: bold;
        text-decoration: underline;
    }
    .bold {
        font-weight: bold;
    }
}
</style>