<template>
    <div class="we-help">
        <div class="we-help__container">
        <div class="container-holder">
            <div class="title-block">
                <h2 class="we-help__title">
                    Who is Right for SeekingSimple?
                </h2>
            </div>

            <ul class="we-help__columns">
                <li
                    v-for="item in items"
                    :key="item.id"
                    class="we-help__column"
                >
                    <h3
                        class="we-help__column-title"
                        @click="item.isActive = !item.isActive"
                    >
                        {{ item.title }}
                    </h3>
                    <div
                        class="we-help__text"
                        :class="item.isActive ? 'active' : '' "
                    >
                        <p>{{ item.text }}</p>
                    </div>
                </li>
            </ul>
        </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            items: [
                {
                    title: 'Payers',
                    text: 'A consumer behavior that has proven intractable is the desire to benefit from the time savings and convenience of processed foods. If health outcomes matter, then directly addressing processed food matters.  Even with other food as medicine initiatives in place, you simply can’t leave a household full of disease-causing ingredients and expect sustained improvements in health outcomes.',
                    isActive: false,
                },
                {
                    title: 'White-Label Partners',
                    text: 'Millions of interactions happen between different types of providers and patients suffering from chronic disease.  There are many scenarios where helping a patient improve the food they eat would be life changing, but until SeekingSimple, there was no easy way to embed or integrate a food-as-medicine solution into your existing applications or patient engagement processes.',
                    isActive: false,
                },
                {
                    title: 'Population Health Experts',
                    text: 'For populations negatively impacted by the foods they eat, finding solutions that can improve both short and long-term outcomes without significant changes in lifestyle is particularly difficult. SeekingSimple\'s Digital Pantry Makeover and associated programs can not only be easier to deploy, it\'s more cost effective and has rapid positive results, even for lower income households.',
                    isActive: false,
                },
                {
                    title: 'Grocery Ecosystem',
                    text: 'More grocery ecosystem players are participating in building the momentum behind food-as-medicine.  SeekingSimple provides a means by which they can participate in the fulfillment of Pantry Makeovers, acquiring new potential long-term customers and delivering the better products that are core to better health.',
                    isActive: false,
                },
            ],
        }
    }
}

</script>

<style>

</style>
