<template>
    <div
        v-if="quiz.length"
        class="main-content main-content--base-layout"
    >
        <div class="main-content__top-content-section">
            <div class="g-container">
                <template v-if="step == 1">
                    <quiz-block
                        :quiz="quiz[0]"
                        @answer="clickAnswer"
                    />

                    <quiz-block
                        v-if="showNextQuiz"
                        :quiz="quiz[1]"
                        @answer="clickAnswer"
                    />
                </template>

                <template v-else>
                    <quiz-block
                        :quiz="quiz[2]"
                        @answer="clickAnswer"
                    />

                    <quiz-block
                        v-if="showNextQuiz"
                        :quiz="quiz[3]"
                        @answer="clickAnswer"
                    />
                </template>
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <RobotDialog
                    v-if="showRobot"
                    :messages="['Correct!']"
                    robot-image="/images/robots/robot_hands_up.svg"
                    centered
                    black-message-color
                    :show-bricks-animation="false"
                    :timeout-value="300"
                />
                <div class="main-content__button-wrap">
                    <el-button
                        v-if="showContinue"
                        round
                        type="primary"
                        class="continue-button"
                        @click="handleContinue"
                    >
                        Continue
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore, mapMutations } from 'vuex'
import QuizBlock from './quiz-block'
import RobotDialog from '~/modules/quiz/components/RobotDialog'
import store from '~/store'
import learningModuleApi from '~/modules/learning-modules/learningModuleApi'
import bus from '~/includes/Event'
import inviteApi from '~/modules/profile/inviteApi'
import userApi from '~/modules/profile/userApi'

const user = store.getters['auth/user']

// const props = defineProps(['quiz'])
const router = useRouter()
const route = useRoute()
const emit = defineEmits(['clickContinue:answer'])
// const store = useStore()

const shortcutQuiz = mapMutations('shortcut_quiz', ['showSuccessRobotDialog', 'showFailRobotDialog'])

const showNextQuiz = ref(false)
const showContinue = ref(false)
const showRobot = ref(false)
const quizAnswer = ref(null)

// const quiz_id = ref(null)
const step = ref(1)

const quiz = ref([])

onMounted( () => {
    getQuiz()
    bus.$emit('set-title', 'Learning Modules')
})

function clickAnswer(answer) {
    showRobot.value = (answer === 'correct')
    quizAnswer.value = answer
    setTimeout(() => {
        document.querySelector('.main-content__bottom-content-section').scrollIntoView({ behavior: 'smooth' })
    }, 900)
    if (step.value == 2 ) {
        if (answer === 'correct' || (showNextQuiz.value)) {
            showContinue.value = true
            return false
        }
    }

    if (answer !== 'correct') {
        if (showNextQuiz.value) { //this is the second step, should force to watch module video
            showContinue.value = true
        } else { //this is the first step, give the second chance
            // showNextQuiz.value = true
            setTimeout(() => {
                showNextQuiz.value = true
            }, 3000) // delay 3s to show the next quiz
        }

    } else {
        showContinue.value = true
    }
}

async function handleContinue() {
    // check step, if step == 1 show step 2
    // if current step == 2  saves results and return back to module page

    if (step.value == 1) {
        step.value++

        showNextQuiz.value = false
        showContinue.value = false
        showRobot.value = false

    } else {
        // save quiz results for module

        if (quizAnswer.value === 'correct') {
            await completeModule()
            if (!userApi.hasFirstExperience('completed_all_lm')) {
                // store.commit('shortcut_quiz/showSuccessRobotDialog') // Hide according to task #359
            }
        } else {
            store.commit('shortcut_quiz/showFailRobotDialog')
        }
        await router.push({name: 'learning-modules'})
    }
}

function getQuiz() {
    learningModuleApi.fetchQuiz(route.params.quiz_id).then(response => {
        if (response.data ) {
            quiz.value = response.data
        }
    })
}

function completeModule() {
    learningModuleApi.quizComplete(route.params.quiz_id)
    return learningModuleApi.completeModule(route.params.quiz_id).then(async response => {
        if (response.data.is_last) {
            await userApi.setFirstExperience('completed_all_lm')
        }
    })
}
</script>
