<template>
    <div class="user-visited-list">
        <div class="user-visited-list__title">
            {{ listTitle }}
        </div>
        <div class="user-visited-list__list">
            <template
                v-for="item in list"
                :key="item.name"
            >
                <div
                    v-if="item.show"
                    class="user-visited-list__item"
                >
                    <div class="user-visited-list__item-name">
                        {{ item.name }}
                    </div>
                    <div
                        v-if="item.finished"
                        class="user-visited-list__item-icon"
                    >
                        <i class="user-visited-list__item-icon-check fa-solid fa-check" />
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import {PMO_STEP} from '~/modules/pmo/routes'
import {MY_ITEM_STEP} from '~/router'
import store from '~/store'
import {LEARNING_MODULE_STEP} from '~/modules/learning-modules/routes'
import {WIZARD_STEP} from '~/modules/quiz/routes'

const user = store.getters['auth/user']
const props = defineProps({
    userData: {
        type: Object,
        default: () => {
            return {}
        }
    },
})
const listTitle = ref('Visited:')
const registerFinished = ref(false)
const profileFinished = ref(false)
const learnFinished = ref(false)
const pmoFinished = ref(false)
const myItemsFinished = ref(false)
const list = ref([
    {
        name: 'Registered',
        finished: registerFinished,
        show: true,
    },
    {
        name: 'Profile builder',
        finished: profileFinished,
        show: true,
    },
    {
        name: 'Learn',
        finished: learnFinished,
        show: true,
    },
    {
        name: 'My Pantry',
        finished: pmoFinished,
        show: props.userData.child_user.is_fdm,
    },
    {
        name: 'My Items',
        finished: myItemsFinished,
        show: true,
    },
])

getFinishedStatus(props.userData.child_user.last_step)

function getFinishedStatus (lastStep) {
    if (lastStep) {
        registerFinished.value = true
    }
    if (lastStep.level > WIZARD_STEP) {
        profileFinished.value = true
    }
    if (lastStep.level > LEARNING_MODULE_STEP) {
        learnFinished.value = true
    }
    if (props.userData.child_user.is_fdm && lastStep.level > PMO_STEP) {
        pmoFinished.value = true
    }
    if (lastStep.level >= MY_ITEM_STEP || (user.scanSeeMyList && learnFinished.value)) {
        myItemsFinished.value = true
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.user-visited-list {
    $block-name: &;
    &__title {
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: 600;
    }
    &__item {
        display: flex;
        align-items: center;
        &:not(:last-child) {
            margin-bottom: 5px;
        }
        &-name {}
        &-icon {
            margin-left: 5px;
            &-check {
                color: $green;
            }
        }
    }
}
</style>
