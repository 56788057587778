import axios from 'axios'
import store from '~/store'

const API_ENDPOINT = 'pmo/categories'

export default {

    getTypes() {
        return axios.get(API_ENDPOINT + '/' + 'types')
    },

    find(slug) {
        return axios.get(API_ENDPOINT + '/' + slug)
    },

    get(slug) {
        return axios.get(API_ENDPOINT + '/get/' + slug)
    },

    getStatus(slug, upc) {
        return axios.get(`${API_ENDPOINT}/get-product-status/${slug}/${upc}`)
    },

    setProductStatus(slug, upc, status) {
        return axios.post(`${API_ENDPOINT}/set-product-status/${slug}/${upc}/${status}`)
    },

    getSummaryProductsByPantry(pantry) {
        return axios.get(API_ENDPOINT + '/summary/' + pantry + '/products')
    },

    getProductsBySubcategory(slug) {
        return axios.get(API_ENDPOINT + '/' + slug + '/products')
    },

    findByType(type) {
        return axios.get(API_ENDPOINT + '/find-by-type/' + type)
    },

    addUpcsToShoppingList(slug, payload) {
        return axios.post(API_ENDPOINT + '/' + slug + '/shopping_list/add', payload)
    },


    setAmountOfSugar(slug, upc, payload) {
        return axios.post(`${API_ENDPOINT}/${slug}/${upc}/set-amount-of-sugar`, payload)
    },

    categoryFinished(data) {
        return axios.post(API_ENDPOINT + '/category-finished', data)
    },

    gameFinished(slug) {
        return axios.post(API_ENDPOINT + '/game-finished/' + slug)
    },

    learnSubcategory(slug) {
        if (! this.isLearnedSubcategory(slug)) {
            store.commit('auth/addFirstExperience', `pmo_subcategory_${slug}_learned`)
            return axios.post(API_ENDPOINT + '/learn-subcategory/' + slug)
        } else {
            return Promise.resolve('Already set')
        }
    },

    isLearnedSubcategory(slug) {
        const user = store.getters['auth/user']
        return user.first_experience && (user.first_experience[`pmo_subcategory_${slug}_learned`] ?? false)
    },

    firstSharedPmo() {
        if (! this.isFirstShared()) {
            store.commit('auth/addFirstExperience', 'first_shared_pmo')
            return axios.post(API_ENDPOINT + '/first-shared')
        } else {
            return Promise.resolve('Already set')
        }
    },

    isFirstShared() {
        const user = store.getters['auth/user']
        return user.first_experience && (user.first_experience.first_shared_pmo ?? false)
    },

    productPmoCategoriesByGtin(gtin14, searchType) {
        let params = {
            gtin14: gtin14,
            searchType: searchType
        }
        return axios.get(API_ENDPOINT + '/product-pmo-categories-by-gtin', {params: params})
    }
}
