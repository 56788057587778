<template>
    <div
        v-loading="loading"
        class="main-content main-content--base-layout search-product"
    >
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div
                    v-if="product"
                    class="search-product__product"
                >
                    <ProductBlock :product="product" />
                    <div
                        v-if="outOfStore"
                        class="search-product__product-info"
                    >
                        Please note that this product is currently not sold in your selected store.
                    </div>
                </div>
                <div
                    v-if="productNotFound"
                    class="search-product__product"
                >
                    <div class="search-product__product-info">
                        <span class="warning">Warning!</span> Product not found.
                    </div>
                </div>
                <div
                    class="search-product__alternatives-info"
                >
                    <div
                        v-if="product"
                        class="search-product__alternatives-title"
                    >
                        <span class="search-product__alternatives-title-value">
                            <span
                                v-if="product.healthier && product.great_choice"
                                class="search-product-warning"
                            >
                                Great Choice!
                            </span>
                        </span>
                    </div>
                    <div class="search-product__alternatives-button">
                        <ReportBadData
                            v-if="product && !productAmountData.length"
                            :product="product"
                        >
                            Wrong Item?
                        </ReportBadData>
                    </div>
                </div>
                <template v-if="isEdit && productAmountData.length">
                    <ChoiceOriginalFrequency
                        v-model:frequency="frequency"
                        v-model:frequency-unit="frequencyUnit"
                        :max="maxFrequency"
                        title="How frequently do you consume this?"
                    />
                    <ChoiceOriginalAmount
                        v-model:amount="amount"
                        v-model:amount-unit="amountUnit"
                        :max="maxAmount"
                        title="How much of this do you consume each time?"
                    />
                    <el-button
                        :disabled="!isValidFrequency || !isValidAmount"
                        round
                        type="primary"
                        class="w-100"
                        @click="saveProduct"
                    >
                        Next
                    </el-button>
                </template>
                <template v-else-if="productAmountData.length">
                    <template v-if="!showedFrequencies">
                        <template
                            v-for="(item, i) in productAmountData"
                            :key="i"
                        >
                            <ChoiceOriginalFrequency
                                v-model:frequency="item.frequency"
                                v-model:frequency-unit="item.frequencyUnit"
                                :max="maxFrequency"
                                title="Original amount"
                                disabled
                            />
                        </template>
                        <ChoiceOriginalFrequency
                            v-model:frequency="frequency"
                            v-model:frequency-unit="frequencyUnit"
                            :max="maxFrequency"
                            :default-frequency-unit="productAmountData[0].frequencyUnit"
                            title="New amount"
                        />
                        <el-button
                            :disabled="!isValidFrequency"
                            round
                            type="primary"
                            class="w-100"
                            @click="showedFrequencies = true"
                        >
                            Next
                        </el-button>
                    </template>
                    <template v-else>
                        <template
                            v-for="(item, j) in productAmountData"
                            :key="j"
                        >
                            <ChoiceOriginalAmount
                                v-model:amount="item.amount"
                                v-model:amount-unit="item.amountUnit"
                                :max="maxAmount"
                                title="Your Original Serving"
                                disabled
                            />
                        </template>
                        <ChoiceOriginalAmount
                            v-model:amount="amount"
                            v-model:amount-unit="amountUnit"
                            :max="maxAmount"
                            title="Your New Serving"
                        />
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <el-button
                                    round
                                    type="primary"
                                    class="w-100"
                                    @click="showedFrequencies = false"
                                >
                                    Back
                                </el-button>
                            </el-col>
                            <el-col :span="12">
                                <el-button
                                    :disabled="!isValidAmount || !reducedSugar"
                                    round
                                    type="primary"
                                    class="w-100"
                                    @click="saveProduct"
                                >
                                    Next
                                </el-button>
                            </el-col>
                        </el-row>
                        <div
                            v-if="! reducedSugar"
                            style="padding-top: 20px"
                        >
                            Please change frequency or amount to reduce the product consumption
                        </div>
                    </template>
                </template>
                <template v-else>
                    <ChoiceOriginalFrequency
                        v-model:frequency="frequency"
                        v-model:frequency-unit="frequencyUnit"
                        :max="maxFrequency"
                        title="How frequently do you consume this?"
                    />
                    <ChoiceOriginalAmount
                        v-model:amount="amount"
                        v-model:amount-unit="amountUnit"
                        :max="maxAmount"
                        title="How much of this do you consume each time?"
                    />
                    <el-button
                        :disabled="!isValidFrequency || !isValidAmount"
                        round
                        type="primary"
                        class="w-100"
                        @click="saveProduct"
                    >
                        Next
                    </el-button>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref, computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import ProductBlock from './ProductBlock'
import ChoiceOriginalFrequency from './ChoiceOriginalFrequency'
import ChoiceOriginalAmount from './ChoiceOriginalAmount'
import ReportBadData from './ReportBadData'
import labelinsightApi from '~/modules/labelinsight/labelinsightApi'
import pmoApi from '~/modules/pmo/pmoApi'
import FrequencyConverter from '~/plugins/FrequencyConverter'
import MeasurementUnitsConverter from '~/plugins/MeasurementUnitsConverter'
import userApi from '~/modules/profile/userApi'
import store from '~/store'
import UserMeasurementUnitsConverter from '~/plugins/UserMeasurementUnitsConverter'

const route = useRoute()
const router = useRouter()

const product = ref(null)
const loading = ref(true)
const outOfStore = ref(false)
const category = ref(null)
const productNotFound = ref(false)

const frequency = ref(0)
const frequencyUnit = ref('')
const isEdit = route?.query?.edit

const amount = ref(0)
const amountUnit = ref('oz')

const productAmountData = ref([])

const showedFrequencies = ref(false)

const maxAmountInGrams = 1000
const amountLimits = {
    g: maxAmountInGrams,
    ml: maxAmountInGrams,
    oz: _.round(MeasurementUnitsConverter.toOunces(maxAmountInGrams, 'g')),
    'fl oz': _.round(MeasurementUnitsConverter.gramToFlOz(maxAmountInGrams)),
}

const maxAmount = computed(() => {
    if (productAmountData.value.length === 1 && !isEdit) {
        const maxAmountForPreviousUnit = maxFrequency.value * productAmountData.value[0].amount / frequency.value
        return Math.round(maxAmountForPreviousUnit / amountLimits[productAmountData.value[0].amountUnit] * amountLimits[amountUnit.value])
    } else {
        return amountLimits[amountUnit.value]
    }
})

const isValidAmount = computed(() => amount.value > 0 && amount.value <= maxAmount.value)

const frequencyLimits = {
    Day: 16,
    Week: 16,
    Month: 12,
    Year: 24,
}

const periodComponents ={
    Day: {
        Day: 1,
        Week: 7,
        Month: 30,
        Year: 365,
    },
    Week: {
        Day: 7,
        Week: 1,
        Month: 4,
        Year: 36,
    },
    Month: {
        Day: 30,
        Week: 4,
        Month: 1,
        Year: 12,
    },
    Year: {
        Day: 365,
        Week: 36,
        Month: 12,
        Year: 1,
    },
}

const maxFrequency = computed(() => {
    if (productAmountData.value.length === 1 && !isEdit) {
        return productAmountData.value[0].frequency * periodComponents[productAmountData.value[0].frequencyUnit][frequencyUnit.value]
    } else {
        return frequencyLimits[frequencyUnit.value]
    }
})

const isValidFrequency = computed(() => frequency.value > 0 && frequency.value <= maxFrequency.value)

onMounted(async () => {
    loading.value = true

    Promise.all([
        fetchProductData(),
        fetchProductAmount(),
        fetchSubCategory(),
    ]).finally(() => {
        if (productAmountData.value.length) {
            frequencyUnit.value = productAmountData.value[0].frequencyUnit
        } else if (productAmountData.value.frequencyUnit) {
            frequencyUnit.value = productAmountData.value.frequencyUnit
        } else {
            frequencyUnit.value = 'Day'
        }
        loading.value = false
    })
})

const fetchSubCategory = async () => {
    const {data} = await pmoApi.get(route.params.pmo_category)
    category.value = data
}

const fetchProductAmount = async () => {
    const {data} = await labelinsightApi.getProductAmount(route.params.upc, route.params.pmo_category)
    productAmountData.value = data

    if(isEdit) {
        amount.value = data[0].amount
        amountUnit.value = data[0].amountUnit
        frequency.value = data[0].frequency
        frequencyUnit.value = data[0].frequencyUnit
    }
    if (data.length) {
        if (! userApi.hasFirstExperience('first-reduced')) {
            store.commit('global-robot/addMessage', {
                message: 'To help lower unhealthy sugars and carbs, reducing how <strong>often</strong> or how <strong>much</strong> of this product you consume is our recommendation.',
                image: '/images/robots/robot_search.svg'
            })
            await userApi.setFirstExperience('first-reduced')
        }
    }
}

const fetchProductData = async () => {
    const {data} = await labelinsightApi.getProductByUpc(route.params.upc, {pmo_category: route.params.pmo_category})
    if (!data.product) {
        productNotFound.value = true
        return
    }
    product.value = data.product
    outOfStore.value = data.out_of_store
    const uom = new UserMeasurementUnitsConverter().getUnits(data.product.lis_data?.totalWeightUom)
    if (_.keys(amountLimits).includes(uom)) {
        amountUnit.value = uom
    }
}

const onNext = () => {
    if (productAmountData.value.length && !showedFrequencies.value) {
        showedFrequencies.value = true
    }

    saveProduct()
}

const saveProduct = async () => {
    if (! reducedSugar.value ) return

    await pmoApi.setAmountOfSugar(category.value.slug, product.value.gtinFormats.gtin14 ,{
        is_edit: isEdit ? 1 : 0,
        amount_values: [{
            frequency: frequency.value,
            frequencyUnit: frequencyUnit.value,
            amount: amount.value,
            amountUnit: amountUnit.value
        }]
    })
    localStorage.setItem('pmo_edit_products', category.value.slug)

    if (route.query.goto === 'summary') {
        await router.push({ name: 'pmo.summary', params: {pantry: category.value.type} })
    } else {
        await router.push({name: 'pmo.show', params: {pmo_category: category.value.slug}})
    }
}

const reducedSugar = computed(() => {
    if (!isValidAmount.value || !isValidFrequency.value) return -1
    if (productAmountData.value.length === 0 || isEdit) return -1

    const old_data = _.last(productAmountData.value)
    const new_gram_per_week = FrequencyConverter.timesPerWeek(frequency.value, frequencyUnit.value) * MeasurementUnitsConverter.toGramms(parseFloat(amount.value), amountUnit.value)
    const old_gram_per_week = FrequencyConverter.timesPerWeek(old_data.frequency, old_data.frequencyUnit) * MeasurementUnitsConverter.toGramms(parseFloat(old_data.amount), old_data.amountUnit)

    return new_gram_per_week - old_gram_per_week < 0
})
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.search-product {
    $block-name: &;
    $local-light-gray: #f0f0f0;
    background-color: $local-light-gray;
    &__product {
        margin-bottom: 25px;
    }
    &__alternatives, &__selected-alternatives {
        margin-bottom: 20px;
        &-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            border-bottom: 1px solid $light-gray;
            padding-bottom: 10px;
        }
        &-title {
            flex-grow: 1;
            padding-right: 10px;
            &-value {
                font-weight: 600;
                color: $black;
            }
            &-info {
                cursor: pointer;
                &:hover {
                    transition: 0.2s color ease-in-out;
                    color: $indigo;
                }
            }
        }
        &-button {
            flex-shrink: 0;
        }
        &-products {
            border-top: 1px solid $light-gray;
            padding-top: 15px;
        }
    }
    &__excluded {
        .product-block {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                z-index: 5;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background-image: url("/images/cross.png");
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                width: 170px;
                height: 170px;
            }
        }
    }
    &__buttons {
        padding: 20px 0;
        @media all and (min-width: $xs) {
            display: flex;
            margin: 0 -10px;
        }
        &-col {
            &:not(:last-child) {
                @media all and (max-width: $xs - 1) {
                    margin-bottom: 10px;
                }
            }
            @media all and (min-width: $xs) {
                width: 50%;
                padding: 0 10px;
            }
        }
    }
    .el-checkbox {
        height: unset;
        display: block;
        &-group {
            display: flex;
        }
    }
    .search-product-warning {
        font-weight: 600;
    }
}
</style>
