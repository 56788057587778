<template>
    <section class="main-content main-content--base-layout">
        <div v-if="!user.is_fdm && (!user.stores || user.stores.length < 1)">
            <div class="text-center">
                No store is selected by FDM yet
            </div>
        </div>
        <div v-else>
            <div class="main-content__top-content-section">
                <div class="g-container">
                    <store-selector
                        v-if="showStores"
                        v-loading="loading"
                        :top-text="topText"
                        :show-primary-dialog="isShowPrimaryDialog"
                        auto-handle-search
                        @selectStores="getSelectedStores"
                        @handlePrimaryDialog="selectPrimary"
                        @handleCancel="handleCancel"
                        @selectPrimaryStore="selectPrimaryStore"
                    />
                    <div
                        v-if="showErrorMessage"
                        class="error-message"
                    >
                        <p class="el-form-item__error error-message__text">
                            Please select at least one store
                        </p>
                    </div>
                    <div
                        v-if="showSuccessMessage"
                        class="default-text"
                    >
                        {{ successMessage }}
                    </div>
                </div>
            </div>
            <div
                class="main-content__bottom-content-section"
                v-if="user.is_fdm"
            >
                <div class="g-container">
                    <div class="main-content__buttons-wrap">
                        <div class="main-content__button-col">
                            <el-button
                                type="primary"
                                round
                                class="continue-button"
                                @click="onSubmit"
                            >
                                Save Profile
                            </el-button>
                        </div>
                        <div class="main-content__button-col">
                            <el-button
                                type=""
                                round
                                class="continue-button"
                                @click="onCancel"
                            >
                                Cancel
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>

<script setup>
import {ref, computed, onMounted, nextTick} from 'vue'
import storeApi from '~/modules/stores/storeApi'
import router from '~/router'
import bus from '~/includes/Event'
import storeVuex from '~/store'
import StoreSelector from '~/base/components/store-selector/StoreSelector'

const user = computed(() => storeVuex.getters['auth/user'])
const showStores = ref(true)
const loading = ref(false)
const stores = ref([])
const selected_stores_id = ref([])
const primary_store = ref(null)
const showErrorMessage = ref(false)
const isShowPrimaryDialog = ref(false)
const topText = 'Please provide your zip code below and we will provide a list of the participating grocery stores in your area.  Please select your favorite store and we will provide your personalized recommendations from that store.'

const showSuccessMessage = ref(false)
const successMessage = ref('Default store has been saved')

const selectedStores = computed(() => {
    return stores.value.filter(o => selected_stores_id.value.includes(o._id))
})

onMounted(() => { bus.$emit('set-title', 'Local Grocery Stores') })

async function saveStores() {
    if (primary_store.value === null) {
        return
    }

    loading.value = true
    if (selected_stores_id.value.length === 1) {
        selectedStores.value[0].is_primary = true
    }
    selectedStores.value.find(o => o._id === primary_store.value).is_primary = true
    await storeApi.saveStores(selectedStores.value)
    isShowPrimaryDialog.value = false
    await storeVuex.dispatch('auth/fetchUser')
    showStores.value = false
    // await nextTick()
    showSuccessMessage.value = true
    showStores.value = true
    loading.value = false
}

function onSubmit() {
    showErrorMessage.value = false
    if (selected_stores_id.value.includes(primary_store.value)) {
        saveStores()
        return
    }

    if (!selectedStores.value.length) {
        showSuccessMessage.value = false
        showErrorMessage.value = true
        return
    }

    selectPrimary()
}

function handleCancel() {
    isShowPrimaryDialog.value = false
}

function onCancel() {
    router.back()
}

function getSelectedStores(obj) {
    isShowPrimaryDialog.value = false
    stores.value = obj.stores
    selected_stores_id.value = obj.selected_stores_id
    primary_store.value = obj.primary_store
}

function selectPrimary() {
    if (selected_stores_id.value.length > 1) {
        handlePrimaryDialog()
    } else {
        primary_store.value = selected_stores_id.value[0]
        saveStores()
    }
}

function selectPrimaryStore() {
    isShowPrimaryDialog.value = false
}

function handlePrimaryDialog() {
    isShowPrimaryDialog.value = true
}
</script>

<style lang="scss" scoped>
.error-message {
    position: relative;
}
</style>
