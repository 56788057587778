<template>
    <div class="footer">
        <div class="container-holder">
            <div class="footer__inner">
                <div class="footer__nav">
                    <ul class="footer__menu">
                        <li
                            v-for="item in menu"
                            :key="item.text"
                            class="footer__menu-item"
                        >
                            <a
                                :href="item.link"
                                class="footer__menu-link"
                                :class="{'footer__menu-link--underline' : item.underline}"
                            >
                                {{ item.text }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="footer__logo">
                    <a
                        :href="logo.link"
                        class="footer__logo-link"
                        tabindex="1"
                    >
                        <img
                            class="footer__logo-i"
                            :src="logo.imgSrc"
                            :alt="logo.altText"
                        >
                    </a>
                </div>
                <div class="footer__copyright">
                    {{ copyright }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            menu: [
                {
                    text: 'Home',
                    link: '/'
                },
                {
                    text: 'Contact Us',
                    link: '/contacts'
                },
                {
                    text: 'PRIVACY POLICY',
                    link: '/privacy-policy',
                    underline: true,
                },
                {
                    text: 'TERMS OF USE',
                    link: '/terms-of-use',
                    underline: true,
                }
            ],
            logo: {
                imgSrc: '/images/landing/logo-white.png',
                link: '/',
                altText: 'SeekingSimple'
            },
            copyright: `© ${new Date().getFullYear()} All rights reserved`
        }
    }
}
</script>
