<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="default-text default-text--offset-bottom">
                    What 3 items when added to your breakfast plate have the right/best amount of net carbs for your health?
                </div>
                <div class="default-text default-text--offset-bottom">
                    The Broke Fast game reinforces improved breakfast choices to help you child start their day in a healthy way.
                </div>
                <DragGame
                    :products="products"
                    @done="handleDone"
                />
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__button-wrap">
                    <el-button
                        :disabled="!done"
                        type="primary"
                        round
                        class="continue-button"
                        @click="clickDone"
                    >
                        Done
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref} from 'vue'
import DragGame from './DragGame'
import {provide} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import {useDoneGame} from '~/modules/games/composables/done'

const name = 'BrokeFast'
const router = useRouter()
const route = useRoute()
const done = ref(false)

provide('range', _.range(0, 22, 2))
provide('limit', 15)

const products = ref([
    {
        id: 1,
        value: 2,
        name: 'Egg, 2 slices bacon & 1/2 avocado: 2 grams net carbs',
        image:'/images/games/egg-slice-becon@2x.png',
    },
    {
        id: 2,
        value: 10,
        name: 'Eggs, salsa, cheese, corn tortilla: 10 grams net carbs',
        image:'/images/games/breakfast-burrito@2x.png',
    },
    {
        id: 3,
        value: 51,
        name: 'Blueberry muffin: 51 grams net carbs',
        image:'/images/games/muffin@2x.png',
    },
    {
        id: 4,
        value: 42,
        name: 'O\'s cereal with milk & blueberries: 42 grams net carbs',
        image:'/images/games/cereal-with-milk@2x.png',
    },
    {
        id: 5,
        value: 10,
        name: 'Chia pudding with blueberries: 10 grams net carbs',
        image:'/images/games/chia-pudding-with-blueberries@2x.png',
    },
    {
        id: 6,
        value: 25,
        name: 'Orange juice: 25 grams net carbs',
        image:'/images/games/orange-juice@2x.png',
    },
])

function handleDone() {
    done.value = true
    scrollTo()
}

function clickDone() {
    useDoneGame(route, router).done()
}

function scrollTo() {
    document.querySelector('.continue-button').scrollIntoView({ behavior: 'smooth' })
}
</script>
