<template>
    <div class="main">
        <div class="pmo-barcode-scanner">
            <div
                v-if="message"
                class="pmo-barcode-scanner__message"
                :class="{'pmo-barcode-scanner__message--hidden': showMessage }"
            >
                {{ message }}
            </div>
            <barcode-scanner
                v-loading="loading"
                @scan="detected"
            />
            <template v-if="showRobot">
                <template v-if="thinking === 'start'">
                    <div class="robot-thinking pmo-barcode-scanner__robot-message">
                        <div class="pmo-barcode-scanner__robot-text">
                            Thinking...
                        </div>
                        <div class="pmo-barcode-scanner__robot-image-wrap">
                            <img
                                class="pmo-barcode-scanner__robot-image"
                                src="/images/robots/robot_glasses.svg"
                                alt=""
                            >
                        </div>
                    </div>
                </template>
                <template v-if="thinking === 'finish'">
                    <div class="robot-thinking pmo-barcode-scanner__robot-message">
                        <div
                            class="pmo-barcode-scanner__robot-text"
                        >
                            Got it!
                        </div>
                        <div class="pmo-barcode-scanner__robot-image-wrap">
                            <img
                                class="pmo-barcode-scanner__robot-image"
                                src="/images/robots/robot_have_answer.svg"
                                alt=""
                            >
                        </div>
                    </div>
                </template>
            </template>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import BarcodeScanner from '~/base/components/BarcodeScanner'
import labelinsightApi from '~/modules/labelinsight/labelinsightApi'
import {useRoute, useRouter} from 'vue-router'

const route = useRoute()
const router = useRouter()

const UPC = ref(null)
const thinking = ref(null)
const message = ref('Scan the barcode of an item, and we’ll see if it’s a good choice.')
const showMessage = ref(false)
const loading = ref(false)

onMounted(() => {
    setTimeout(() => {
        showMessage.value = true
    }, 5000)
})

const showRobot = computed(() => !labelinsightApi.isFirstScan())

const successRoute = computed(() => {
    return route.params.pmo_category
        ? {name: 'product.frequency', params: {upc: UPC.value, pmo_category: route.params.pmo_category}}
        : {name: 'product.show', params: {upc: UPC.value, pmo_category: null}, query:route.query}
})

const failRoute = computed(() => {
    return route.params.pmo_category
        ? {name: 'pmo.product-not-found', params: {pmo_category: route.params.pmo_category, upc: UPC.value}}
        : {name: 'pmo.product-not-found'}
})

async function detected(scanResult) {
    // console.log(scanResult)
    UPC.value = scanResult.barcodes.reduce((string, barcode) => barcode.data, '')

    loading.value = true
    thinking.value = 'start'
    labelinsightApi.firstScan()

    try {
        await labelinsightApi.getProductByUpc(UPC.value, {pmo_category: route.params.pmo_category}, route.query)
        thinking.value = 'finish'

        if (showRobot.value) {
            setTimeout(() => {router.push(successRoute.value)}, 1000)
        } else {
            await router.push(successRoute.value)
        }
    } catch (errors) {
        await router.push(failRoute.value)
    }
}

</script>

<style lang="scss">
@import "resources/sass/_variables.scss";
.pmo-barcode-scanner {
    background-color: rgba(0, 0, 0, 0.7);
    &__message,
    &__robot-message {
        position: fixed;
        z-index: 2010;
        border-radius: 8px;
        overflow: hidden;
        background-color: rgba(255,255,255, 70%);
    }
    &__message {
        left: calc(50% - 170px);
        top: 20px;
        width: 340px;
        padding: 10px 15px;
        transition: 0.2s opacity ease-in-out, 0.2s visibility ease-in-out;
        &--hidden {
            opacity: 0;
            visibility: hidden;
        }
    }
    &__robot {
        &-message {
            left: calc(50% - 90px);
            bottom: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            width: 180px;
            height: 180px;
            padding: 15px;
        }
        &-text {
            display: block;
            font-size: 16px;
            font-weight: 600;
            text-align: center;
            text-overflow: ellipsis;
            color: $black;
        }
        &-image {
            max-width: 100%;
            max-height: 100%;
            &-wrap {
                width: 120px;
                height: 120px;
                overflow: hidden;
            }
        }
    }
}
</style>
