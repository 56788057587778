export default {
    timesPerWeek(frequency, measurement) {
        const to_week = {
            'Day': 7,
            'Week': 1,
            'Month': 7 / 30,
            'Year':  7 / 365,
        }

        if (! to_week[measurement]) {
            return new Error(`"${measurement}" measurement is undefined`)
        }

        if ( typeof frequency !== 'number' ) {
            return new Error(`"${frequency}" is not numeric`)
        }

        return frequency * to_week[measurement]
    }
}
