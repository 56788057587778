<template>
    <div class="switch-sections">
        <div
            :class="{active: isShopActive}"
            class="switch-sections__btn"
            @click="clickShop"
        >
            Shop
        </div>
        <div
            :class="{active: isRateActive}"
            class="switch-sections__btn"
            @click="clickRate"
        >
            Rate
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const isShopActive = ref(route.name === 'my-items.shop')
const isRateActive = ref(route.name === 'my-items.rate')

function clickShop() {
    router.push({ name: 'my-items.shop'})
}

function clickRate() {
    router.push({ name: 'my-items.rate'})
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.switch-sections {
    $block-name: &;
    $local-light-gray: #f0f0f0;
    display: flex;
    align-items: center;
    &__btn {
        height: 36px;
        padding: 5px 25px;
        border: 1px solid $blue;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.3;
        color: $blue;
        background-color: $local-light-gray;
        transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s box-shadow ease-in-out;
        cursor: pointer;
        user-select: none;
        &:hover {
            box-shadow: 0 0 6px rgba(0, 0, 0, 20%);
        }
        &:first-child {
            border-radius: 18px 0 0 18px;
            padding: 6px 25px 6px 30px;
        }
        &:last-child {
            border-radius: 0 18px 18px 0;
            padding: 6px 30px 6px 25px;
        }
        &.active {
            color: $white;
            background-color: $blue;
            pointer-events: none;
        }
    }
}
</style>
