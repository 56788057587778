<template>
    <el-container class="auth-page">
        <el-main>
            <div class="auth-page__top">
                <div class="auth-page__image-wrap">
                    <div class="auth-page__image-wrap">
                        <img
                            v-if="!loginPath"
                            class="auth-page__image"
                            src="/images/auth/seekingsimple-logo.svg"
                            alt=""
                        >
                        <img
                            v-else
                            class="auth-page__image companies_logo"
                            :src="loginPath"
                            alt=""
                        >
                    </div>
                </div>
            </div>
            <el-card>
                <el-form
                    ref="forgotPasswordForm"
                    :model="form"
                    :rules="rules"
                    class="auth-form"
                    @keydown.enter.prevent="forgotPassword"
                    @submit.prevent="forgotPassword"
                >
                    <el-form-item>
                        <div class="auth-form__title">
                            Reset your password
                        </div>
                    </el-form-item>
                    <el-form-item
                        prop="email"
                        :error="errors.get('email')"
                    >
                        <label class="el-form-item__label--floating-label">
                            <el-input
                                v-model="form.email"
                                name="email"
                                type="email"
                                placeholder=" "
                                class="el-input--floating-label"
                            >
                                <template #prefix>
                                    Email
                                </template>
                            </el-input>
                        </label>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            :loading="loading"
                            round
                            type="primary"
                            class="w-100"
                            @click.prevent="forgotPassword"
                        >
                            Send Password Reset Email
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>

import {Errors} from '~/includes/Errors'
import authApi from '~/modules/auth/authApi'

export default {
    name: 'ForgotPassword',
    data() {
        return {
            loading: false,
            form: {},
            loginPath: '',
            rules: {
                email: [
                    { required:true, message: 'Please enter your email', trigger: 'blur' },
                    { type: 'email', message: 'Invalid email', trigger: 'blur' }
                ],
            },
            errors: new Errors(),
        }
    },
    created() {
        const localStorageCompanyData =  JSON.parse(localStorage.getItem('useClientLevel'))
        if (localStorageCompanyData?.logo_path) {
            this.loginPath = localStorageCompanyData.logo_path
        }
    },
    methods: {
        forgotPassword() {
            this.loading = true
            authApi.forgotPassword(this.form).then(response => {
                // this.$message.success(response.data.status)
                this.$router.push({name: 'Home'})
            }).catch(error => {
                if (error.response.data.errors)
                    this.errors.record(error.response.data.errors)
            }).finally(() => this.loading = false)
        }
    },
}
</script>
