<template>
    <div class="sugar-progress">
        <div class="sugar-progress__text">
            <div
                v-show="progress.name"
                :style="{ color: progress.color }"
                class="sugar-progress__text-value"
            >
                {{ progress.name }}
            </div>
        </div>
        <div class="sugar-progress__bar">
            <el-progress
                :show-text="false"
                :stroke-width="15"
                :percentage="progress.percent"
                :color="progress.color"
                class="el-progress--sugar-level"
                :style="`background: linear-gradient(to left, #fae9e9 ${after_limit}%, #e6e6e6 ${after_limit}%);`"
            >
                <div
                    class="el-progress__marker"
                    :style="`left: ${before_limit}%;`"
                >
                    <div class="el-progress__marker-line" />
                </div>
            </el-progress>
        </div>
        <div class="sugar-progress__scale">
            <div class="sugar-progress__scale-item-wrap">
                <div
                    v-for="(item, i) in range"
                    :key="item"
                    class="sugar-progress__scale-item"
                >
                    {{ item }}
                    <span
                        v-if="i === range.length - 1"
                        class="sugar-progress__scale-item-plus"
                    >
                        +
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, inject} from 'vue'

const name = 'SugarProgress'

const props = defineProps({
    value: {
        type: Number,
        default: 0,
    },
    title: {
        type: String,
        default: '',
    },
})

const range = inject('range')
const limit = inject('limit')

const progress = computed(() => {
    return {
        value: props.value,
        name: props.title,
        percent: props.value / _.max(range) * 100,
        color: props.value <= limit ? '#484BAF' : '#941a49',
    }
})

const before_limit = computed(() => limit / _.max(range) * 100)
const after_limit = computed(() => 100 - before_limit.value)

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.sugar-progress {
    $block-name: &;
    padding: 0 10px;
    &__text {
        margin-bottom: 5px;
        &-value {
            font-size: 15px;
            line-height: 1.2;
            @media all and (min-width: $xs){
                font-size: 18px;
            }
        }
    }
    &__bar {
        margin-bottom: 10px;
    }
    &__scale {
        position: relative;
        &-item {
            width: 100%;
            font-size: 18px;
            line-height: 1.3;
            text-align: center;
            letter-spacing: normal;
            color: $gray;
            &:last-child {
                white-space: nowrap;
            }
            &-wrap {
                display: flex;
                justify-content: normal;
                width: 110%;
                position: relative;
                margin: 0 -5%;
            }
            &-plus {
                padding-right: 7px;
                margin-left: -5px;
                @media all and (min-width: $s) {
                    padding-right: 10px;
                }
            }
        }
    }
}
</style>
