<template>
    <div class="user-intro">
        <div
            class="user-intro__inner"
            :class="{'user-intro__inner--show-bg': showBg}"
        >
            <div
                ref="robotBlock"
                class="user-intro__robot"
                :style="{left: robotPosHor, top: robotPosVer, bottom: robotPosVerBottom, opacity: robotHide}"
            >
                <div class="user-intro__robot-inner">
                    <TransitionGroup
                        name="message"
                        tag="ul"
                        mode="out-in"
                        class="user-intro__robot-message-list"
                    >
                        <template
                            v-for="(message, key) in robotMessagesAll"
                            :key="key"
                        >
                            <li
                                v-if="isVisible[key]"
                                class="user-intro__robot-message"
                            >
                                <div
                                    :id="'message_' + key"
                                    class="user-intro__message-text"
                                    v-html="message.text"
                                />
                                <div
                                    v-if="message.btnText"
                                    class="user-intro__message-button-wrap"
                                    :class="{'user-intro__message-button-wrap--center' : message.btnPosition === 'center'}"
                                >
                                    <el-button
                                        v-if="message.btnText"
                                        round
                                        bg
                                        type="info"
                                        class="user-intro__message-button"
                                        :class="{'user-intro__message-button--wide': message.btnText && !message.btnCancelText}"
                                        @click="clickCallbackBtn"
                                    >
                                        {{ message.btnText }}
                                    </el-button>
                                    <el-button
                                        v-if="message.btnCancelText"
                                        round
                                        type="info"
                                        class="user-intro__message-button"
                                        @click="clickCancelCallbackBtn"
                                    >
                                        {{ message.btnCancelText }}
                                    </el-button>
                                </div>
                            </li>
                        </template>
                    </TransitionGroup>
                    <div class="user-intro__robot-bricks">
                        <div class="user-intro__robot-brick user-intro__robot-brick--1" />
                        <div class="user-intro__robot-brick user-intro__robot-brick--2" />
                        <div class="user-intro__robot-brick user-intro__robot-brick--3" />
                        <div class="user-intro__robot-brick user-intro__robot-brick--4" />
                        <div class="user-intro__robot-brick user-intro__robot-brick--5" />
                        <div class="user-intro__robot-brick user-intro__robot-brick--6" />
                        <div class="user-intro__robot-brick user-intro__robot-brick--7" />
                    </div>
                    <div
                        class="user-intro__robot-image"
                        :style="{justifyContent: robotAlign}"
                    >
                        <img
                            ref="robotRef"
                            :key="robotImg"
                            :src="robotImg"
                            class="user-intro__robot-image-i"
                            alt=""
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import store from '~/store'
import {TIME_FOR_SHOWING_LINE_S} from "~/base/constants/points.constants";

const props = defineProps({
    robotMessagesAll: {
        type: Array,
        default: [],
    },
})

const robotMessages = ref(props.robotMessagesAll)
const robotPosHor = ref(null)
const robotPosVer = ref(null)
const robotPosVerBottom = ref(null)
const isVisible = ref([])
const currentSlide = ref(0)
const showContinueButton = ref(false)
const robotBlock = ref(null)
const robotImg = ref(null)
const robotAlign = ref(null)
const robotHide = ref(1)
const showBg = ref(null)
const robotRef = ref(null)
const emit = defineEmits()

const user = store.getters['auth/user']
const sleep = ms => new Promise(r => setTimeout(r, ms));
async function setTimer() {
    let robotTime = 800
    if (currentSlide.value > 0) {
        robotTime = await getMessageTime(currentSlide.value - 1) || 5000
    }
    await showRobot()

    setTimeout(async () => {
        await showCurrentSlide()
        const messageTime = await getMessageTime(currentSlide.value -1) || 5000
        if (currentSlide.value < props.robotMessagesAll.length) {
            setTimer()
            setTimeout(hideSlide, messageTime)
        } else {
            setTimeout(hideUserIntro, messageTime)
            showContinueButton.value = true
        }
    }, robotTime + 600)
}

onMounted( () => {
    hideBricksAnimation()
    setRobotPosition(robotMessages.value[0].robotPosition)
    document.body.classList.add('user-intro-animation')
})

isVisible.value = props.robotMessagesAll.map(() => false)
requestAnimationFrame(setTimer)


function showBricksAnimation() {
    const bricks = document.querySelectorAll('.user-intro__robot-brick');

    bricks.forEach(brick => {
        brick.style.animation = '';
    });
}

function hideBricksAnimation() {
    const bricks = document.querySelectorAll('.user-intro__robot-brick');

    bricks.forEach(brick => {
        brick.style.animation = 'none';
    });
}

async function getMessageTime(index) {
    const id = 'message_' + index
    const messageBox = document.getElementById(id);
    if(!messageBox) {
        return await new Promise(resolve => setTimeout(() => {
            resolve(getMessageTime(index))
        }, 50))
    } else {
        const lineHeight = parseInt(window.getComputedStyle(messageBox).lineHeight);
        const messageHeight = messageBox.offsetHeight;
        const numberOfLines = Math.floor(messageHeight / lineHeight);
        return (numberOfLines * TIME_FOR_SHOWING_LINE_S)
    }
}

async function showCurrentSlide() {
    setRobotPosition(robotMessages.value[currentSlide.value].robotPosition)
    robotImg.value = robotMessages.value[currentSlide.value].robotImage
    setRobotImagePosition(robotMessages.value[currentSlide.value].robotAlign)
    setRobotImageHide(robotMessages.value[currentSlide.value].robotImageHide)
    await showRobot()
    await sleep(500)
    showBricksAnimation()
    await sleep(1500)
    hideBricksAnimation()
    isVisible.value[currentSlide.value] = true
    currentSlide.value++
}

async function showRobot() {
    return await new Promise(resolve => setTimeout(() => {
        if (robotRef.value) {
            robotRef.value.style.opacity = '1';
            robotRef.value.style.visibility = 'visible';
        }
        resolve()
    }, 50))
}

function hideRobot() {
    if (robotRef.value) {
        robotRef.value.style.opacity = '0';
        robotRef.value.style.visibility = 'hidden';
    }
}

function hideSlide() {
    emit('finishAnimationScene', currentSlide)
    if (!robotMessages.value[robotMessages.value.length - 1].btnText) {
        hideRobot()
    }
    isVisible.value[currentSlide.value - 1] = false
    if(robotMessages.value[currentSlide.value - 1].bordered) {
        removeBorder(robotMessages.value[currentSlide.value - 1].robotPosition)
    }
}

function setRobotPosition(position) {
    const windowHeight = window.innerHeight
    const windowWidth = window.innerWidth
    const elem = document.querySelector(position)
    if (position === 'bottom-left-screen-corner') {
        robotPosHor.value = '15px'
        robotPosVerBottom.value = '15px'
    }
    if (position === 'bottom-right-screen-corner') {
        robotPosHor.value = `${window.innerWidth - robotBlock.value.clientWidth - 15}px`
        robotPosVerBottom.value = '15px'
    }
    if(robotMessages.value[currentSlide.value].bordered) {
        addBorder(elem)
    }
    if (!elem ) return
    const elemValues = elem.getBoundingClientRect()
    robotPosHor.value = `${elemValues.x}px`
    robotPosVer.value = `${elemValues.y + elemValues.height}px`
    robotPosVerBottom.value = null
    if (windowWidth - elemValues.x - robotBlock.value.clientWidth < 0) {
        robotPosHor.value = `${windowWidth - robotBlock.value.clientWidth}px`
    }
    if (robotMessages.value[currentSlide.value].rightAlignOnPage) {
        robotPosHor.value = `${elemValues.x + elemValues.width - robotBlock.value.clientWidth}px`
    }
    if (robotMessages.value[currentSlide.value].centerAlignOnPage) {
        robotPosHor.value = `${windowWidth/2 - robotBlock.value.clientWidth/2}px`
    }
    if (windowHeight - (elemValues.y + elemValues.height) < (robotBlock.value.clientHeight + 65)) {
        let gap = Math.abs(windowHeight - (elemValues.y + elemValues.height) - (robotBlock.value.clientHeight + 65))
        if(Math.round(gap) > 0) {
            scrollTo({
                top: gap,
                left: 0,
                behavior: 'smooth'
            })
            robotPosVer.value = null
            robotPosVerBottom.value = '0px'
        }
    }
}

function setRobotImagePosition(position) {
    const positions = {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end',
    }
    return robotAlign.value = positions[position] ?? 'flex-start'
}

function setRobotImageHide(value) {
    const imageHide = {
        hide: 0,
        undefined: 1
    }
    return robotHide.value = imageHide[value] ?? 1
}

function hideUserIntro() {
    emit('finishUserIntro')
    if (!robotMessages.value[robotMessages.value.length - 1].btnText) {
        hideRobot()
    }
    const elem = document.querySelector(robotMessages.value[robotMessages.value.length - 1].robotPosition)
    if (elem?.classList.contains('user-intro--bordered')) {
        removeBorder(robotMessages.value[robotMessages.value.length - 1].robotPosition)
    }
    document.body.classList.remove('user-intro-animation')
    cancelAnimationFrame(setTimer)
}

function addBorder(elem){
    elem.classList.add('user-intro--bordered')
}

function removeBorder(elem){
    const element = document.querySelector(elem)
    if(element) {
        element.classList.remove('user-intro--bordered')
    }
}

function clickCallbackBtn() {
    emit('callbackBtn')
}

function clickCancelCallbackBtn() {
    emit('cancelCallbackBtn')
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.user-intro {
    $block-name: &;
    position: fixed;
    z-index: 2001;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    user-select: none;
    background-color: rgba(0,0,0,0.1);
    &__inner {
        position: relative;
        width: 100%;
        height: 100%;
        @media (min-width: 1600px) {
            margin-left: calc(10vw);
        }
        &--show-bg {
            &:before {
                content: "";
                position: absolute;
                top: 112px;
                right: 0;
                left: 0;
                bottom: 0;
                background-color: $white;
            }
        }
    }
    &__robot {
        position: absolute;
        opacity: 0;
        transition: 0.45s top linear, 0.45s bottom linear, 0.45s left linear, 0.45s opacity linear;
        will-change: top, left, opacity;
        &-inner {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: flex-end;
            min-width: 325px;
            min-height: 100%;
        }
        &-message {
            width: 325px;
            border-radius: 18px;
            padding: 8px 15px;
            line-height: 1.4;
            text-align: left;
            background: $indigo;
            color: white;
            overflow: hidden;
            @media all and (min-width: $xs) {
                padding: 10px 20px;
            }
            &-list {
                display: flex;
                align-items: flex-end;
                min-height: 44px;
                transition: 0.05s min-height linear;
                will-change: min-height;
            }
            &--black {
                font-weight: 600;
                color: $dark;
                background-color: transparent;
            }
            &-button {
                color: $white;
                background-color: transparent;
                &:hover,
                &:active,
                &:focus {
                    color: $white;
                    background-color: transparent;
                }
                &-wrap {
                    text-align: right;
                }
            }
        }
        &-bricks {
            margin-top: -25px;
            position: relative;
            width: 100%;
            height: 50px;
        }
        &-brick {
            position: absolute;
            z-index: 10;
            border-radius: 2px;
            background-color: $indigo;
            &--1 {
                left: 50%;
                width: 25px;
                height: 25px;
                opacity: 0;
                transform: translateX(-50%);
                animation: bricks-fly 0.65s linear 0.65s , 0.55s grow-up 1.29s;
            }
            &--2 {
                left: 59%;
                width: 15px;
                height: 15px;
                opacity: 0;
                animation: 1s brick-fly 1s ease-in-out, 0.85s brick-2-fly 0.85s linear;
            }
            &--3 {
                left: 35%;
                width: 13px;
                height: 13px;
                opacity: 0;
                animation: 0.9s brick-fly 0.9s ease-in-out, 0.85s brick-3-fly 0.85s linear;
            }
            &--4 {
                left: 41%;
                width: 11px;
                height: 11px;
                opacity: 0;
                animation: 1.0s brick-fly 1.0s linear, 0.85s brick-4-fly 0.85s linear;
            }
            &--5 {
                left: 55%;
                width: 9px;
                height: 9px;
                opacity: 0;
                animation: 0.9s brick-fly 0.9s linear, 0.85s brick-5-fly 0.85s linear;
            }
            &--6 {
                left: 65%;
                width: 8px;
                height: 8px;
                opacity: 0;
                animation: 0.9s brick-fly 0.9s ease-in-out, 0.85s brick-5-fly 0.85s linear;

            }
            &--7 {
                left: 31%;
                width: 8px;
                height: 8px;
                opacity: 0;
                animation: 0.9s brick-fly 0.9s ease-in-out, 0.85s brick-5-fly 0.85s linear;
            }
        }
        &-image {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100px;
            overflow: hidden;
            @media all and (min-width: 390px) {
                height: 140px;
            }
            &-i {
                transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
                max-width: 100%;
                max-height: 100%;
                visibility: hidden;
                opacity: 0;
            }
        }
    }
    &__message{
        &-button {
            width: 47%;
            font-size: 18px;
            font-weight: 600;
            color: $indigo;
            background-color: $white;
            &-wrap {
                position: relative;
                z-index: 11;
                margin: 10px -20px 0;
                border-top: 1px solid $white;
                padding: 10px 20px 0;
                &--center {
                    text-align: center;
                }
            }
            &--wide {
                width: 100%;
            }
        }
    }
    @keyframes bricks-fly {
        0% {
            top: 100%;
            transform: rotate(0),translateX(-50%);
            opacity: 0
        }
        50% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            top: 0;
            opacity: 1;
            transform: rotate(180deg);
        }
    }
    @keyframes brick-fly {
        0% {
            top: 100%;
            opacity: 0;
            transform: rotate(0);
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
            box-shadow: 0 0 0 0 $indigo;
        }
        100% {
            box-shadow: 0 0 3px 3px $indigo;
            top: 0;
            opacity: 0;
        }
    }
    @keyframes brick-2-fly {
        100% {
            transform: rotate(200deg);
        }
    }
    @keyframes brick-3-fly {
        100% {
            transform: rotate(270deg);
        }
    }
    @keyframes brick-4-fly {
        100% {
            transform: rotate(300deg);
        }
    }
    @keyframes brick-5-fly {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes grow-up {
        from {
            width: 25px;
            border-radius: 5px;
            opacity: 1;
        }
        to{
            width: 325px;
            border-radius: 18px;
            opacity: 1;
        }
    }
    .message-enter-from {
        height: 0;
        opacity: 0;
        padding: 0 20px;
    }
    .message-enter-active {
        transition: 0.6s opacity ease-in, 0.5s height ease-in, 0.25s padding ease-in;
    }
    .message-enter-to {
        height: 70px;
        opacity: 1;
        padding: 10px 20px;
    }

    .message-leave-from {
        height: 0;
        opacity: 1;
    }
    .message-leave-active {
        transition: 0.50s opacity linear, 0.50s height linear;
    }
    .message-leave-to {
        height: auto;
        opacity: 0;
    }
}
</style>
