<template>
    <div class="main-content">
        <div class="g-container">
            <profile-menu :list="userLinks" />
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import ProfileMenu from './ProfileMenu'
import bus from '~/includes/Event'
import store from '~/store'

onMounted(() => bus.$emit('set-title', 'Profile'))

const user = store.getters['auth/user']

const fdmItemText = (user.is_primary ? 'Add ' : '') + 'Food Decision Maker'

const userLinks = ref([
    {
        path: 'profile.info',
        title: 'Personal Information',
        icon: '/images/svg-icons/user-gear-icon.svg'
    },
    {
        path: 'profile.reset-password',
        title: 'Reset Password',
        icon: '/images/svg-icons/key-icon.svg'
    },
    {
        path: 'profile.notification-settings',
        title: 'Notifications & Rate of Change',
        icon: '/images/svg-icons/comment-icon.svg',
    },
    {
        path: 'profile.your-household',
        title: 'Your Household',
        icon: '/images/svg-icons/household-icon.svg',
        primary: true
    },
    {
        path: 'profile.grocery-stores',
        title: 'Local Grocery Stores',
        icon: '/images/svg-icons/grocery-store-icon.svg',
    },
    {
        path: 'profile.loyalty-program',
        title: 'Loyalty Program',
        icon: '/images/svg-icons/grocery-store-icon.svg',
    },
    {
        path: 'profile.measurements-settings',
        title: 'Measurements Settings',
        icon: '/images/svg-icons/comment-icon.svg',
    },
    {
        path: 'profile.sugar-substitutes',
        title: 'Sugar Substitutes',
        icon: '/images/svg-icons/cake-icon.svg',
        fdm: true
    },
    {
        path: 'profile.fdm',
        title: fdmItemText,
        icon: '/images/svg-icons/shield-icon.svg',
    },
    {
        path: 'profile.child-users',
        title: 'Add Additional Users',
        icon: '/images/svg-icons/people-icon.svg',
        primary: true
    },
])
</script>
