<template>
    <section
        class="main-content main-content--button-bottom-fixed questions-page"
    >
        <div class="main-content__main">
            <div class="g-container">
                <div class="questions-page__question-module">
                    <question-module-header
                        :image="questionModuleImage"
                        :name="questionModuleName"
                        class="question-module--middle-size"
                    />
                </div>

                <store-selector
                    :top-text="topText"
                    :show-primary-dialog="isShowPrimaryDialog"
                    :show-robot="true"
                    :show-top-text="false"
                    @selectStores="getSelectedStores"
                    @handlePrimaryDialog="selectPrimary"
                    @handleCancel="handleCancel"
                    @selectPrimaryStore="selectPrimaryStore"
                />
            </div>
        </div>
        <!-- <div class="main-content__bottom">
            <div class="g-container">
                <div class="main-content__bottom-wrap">
                    <el-button
                        v-if="selected_stores_id.length > 0"
                        :disabled="selected_stores_id.length === 0 || loading"
                        :loading="loading"
                        type="primary"
                        round
                        class="continue-button"
                        @click="selectPrimary"
                    >
                        Continue
                    </el-button>
                </div>
            </div>
        </div> -->
    </section>
</template>

<script setup>

import {ref, computed, onMounted} from 'vue'
import storeApi from '~/modules/stores/storeApi'
import quizApi from '~/modules/quiz/quizApi'
import storeVuex from '~/store'
import router from '~/router'
import bus from '~/includes/Event'
import QuestionModuleHeader from '~/modules/quiz/components/question-module-header/QuestionModuleHeader'
import StoreSelector from '~/base/components/store-selector/StoreSelector'

const loading = ref(false)
const stores = ref([])
const selected_stores_id = ref([])
const primary_store = ref(null)
const questionModuleImage = ref('/images/quiz/household.png')
const questionModuleName = ref('About your household')
const topText = 'Please provide your zip code below and we will provide a list of the participating grocery stores in your area.  Please select your favorite store and we will provide your personalized recommendations from that store. '
const isShowPrimaryDialog = ref(false)

onMounted(() => bus.$emit('set-title', 'About your household'))

const selectedStores = computed(() => {
    return stores.value.filter(o => selected_stores_id.value.includes(o._id))
})

async function saveStores() {
    if (primary_store.value === null) {
        return
    }
    loading.value = true
    if (selected_stores_id.value.length === 1) {
        selectedStores.value[0].is_primary = true
    }
    selectedStores.value.find(o => o._id === primary_store.value).is_primary = true
    await storeApi.saveStores(selectedStores.value)
    await quizApi.setHouseholdDone()
    await storeVuex.dispatch('auth/fetchUser')
    await router.push({name: 'quiz.loaylty-program'})
    loading.value = false
}

function selectPrimary() {
    if (selected_stores_id.value.includes(primary_store.value)) {
        saveStores()
        return
    }

    if (selected_stores_id.value.length > 1) {
        handlePrimaryDialog()
    } else {
        primary_store.value = selected_stores_id.value[0]
        saveStores()
    }
}

function handleCancel() {
    isShowPrimaryDialog.value = false
}

function getSelectedStores(obj) {
    isShowPrimaryDialog.value = false
    stores.value = obj.stores
    selected_stores_id.value = obj.selected_stores_id
    primary_store.value = obj.primary_store
}

function selectPrimaryStore() {
    isShowPrimaryDialog.value = false
    saveStores()
}
function handlePrimaryDialog() {
    isShowPrimaryDialog.value = true
}
</script>
