<template>
    <el-form
        ref="registerForm"
        :model="form"
        :rules="rules"
        label-width="130px"
        @keyup.enter="onSubmit"
    >
        <el-form-item
            prop="name"
            label="Name"
            :error="formErrors.get('name')"
        >
            <el-input
                v-model="form.name"
                name="name"
                type="test"
            />
        </el-form-item>
        <el-form-item
            prop="email"
            label="Email"
            :error="formErrors.get('email')"
        >
            <el-input
                v-model="form.email"
                name="email"
                type="email"
            />
        </el-form-item>
        <el-form-item
            prop="password"
            label="Password"
            :error="formErrors.get('password')"
        >
            <el-input
                v-model="form.password"
                name="password"
                show-password
            />
        </el-form-item>
        <el-form-item
            prop="password_confirmation"
            label="Confirm password"
        >
            <el-input
                v-model="form.password_confirmation"
                name="password_confirmation"
                show-password
            />
        </el-form-item>
        <el-form-item>
            <el-button
                :loading="loading"
                @click.prevent="onSubmit"
            >
                Submit
            </el-button>
        </el-form-item>
    </el-form>
</template>

<script>
import {Errors} from '~/includes/Errors'
import validatePassword from '~/includes/mixins/validatePassword'

export default {
    name: 'RegisterForm',
    mixins: [ validatePassword ],
    props: {
        errors: {
            type: Object,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            form: {
                email: '',
                password: '',
                password_confirmation: ''
            },
            rules: {
                email: [
                    { required:true, message: 'Email is required', trigger: 'blur' },
                    { type: 'email', message: 'Invalid email', trigger: 'blur' }
                ],
                name: [
                    { required:true, trigger: 'blur'},
                    { trigger: 'blur', min: 3, max: 255 }
                ],
                password: [
                    { required: true },
                    { validator: this.checkPasswordComplicated, trigger: ['change', 'blur'] }
                ],
                password_confirmation : [
                    { required: true },
                    { validator: this.checkPassIdentical, trigger: ['change', 'blur'] }
                ]
            },
            formErrors: new Errors()
        }
    },
    watch: {
        errors: function (val) {
            this.formErrors.record(this.errors)
        }
    },
    methods: {
        onSubmit(e) {
            this.validateForm('registerForm').then((valid)=>{
                this.$emit('submit', this.form)
            })
        },
        async validateForm(formName) {
            if (typeof this.formErrors !== 'undefined') {
                this.formErrors.clear(null)
            }
            return this.$refs[formName].validate()
        },
    }
}
</script>
