<template>
    <div class="main-content__bottom-content-section">
        <div class="g-container">
            <div class="search-product__buttons">
                <div class="search-product__buttons-col">
                    <el-button
                        :loading="removeLoading"
                        :type="removeButtonType"
                        :disabled="status === STATUS_REMOVED"
                        class="w-100"
                        round
                        @click="removeOrigin"
                    >
                        <i
                            v-if="status === STATUS_REMOVED"
                            class="fa-solid fa-circle-check"
                        />
                        Remove
                    </el-button>
                </div>
                <div class="search-product__buttons-col">
                    <el-button
                        class="w-100"
                        round
                        @click="reduceOrigin"
                    >
                        Reduce
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import {computed, onMounted, ref} from 'vue'
import pmoApi from '~/modules/pmo/pmoApi'
import {STATUS_REMOVED} from '~/modules/pmo/summaryStatuses.constants'
import {useRoute, useRouter} from 'vue-router'
import bus from '~/includes/Event'

const route = useRoute()
const router = useRouter()

const props = defineProps({
    gtin14: {
        type: String,
        required: true
    }
})

onMounted(() => {
    bus.$on('setPmoItemStatus', new_status => {
        status.value = new_status
    })
})

const removeLoading = ref(false)

const status = ref('')

const getStatus = async () => {
    const {data} = await pmoApi.getStatus(route.params.pmo_category, props.gtin14)
    status.value = data.status
}

getStatus()

const removeButtonType = computed(() => status.value === STATUS_REMOVED ? 'primary' : 'default')

const removeOrigin = async () => {
    removeLoading.value = true
    const responses = await Promise.all([
        await pmoApi.get(route.params.pmo_category),
        pmoApi.setProductStatus(route.params.pmo_category, props.gtin14, STATUS_REMOVED)
    ])

    removeLoading.value = false
    const type = responses[0].data.type
    await router.push({ name: 'pmo.summary', params: {pantry: type} })
}

const reduceOrigin = () => {
    router.push({
        name: 'product.frequency',
        params: {upc: props.gtin14, pmo_category: route.params.pmo_category},
        query: route.query
    })
}
</script>
<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.search-product {
    &__buttons {
        padding: 0 0 20px 0;
        display: flex;
        margin: 0 -10px;
        &-col {
            width: 50%;
            padding: 0 10px;
        }
    }
}
</style>
