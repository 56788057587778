<template>
    <div
        v-if="!loading"
        class="pmo-page-slider-main"
    >
        <div class="pmo-page-slider">
            <div
                v-if="!showPrev"
                class="pmo-page-slider__prev"
                @click="goToPmoSummary(prevPage)"
            >
                <i class="pmo-page-slider__prev-btn fa-solid fa-angle-left" />
            </div>

            <div class="pmo-page-slider__progress">
                <div class="pmo-page-slider__progress-info">
                    <span class="pmo-page-slider__progress-info-page">
                        {{ page }}
                    </span>
                    of
                    <span class="pmo-page-slider__progress-info-total">
                        {{ total }}
                    </span>
                </div>
                <div class="pmo-page-slider__progress-bar">
                    <el-progress
                        :percentage="percent"
                        :show-text="false"
                        :stroke-width="10"
                        color="#c8c9e7"
                        class="el-progress--pmo"
                    />
                </div>
            </div>
            <div
                v-if="!showNext"
                class="pmo-page-slider__next"
                @click="goToPmoSummary(nextPage)"
            >
                <i class="pmo-page-slider__next-btn fa-solid fa-angle-right" />
            </div>
        </div>
        <el-button
            class="pmo-page-slider__continue-btn"
            type="primary"
            round
            @click="router.push({name: 'pmo'})"
        >
            Continue
        </el-button>
    </div>
</template>

<script setup>
import {computed, ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import pmoApi from '~/modules/pmo/pmoApi'

const router = useRouter()
const route = useRoute()

const emit = defineEmits()

const props = defineProps({
    page: {
        type: Number,
        required: true,
    },
})

const total = 6

const loading = ref(false)
const showNext = ref(true)
const showPrev = ref(true)
const nextPage = ref(parseInt(props.page) + 1)
const prevPage = ref(parseInt(props.page) - 1)

fetchNext(nextPage.value)
fetchPrev(prevPage.value)

async function fetchNext(type) {
    if (type > total) return
    loading.value = true

    const {data} = await pmoApi.getSummaryProductsByPantry(type)
    const hasProds = data.products_top_5.length + data.products_other.length !== 0

    nextPage.value = type
    showNext.value = !hasProds

    if (!hasProds) {
        await fetchNext(type + 1)
    }

    loading.value = false
}

async function fetchPrev(type) {
    if (type < 1) return

    const {data} = await pmoApi.getSummaryProductsByPantry(type)
    const hasProds = data.products_top_5.length + data.products_other.length !== 0

    prevPage.value = type
    showPrev.value = !hasProds

    if (!hasProds) {
        await fetchPrev(type - 1)
    }
}

const percent = computed(() => props.page / total * 100)

function goToPmoSummary(type) {
    router.push({name: 'pmo.summary', params: {pantry: type}})
}

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
    .pmo-page-slider-main {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .pmo-page-slider {
        $block-name: &;
        display: flex;
        justify-content: center;
        align-items: center;
        &__prev,
        &__next {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            cursor: pointer;
            &:hover {
                #{$block-name}__prev-btn,
                #{$block-name}__next-btn {
                    color: $green;
                }
            }
            &-btn {
                font-size: 12px;
                color: $indigo;
                transition: 0.2s color ease-in-out;
            }
        }
        &__continue-btn {
            display: flex;
            margin-left: 10px;
            width: 50%;
            max-width: 250px;
        }
        &__progress {
            width: 110px;
            margin: 0 5px;
            &-info {
                margin-bottom: 3px;
                font-size: 12px;
                line-height: 1.35;
                text-align: center;
                color: $indigo;
                user-select: none;
            }
        }
    }
</style>
