<template>
    <div>
        <LandingIntro />
        <LandingWorks />
        <LandingCarousel />
        <LandingWeHelp />
        <LandingTestimonilasV1 />
    </div>
</template>

<script>
import LandingIntro from './LandingIntro'
import LandingCarousel from './LandingCarousel'
import LandingBenefits from './LandingBenefits'
import LandingWorks from './LandingWorks'
import LandingTestimonilasV1 from './LandingTestimonilasV1'
import LandingWeHelp from './LandingWeHelp'

export default {
    name: 'LandingIndex',
    components: {
        LandingIntro,
        LandingCarousel,
        LandingBenefits,
        LandingWorks,
        LandingTestimonilasV1,
        LandingWeHelp,
    },
}
</script>
