<template>
    <div
        class="product-preview-block"
        :class="{
            'product-preview-block--no-margin-bottom': noMarginBottom,
            'product-preview-block--cart-product-block': cartProductBlock,
        }"
        @click="openProductDetails"
    >
        <div class="product-preview-block__image">
            <img
                :src="productImageUrl"
                :alt="product.productTitle"
                @error="imageLoadError"
            >
        </div>
        <div
            class="product-preview-block__content"
            :class="{
                'product-preview-block__content--no-border': noBorder,
                'product-preview-block__content--shop-product-block': shopProductBlock
            }"
        >
            <div class="product-preview-block__content-wrap">
                <p class="product-preview-block__content-brand">
                    {{ product.brand }}
                </p>
                <p class="product-preview-block__content-title">
                    {{ product.productTitle }}: {{ product.variant }}
                </p>
            </div>
            <div
                v-if="false && shopProductBlock"
                class="product-preview-block__content-link"
                @click.stop="openProductMoreChoices"
            >
                More choices
            </div>
            <div
                v-if="product.not_available && shopProductBlock"
                class="product-preview-block__content-notification"
            >
                Item is no longer available
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
        // ProductNutrition
    },
    props: {
        product: {
            type: Object,
            default: () => { return {}},
        },
        noBorder: {
            type: Boolean,
            default: false
        },
        noMarginBottom: {
            type: Boolean,
            default: false
        },
        shopProductBlock: {
            type: Boolean,
            default: false
        },
        cartProductBlock: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            netCarbValue: 0,
            netCarbUom: '',
            productImageLoaded: true,
        }
    },
    computed: {
        nutrition() {
            let nutr = this.product?.factPanel?.nutrition

            if (!nutr) {
                nutr = this.product?.factPanel?.supplement
            }
            return nutr
        },
        serving() {
            return this.nutrition?.serving
        },
        sugarColumn() {
            return this.getFactPanelColumn('Sugars') || this.getFactPanelColumn('Added Sugars')
        },
        totalCarbs() {
            return this.getFactPanelColumn('Total Carbohydrate')
        },
        fiber() {
            return this.getFactPanelColumn('Fiber')||this.getFactPanelColumn('Dietary Fiber')
        },
        netCarb() {
            let netCarbValue = 0
            let netCarbUom = ''

            if (this.totalCarbs) {
                netCarbValue = this.totalCarbs.value
                netCarbUom = this.totalCarbs.uom
            }
            if (this.fiber) {
                netCarbValue = netCarbValue - this.fiber.value
                if (netCarbValue < 0) {
                    netCarbValue = 0
                }
            }

            return {
                value: netCarbValue,
                uom: netCarbUom
            }
        },
        productImageUrl() {
            if (this.product && this.product.gtinFormats.gtin14 && this.product.imagesArray[0] && this.productImageLoaded) {
                return this.product.imagesArray[0]

                // return '/images/products/' + this.product.gtinFormats.gtin14 + '.jpg'
            } else {
                return '/images/ImageUnavailable2.png'
            }
        }
    },
    methods: {
        getFactPanelColumn(columnName) {
            let column = {
                value: 0,
                uom: 'g'
            }

            if (this.nutrition && this.nutrition.columns) {
                let amountPerServing = _.find(this.nutrition.columns, o => {
                    if (o.name === null) {
                        return false
                    }
                    return /serving/i.test(o.name)
                })

                if (!amountPerServing) {
                    amountPerServing = _.find(this.nutrition.columns, o => {
                        if (o.name === null) {
                            return false
                        }
                        return /packaged/i.test(o.name)
                    })
                }

                if (!amountPerServing &&  this.nutrition.columns.length > 0) {
                    amountPerServing = this.nutrition.columns[0]
                }

                if (amountPerServing){
                    column = _.find(amountPerServing.nutrients, (o) => {
                        return (o.name.toLocaleLowerCase() === columnName.toLocaleLowerCase())
                    })
                }
            }

            return column
        },
        openProductDetails() {
            if (!this.shopProductBlock&&!this.cartProductBlock) {
                return this.openProductMoreChoices()
            }
            let query = {}
            if (this.$route.query.store_id) {
                query.store_id = this.$route.query.store_id
            }

            this.$router.push({
                name: 'product.show',
                params: {
                    upc: this.product.gtinFormats.gtin14,
                    pmo_category: this.$route?.params?.pmo_category
                },
                query: query
            })
        },
        openProductMoreChoices() {
            let query = {}
            if (this.$route.query.store_id) {
                query.store_id = this.$route.query.store_id
            }

            this.$router.push({
                name: 'product.frequency',
                params: {
                    upc: this.product.gtinFormats.gtin14,
                    pmo_category: this.$route?.params?.pmo_category
                },
                query: query
            })
        },
        imageLoadError() {
            this.productImageLoaded = false
        },
    }
}
</script>

<style  lang="scss" scoped>
@import "resources/sass/_variables.scss";
.product-preview-block {
    $block-name: &;
    display: flex;
    align-items: center;
    cursor: pointer;
    &:not(:last-child){
        margin-bottom: 10px;
    }
    &__image {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        margin-right: 10px;
        border: 2px solid $light-gray;
        border-radius: 5px;
        overflow: hidden;
        background-color: $white;
        &-i,
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__content {
        flex-grow: 1;
        display: flex;
        align-items: center;
        min-height: 70px;
        border-bottom: 1px solid $light-gray;
        &-wrap {
            max-height: 95px;
            overflow: hidden;
        }
        &-title {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.2;
            color: $dark;
        }
        &-brand {
            font-size: 14px;
            font-weight: 200;
            line-height: 1.2;
            color: $dark;
        }

        &-notification {
            position: relative;
            z-index: 2;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.15;
            letter-spacing: 1px;
            text-transform: uppercase;
            transition: 0.2s color ease-in-out;
            color: orange;
        }

        &-link {
            position: relative;
            z-index: 2;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.15;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: $gray;
            transition: 0.2s color ease-in-out;
            &:hover {
                color: $green;
            }
        }
        &--no-border {
            border-bottom: unset;
            #{$block-name} {
                &__content {
                    &-title {
                        max-height: 52px;
                    }
                }
            }
        }
        &--shop-product-block {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            #{$block-name} {
                &__content {
                    &-wrap {
                        max-height: 50px;
                        margin-bottom: 5px;
                        line-height: 1.2;
                    }
                }
            }
        }
    }
    &--cross-out-text,
    &--cart-product-block {
        #{$block-name} {
            &__content {
                &-wrap {
                    // text-decoration: line-through;
                }
            }
        }
    }
    &--cross-out-text {
        #{$block-name} {
            &__content {
                &-title,
                &-brand {
                    color: $gray;
                }
            }
        }
    }
    &--no-margin-bottom {
        margin-bottom: 0 !important;
    }
    &--cart-product-block {
        //opacity: 0.4;
        padding-left: 31px;
    }
}
</style>
