<template>
    <div class="total-block" :class="{ red:isRed }">
        <div class="total-block__sting">
            <div class="total-block__all">
                <span class="total-block__amount">
                    Total: {{ totalSugar }}
                </span>&nbsp;
                <span class="total-block__description">
                     gm sugar / week
                </span>
            </div>
            <div class="total-block__reduced">
                <span class="total-block__reduced-sugar">
                    {{ totalReducedSugar }} gm saved
                </span>
            </div>
        </div>
        <div class="total-block__sting">
            <div class="total-block__sting">
                <div class="total-block__all">
                    <span class="total-block__amount">
                        Total: {{ totalNetCarbs }}
                    </span>&nbsp;
                    <span class="total-block__description">
                         gm net carbs / week
                    </span>
                </div>
                <div class="total-block__reduced">
                    <span class="total-block__reduced-sugar">
                        {{ totalReducedNetCarbs }} gm saved
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
    totalSugar: {
        type: Number,
        required: true,
    },
    totalReducedSugar: {
        type: Number,
        required: true,
    },
    totalNetCarbs: {
        type: Number,
        required: true,
    },
    totalReducedNetCarbs: {
        type: Number,
        required: true,
    }
})

const isRed = computed(() => {
    return props.totalSugar > 175
})
</script>
<style lang="scss">
@import "resources/sass/_variables.scss";
.total-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px #ccc solid;
    font-weight: 600;
    font-size: 16px;

    &__sting {
        justify-content: space-between;
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
    }

    &__all {
        display: flex;
        flex-wrap: nowrap;
    }

    &__amount {
        display: block;
    }

    &__description {
        display: flex;
        flex-wrap: nowrap;
        font-size: 12px;
        align-items: center;
    }

    &__sugar-measurement {
        display: block;
        text-align: start;
        font-size: 12px;
        opacity: .8;
        font-weight: normal;
        margin-bottom: 10px;
    }

    &__reduced {
        color: var(--el-color-primary);
    }

    &__reduced-sugar {
        display: block;
    }

    &__reduced-sugar-measurement {
        display: block;
        text-align: end;
        font-weight: normal;
        font-size: 12px;
        opacity: .8;
        @media (max-width: 480px) {
            text-align: start;
        }
    }
}
.red {
    color: $maroon;
}
</style>
