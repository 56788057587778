<template>
    <div
        v-if="quiz"
        class="main-content main-content--base-layout"
    >
        <div class="main-content__top-content-section">
            <div id="start-savvy"></div>
            <div class="g-container">
                <div class="questions-page__question">
                    <quiz-question-rate
                        :question-text="quiz.questions[0].text"
                        :range-list="range_list"
                        :range-value="range_value"
                        @changeRangeValue="getRangeValue"
                    />
                </div>
<!--                <div-->
<!--                    v-if="showInfoNotification"-->
<!--                    class="info-notification default-text"-->
<!--                >-->
<!--                    <i class="info-notification__icon fa-solid fa-info-circle" />-->
<!--                    {{ infoNotificationText }}-->
<!--                </div>-->
            </div>
        </div>
        <div class="main-content__bottom-content-section robot-container">
            <div class="g-container">
                <div class="main-content__button-wrap">
                    <el-button
                        :disabled="loading"
                        :loading="loading"
                        type="primary"
                        round
                        class="continue-button"
                        @click="finishQuiz"
                    >
                        Continue
                    </el-button>
                </div>
            </div>
        </div>
        <user-intro
            v-if="showUserIntro"
            :robot-messages-all="robotMessages"
            @finishUserIntro="finishUserIntro"
        />
        <user-intro
            v-if="showRobotMessageSms"
            :robot-messages-all="robotMessageSms"
            @callbackBtn="handleCallbackBtn"
            @cancelCallbackBtn="handleCancelCallbackBtn"
        />
        <el-dialog
            v-model="showPhoneNumberDialog"
            :show-close="false"
            title="Phone number"
            custom-class="el-dialog--phone-number"
            :close-on-click-modal="false"
        >
            <el-form
                :model="form"
                :rules="rules"
                class="profile-info__form"
            >
                <el-form-item
                    prop="phone_number"
                    class="profile-info__form-section-item"
                    :error="formErrors.get('phone_number')"
                    @keydown.enter.prevent="sendPhoneNumber"
                    @submit.prevent="sendPhoneNumber"
                >
                    <label class="el-form-item__label--input-mask">
                        <el-input
                            id="phoneNumberInput"
                            ref="phoneNumberInput"
                            v-model="form.phone_number"
                            name="phone_number"
                            placeholder="(###)###-####"
                            maxlength="13"
                            class="el-input--input-mask"
                            autofocus="true"
                            @input="phoneMaskFormatter"
                        >
                            <template #prefix>
                                Phone Number
                            </template>
                        </el-input>
                    </label>
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button
                        class="el-button--transparent"
                        @click="cancelPhoneNumberDialog"
                    >
                        Cancel
                    </el-button>
                    <el-button
                        :disabled="!(form.phone_number.length >= 13)"
                        class="el-button--transparent"
                        @click="sendPhoneNumber"
                    >
                        Send
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import quizApi from '~/modules/quiz/quizApi'
import userApi from '~/modules/profile/userApi'
import bus from '~/includes/Event'
import UserIntro from '~/modules/user-steps-overview/components/UserIntro'
import {Errors} from '~/includes/Errors'
import validatePassword from '~/includes/mixins/validatePassword'
import store from '~/store'
import RobotDialog from '~/modules/quiz/components/RobotDialog'
import QuizQuestionRate from '~/base/components/quiz-question-range/QuizQuestionRange'

export default {
    name: 'QuizRocShow',
    components: {RobotDialog, UserIntro, QuizQuestionRate},
    mixins: [ validatePassword ],
    data() {
        return {
            quiz: null,
            done: false,
            loading: false,
            range_value: 1,
            range_list: [
                {
                    value: '90 days',
                    img: '/images/robots/robot_profile.svg',
                    text: 'Awesome! In 90 days you will have completed your Pantry Makeover. <br/>' +
                        'In order to support this progress I will reach out <b>one time a week</b> with a text message or email.',
                    class: '',
                },
                {
                    value: '60 days',
                    img: '/images/robots/robot_skate.svg',
                    text: 'Super! In 60 days you will have completed your Pantry Makeover. <br/>' +
                        'In order to support this progress I will reach out <b>twice a week</b> with a text message or email.',
                    class: 'active',
                },
                {
                    value: '30 days',
                    img: '/images/robots/robot_rocket.svg',
                    text: 'Great! In under 30 days you will have completed your Pantry Makeover. <br/>' +
                        'In order to support this progress I will reach out <b>daily</b> with a text message or email.',
                    class: '',
                },
            ],
            robotMessages: [
                {
                    robotPosition: '#start-savvy',
                    robotImage: '/images/robots/robot_smile.svg',
                    text: 'How quickly would you like to reach your aspiration goal',
                },
                {
                    robotPosition: '#start-savvy',
                    robotImage: '/images/robots/robot_smile_reflect.svg',
                    robotAlign: 'right',
                    text: 'We will send communications accordingly to keep you on course.',
                },
            ],
            robotMessageSms: [
                {
                    robotPosition: 'bottom-left-screen-corner',
                    robotImage: '/images/robots/robot_smile.svg',
                    robotAlign: 'center',
                    text: 'Will you accept text messages.',
                    btnText: 'Yes',
                    btnCancelText: 'No',
                    btnPosition: 'center',
                },
            ],
            showRobotMessageSms: false,
            showUserIntro: true,
            showPhoneNumberDialog: false,
            form: {
                phone_number: ''
            },
            rules: {
                phone_number: [
                    { required: true, message: 'Please enter your phone number', trigger: 'blur' },
                    { validator: this.checkPhoneNumber, trigger: ['change', 'blur'] }
                ]
            },
            formErrors: new Errors(),
            showInfoNotification: false,
            infoNotificationText: 'To adjust text notifications or timeframe for pantry makeover visit your Profile in the main menu.',
        }
    },
    computed: {
        userPersonalType(){
            return store.getters['auth/user'].personal_type
        },
        userAspiration(){
            return store.getters['auth/user'].aspiration
        },
        additionalMessage() {
            return this.setAdditionMessage(this.userAspiration)
        }
    },
    watch: {
        errors: function () {
            this.formErrors.record(this.errors)
        }
    },
    async created() {
        await this.fetchSubQuiz()
        this.quiz.questions[0].answer = this.range_list[1].value
    },
    mounted() {
        bus.$emit('set-title', 'Aspirations') //'Motivations & Frustrations')
        this.robotMessage = this.setRobotMessage(this.userPersonalType, this.additionalMessage)
    },
    methods: {
        finishUserIntro() {
            this.showUserIntro = false
        },
        async fetchSubQuiz() {
            const {data} = await quizApi.getSubQuiz(this.$config.rocQuizSlug)
            if (!!data) this.quiz = data
        },
        async finishQuiz() {
            this.loading = true
            await quizApi.saveAnswers(this.quiz)
            this.showRobotMessageSms = true
        },
        handleCallbackBtn() {
            this.showRobotMessageSms = false
            this.showPhoneNumberDialog = true
            window.setTimeout(() => {
                document.getElementById('phoneNumberInput').focus()
            }, 500)
        },
        handleCancelCallbackBtn() {
            this.showRobotMessageSms = false
            this.$router.push({name: 'quiz.index'})
            this.loading = false
        },
        async sendPhoneNumber() {
            await userApi.savePhoneNumber(this.form.phone_number, true)
            this.showPhoneNumberDialog = false
            this.$router.push({name: 'quiz.index'})
        },
        checkPhoneNumber(rule, value, callback) {
            if(! /[(]\d{3}[)](\d{3})-(\d{4})/.test(value)) {
                return callback(new Error(
                    'Please enter correct phone number'
                ))
            }
            else {
                callback()
            }
        },
        setRobotMessage(userPersonalType, aspirationPart) {
            const messages = {
                'AA': `Together we can achieve your goal ${aspirationPart}`,
                'AB': 'This isn\'t hard if we do it right! Let\'s play it smart.',
                'AC': 'I\'m on your team. Together we can get this done!',
                'BA': 'You are not alone, but your journey is your own. I can help.',
                'BB': 'I\'m your friendly coach that can help you choose wisely.',
                'BC': 'Give me your hand, we can do this!',
                'CA': 'Together we can do this right!',
                'CB': `You owe this to yourself! Together we can achieve your goal ${aspirationPart}`,
                'CC': 'I will guide you safely on your journey.'
            }
            return messages[userPersonalType] ?? 'Your profile is almost complete! Give me your hand, we can do this!'
        },
        setAdditionMessage(value){
            const additionMessage = {
                'sleep': 'of improving your sleep',
                'stress': 'of reducing your stress',
                'weight': 'of losing weight',
                'energy': 'of increasing your energy',
                'health': 'of making healthier choices',
            }
            return additionMessage[value] ?? ''
        },
        phoneMaskFormatter(value) {
            this.form.phone_number = this.form.phone_number.replace(/[\D]/g, '')
            if (this.form.phone_number.length > 5) {
                this.form.phone_number = this.form.phone_number.replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)$2-$3').substring(0,13)
            } else if (this.form.phone_number.length > 3) {
                this.form.phone_number = this.form.phone_number.replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)$2').substring(0,13)
            } else if (this.form.phone_number.length > 0) {
                this.form.phone_number = this.form.phone_number.replace(/(\d{0,3})(\d{0,3})(\d{0,4})/, '($1)').substring(0,13)
            } else {
                // this.form.phone_number = '(###)###-####'
            }
        },
        cancelPhoneNumberDialog() {
            this.showPhoneNumberDialog = false
            this.showRobotMessageSms = false
            this.$router.push({name: 'quiz.index'})
        },
        getRangeValue(value){
            this.quiz.questions[0].answer = value
        }
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.robot {
    &-container {
        position: relative;
    }
    &-dialog {
        position: absolute;
        z-index: 30;
        right: 30px;
        bottom: 70px;
    }
}

.info-notification {
    display: flex;
    align-items: center;
    border-radius: 10px;
    padding: 5px 10px;
    line-height: 1.3;
    color: $white;
    background-color: lighten($green, 15%);
    @media all and (min-width: $s) {
        padding: 10px;
    }
    &__icon {
        margin-right: 10px;
        font-size: 18px;
    }
}
</style>
