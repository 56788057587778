export const state = {
    countUnCompleted: 0,
}

// getters
export const getters = {
    countUnCompleted: state => state.countUnCompleted,
}

// mutations
export const mutations = {
    setCountUncompleted (state, count) {
        state.countUnCompleted = count
    },
}
