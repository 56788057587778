<template>
    <div class="nutrition-facts">
        <div class="nutrition-facts__title">
            {{ title }}
        </div>
        <div
            v-if="nutritionFacts.serving"
            class="nutrition-facts__serving"
        >
            <div
                v-if="nutritionFacts.serving.size && nutritionFacts.serving.sizeUom"
                class="nutrition-facts__serving-col"
            >
                Serving Size: {{ nutritionFacts.serving.size }} {{ nutritionFacts.serving.sizeUom }}
            </div>
            <div
                v-if="nutritionFacts.serving.numberPerPack"
                class="nutrition-facts__serving-col"
            >
                Number of servings: {{ nutritionFacts.serving.numberPerPack }}
            </div>
        </div>
        <div
            v-for="column in nutritionFacts.columns"
            :key="column.name"
            class="nutrition-facts__list"
        >
            <div class="nutrition-facts__list-name">
                {{ column.name }}
            </div>
            <div class="nutrition-facts__list-table">
                <div
                    v-for="item in column.nutrients||column.supplements"
                    :key="item.name"
                    class="nutrition-facts__list-row"
                >
                    <nutritional-properties-list :item="item" />
                </div>
            </div>
            <div class="nutrition-facts__list-note">
                {{ text }}
            </div>
        </div>
    </div>
</template>

<script setup>
import NutritionalPropertiesList from './NutritionalPropertiesList'
const name = 'NutritionFacts'
const props = defineProps({
    nutritionFacts: {
        type: Array,
        default: () => { return []},
    }
})
const title = 'Nutrition Facts'
const text = '* The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice.'
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.nutrition-facts {
    $block-name: &;
    &__title {
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: 600;
        color: $dark;
    }
    &__serving {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;
        border-bottom: 4px solid $dark;
        padding-bottom: 10px;
        &-col {
            font-size: 13px;
            &:not(:last-child) {
                margin-right: 10px;
            }
        }
    }
    &__list {
        margin-bottom: 25px;
        &-name {
            padding-bottom: 10px;
            font-size: 13px;
            font-weight: 600;
        }
        &-table {
            margin-bottom: 10px;
            border-top: 4px solid $dark;
            border-bottom: 4px solid $dark;
            padding-top: 10px;
        }
    }
}
</style>
