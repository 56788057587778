<template>
    <div class="main-content">
        <div class="container">
            <el-row>
                <el-col>
                    <h3>Drag:</h3>
                    <router-link :to="{name: 'games.broke-fast-kids-camp'}">
                        Broke Fast Kids Camp (Done)
                    </router-link>
                </el-col>
                <el-col>
                    <router-link :to="{name: 'games.broke-fast'}">
                        Broke Fast Section (Done)
                    </router-link>
                </el-col>
                <el-col>
                    <router-link :to="{name: 'games.meal-time'}">
                        Meal Time Section (Done)
                    </router-link>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <h3>Matches:</h3>
                    <router-link :to="{name: 'games.liquid-candy'}">
                        Liquid Candy Section (Done)
                    </router-link>
                </el-col>
                <el-col>
                    <router-link :to="{name: 'games.liquid-candy-kids-camp'}">
                        Liquid Candy Kids Camp (Done)
                    </router-link>
                </el-col>
                <el-col>
                    <router-link :to="{name: 'games.sneacky-snacks-kids-camp'}">
                        Sneaky Snacks Kids Camp (Done)
                    </router-link>
                </el-col>
                <el-col>
                    <router-link :to="{name: 'games.deserted'}">
                        Desserted Section (Done)
                    </router-link>
                </el-col>
            </el-row>
            <el-row>
                <el-col>
                    <h3>Word Search:</h3>
                    <router-link :to="{name: 'games.conned-e-ments'}">
                        Conned E Ments (Done)
                    </router-link>
                </el-col>
                <el-col>
                    <router-link :to="{name: 'games.sneaky-snacks'}">
                        Sneacky Snacks (Done)
                    </router-link>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
