<template>
    <user-intro
        :robot-messages-all="robotMessages"
        @finishUserIntro="goNext"
    />
    <header class="header">
        <div class="header__left-side">
            <div class="header__menu">
                <i class="header__menu-icon fa fa-bars" />
            </div>
        </div>
        <div class="header__center">
            <h1 class="header__text">
                Pantry Makeover
            </h1>
        </div>
        <div class="header__right-side">
            <div class="header__menu" />
        </div>
    </header>
    <div class="nav-menu">
        <div class="nav-menu__list">
            <div
                v-for="item in items"
                :key="item.title"
                class="nav-menu__item"
                :class="{'active': item.active}"
            >
                <span class="nav-menu__label">
                    <span class="nav-menu__label-icon">
                        <inline-svg
                            class="nav-menu__label-icon-i"
                            :src="item.icon"
                        />
                    </span>
                    <span class="nav-menu__label-text">
                        {{ item.title }}
                    </span>
                </span>
            </div>
        </div>
    </div>
    <div class="main-content main-content--offset-bottom">
        <div class="g-container">
            <div class="shop__functional-section">
                <div class="shop__functional-section-col" />
                <div class="shop__functional-section-col">
                    <el-button
                        round
                        class="share-shopping-list__btn"
                    >
                        <span class="share-shopping-list__btn-icon el-button__icon">
                            <inline-svg
                                class="share-shopping-list__btn-icon-svg el-button__icon-svg"
                                src="/images/svg-icons/share-icon.svg"
                                fill="#00315D"
                            />
                        </span>
                        Share
                    </el-button>
                </div>
            </div>
            <div class="shop__category-list">
                <div
                    v-for="category in categories"
                    :key="category._id"
                    class="shop__category"
                >
                    <shop-category :category="category" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import InlineSvg from 'vue-inline-svg'
import ShopCategory from '~/modules/my-items/components/ShopCategory'
import UserIntro from './UserIntro'
import store from '~/store'
import {useRoute, useRouter} from 'vue-router'
import {PMO_STEP} from '~/modules/pmo/routes'

const router = useRouter()
const route = useRoute()

const props = defineProps({
    nextRoute: {
        type: Object,
        required: true,
    },
})

const user = store.getters['auth/user']

const items = [
    {
        title: 'Learn',
        icon: '/images/svg-icons/education-icon.svg',
        active: false
    },
    {
        title: 'My pantry',
        icon: '/images/svg-icons/pantry-icon.svg',
        active: false
    },
    {
        title: 'My items',
        icon: '/images/svg-icons/basket-icon.svg',
        active: true
    },
]

// const activeShop = {
//     banner: 'Albertsons',
//     address: '7100 W STATE ST.',
//     name: 'Boise - Glenwood & State',
//     productsCount: 6,
// }

const categories = [
    {
        name: 'Juice - Tropi Juice',
        items: [
            {
                brand: 'TROPICANA',
                productTitle: 'ORANGE ORIGINAL NO PULP JUICE',
                gtinFormats: {
                    gtin14: '00012000172007',
                    gtin12: '012000172007'
                },
                imagesArray: ['/images/products/ORANGE_ORIGINAL_NO_PULP_JUICE.jpg']
            }
        ]
    },
    {
        name: 'Soda - Flavor Soda',
        items: [
            {
                brand: 'Jarritos',
                productTitle: 'Tamarind Natural Flavor Soda',
                gtin14: '00012000172007',
                upc: '012000172007',
                gtinFormats: {
                    gtin14: '00012000172007',
                    gtin12: '012000172007'
                },
                imagesArray: ['/images/products/TAMARIND_FLAVOR_SODA.jpeg']
            }
        ]
    },
    {
        name: 'Cooking Sauces - Apple Cider Vinegar',
        items: [
            {
                gtin14:'00013000008143',
                upc:'013000008143',
                brand:'HEINZ',
                productTitle:'APPLE CIDER VINEGAR',
                gtinFormats:{
                    gtin14:'00013000008143',
                    gtin12:'013000008143'
                },
                imagesArray:['https://ssimagestorage1.blob.core.windows.net/images/54214992_rxknlwxclbrknj5aahkb.jpg']
            }
        ]
    },
    {
        name: 'Chips & Snacks - Tortilla & Corn Chips',
        items: [
            {
                brand:'BUGLES',
                productTitle:'ORIGINAL FLAVOR CRISPY CORN SNACKS',
                gtin14:'00016000283701',
                upc:'016000283701',
                gtinFormats:{
                    gtin12:'016000283701',
                    gtin14:'00016000283701'
                },
                imagesArray:['https://ssimagestorage1.blob.core.windows.net/images/46674604_331e8bd9-0f77-4316-8d89-c1e72a5bf06a.png']
            },
            {
                gtin14:'00787359175039',
                upc:'787359175039',
                brand:'FRESH GOURMET',
                productTitle:'LIGHTLY SALTED TORTILLA STRIPS',
                gtinFormats:{
                    gtin12:'787359175039',
                    gtin14:'00787359175039'
                },
                imagesArray:['https://ssimagestorage1.blob.core.windows.net/images/43252042_46b4d48d-ffab-4f88-b2af-3f7d538a2c7f.jpg']
            }
        ]
    },
    {
        name: 'Yogurt - Classic Low Fat',
        items: [
            {
                gtin14:'00070470003078',
                upc:'070470003078',
                brand:'YOPLAIT',
                productTitle:'HARVEST PEACH ORIGINAL LOW FAT YOGURT',
                gtinFormats:{
                    gtin12:'070470003078',
                    gtin14:'00070470003078'
                },
                imagesArray:['https://ssimagestorage1.blob.core.windows.net/images/47690750_3167089114.jpg']
            }
        ]
    },
    {
        name: 'Ice Cream - With Candy',
        items: [
            {
                gtin14:'00070640016204',
                upc:'070640016204',
                brand:'CHILLY COW',
                productTitle:'MINT DARK CHOCOLATE CHIP LIGHT ICE CREAM',
                gtinFormats:{
                    gtin14:'00070640016204',
                    gtin12:'070640016204'
                },
                imagesArray:['https://ssimagestorage1.blob.core.windows.net/images/42199683_8b9b8fb1-1554-49b1-8aa8-dad127bb5d26.jpg']
            }
        ]
    },
    {
        name: 'Soda - Diet Cola',
        items: [
            {
                brand: 'PEPSI',
                productTitle: 'DIET COLA',
                gtin14: '00012000172007',
                upc: '012000172007',
                gtinFormats: {
                    gtin14: '00012000172007',
                    gtin12: '012000172007'
                },
                imagesArray: ['https://ssimagestorage1.blob.core.windows.net/images/59046253_00012000172007_L1.jpg']
            }
        ]
    },
    {
        name: 'Soda - Energy Drinks',
        items: [
            {
                brand: 'Red Bull',
                productTitle: 'ENERGY DRINK: ',
                gtin14: '00012000172007',
                upc: '012000172007',
                gtinFormats: {
                    gtin14: '00012000172007',
                    gtin12: '012000172007'
                },
                imagesArray: ['https://ssimagestorage1.blob.core.windows.net/images/45780686_e9d3a97e-92fb-4bdc-8331-91eb84655515.jpg']
            },
            {
                brand: 'Red Bull',
                productTitle: 'SUGAR FREE energy drink: ',
                gtin14: '00012000172007',
                upc: '012000172007',
                gtinFormats: {
                    gtin14: '00012000172007',
                    gtin12: '012000172007'
                },
                imagesArray: ['https://ssimagestorage1.blob.core.windows.net/images/45780687_1f6255e1-79d1-4367-afbb-43097399aea3.jpg']
            },
            {
                brand: 'Red Bull',
                productTitle: 'ENERGY DRINK: ',
                gtin14: '00012000172007',
                upc: '012000172007',
                gtinFormats: {
                    gtin14: '00012000172007',
                    gtin12: '012000172007'
                },
                imagesArray: ['https://ssimagestorage1.blob.core.windows.net/images/44922552_a36ba2f5-870e-4a7e-85a7-518357e13a1e.jpg']
            },
        ]
    },
]

const robotMessages = [
    {
        robotPosition: 'bottom-left-screen-corner',
        robotImage: '/images/robots/robot_smile.svg',
        text: 'Step ' + (user.is_fdm ? '4' : '3') + ', we provide an easy to use list so you can <b>find great alternatives</b> in your grocery store.',
    },
]

function goNext() {
    router.push({name: props.nextRoute})
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.shop {
    &__functional-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 -5px 20px;
        &-col {
            padding: 0 5px;
        }
    }
    &__shop-list {
        margin: 0 -15px 10px;
        border-bottom: 1px solid $light-gray;
        padding: 0 15px 15px;
        @media all and (min-width: $m) {
            margin: 0 -30px 10px;
            padding: 0 30px 15px;
        }
    }
    &__category {
        margin-bottom: 10px;
    }
    &__search {
        &-btn {
            max-width: 350px;
            margin: 0 auto;
        }
    }
}
.switch-sections {
    $block-name: &;
    $local-light-gray: #f0f0f0;
    display: flex;
    align-items: center;
    &__btn {
        height: 36px;
        padding: 5px 25px;
        border: 1px solid $blue;
        font-size: 18px;
        font-weight: 500;
        line-height: 1.3;
        color: $blue;
        background-color: $local-light-gray;
        transition: 0.2s color ease-in-out, 0.2s background-color ease-in-out, 0.2s box-shadow ease-in-out;
        cursor: pointer;
        user-select: none;
        &:hover {
            box-shadow: 0 0 6px rgba(0, 0, 0, 20%);
        }
        &:first-child {
            border-radius: 18px 0 0 18px;
            padding: 6px 25px 6px 30px;
        }
        &:last-child {
            border-radius: 0 18px 18px 0;
            padding: 6px 30px 6px 25px;
        }
        &.active {
            color: $white;
            background-color: $blue;
            pointer-events: none;
        }
    }
}
.share-shopping-list {
    $local-light-gray: #f0f0f0;
    &__btn {
        border-color: $blue;
        color: $blue;
        background-color: $local-light-gray;
        transition: 0.2s box-shadow ease-in-out;
    }
}
.shop-list {
    $block-name: &;
    position: relative;
    user-select: none;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__current {
        max-width: 85%;

        &-shop {
            display: flex;
            align-items: center;
            cursor: pointer;

            &-wrap {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &-wrap,
            &-banner,
            &-address,
            &-name {
                transition: 0.2s color ease-in-out;
            }

            &-banner {
                margin-right: 5px;
                font-size: 20px;
                font-weight: 600;
                line-height: 1.4;
                letter-spacing: 0.15px;
                color: $dark;
            }

            &-address,
            &-name {
                font-size: 15px;
                font-weight: 400;
                color: $gray;
            }
        }

        &-arrow {
            flex-shrink: 0;
            margin-left: 10px;

            &-icon {
                color: $gray;
                transform-origin: center;
                transition: 0.2s color ease-in-out, 0.3s transform ease-in-out;
            }
        }
    }

    &__count {
        flex-shrink: 0;
        margin-left: 10px;

        &-value {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 17px;
            height: 17px;
            border: 1px solid $white;
            border-radius: 8px;
            padding: 2px 4px;
            font-size: 11px;
            font-weight: 500;
            color: $white;
            background-color: $indigo;
        }
    }
}
</style>
