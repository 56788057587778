<template>
    <div
        v-loading="loading"
        class="main-content product-page"
    >
        <div class="g-container">
            <div
                v-if="product"
                class="product-page__inner"
            >
                <div class="product-page__product">
                    <ProductMainInfo
                        :product="product"
                    />
                    <div
                        v-if="outOfStore"
                        class="product-page__product-info"
                    >
                        Please note that this product is currently not sold in your selected store.
                    </div>
                    <div
                        v-if="productNotFound"
                        class="product-page__product"
                    >
                        <div class="product-page__product-info">
                            <span class="product-page__product-info-warning">Warning!</span> Product not found.
                        </div>
                    </div>
                </div>
                <div class="product-page__product-tabs">
                    <el-tabs
                        v-model="activeTab"
                        class="el-tabs--wide"
                        @tab-click="handleClick"
                    >
                        <el-tab-pane
                            label="Ingredients"
                            name="Ingredients"
                        >
                            <template v-if="product.ingredients && product.ingredients.declaration">
                                <div class="el-tab-pane__content">
                                    {{ product.ingredients.declaration }}
                                </div>
                            </template>
                        </el-tab-pane>
                        <el-tab-pane
                            label="Allergens"
                            name="Allergens"
                        >
                            <template v-if="product.warning">
                                <div class="el-tab-pane__content">
                                    {{ product.warning }}
                                </div>
                            </template>
                        </el-tab-pane>
                        <el-tab-pane
                            label="Nutrition"
                            name="Nutrition"
                        >
                            <div
                                v-if="productNutritionFacts"
                                class="el-tab-pane__content"
                            >
                                <nutrition-facts :nutrition-facts="productNutritionFacts" />
                            </div>
                        </el-tab-pane>
                        <el-tab-pane
                            label="Certs"
                            name="Certs"
                        >
                            <div class="el-tab-pane__content">
                                Certs content
                            </div>
                        </el-tab-pane>
                        <el-tab-pane
                            label="Manufacturer"
                            name="Manufacturer"
                        >
                            <div class="el-tab-pane__content">
                                <div
                                    v-for="pData in product.partnerData"
                                    :key="pData.partner"
                                >
                                    <p>{{ pData.partner }}</p>
                                    <div
                                        v-for="item in pData.data"
                                        :key="item.value"
                                    >
                                        {{ item.name }}: {{ item.value }}
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane
                            label="Store"
                            name="Store"
                        >
                            <div class="el-tab-pane__content">
                                <ul class="favorites-storelist">
                                    <li
                                        v-for="(line, i) in stores"
                                        :key="i"
                                    >
                                        <a
                                            href="#"
                                            class="favorites-store"
                                        >
                                            <div class="favorites-store-btn-wrap" />
                                            <div class="favorites-store-footer">
                                                <span class="favorites-store-name">{{ line.banner }} - {{ line.name }}</span>
                                                <span
                                                    v-if="line.dist"
                                                    class="favorites-store-distance"
                                                >{{ Math.round(line.dist.calculated/1609.344 * 10)/10 }} mi</span>
                                            </div>
                                            <div class="favorites-store-overlay" />
                                            <div class="favorites-image-bg">
                                                <img
                                                    :src="line.store_image_url"
                                                    alt=""
                                                >
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <div
                v-else-if="productNotFound"
                class="product-page__product"
            >
                <div class="product-page__product-info">
                    <span class="product-page__product-info-warning">Warning!</span> Product not found.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductMainInfo from './ProductMainInfo'
import labelinsightApi from '~/modules/labelinsight/labelinsightApi'
import pmoApi from '~/modules/pmo/pmoApi'
import store from '~/store'
import axios from 'axios'
import NutritionFacts from './NutritionFacts'

export default {
    components: {
        NutritionFacts,
        ProductMainInfo,
    },
    middleware: 'auth',
    data() {
        return {
            product: null,
            alternatives: [],
            selected: [],
            loading: true,
            showMyItems: true,
            outOfStore: false,
            healthier: false,
            productNotFound: false,
            activeTab:'Ingredients',
            stores: [],
            like: 0,
            productNutritionFacts: null,
        }
    },
    mounted() {
        this.getData()
        if (this.$route.params.pmo_category) {
            this.showMyItems = false
        }
    },
    methods: {
        async getData() {
            let params = this.$route.query
            if (this.$route.params.pmo_category && !this.$route.params.mapped) {
                params = {
                    ...params,
                    // pmo_category: this.$route.params.pmo_category
                }
            }

            try {
                this.loading = true
                let response = await  labelinsightApi.getProductByUpc(this.$route.params.upc, params)
                this.product = response.data.product
                //   this.alternatives = response.data.alternatives
                this.outOfStore = response.data.out_of_store
                this.healthier = response.data.healthier
                this.loading = false
                this.productNotFound = false
                this.likeByProduct(this.product.gtinFormats.gtin14)

                this.productNutritionFacts = response.data.product.factPanel.nutrition||response.data.product.factPanel.supplement

                console.log(this.productNutritionFacts)

                if (false&&navigator.geolocation) {
                    // debugger
                    await navigator.geolocation.getCurrentPosition((position) => {
                        this.position = position
                        this.getStores()
                    })
                } else {
                    this.getStores()
                }
            }
            catch (error) {
                this.loading = false
                this.productNotFound = true
            }
        },
        getStores() {
            // debugger
            if (this.stores.length === 0 && this.product.gtinFormats.gtin14) {
                let params = {
                    gtin: this.product.gtinFormats.gtin14
                }

                if (this.position&&this.position.coords) {
                    params.latitude = this.position.coords.latitude
                    params.longitude = this.position.coords.longitude
                }
                axios.get('/labelinsight/product/stores/', {params: params}).then(response => {
                    // debugger
                    this.stores = response.data.map(function (store) {

                        if (!store.selected) {
                            store.selected = false
                        }
                        return store
                    })
                })
            }
        },
        saveRecommendation() {
            const index = this.selected.findIndex(item => item === this.$route.params.upc)
            if (index >= 0) {
                this.selected.splice(this.selected.findIndex(item => item === this.$route.params.upc), 1)
            }
            // if (this.selected.length == 0) {
            //     this.selected.push(this.product.gtinFormats.gtin14)
            // }
            this.saveSelected()
        },
        keepOriginalItem() {
            this.selected = [this.product.gtinFormats.gtin14]
            this.saveSelected()
        },
        saveMyItems() {
            this.saveRecommendation()
        },
        async saveSelected() {
            const pmo_category = this.$route.params.pmo_category
            if (!pmo_category) {
                // if pmo_category is not set, we need to save the product to my items
                store.dispatch('my-items/addItemToList', {
                    gtins: this.selected,
                    original_gtin: this.product.gtinFormats.gtin14
                })
                await this.$router.push({ name: 'my-items.shop', params: {} })
            } else {
                await pmoApi.addUpcsToShoppingList(pmo_category, {
                    gtins: this.selected,
                    original_gtin: this.product.gtinFormats.gtin14
                })
                // always save the product to my items
                store.dispatch('my-items/addItemToList', {
                    gtins: this.selected,
                    original_gtin: this.product.gtinFormats.gtin14
                })
                await this.$router.push({ name: 'pmo.show', params: {pmo_category: pmo_category} })

            }
        },
        likeByProduct( gtin) {
            axios.post('my-items/like-by-product', {gtin: gtin}).then(response => {
                this.product.liked = response.data.liked
                // console.log(response)
            })

        },

    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.product-page {
    flex: 1;
    padding: 0 0 20px;
    &__product {
        margin-bottom: 15px;
        &-info {
            &-warning {
                font-weight: 600;
                color: $maroon;
            }
        }
    }
}
</style>
