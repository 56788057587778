<template>
    <div
        v-if="quiz"
        class="main-content main-content--base-layout"
    >
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="questions-page__question-module">
                    <question-module-header
                        :image="quiz.image"
                        :name="quiz.name"
                        class="question-module--middle-size"
                    />
                </div>
                <div class="questions-page__question">
                    <QuizQuestions
                        v-if="quiz.questions && $route.params.slug === $config.aspirationQuizSlug"
                        v-show="showQuestions"
                        ref="QuizQuestions"
                        :questions="quiz.questions"
                        @done="toggleContinue"
                        @paging="setNextPage"
                    />
                    <QuizMotivationsQuestions
                        v-if="quiz.questions && $route.params.slug === $config.motivationQuizSlug"
                        v-show="showQuestions"
                        ref="QuizQuestions"
                        :questions="quiz.questions"
                        @done="toggleContinue"
                        @paging="setNextPage"
                    />
                </div>
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div
                    v-if="showDialog"
                    class="main-content__dialog"
                >
                    <component
                        :is="dialogComponent"
                        v-show="showDialog"
                    />
                </div>
                <div
                    v-if="showQuestions"
                    class="main-content__button-wrap"
                >
                    <el-button
                        v-if="showContinueBtn"
                        v-show="showQuestions"
                        :disabled="!done || loading"
                        :loading="loading"
                        type="primary"
                        round
                        class="continue-button"
                        @click="handleContinue"
                    >
                        Continue
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import QuizQuestions from './QuizQuestions'
import QuizMotivationsQuestions from './motivations/QuizMotivationsQuestions'

import {mapGetters} from 'vuex'
import quizApi from '~/modules/quiz/quizApi'
import bus from '~/includes/Event'
import QuestionModuleHeader from '~/modules/quiz/components/question-module-header/QuestionModuleHeader'
export default {
    name: 'QuizShow',
    components: {
        QuestionModuleHeader,
        QuizQuestions,
        QuizMotivationsQuestions,
    },
    data() {
        return {
            quiz: null,
            done: false,
            lastPage: 1,
            loading: false,
        }
    },
    computed: {
        ...mapGetters('quiz', ['showContinueBtn', 'customContinueBtnUrl', 'showQuestions', 'showDialog', 'dialogComponent'])
    },
    async created() {
        await this.fetchSubQuiz()
        if (this.$route.query.page > 1 && !this.quiz.questions[0].answer) {
            await this.$router.push(this.$route.path)
        }
        bus.$emit('set-title', this.quiz.name)
        this.$bus.$on('finish-quiz', this.finishQuiz)
        this.$bus.$on('save-answers', this.saveAnswers)
    },
    mounted() {
        window.onpopstate = () => this.done = true
    },
    methods: {
        async fetchSubQuiz() {
            const {data} = await quizApi.getSubQuiz(this.$route.params.slug)
            if (!!data) this.quiz = data
        },
        toggleContinue(done) {
            this.done = done
        },
        setNextPage(last_page) {
            this.lastPage = last_page
        },
        handleContinue() {
            let currentPage = Number(this.$route.query?.page) || 1
            if (!!this.customContinueBtnUrl) {
                this.$router.push(this.customContinueBtnUrl.href)
            } else if (this.lastPage > currentPage) {
                this.done = false
                this.$router.push({name: 'quiz.show', params: {slug: this.$route.params.slug}, query: {page: currentPage + 1}})
            } else {
                this.finishQuiz()
            }
        },
        async finishQuiz() {
            this.loading = true
            await quizApi.saveAnswers(this.quiz)
            await quizApi.setDone(this.quiz._id)
            this.loading = false

            if (this.quiz.slug === this.$config.aspirationQuizSlug) {
                await this.$router.push({name: 'quiz.roc'})
            } else {
                await this.$router.push({name: 'quiz.index'})
            }
        },
    }
}
</script>
