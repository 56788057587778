<template>
    <div class="app">
      <div v-if="!isOnline" class="internet-notify">No Internet Connection</div>
      <div v-if="isLowConnect && isOnline" class="internet-notify">Bad Internet Connection</div>
        <router-view
            :key="$route.path"
            v-slot="{ Component }"
        >
            <component
                :is="Component"
            />
        </router-view>
        <FullscreenRobotMessage
            v-if="messages.length"
            :messages="messages"
            :robot-image="robotImage"
            @finish="finishOneMessage"
        />
        <FullscreenStarMessage
            v-if="(points > 0 && type !== TYPE_ROBOT) && authUser.client_level && authUser.client_level.has_starts_points_system === true"
            :stars="points"
            :type="type"
            @finish="finishOnePoints"
        />
        <pwa-shortcut-modal/>
    </div>
</template>

<script setup>
import {computed, ref} from 'vue'
import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import store from '~/store'
import bus from '~/includes/Event'
import {ElMessage} from 'element-plus'
import FullscreenRobotMessage from '~/base/components/fullscreen-robot-message/FullscreenRobotMessage'
import FullscreenStarMessage from '~/base/components/fullscreen-star-message/FullscreenStarMessage'
import {TYPE_ROBOT} from './constants/points.constants'
import {useInternetConnection} from '~/includes/composables/useInternetConnection';
import PwaShortcutModal from "~/base/components/pwaShortcutModal.vue";

import {useRoute} from 'vue-router'
import {useLowInternetConnection} from "~/includes/composables/useLowInternetConnection";
import {faviconChanging} from "~/includes/composables/faviconChanging";
import notifyApi from "~/modules/notifications/notifyApi";
import learningModuleApi from "~/modules/learning-modules/learningModuleApi";

faviconChanging()

const route = useRoute()

const messages = computed(() => store.getters['global-robot/messages'])
const points = computed(() => store.getters['global-robot/points'])
const type = computed(() => store.getters['global-robot/type'])
const robotImage = computed(() => store.getters['global-robot/image'])
const { isOnline } = useInternetConnection();
const { isLowConnect } = useLowInternetConnection();
const authUser = computed(() => store.getters['auth/user'])

const messageTimeout = ref(null)
function finishOneMessage() {
    if (messageTimeout.value) clearTimeout(messageTimeout.value)
    messageTimeout.value = setTimeout(() => store.commit('global-robot/clear'), 4000)
}

const pointsTimeout = ref(null)
function finishOnePoints() {
    store.commit('global-robot/clearPoints')
}

window.Pusher = Pusher

bus.$on('userLoggedIn', startWebSocketListeners)
bus.$on('logout', stopWebSocketListeners)

function startWebSocketListeners() {
    const token = computed(() => store.getters['auth/token'])
    const user = computed(() => store.getters['auth/user'])

    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: window.config.pusherKey,
        cluster: window.config.pusherCluster,
        forceTLS: true,
        auth: {
            headers: {
                Authorization: 'Bearer ' + token.value
            },
        },
    })

    window.Echo.private('App.ClientLevel.' + user.value.client_level_id)
        .listen('NewLearningModuleEvent', (e) => {
            learningModuleApi.totalNotCompleted()
                .then(response => {
                    store.commit('learning-modules/setCountUncompleted', response.data)
                })
        })

    window.Echo.private('App.User.' + user.value._id)
        .listen('UserNotifyEvent', (e) => {
            notifyApi.getCountUnreadNotifications()
                .then(response => {
                    store.commit('base/count_unread_notifications', response.data)
                })
            if (route.path.includes(e.page) || e.page === '*') {
                e.duration = 5000
                e.dangerouslyUseHTMLString = true
                ElMessage(e)
            }
        })
        .listen('ChangeFdmEvent', (e) => {
            store.dispatch('auth/fetchUser')
            bus.$emit('change-fdm', e.is_fdm)
        })
        .listen('UpdatePoints', (e) => {
            store.dispatch('auth/updateUserField', {points: e.total_points})
            if (e.message) store.commit('global-robot/addMessage', {message: e.message})
            if (e.add_points) {
                finishOnePoints()
                store.commit('global-robot/addPoints', {points: e.add_points, type:e.type})
            }
        })
}

function stopWebSocketListeners() {
    const user = computed(() => store.getters['auth/user'])
    if (window.Echo) {
        window.Echo.leave('App.User.' + user.value._id)
        window.Echo.leave('App.ClientLevel.' + user.value.client_level_id)
        window.Echo.disconnect()
        delete window.Echo
    }
    window.Echo = null
}

</script>

<style scoped>
.internet-notify {
  background: red;
  text-align: center;
  padding: 5px;
  color: white;
}
</style>
