<template>
    <div class="main-content main-content--add-product">
        <div class="g-container">
            <div class="main-content__add-product">
                <div class="add-product">
                    <h1>Adding product</h1>
                    <el-form
                        ref="form"
                        :model="product"
                        :rules="rules"
                        class="add-product__form"
                        @submit.prevent="saveSubmit"
                    >
                        <el-row justify="end">
                            <el-col :span="24">

                                <el-form-item
                                    prop="upc"
                                    class="profile-info__form-section-item"
                                    :error="formErrors.get('upc')"
                                >
                                    <el-input
                                        v-model="product.upc"
                                        type="number"
                                        placeholder="UPC"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row justify="end">
                            <el-col :span="24">
                                <el-form-item
                                    prop="productTitle"
                                    :error="formErrors.get('productTitle')"
                                >
                                    <el-input
                                        v-model="product.productTitle"
                                        placeholder="Product Name"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row justify="end">
                            <el-col :span="24">
                                <el-form-item
                                    prop="brand"
                                    :error="formErrors.get('brand')"
                                >
                                    <el-input
                                        v-model="product.brand"
                                        placeholder="Brand"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row justify="end">
                            <el-col
                                :span="12"
                                :xs="24"
                            >
                                <el-form-item
                                    prop="netWeight"
                                    :error="formErrors.get('netWeight')"
                                >
                                    <el-input
                                        v-model="product.netWeight"
                                        type="number"
                                        placeholder="Volume or Weight"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                :xs="24"
                            >
                                <el-form-item
                                    prop="netWeightUnit"
                                    :error="formErrors.get('netWeightUnit')"
                                >
                                    <select-unit
                                        v-model="product.netWeightUnit"
                                        placeholder="Net Weight Unit"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row justify="end">
                            <el-col
                                :span="12"
                                :xs="24"
                            >
                                <el-form-item
                                    prop="servingSize"
                                    :error="formErrors.get('servingSize')"
                                >
                                    <el-input
                                        v-model="product.servingSize"
                                        type="number"
                                        placeholder="Serving Size"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                :xs="24"
                            >
                                <el-form-item
                                    prop="servingSizeUnit"
                                    :error="formErrors.get('servingSizeUnit')"
                                >
                                    <select-unit
                                        v-model="product.servingSizeUnit"
                                        placeholder="Serving Size Unit"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col
                                :span="24"
                                :xs="24"
                            >
                                <el-form-item
                                    prop="servingsPerContainer"
                                    :error="formErrors.get('servingsPerContainer')"
                                >
                                    <el-input
                                        v-model="product.servingsPerContainer"
                                        type="number"
                                        placeholder="Servings Per Container"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row justify="end">
                            <el-col
                                :span="12"
                                :xs="24"
                            >
                                <el-form-item
                                    prop="totalCarbohydrate"
                                    :error="formErrors.get('totalCarbohydrate')"
                                >
                                    <el-input
                                        v-model="product.totalCarbohydrate"
                                        type="number"
                                        placeholder="Total Carbohydrate"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                :xs="24"
                            >
                                <el-form-item
                                    prop="totalCarbohydrateUnit"
                                    :error="formErrors.get('totalCarbohydrateUnit')"
                                >
                                    <select-unit
                                        v-model="product.totalCarbohydrateUnit"
                                        placeholder="Total Carbohydrate Unit"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row justify="end">
                            <el-col
                                :span="12"
                                :xs="24"
                            >
                                <el-form-item
                                    prop="totalSugar"
                                    :error="formErrors.get('totalSugar')"
                                >
                                    <el-input
                                        v-model="product.totalSugar"
                                        type="number"
                                        placeholder="Total Sugar"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                :xs="24"
                            >
                                <el-form-item
                                    prop="totalSugarUnit"
                                    class=" w-100"
                                    :error="formErrors.get('totalSugarUnit')"
                                >
                                    <select-unit
                                        v-model="product.totalSugarUnit"
                                        placeholder="Total Sugar Unit"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row justify="end">
                            <el-col
                                :span="12"
                                :xs="24"
                            >
                                <el-form-item
                                    prop="dietatyFiber"
                                    :error="formErrors.get('dietatyFiber')"
                                >
                                    <el-input
                                        v-model="product.dietatyFiber"
                                        type="number"
                                        placeholder="Dietary Fiber"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col
                                :span="12"
                                :xs="24"
                            >
                                <el-form-item
                                    label=""
                                    prop="dietatyFiberUnit"
                                    :error="formErrors.get('dietatyFiberUnit')"
                                >
                                    <select-unit
                                        v-model="product.dietatyFiberUnit"
                                        placeholder="Dietary Fiber Unit"
                                        class="add-product__input"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-form-item
                                label="Front Image"
                                label-width="170px"
                                prop="frontImage"
                                :error="formErrors.get('frontImage')"
                            >
                                <el-col
                                    v-if="showElUploadButtons"
                                    class="center"
                                >
                                    <el-upload
                                        class="add-product__upload"
                                        :action="''"
                                        :on-change="beforeFileUploadFront"
                                        :file-list="frontImage"
                                        :auto-upload="false"
                                        :show-file-list="true"
                                        :multiple="false"
                                    >
                                        <el-button
                                            size="small"
                                            round
                                            type="primary"
                                        >
                                            Upload
                                        </el-button>
                                    </el-upload>
                                </el-col>
                                <template v-else>
                                    <el-col
                                        class="left"
                                        :span="6"
                                    >
                                        <el-button
                                            size="small"
                                            round
                                            type="primary"
                                            @click="takePhoto('frontImage')"
                                        >
                                            Take Photo
                                        </el-button>
                                    </el-col>
                                    <el-col
                                        :span="6"
                                    >
                                        <el-card v-if="product.frontImage">
                                            <el-image

                                                :src="getUrlFromFile(product.frontImage)"
                                                fit="contain"
                                                style="width: 100%; height: 100%;"
                                            />
                                        </el-card>
                                    </el-col>
                                </template>
                            </el-form-item>
                        </el-row>
                        <el-row>
                            <el-form-item
                                label="Nutrition Facts Image"
                                label-width="170px"
                                prop="nutritionFactImage"
                                :error="formErrors.get('nutritionFactImage')"
                            >
                                <el-col
                                    v-if="showElUploadButtons"
                                    class="center"
                                >
                                    <el-upload
                                        class="add-product__upload"
                                        :action="uploadUrl"
                                        :on-change="beforeFileUploadNutritionFact"
                                        :file-list="nutritionFactImage"
                                        :auto-upload="false"
                                        :show-file-list="true"
                                        :multiple="false"
                                    >
                                        <el-button
                                            size="small"
                                            round
                                            type="primary"
                                        >
                                            Upload
                                        </el-button>
                                    </el-upload>
                                </el-col>
                                <template v-else>
                                    <el-col
                                        class="left"
                                        :span="6"
                                    >
                                        <el-button
                                            size="small"
                                            round
                                            type="primary"
                                            @click="takePhoto('nutritionFactImage')"
                                        >
                                            Take Photo
                                        </el-button>
                                    </el-col>
                                    <el-col :span="6">
                                        <el-card v-if="product.nutritionFactImage">
                                            <el-image

                                                :src="getUrlFromFile(product.nutritionFactImage)"
                                                fit="contain"
                                                style="width: 100%; height: 100%;"
                                            />
                                        </el-card>
                                    </el-col>
                                </template>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </div>
                <div>
                    <el-row>
                        <el-col class="center">
                            {{ formErrors.get('dietatyFiberUnit') }}
                            <el-button
                                type="primary"
                                round
                                class="continue-button"
                                :loading="formLoading"
                                @click="saveSubmit"
                            >
                                Save
                            </el-button>
                            <el-button
                                type="default"
                                round
                                class="continue-button cancel_button"
                                @click="cancel"
                            >
                                Cancel
                            </el-button>
                        </el-col>
                    </el-row>
                </div>
                <div
                    v-if="showCameraContainer"
                    class="camera-container-wrapper"
                >
                    <camera
                        ref="cameraRef"
                        :key="cameraKey"
                        :width="320"
                        :height="240"
                        :mirror="true"
                        :fps="30"
                        :play="false"
                        :audio="false"
                        :refocus="false"
                        :debug="false"
                        :take-photo="false"
                        @started="cameraStarted"
                        @snapshot="cameraSnapshot"
                        @error="cameraNotSupport"
                    />
                    <div class="button-container">
                        <el-button
                            size="small"
                            round
                            type="primary"
                            @click="cameraSnapshot"
                        >
                            Capture
                        </el-button>
                        <el-button
                            size="small"
                            round
                            type="default"
                            @click="cancelCamera"
                        >
                            Cancel
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref, computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import labelinsightApi from '~/modules/labelinsight/labelinsightApi'
// import pmoApi from '~/modules/pmo/pmoApi'
// import userApi from '~/modules/profile/userApi'
// import store from '~/store'
import {ElMessage} from 'element-plus'
import SelectUnit from './SelectUnit'
import {Errors} from '~/includes/Errors'
import Camera from 'simple-vue-camera'


const product = ref({
    upc: '',
    productTitle: '',
    brand: '',
    netWeight: '',
    netWeightUnit: '',
    servingSize: '',
    servingSizeUnit: '',
    servingsPerContainer: '',
    totalCarbohydrate: '',
    totalCarbohydrateUnit: '',
    totalSugar: '',
    totalSugarUnit: '',
    dietatyFiber: '',
    dietatyFiberUnit: '',
    frontImage: '',
    nutritionFactImage: '',
    pmo_category: '',
})
const frontImage = ref([])
const nitritionFactImage = ref([])

const productExists = ref(false)

const cameraRef = ref(null)
const showCameraContainer = ref(false)
const showElUploadButtons = ref(false)
const cameraKey = ref(0)
const captureType = ref('frontImage')

const route = useRoute()
const router = useRouter()

const rules = ref({
    upc: [
        {required: true, message: 'Please input UPC', trigger: 'blur', validator: (rule, value, callback) => {
            if (!isNaN(value) && parseInt(value) > 0) {
                callback()
            } else {
                callback(new Error('Please input valid UPC'))
            }
        }},
    ],
    productTitle: [
        {required: true, message: 'Please input product name', trigger: 'blur'},
    ],
    brand: [
        {required: true, message: 'Please input brand', trigger: 'blur'},
    ],
    netWeight: [
        {required: true, message: 'Please input Volume or Weight', trigger: 'blur', validator: (rule, value, callback) => {
            if (!isNaN(value) && value > 0) {
                callback()
            } else {
                callback(new Error('Please input valid volume or weight'))
            }
        }},
    ],
    netWeightUnit: [
        {required: true, message: 'Please select volume or weight unit', trigger: 'blur'},
    ],
    servingSize: [
        {required: true, message: 'Please input serving size', trigger: 'blur', validator: (rule, value, callback) => {
            if (!isNaN(value) && value > 0) {
                callback()
            } else {
                callback(new Error('Please input valid serving size'))
            }
        }},
    ],
    servingSizeUnit: [
        {required: true, message: 'Please select serving size unit', trigger: 'blur'},
    ],
    servingsPerContainer: [
        {required: true, message: 'Please input servings per container', trigger: 'blur', validator: (rule, value, callback) => {
            if (!isNaN(value) && value > 0) {
                callback()
            } else {
                callback(new Error('Please input valid servings per container'))
            }
        }},
    ],
    totalCarbohydrate: [
        {required: true, message: 'Please input total carbohydrate', trigger: 'blur', validator: (rule, value, callback) => {
            if (!isNaN(value) && value >= 0) {
                callback()
            } else {
                callback(new Error('Please input valid total carbohydrate'))
            }
        }},
    ],
    totalCarbohydrateUnit: [
        {required: true, message: 'Please select total carbohydrate unit', trigger: 'blur', validator: (rule, value, callback) => {
            if (!isNaN(product.value.totalCarbohydrate) && product.value.totalCarbohydrate > 0) {
                if (value) {
                    callback()
                } else {
                    callback(new Error('Please select total carbohydrate unit'))
                }
            } else {
                callback()
            }
        }},
    ],
    totalSugar: [
        {required: true, message: 'Please input total sugar', trigger: 'blur', validator: (rule, value, callback) => {
            if (!isNaN(value) && value >= 0) {
                callback()
            } else {
                callback(new Error('Please input valid total sugar'))
            }
        }},
    ],
    totalSugarUnit: [
        {required: true, message: 'Please select total sugar unit', trigger: 'blur', validator: (rule, value, callback) => {
            if (!isNaN(product.value.totalSugar) && product.value.totalSugar > 0) {
                if (value) {
                    callback()
                } else {
                    callback(new Error('Please select total sugar unit'))
                }
            } else {
                callback()
            }
        }},
    ],
    dietatyFiber: [
        {required: true, message: 'Please input dietaty fiber', trigger: 'blur', validator: (rule, value, callback) => {
            if (!isNaN(value) && value >= 0) {
                callback()
            } else {
                callback(new Error('Please input valid dietaty fiber'))
            }
        }},
    ],
    dietatyFiberUnit: [
        {required: true, message: 'Please select dietaty fiber unit', trigger: 'blur', validator: (rule, value, callback) => {
            if (!isNaN(product.value.dietatyFiber) && product.value.dietatyFiber > 0) {
                if (value) {
                    callback()
                } else {
                    callback(new Error('Please select dietaty fiber unit'))
                }
            } else {
                callback()
            }
        }},
    ],
    frontImage: [
        {required: true, message: 'Please upload front image', trigger: 'blur', validator: (rule, value, callback) => {
            if (value) {
                callback()
            } else {
                callback(new Error('Please upload front image'))
            }
        }},
    ],
    nutritionFactImage: [
        {required: true, message: 'Please upload nutrition fact image', trigger: 'blur', validator: (rule, value, callback) => {
            if (value) {
                callback()
            } else {
                callback(new Error('Please upload nutrition fact image'))
            }
        }},
    ],
})

const form = ref(null)
const formLoading = ref(false)
const formErrors = ref(new Errors())

onMounted(() => {
    if (route.params.upc) {
        product.value.upc = route.params.upc
    }
    if (route.params.pmo_category) {
        product.value.pmo_category = route.params.pmo_category
    }
})

function cancel() {
    router.push({name: 'pmo'})
}

function saveSubmit() {
    form.value.validate(valid => {
        // debugger
        if (valid) {
            formLoading.value = true
            formErrors.value.clear()
            labelinsightApi.addProduct(prepareFormData()).then(response => {
                // debugger
                if (response.data.product && response.data.product.upc) {
                    ElMessage.success('Product added successfully')
                    router.push({name: 'product.frequency', params: {upc: response.data.product.upc, pmo_category: product.value.pmo_category}})
                } else {
                    ElMessage.error('Error while adding product')
                }
            }).catch(errors => {
                formErrors.value.record(errors.response.data.errors)

                ElMessage.error('Error while adding product')
            }).finally(() => {
                formLoading.value = false
            })
        } else {
            return false
        }
    })
}

function prepareFormData() {
    let formData = new FormData()
    // debugger
    for (let field in product.value) {
        if (product.value[field] === null) continue

        if (product.value[field] instanceof File) {
            formData.set(field, product.value[field])
        } else if (product.value[field] instanceof Object) {
            formData.set(field, JSON.stringify(product[field]))
        } else {
            formData.append(field, product.value[field])
        }
    }

    if (_.isString(product.value['frontImage'])) {
        formData.delete('frontImage')
    }
    if (_.isString(product.value['nutritionFactImage'])) {
        formData.delete('nutritionFactImage')
    }

    return formData
}

function beforeFileUploadFront(file, fileList) {
    return beforeFileUpload(file, fileList, 'front')
}

function beforeFileUploadNutritionFact(file, fileList) {
    return beforeFileUpload(file, fileList, 'nutritionFact')
}

function beforeFileUpload(file, fileList, imageType) {
    if (fileIsTooBig(file)) {
        return false
    }

    if (file.raw.type !== 'image/jpeg' && file.raw.type !== 'image/png') {
        ElMessage.error('This file should be jpeg or png')
        return false
    }

    if (imageType === 'front') {
        product.value.frontImage = file.raw //URL.createObjectURL(file.raw)
    } else {
        product.value.nutritionFactImage = file.raw //URL.createObjectURL(file.raw)
    }

    return true
}

function fileIsTooBig(file, sizeMb = 10) {
    if (file.size / 1024 / 1024 > sizeMb) {
        ElMessage.error('This file should be less then ' + sizeMb + ' Mb')
        return true
    }
    return false
}

function takePhoto(type) {
    showCameraContainer.value = true
    captureType.value = type
}

function cameraStarted() {
    console.log('cameraStarted')
}

function cameraNotSupport() {
    console.log('cameraNotSupport')
    showElUploadButtons.value = true
}

async function cameraSnapshot() {
    const blob = await cameraRef.value.snapshot(
        { width: 1920, height: 1080 },
        'image/png',
        0.9
    )

    const file = new File([blob], captureType.value + '.png', { type: 'image/png' })
    if (captureType.value === 'frontImage') {
        form.value.frontImage = file
        product.value.frontImage = file
    } else {
        form.value.nutritionFactImage = file
        product.value.nutritionFactImage = file
    }
    cameraRef.value?.stop()
    showCameraContainer.value = false
    cameraKey.value++
}

function cancelCamera() {
    cameraRef.value?.stop()
    showCameraContainer.value = false
}

function getUrlFromFile(file) {
    if(file instanceof File) return URL.createObjectURL(file)
    return ''
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";

.add-product {
    h1 {
      margin-bottom: 15px;
    }
}
.center {
    text-align: center!important;
}
.left {
    text-align: left!important;
    padding-left: 24px;
}

.cancel_button {
  @media all and (max-width: 768px) {
    margin-top: 10px;
    margin-left: 0;
  }
}

.camera-container-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: 100;

    .button-container {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
    }
}

</style>
