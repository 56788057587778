<template>
    <el-radio-group
        v-model="question.answer"
        class="radio-block"
        @change="emits('set', question._id, question.answer)"
    >
        <el-radio
            v-for="variant in question.variants"
            :key="variant._id"
            :label="variant._id"
        >
            <span v-html="variant.value" />
        </el-radio>
    </el-radio-group>
</template>

<script setup>
const props = defineProps({
    question: {
        type: Object,
        required: true,
    }
})
const emits = defineEmits(['set'])
</script>

<style lang="scss">
.radio-block, .radio-block .el-radio {
    display: block;
}
</style>
