<template>
    <div>
        <h1>Verification</h1>
        <el-form
            ref="resendForm"
            :model="form"
            :rules="rules"
            label-position="left"
            label-width="120px"
            @submit.prevent="resend"
        >
            <el-form-item
                prop="email"
                label="Email"
                class="form-group"
            >
                <el-input
                    v-model="form.email"
                    name="email"
                    type="email"
                />
            </el-form-item>
            <el-form-item style="width:100%;">
                <el-button
                    class="w-100"
                    type="success"
                    @click.prevent="resend"
                >
                    Resend
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>

import authApi from '../authApi'

export default {
    name: 'ResendVerification',
    data() {
        return {
            form: {},
            rules: {
                email: [
                    {required: true, message: 'Email is required', trigger: 'blur'},
                    {type: 'email', message: 'Invalid email', trigger: ['blur', 'change']}
                ],
            },
        }
    },
    methods: {
        resend() {
            authApi.resend(this.form).then(response => {
                this.$message({
                    message: response.data.message,
                    type: response.data.type
                })
            })
        }
    },
}
</script>
