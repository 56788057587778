<template>
    <section class="main">
        <Header />
        <Notifications />
        <Child />
    </section>
</template>

<script setup>
import Child from '~/base/components/Child'
import Header from '~/modules/learning-modules/components/HeaderLesson'
import Notifications from '~/base/components/notifications/NotificationsDeprecated'
</script>
