<template>
    <el-button
        v-if="showSkipButton"
        round
        @click="openQuiz"
    >
        Skip Ahead, I Know This
    </el-button>
    <RobotDialog
        v-if="showRobot"
        :messages="robotMessages"
        robot-image="/images/robots/robot_hand_up.png"
        :show-bricks-animation="false"
        button-text="Got It"
        :button-callback="buttonCallback"
    />
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'

import RobotDialog from '~/modules/quiz/components/RobotDialog'
import store from "~/store";

const props = defineProps({
    module: {
        type: Object,
        required: true
    },
    buttonIndex: {
        type: String,
        default: 0
    }
})

const router = useRouter()
const authUser = computed(() => store.getters['auth/user'])
const showRobot = ref(false)
const isFirstClick = ref(!props.buttonIndex)
const showSkipButton = ref(false) // see #1262 (Please hide and comment out the button for all learning modules.)

const robotMessages = computed(() => {
    if (authUser.value.client_level && authUser.value.client_level.has_starts_points_system === true) {
        return ['Answer a couple of questions to show you understand, and you can unlock the next section.',
            'You can also go back and review the content any time.']
    } else {
        return ['Answer a couple of questions to show you understand, and you can unlock the next section.',
            'You\'ll still earn stars, and you can also go back and review the content any time.']
    }
})

function openQuiz() {
    if (isFirstClick.value) {
        //show robot
        showRobot.value = true
    } else {
        routeToQuiz()
    }
}

function routeToQuiz() {
    router.push({name: 'learning-modules.quiz', params: {quiz_id: props.module._id}, query: {}})
}

function buttonCallback() {
    showRobot.value = false
    routeToQuiz()
}

</script>
