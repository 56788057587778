<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="default-text default-text--offset-bottom default-text--bold">
                    Sneaky Snacks
                </div>
                <div class="default-text default-text--offset-bottom">
                    Drag the snacks in order from <strong>lowest</strong> to <strong>highest sugar</strong>, based on grams added.
                </div>
                <div class="default-text default-text--offset-bottom">
                    This game is meant to show your child better snack choices to help with their energy and focus through the day.
                </div>
                <div class="default-text default-text--bold">
                    Lowest Sugar
                </div>
                <OrderGame
                    :products="products"
                    @done="done = true"
                />
                <div class="default-text default-text--bold default-text--align-right">
                    Highest Sugar
                </div>
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__button-wrap">
                    <el-button
                        :disabled="!done"
                        type="primary"
                        round
                        class="continue-button"
                        @click="clickDone"
                    >
                        Done
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import OrderGame from '~/modules/games/components/matches/OrderGame'
import {ref} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import pmoApi from '~/modules/pmo/pmoApi'
import userApi from '~/modules/profile/userApi'

const name = 'SneakySnacksKidsCamp'
const router = useRouter()
const route = useRoute()
const done = ref(false)

const products = ref([
    {
        id: 1,
        value: 12,
        name: 'Cookies',
        image: '/images/games/cookies-sandwich.png',
    },
    {
        id: 2,
        value: 1,
        name: 'Popcorn',
        image: '/images/games/popcorn.png',
    },
    {
        id: 3,
        value: 42,
        name: 'Candy',
        image: '/images/games/candy.png',
    },
    {
        id: 4,
        value: 9,
        name: 'Granola bar',
        image: '/images/games/granola-bar.png',
    },
    {
        id: 5,
        value: 8,
        name: 'Yogurt',
        image: '/images/games/yogurt.png',
    },
    {
        id: 6,
        value: 0,
        name: 'Almonds',
        image: '/images/games/almonds.png',
    },
    {
        id: 7,
        value: 20,
        name: 'Peanut butter cup',
        image: '/images/games/peanut-butter-cup.png',
    },
    {
        id: 8,
        value: 0,
        name: 'Cheese',
        image: '/images/games/cheese.png',
    },
])

function clickDone() {
    userApi.setFirstExperience('pmo_sub_' + route.name.split('.')[1] + '_finished')
    pmoApi.gameFinished(route.name.split('.')[1])
    // router.push({name: 'pmo'})
    router.push({name: 'pmo.welcome-game', params: {game_slug: "sneaky-snacks"}})
}
</script>
