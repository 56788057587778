<template>
    <section class="main">
        <Header />
        <NavTabs />
        <Notifications />
        <child />
    </section>
</template>

<script setup>
import Child from '~/base/components/Child'
import Header from '~/base/components/Header'
import NavTabs from '~/base/components/NavTabs'
import Notifications from '~/base/components/notifications/NotificationsDeprecated'
</script>
