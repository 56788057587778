<template>
    <el-radio-group
        v-model="question.answer"
        class="el-radio-group--inline"
        @change="$emit('set', question._id, question.answer)"
    >
        <el-radio
            v-for="variant in question.variants"
            :key="variant._id"
            :label="variant._id"
            class="el-radio--inline"
        >
            {{ labels[variant.value] }}
        </el-radio>
    </el-radio-group>
</template>

<script>
export default {
    name: 'FormRadioInline',
    props: {
        question: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            labels: {
                1: 'NEVER',
                2: 'RARELY',
                3: 'SOMETIMES',
                4: 'FREQUENT',
                5: 'ALWAYS',
            }
        }
    },
}
</script>
