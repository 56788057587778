<template>
    <div>
        <div class="intro flex dark justify-space-between pt-4">
            <div class="container-holder">
                <div class="lp__column-l">
                    <div class="lp__content">
                        <h1 class="lp__title lp__title--h1 lp__title--white">
                            Directly Addressing the Health Disaster Associated with Poor-Quality Processed Foods
                        </h1>
                        <p class="lp__text lp__text--big lp__text--white">
                            For food-as-medicine to be effective over the long term, we must reduce the disease-causing ingredients concentrated in household food supplies.  SeekingSimple’s Digital Pantry Makeover does exactly that.
                        </p>
                    </div>
                </div>
                <div class="lp__column-r lp__column-r--width-desktop-big">
                    <div class="lp__image">
                        <img
                            class="lp__image-i"
                            src="/images/landing/intro-img.png"
                            alt=""
                        >
                    </div>
                </div>
            </div>
        </div>
        <div class="description-second flex reverse align-center justify-space-between">
            <div class="text-center">
                <h1 class="lp__title lp__title--h1--without-margin">
                    Processed Foods Are a Dominant Health Influence
                </h1>
                <p class="lp__text lp__text--big text-color-secondary">
                    Driving food-related disease for 230 million in the US <sup>1</sup>
                </p>
            </div>
            <div class="container-holder mt-4">
                <div class="lp__column-l lp__column-l--increased">
                    <div>
                        <p class="lp__text">
                            Most food-as-medicine solutions attempt to significantly reduce processed food consumption.
                        </p>
                        <p class="lp__text">
                            Giving up processed food means giving up time savings and convenience.
                        </p>
                        <p class="lp__text mb-0">
                            Most people see this as a significant impact to lifestyle and reject it.
                        </p>
                    </div>
                </div>
                <div class="lp__column-r lp__column-r--width-desktop">
                    <div class="lp__grocery-image">
                        <img
                            class="lp__grocery-image__image"
                            src="/images/landing/grocery.jpg"
                            alt=""
                        >
                        <div class="lp__grocery-image__text">
                            <p>Average person gets ~2/3 of their daily calories from processed food <sup>2</sup></p>
                            <p>The average household stocks dozens of processed food items</p>
                            <p class="mb-0">~85% of the disease-causing agents are found in these foods <sup>3</sup></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="reference">
                <el-divider class="reference__divider" />
                <ul class="reference__list">
                    <li>Per US Dept. of Health</li>
                    <li>JAMA Trends in Consumption of Ultra Processed Foods + American Journal of Clinical Nutrition</li>
                    <li>UCLA analysis of diet efficacy based on the results of 31 long-term studies</li>
                </ul>
            </div>
        </div>
        <div class="description-first flex reverse align-center justify-space-between">
            <div class="lp__ss-image">
                <img
                    src="/images/landing/SS-image.svg"
                    alt="logo"
                >
            </div>
            <div class="description-first__content">
                <div class="description-first__right-background"></div>
                <div class="description-first__right-background-round"></div>
                <div class="container-holder">
                    <div class="lp__column-l lp__column-l--width-desktop">
                        <div class="lp__ss-image-mobile">
                            <img
                                src="/images/landing/SS-image.svg"
                                alt="logo"
                            >
                        </div>
                        <div class="lp__content">
                            <ul>
                                <li class="mb-2">Disease specific guided journeys</li>
                                <li class="mb-2">Scans product labels and provides local recommendations</li>
                                <li class="mb-2">Incorporates best practices in human engagement</li>
                                <li class="mb-2">Incentivizes new purchase behaviors</li>
                                <li>Complete programs designed to engage lower income, higher risk populations</li>
                            </ul>
                        </div>
                    </div>
                    <div class="lp__column-r lp__column-l--increased text-center">
                        <div class="lp__content-r">
                            <h1 class="lp__title lp__title--h1--without-margin mb-2">
                                Consumer Savvy Solution
                            </h1>
                            <p class="lp__text text-color-secondary">
                                <strong>Our "Digital Pantry Makeover" reduces disease-causing <u>ingredients</u> in the processed food we eat...</strong>
                            </p>
                            <div class="lp__summary-image">
                                <img
                                    src="/images/landing/SS-Summary.png"
                                    alt="summary"
                                >
                            </div>
                            <p class="lp__text">
                                <strong>We don't change <u>lifestyles</u>, we simply change the products people buy</strong>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--        <div class="about flex align-center justify-space-between">-->
        <!--            <div class="container-holder">-->
        <!--                <div class="lp__column-l lp__column-l&#45;&#45;width-desktop">-->
        <!--                    <div class="lp__image">-->
        <!--                        <img-->
        <!--                            src="/images/landing/about-img.png"-->
        <!--                            alt=""-->
        <!--                        >-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="lp__column-r">-->
        <!--                    <div class="lp__content">-->
        <!--                        <h2 class="lp__title">-->
        <!--                            SeekingSimple is the new&nbsp;paradigm-->
        <!--                        </h2>-->
        <!--                        <p class="lp__text">-->
        <!--                            One where we move away from what is hard for people to do, to something easy. By focusing on a simple, consumer savvy digital method for reducing a target ingredient in households, SeekingSimple maintains core food culture while changing the trajectory of the most common chronic diseases.-->
        <!--                        </p>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
        <landing-info-block
            :title="infoBlockFirst.title"
            :subtitle="infoBlockFirst.subtitle"
            :quote="infoBlockFirst.quote"
            :image="infoBlockFirst.imagePath"
            :without_quote="infoBlockFirst.without_quote"
        />

        <div class="adapt-block">
            <div class="container-holder">
                <div class="adapt-block__title lp__title">
                    Simply changing the density of target food ingredients in household foods is easy to adopt
                </div>
                <div class="adapt-block__inner">
                    <div class="adapt-block__content">
                        <div class="adapt-block__content-text">
                            100% of Digital Pantry Makeover participants say it's easy compared to a diet
                        </div>
                    </div>
                    <div class="adapt-block__image">
                        <img
                            src="/images/landing/adapt-block-img.jpg"
                            alt=""
                            class="adapt-block__image-i"
                        >
                    </div>
                </div>
            </div>
        </div>

        <landing-info-block
            :title="infoBlockSecond.title"
            :subtitle="infoBlockSecond.subtitle"
            :quote="infoBlockSecond.quote"
            :image="infoBlockSecond.imagePath"
            offset-top
            title-color-blue
            quote-bg-green
        />
    </div>
</template>

<script setup>
import LandingInfoBlock from './LandingInfoBlock'

const infoBlockFirst = {
    title: 'Pantry Makeover works where diets don’t...',
    subtitle: 'and it works for the entire household',
    imagePath: '/images/landing/info-block-img-1.png',
    without_quote: true
}
const infoBlockSecond = {
    title: 'Embracing consumer eating behaviors and household food culture encourages adoption',
    quote: 'By changing the household’s processed food products rather than an individual’s eating behaviors, we get broader buy in and engagement, and have the potential for generational change in processed food awareness.',
    imagePath: '/images/landing/info-block-img-2.jpg'
}
</script>
