<template>
    <section class="main">
        <child />
    </section>
</template>

<script setup>
import Child from '~/base/components/Child'
const name = 'ProductLayout'
</script>
