<template>
    <div class="main-content main-content--offset-bottom">
        <div class="g-container">
            <div class="tips default-content">
                <h1 class="text-center">
                    SeekingSimple Pantry Makeover FAQ
                </h1>
                <div><u class="title">WHAT IS SEEKINGSIMPLE™:</u>
                    We are a company that believes food really can be medicine. Our mission is to help you easily find enjoyable, convenient food alternatives in your local grocery store that are lower in disease causing ingredients.
                </div>
                <ol>
                    <li>
                        <strong>I don’t understand what SeekingSimple™ and the Pantry Makeover is. Can you explain?</strong>
                        The Pantry Makeover provides an opportunity to easily reduce disease causing ingredients from your household food supply. It is a step-by-step process of inventorying items in your pantry prioritizing them by worst offenders with the highest density of sugar and carbohydrates in your household. SeekingSimple’s Pantry Makeover takes the guesswork out of identifying better products that you can find in your favorite grocery store.
                    </li>
                    <li>
                        <strong>Why focus on reducing sugar and carbohydrates?</strong>
                        Clinical science is making it clear that reducing sugar and carbohydrates may be the most important ingredients to improve health and other aspects such as sleep and stress. We realize that it can be a daunting task to figure out which products in our pantry contribute to our highest consumption of these proven disease-causing ingredients. We make it easy to find better quality products for you and your household.
                    </li>
                    <li>
                        <strong>What Health Condition is SeekingSimple™ trying to impact?</strong>
                        Currently we are beginning with Metabolic Disease. Research shows that many chronic health conditions, like heart disease, diabetes and obesity begin with the metabolic dysfunction identified in the process of Metabolic Disease.
                    </li>
                    <li>
                        <strong>I just want to start grocery shopping. What can I do?</strong>
                        The Pantry Makeover process is not another grocery shopping app. We have included education and behavioral science to make your experience easier and more enjoyable. We have found that breaking your pantry down into easily managed categories and identifying your individual household food culture leads to lasting change that is relevant to you and your household. Whether that is improving your sleep or the health of your family, understanding more than just your shopping list will help us help you create lasting change.
                    </li>
                </ol>

                <div><u class="title">SETTING UP YOUR PROFILE</u></div>
                <ol>
                    <li>
                        <strong>Aspirations:</strong>
                        Aspirations = behavioral science = your why. Aspirations are an evaluation of your progress as you implement replacement items found during your Pantry Makeover.
                    </li>
                    <ol type="a">
                        <li>
                            <strong>Why is SeekingSimple™ asking me to choose an aspiration?</strong>
                            Establishing what is important to you and how it relates to food is our focus at SeekingSimple™. We believe that you can improve daily health issues in your life by altering the foods you consume.
                        </li>
                        <li>
                            <strong>Why can’t I choose multiple aspirations?</strong>
                            Changing the density of sugar and carbohydrates you consume will improve all the aspirations. We want you to focus on the one aspiration that is most important for you so we can help you quantify your success.
                        </li>
                        <li>
                            <strong>How will my Aspiration be used to motivate me?</strong>
                            We will regularly check in after you purchase and rate products to see if your aspiration scale has changed. Our goal is to help you link how you are feeling with food you eat.
                        </li>
                        <li>
                            <strong>Can I change my aspiration?</strong>
                            Currently we do not support changing your aspiration. You can update it regularly by visiting “Aspiration Check-in” under the “Help” menu.
                        </li>
                        <li>
                            <strong>Where do I update my aspiration status?</strong>
                            You can update your aspiration by visiting “Aspiration Check-in” under the “Help” menu.
                        </li>
                        <li>
                            <strong>What does purchasing products have to do with my aspiration?</strong>
                            Reducing disease causing ingredients like sugar and carbohydrates will help improve your aspiration or daily aspects of your life. The more alternative food recommendations you integrate into your life, the more you will reduce these ingredients and will see improvement in your aspiration.
                        </li>
                    </ol>

                    <li>
                        <strong>Household:</strong>
                        Household = term we use to describe all members living under one roof consuming the available food supply.
                    </li>
                    <ol type="a">
                        <li>
                            <strong>Why is SeekingSimple™ asking about the number and age of people in my household?</strong>
                            We recognize that a pantry is a shared group of products that multiple people may be eating. We aim to address your individual household food culture by understanding who might benefit from improved products we are going to recommend during your Pantry Makeover.
                        </li>
                        <li>
                            <strong>I have a child under 8, what will they be expected to do?</strong>
                            We have created some content in the form of videos and games to help engage children under 8. This is not mandatory, but we have found it helpful to educate children so they will be more receptive to new food products when they are brought into your household.
                        </li>
                        <li>
                            <strong>What is a Food Decision Maker?</strong>
                            The Food Decision Maker is the person who makes most of the decisions about which foods are purchased on a regular basis to fill a household pantry. They plan the meals, create the shopping list, and generally make most of the food purchasing decisions.
                        </li>
                        <li>
                            <strong>What is different about the Food Decision Makers experience if I invite one within SeekingSimple™?</strong>
                            If you invite your Food Decision Maker (FDM) they will have a very similar beginning experience to yours. Their account will be linked to yours; they will have their own aspiration and they will have access to all the education content. The major difference is that whoever is considered the FDM will have access to the Pantry Makeover process and will be able to scan and replace products in the household.
                        </li>
                        <li>
                            <strong>What if my Food Decision Maker does not want to join me and work through this process? Can I do it on my own?</strong>
                            Absolutely. The goal is to make better products available to you and your household. You can do the Pantry Makeover process and share the shopping list with whoever does the grocery shopping.
                        </li>
                        <li>
                            <strong>What happens when my Food Decision Maker joins SeekingSimple™?</strong>
                            They will respond to an email exactly as you did. They will create their own account which will be linked to yours and they will build their profile. They will go through the same LEARN process and have access to all the education content. The major difference is that they will have access to the Pantry Makeover process and will be able to scan and replace products for your household.
                        </li>
                        <li>
                            <strong>Why do I need to select a specific grocery store?</strong>
                            We realize that finding improved products in a store can be challenging for many reasons. By partnering with your local grocery store, the one you select, we help to ensure the products that you choose as a replacement are available to you.
                        </li>
                        <li>
                            <strong>What if I don’t have a Loyalty Number?</strong>
                            You may select “I don’t have a loyalty number’ and advance to the next screen.
                        </li>
                        <li>
                            <strong>What will the grocery Loyalty Number be used for?</strong>
                            Our goal is to support your improved buying decisions by working with your retailers. By utilizing the loyalty program, the grocer can pass along specific savings on healthy products that you choose that will lower disease causing ingredients in your household food supply.
                        </li>
                    </ol>

                    <li>
                        <strong>Learning and interaction style:</strong>
                        Learning and interaction style utilize behavioral science to support how we communicate with you, speak your language, customize your journey, and individualize the approach to better support your success.
                    </li>
                    <ol type="a">
                        <li>
                            <strong>Why is SeekingSimple™ asking me these questions?</strong>
                            Our aim is to support you in making a positive change by making healthy products easy to choose. If we understand you better, we can deliver information and engagement activities that match your style. For your success, making it simple in a way that is easy and comfortable requires a little more understanding.
                        </li>
                        <li>
                            <strong>How will the motivation information be used by SeekingSimple™?</strong>
                            It allows us to present information to you in a way that interests and engages you. Understanding you allows us to individualize the program to fit you.
                        </li>
                        <li>
                            <strong>What if none of the descriptions really fit me?</strong>
                            We don’t expect that everything in our description is going to be a correct assessment of you. Our hope is to get close, does some of this ring true? We will do our best to make this program seem effortless with a little help.
                        </li>
                        <li>
                            <strong>Why is SeekingSimple™ asking me to choose a time frame to complete the Pantry Makeover?</strong>
                            Overhauling your entire pantry can be daunting. We are breaking the process down into smaller sized pieces so you can make selections, make purchases, and then rate what you like or dislike. We want you to do it over some time, to have an opportunity to integrate changes and try improved products on a normal shopping cadence. We expect this to take anywhere from under 60 to about 120 days.
                        </li>
                        <li>
                            <strong>Can I change how quickly I want to complete the Pantry Makeover?</strong>
                            It is easy to change how quickly or slowly you choose to complete the Pantry Makeover. Go to “Profile” under the “Help” menu and choose “Notifications & Rate of Change”. We understand that things come up in life and we are not able to allocate the time we originally assumed would be available. Completing the Pantry Makeover process and integrating improved products into your household food supply is our goal, you set the pace.
                        </li>
                        <li>
                            <strong>How and how often will SeekingSimple™ contact me?</strong>
                            This depends on the Rate of Change you selected when you built your profile. Our expected rhythm for sending notifications is from once a day to once a week. We will remain focused on encouragement as we support you in this process.
                        </li>

                        <li>
                            <strong>How can I opt out of or change how I get the notifications and messages sent by SeekingSimple™?</strong>
                            It is easy to change your Notification schedule. Go to “Profile” under the “Help” menu and choose “Notifications & Rate of Change”. Our notifications are linked to the speed at which you choose to complete the process. You can choose to increase or decrease the pace of the messaging, or you can opt out completely here. Completing the Pantry Makeover process and integrating improved products into your household food supply is our goal, you set the tone of how you want to be supported.
                        </li>
                    </ol>
                </ol>

                <div><u class="title">WHAT DOES THE SEEKINGSIMPLE™ LEARN SECTION INTEND TO DO?</u>
                    – It is our belief that knowledge is power, and <b>a person who has been educated has control of their life by using the strength of knowledge.</b> The ability to acquire knowledge, preserve and pass it on to the future generation makes us powerful. It enables us to control external forces like food selection and use this for the health benefits of our entire household. The World Health Organization states: “Health literacy implies the achievement of a level of knowledge, personal skills and confidence to take action to improve personal and community health by changing personal lifestyles and living conditions.”
                </div>

                <ol>
                    <li>
                        <strong>How can I review the information SeekingSimple™ presents?</strong>
                        Information is available to view as a Video or to read as a Transcript. Links to References are provided as well so you can do additional research if you are interested. Our methods are supported by science.
                    </li>
                    <li>
                        <strong>Where can I reference the Tips that I find Helpful after I leave the learning module?</strong>
                        “TIPS” are available to you beneath the “Help” menu. They are sorted with the ones you found helpful listed at the top.
                    </li>
                    <li>
                        <strong>What is Skip Ahead?</strong>
                        If you feel you are proficient with the education content in each section as described beneath the icon, you can choose “Skip Ahead” and answer a few questions to confirm. We have found most people enjoy the short education refresher videos and recommend reviewing all the content.
                    </li>
                    <li>
                        <strong>Can I share this information with my friends and family?</strong>
                        Absolutely! You will see the share icon on the topmost tool bar beside the stars you have earned when you are on a specific learn module. This will allow you to share a link to each video.
                    </li>
                    <li>
                        <strong>How do I accumulate Stars and Points?</strong>
                        You earn stars for each action you take as you go through the Pantry Makeover program. Complete a LEARN module, choose a great Replacement product with less sugar and carbs, or Rate a product that you have purchased and tried are a few of the ways you accumulate stars and points.
                    </li>
                    <li>
                        <strong>What can I do with the Stars and Points?</strong>
                        This will depend on the company who invited you to participate. The stars are fundamentally a way to indicate your progress and award you for milestones you are achieving in each area of the Pantry Makeover Process. Please review your recruitment information to confirm how these points may be integrated for you.
                    </li>
                    <li>
                        <strong>How can I revisit and look at the content under the LEARN section?</strong>
                        The content remains accessible after you have reviewed it the first time. From the top toolbar merely click on “LEARN” and all the content is available beneath the appropriate Learn section icon.
                    </li>
                    <li>
                        <strong>WHAT IS A SEEKINGSIMPLE™ PANTRY MAKEOVER?</strong>
                        Pantry Makeover provides an opportunity to easily reduce disease causing ingredients from your household food supply. It is a step-by-step process of inventorying items in your pantry prioritizing them by worst offenders with the highest density of sugar and carbohydrates in your household. SeekingSimple’s Pantry Makeover takes the guesswork out of identifying better products that you can find in your favorite grocery store.
                    </li>
                    <li>
                        <strong>What is the logic of the six different categories in the Pantry Makeover?</strong>
                        The categories are organized by ingredient density. By addressing the pantry areas with the highest sugar and carbohydrates first we support you in making the most significant difference to your household food supply.
                    </li>
                    <li>
                        <strong>How long should it take to complete the Pantry Makeover?</strong>
                        We have set the process up into bite sized pieces. You can choose to complete the entire process in 60, 90 or 120 days. This means doing each of the 6 categories over 1 – 2.5 weeks each. We want you to have time to choose replacements, make purchases, have your household try the new items, and rate the products you selected.
                    </li>
                    <li>
                        <strong>Why do I need to select types of sugars in Sugar Substitutes?</strong>
                        There are many alternatives when it comes to products with reduced sugar. A lot of research has gone into natural and synthetic sugar replacements, and we want to ensure that we recommend products that meet your preferences.
                    </li>
                    <li>
                        <strong>Can I change my mind and update the ingredients and Sugar Substitutes I allow? Where do I update my preferences?</strong>
                        Absolutely! Under the “Help” menu choose “Profile” then you will see “Sugar Substitutes” where you can change and update your preferences at any time. All future replacement suggestions will take the new selections into account.
                    </li>
                    <li>
                        <strong>Why can’t I choose any of the categories? It is forcing me to start with Liquid Candy.</strong>
                        We have created a process through your pantry which guides you to the locations where the highest density of sugar and carbs reside. Based on current science beverages are the leading source of added sugars in the American diet. Addressing these areas logically and in order ensure that we support you in addressing the disease-causing ingredients efficiently as you work through your Pantry Makeover.
                    </li>
                    <li>
                        <strong>How do I scan items to find healthier alternatives?</strong>
                        In any subcategory, click on the “Scan Item” button. Your camera will appear with crosshairs. Hold the product you want to scan, barcode up to your camera, and the item will be captured.
                    </li>
                    <li>
                        <strong>What if a product is not returned after I have scanned the barcode?</strong>
                        There are a few reasons this may happen. Sometimes, based on the way products are bundled into a larger package like a 12 pack of soda. You may scan the individual product, but the correct barcode is on the outside of the 12 pack. This can happen with Costco products. In addition, some private label products for specific grocers are not shared or the product is so new it is not yet in the database.
                    </li>
                    <li>
                        <strong>Should I add items that we consume regularly but don’t currently have in my Pantry? How do I do this?</strong>
                        Great question. Reducing disease causing ingredients is the goal of the Pantry Makeover. If you or your household regularly consumes any product that is currently not available to scan you can search for it in the database. On any subcategory screen choose the “Search” button.
                    </li>
                    <li>
                        <strong>What if the product is not found when I search?</strong>
                        Sorry to say it may be the description. Try searching by Brand if you know it. Sometimes private label products for specific grocers are not shared or the product is so new it is not yet in the database.
                    </li>
                    <li>
                        <strong>Why did I get the message “Look at other Pantry Makeover categories….” when I scanned an item?</strong>
                        We have created a set of subcategories with complex algorithms to help give you the best possible recommendations. If you are scanning a product in the wrong subcategory the system will let you know which other subcategory will provide a better set of replacements.
                    </li>
                    <li>
                        <strong>Why is SeekingSimple asking me to enter how frequently and how much of a product I consume.</strong>
                        To make the most impact on reducing disease causing ingredients we want to focus on the items that have the highest amounts of sugar and carbs. Identifying the frequency and actual consumption of the products you consume will give a more accurate picture of your true consumption. This way we can focus on a smaller number of products that may be causing the biggest problem.
                    </li>
                    <li>
                        <strong>Why are items on the Sugar Inventory List being identified?</strong>
                        We calculated the total sugar and total net carbohydrates you consume per week based on what you entered in frequency and actual for each item you scanned. Based on research, we set a threshold for both sugar and net carbs, if your item exceeded this it was placed on the Sugar inventory List. You now have the option to find healthier replacement items for your household by clicking on “replace”.
                    </li>
                    <li>
                        <strong>Why can’t I choose to Replace items designated “Item to Retain”?</strong>
                        The goal is to proceed through your entire Pantry Makeover making changes to disease causing ingredients category by category. We have tried to prioritize the products with the highest sugar and carbohydrate in the Replace section. The items indicated as those to Retain may not be perfect, but for the first pass through the Pantry Makeover these products are not where to focus.
                    </li>
                    <li>
                        <strong>At the bottom of the Sugar Inventory List what does “Potential gm saved” mean?</strong>
                        Every time you choose to Replace, Reduce or Remove a product we show you the potential grams of sugar you will save or remove from your household food supply. It is a “potential” number because we recognize that the replacement products must be purchased and integrated into regular consumption. The ongoing practice of reducing or removing products indicated are also a future “potential” for reduction in disease causing ingredients.
                    </li>
                    <li>
                        <strong>How do I add products to a Sugar Inventory List at the end of a category once I have completed it?</strong>
                        In the top right corner of this list, you will see a pantry icon with a plus “+” sign. When you click on it, it will return you to the subcategories to add an item.
                    </li>
                    <li>
                        <strong>Why can’t I edit an item after I have completed my Pantry Makeover category to change the frequency and actual consumption to change the g/s or g/nc totals on the Sugar Inventory List?</strong>
                        We ask you after each item is chosen to “adjust” if necessary. This is your opportunity during the process of the Pantry Makeover to confirm the grams of sugar (g/s) and grams of net carbs (g/nc) you are consuming. The Sugar Inventory List is where you take action to modify your behavior and make new changes. You can choose to Reduce your consumption to show a savings.
                    </li>
                    <li>
                        <strong>What if I don’t like any of the alternatives that are presented after I click on “Replace” an item?</strong>
                        After completing a category and choosing “Replace” from the Sugar Inventory List, if you do not like any of the replacements suggested you can always choose “Save Original Item” at the bottom of the page. Note, this item will NOT be placed on your “My Items” list and it will not qualify for the subsidized Purchase Program (if offered by your company). Our goal is to help you reduce unhealthy ingredients by choosing replacement products and trying them.
                    </li>
                    <li>
                        <strong>Why is SeekingSimple™ returning these replacement alternatives? How do I know these products are better?</strong>
                        The goal of the Pantry Makeover is to reduce harmful ingredients. The replacement products that are being suggested to you are lower in sugar and carbohydrates. You can quickly compare “Sugar per oz” and “Net Carb per oz” to see the reduction compared to your original item. This is important because it takes the unreliable package serving size out of the equation and makes your decision simple.
                    </li>
                    <li>
                        <strong>Why is SeekingSimple™ returning these replacement alternatives? How do I know these products are better?</strong>
                        The goal of the Pantry Makeover is to reduce harmful ingredients. The replacement products that are being suggested to you are lower in sugar and carbohydrates. You can quickly compare “Sugar per oz” and “Net Carb per oz” to see the reduction compared to your original item. This is important because it takes the unreliable package serving size out of the equation and makes your decision simple.
                    </li>
                    <ol>
                        <li>
                            <strong>Please explain what the information on the screen means.</strong>
                        </li>

                        <ol>
                            <li>
                                <strong>Total size per item (pkg)</strong>
                                This refers to the total number of ounces in the entire package you are scanning.
                            </li>
                            <li>
                                <strong>Sugar per fl oz. or per oz.</strong>
                                To make a proper comparison we take the total size of the package and divide the total sugar to arrive at a per ounce number. Comparing oz per oz gives you a much better idea of what recommendation to choose.
                            </li>
                            <li>
                                <strong>Net Carb per fl oz or per oz</strong>
                                Net carbs refers to the total carbohydrates minus fiber. This is an important number because fiber is helpful in slowing the release of sugar produced from the carbohydrate conversion during digestion. Net Carb per ounce takes the total size of the package and divides the total net carbs to arrive at a per ounce number.
                            </li>
                            <li>
                                <strong>Total sugar per item (pkg)</strong>
                                We chose to show you this number because in many instances you may consume an entire package and the nutrition label suggestion for serving size is inaccurate. This is the total ounces in the product multiplied by the sugar per ounce.
                            </li>
                        </ol>
                    </ol>

                    <li>
                        <strong>How do I find more information about these products?</strong>
                        There are multiple ways to access the full nutrition and product information. When you have scanned a product, you can click on the “Product Description”. When you are on a subcategory with “originally scanned items” beneath it, you can click on the “Image”. When you are on the replacement screen you can click on any “Product Description”. In most cases, you may select the specific product image or description to see the full product information.
                    </li>
                    <li>
                        <strong>How do I select an alternative?</strong>
                        From the Sugar Inventory List click on “Replace”. Your original product will be displayed with a set of products lower in harmful ingredients below, in most cases you may need to scroll right for more alternatives. By clicking on the “Image”, you indicate this is your choice for a replacement. You will see the image highlight and a “Radio Button” activate. You can then click “Save Alternative”.
                    </li>
                    <li>
                        <strong>What happens when I select a Recommendation?</strong>
                        When you have made a replacement selection and click “Save Alternative”, this item now gets added to your “My Items” list which is an easy to access shopping list for purchasing healthier products. Your Sugar Inventory List also gets updated, and you will see the grams of sugar you are set to save.
                    </li>
                    <li>
                        <strong>Can I choose multiple recommendations?</strong>
                        To keep the process simple, we have made it that you replace one item at a time. It does not mean you can’t in future come back and try a different alternative, but the goal is to get you on track for trying new, improved products at every shopping outing.
                    </li>
                    <li>
                        <strong>Why would I choose “We Don’t Drink or Eat This”? </strong>
                        There may be instances where there are a group of products that you and your household never consume. This button helps to expedite the Pantry Makeover Process and to congratulate you by giving you additional stars for this beneficial behavior.
                    </li>
                    <li>
                        <strong>Why does SeekingSimple™ present so many sub-categories in each Category?</strong>
                        There are thousands of foods in every grocery store and sometimes hundreds in your household. To make sure we guide you specifically to where the disease-causing ingredients are, we used sub-categories to break the process of inventorying your pantry into manageable pieces. We want to help you scan and inventory your entire food supply.
                    </li>
                    <li>
                        <strong>How do I move back to a previous sub-category?</strong>
                        At the bottom of the screen, you will see a navigation bar with arrows left and right. The left arrow will take you back to a sub-category, and the right will take you to the next one. If you haven’t completed an action in a sub-category there will be no right arrow available.
                    </li>
                    <li>
                        <strong>How do I get to the next Category?</strong>
                        Moving to the next category when one is completed is accomplished from the main Pantry Makeover Category screen. Click the “Go” button which is highlighted. Remember this is organized specifically to address the areas of highest density so the order of the categories is on purpose.
                    </li>
                    <li>
                        <strong>What does Revisit mean on the main Pantry Makeover Category screen?</strong>
                        If you want to review your Sugar Inventory List to make replacements or see your sugar reduction by category, you click “Revisit”. If you want to add a product to a category once it is complete you can also click “Revisit”.
                    </li>
                    <li>
                        <strong>How do I add an item to my Sugar Inventory List after I have completed a category?</strong>
                        In the top right corner of the Sugar Inventory List there is an icon with a bottle, bowl and “+” plus sign. Click this and it will take you back to your sub-category screens. To easily move through them use the arrow navigation bar at the bottom of the screen.
                    </li>
                </ol>

                <div class="mb-2"><u class="title">WHAT IS THE SUGAR INVENTORY LIST AND HOW DO I USE IT? </u>
                    The Sugar Inventory List is a summary of all the items you have entered from a single category. It quantifies the amount of sugar and net carbs you consume on a weekly basis by item and the list is sorted with the products that have the highest sugar content or net carbs at the top of the list. You can now go and choose Replacement items by clicking on the “Replace” option. The goal of your Pantry Makeover is to prioritize the products in each category that have the highest density of disease-causing ingredients.
                </div>

                <div><u class="title">WHAT IS THE MY ITEMS LIST USED FOR?</u>
                    My Items is a convenient list where all the Replacements for lower sugar and carbohydrate products that you have chosen are organized. At the top of the list, you will see the default grocery store you chose when setting up your profile where you can find these products. There is also a “Share” button that you can use to send the list to anyone of your choice. The list is divided into two areas, the top lists all products that have yet to be purchased, and the bottom is the list of items you have purchased or Replaced with an icon indicating that you rated these products as good – Thumbs UP.
                </div>
                <ol>
                    <li>
                        <strong>How do I indicate that I found and purchased items from this list?</strong>
                        Simply select the product by clicking in the “Box” next to the image. It will now move that item down to the Replaced section. It will wait for you to try the product, so you indicate whether this is a good product, give it a Thumbs UP, or not a great product that you give a Thumbs DOWN.
                    </li>
                    <li>
                        <strong>What is the logic of this list?</strong>
                        My Items is a convenient list where all the Replacements for lower sugar and carbohydrate products that you have chosen are organized. They are sorted by grocery category to make it easier for you to find them in your local store. The list is divided into two areas, the top lists all products that have yet to be purchased, and the bottom is the list of items you have purchased or Replaced with an icon indicating that you rated these products as good – Thumbs UP.
                    </li>
                    <li>
                        <strong>Can I change the store that I want to shop at?</strong>
                        Changing your preferred store is available under the “Help” menu. Click on “Profile” then select “Local Grocery Stores”. If you have moved or want to shop under a different zip code, you can adjust that first. Otherwise, all the stores available close to you that we partner with will be listed for you to change your selection. Please NOTE – all future replacement suggestions will search this new location.
                    </li>
                    <li>
                        <strong>Can I share this list with someone who is going to the store for me?</strong>
                        There is a “Share” icon next to your store selection. You can choose to send a written list via SMS or email to an address of your choice.
                    </li>
                    <li>
                        <strong>Why should I rate the products I have purchased with a Thumbs UP or Thumbs DOWN?</strong>
                        We want to keep your My Items list as relevant as possible. If you buy and like products, rating them Thumbs UP will keep them on your My Items list so they are easy to buy again to continue your reduction of harmful ingredients. If you dislike a product, by telling us why we then give you an opportunity to find a new replacement for the original product you chose that was identified as high in sugar.
                    </li>
                    <li>
                        <strong>What does the Sugar Report tell me?</strong>
                        This is a summary of your sugar progress across all categories that you have completed. It tells you how many products you have Replaced, Reduced or Removed and totals your sugar savings. It also indicates how many products you have yet to purchase to increase your sugar reduction.
                    </li>
                </ol>

                <div><u class="title">WHAT IS SIMPLY SPEND?</u>
                    Simply Spend is a digital grocery benefit program provided by your company. To help encourage healthier grocery shopping a specific amount of money (individually determined by your company) has been associated with your account. You can use this money at your participating grocery store to purchase the healthier replacement grocery items. A QR code will be available on your phone to use at the check-out.
                </div>
                <ol>
                    <li>
                        <strong>How does Simply Spend work?</strong>
                        When you are in My Items, click on the green tool bar titled “Simply Spend”. You will be directed to a short tutorial showing you how to use your grocery benefit dollars. If you choose to skip the tutorial it is self-explanatory.
                    </li>
                    <li>
                        <strong>How do I know how much money is available for me to spend?</strong>
                        Beneath active offers a virtual card will be displayed with a $ amount indicating the amount available for you at our participating local grocer.
                    </li>
                    <li>
                        <strong>Can I use this money for anything?</strong>
                        No, a limited selection of products has been authorized to use this money for. These are all products curated to be lower in disease causing ingredients like sugar and net carbs.
                    </li>
                    <li>
                        <strong>How do I pay or use this money when I’m in the store? </strong>
                        Click on the “Pay with Card” button, directions for the cashier or self-check out are presented, click the blue button “Click to scan the barcode”. A QR code will be loaded that you can scan to apply the money from your card to your purchase. NOTE* only items that have been approved will be deducted from your bill.
                    </li>
                    <li>
                        <strong>Can I use the QR code at any grocery store.</strong>
                        Unfortunately, no. We have been working with retail partners to address the availability of healthier grocery products. Currently only those we are partnering with will be able to accept Simply Spend as a form of payment.
                    </li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script  setup>
import bus from '~/includes/Event'
import {onMounted} from 'vue'
onMounted(() => { bus.$emit('set-title', 'Tips Summary')})
</script>

<style lang="scss" scoped>
.title {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0.15px;
}
</style>
