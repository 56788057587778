import LearningModuleLesson from './components/Lesson'
import LearningModulesIndex from './components/LearningModulesIndex'
import LearningModuleQuiz from './components/quiz'
import LearningModuleLearn from './components/Learn'
import Child from '~/base/components/Child'
import userApi from '~/modules/profile/userApi'

export const LEARNING_MODULE_STEP = 3

const meta = {
    auth: true,
    step: LEARNING_MODULE_STEP
}

export default [
    {
        path: 'learning-modules',
        component: Child,
        children: [
            {
                path: '',
                name: 'learning-modules',
                component: LearningModulesIndex,
                meta: {
                    auth: true,
                    layout: 'NavigationLayout',
                    step: LEARNING_MODULE_STEP
                },
            },
            {
                path: 'lesson/:lesson_id',
                name: 'learning-modules.lesson',
                component: LearningModuleLesson,
                meta: {
                    auth: true,
                    layout: 'LearningLesson',
                    step: LEARNING_MODULE_STEP
                },
            },
            {
                path: 'quiz/:quiz_id',
                name: 'learning-modules.quiz',
                component: LearningModuleQuiz,
                meta: {
                    auth: true,
                    layout: 'LearningLesson',
                    step: LEARNING_MODULE_STEP
                },
            },
            // {
            //     path: 'learn',
            //     name: 'learning-modules.learn',
            //     component: LearningModuleLearn,
            //     meta: {
            //         auth: true,
            //         layout: 'NavigationLayout',
            //         step: LEARNING_MODULE_STEP
            //     },
            //     beforeEnter: (to, from) => {
            //         if (userApi.hasFirstExperience('pmo_learn_slide')) {
            //             return {name: 'learning-modules'}
            //         }
            //     }
            // }
        ]
    }
]
