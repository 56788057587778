<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="g-container">
                <slot />
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__robot">
                    <RobotDialog
                        v-if="messages"
                        :messages="messages"
                        :robot-image="image"
                        class="bottom-robot"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, watch} from 'vue'
import store from '~/store'
import RobotDialog from '../RobotDialog'
import {useRouter} from 'vue-router'

const messages = computed(() => store.getters['check-in-response/robotMessages'])
const image = computed(() => store.getters['check-in-response/robotImage'])

const router = useRouter()

watch(messages, val => {
    if (val) setTimeout(() => {
        store.commit('check-in-response/clear')
        router.push({name: 'my-items.shop'})
    }, 12000)
})

</script>
<style lang="scss" scoped>
.bottom-robot {
    position: absolute;
    bottom: 15px;
    left: 15px;
    box-shadow: 0 0 100px 200px rgba(255, 255, 255, .5);
    background: rgba(255, 255, 255, .5);
}
</style>
