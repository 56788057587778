<template>
    <div class="product-item__btn-wrap">
        <div class="product-item__btn">
            <div
                class="functional-button"
                :class="{'functional-button--like': product.liked === 1}"
                @click="clickLike"
            >
                <i class="functional-button__icon fa-solid fa-thumbs-up" />
            </div>
        </div>
        <div
            class="product-item__btn"
        >
            <div
                class="functional-button functional-button--align-bottom"
                :class="{'functional-button--dislike': product.liked === -1}"
                @click="clickDislike"
            >
                <i class="functional-button__icon fa-solid fa-thumbs-down" />
            </div>
        </div>

        <el-dialog
            v-model="showDialogModal"
            title="What was the reason this product was not appealing?"
            custom-class="el-dialog--dislike-product"
        >
            <div class="product-question-wrap">
                <div
                    v-for="question in questions"
                    :key="question.id"
                    class="product-question"
                >
                    <div class="product-question__text">
                        {{ question.title }}
                    </div>

                    <el-radio-group
                        v-model="answers[question.id]"
                        class="radio-group quiz-rate product-question__rate"
                        @change="$emit('set', question.id, answers[question.id])"
                    >
                        <el-radio-button
                            v-for="variant in _.range(1, question.variants+1)"
                            :key="variant"
                            :label="variant"
                        >
                            <span v-html="variant" />
                        </el-radio-button>
                    </el-radio-group>
                    <div class="product-question__satisfied">
                        Very Satisfied
                    </div>
                    <div class="product-question__dissatisfied">
                        Very Dissatisfied
                    </div>
                </div>
            </div>

            <div class="product-question">
                <div class="product-question__text">
                    Would you like to choose a different replacement?
                </div>
                <div class="product-question__btn-section">
                    <div class="product-question__btn-wrap">
                        <el-button
                            round
                            class="el-button product-question__btn"
                            :disabled="!enableButtons"
                            @click="onClickYes"
                        >
                            Yes
                        </el-button>
                    </div>
                    <div class="product-question__btn-wrap">
                        <el-button
                            round
                            class="el-button product-question__btn"
                            :disabled="!enableButtons"
                            @click="onClickNo"
                        >
                            No
                        </el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script setup>

import store from '~/store'
import _ from 'lodash'
import { ref, watch } from 'vue'
import {useRoute, useRouter} from 'vue-router'
// import userApi from '~/modules/profile/userApi'
import bus from '~/includes/Event'
import pmoApi from '~/modules/pmo/pmoApi'
import {STATUS_REMOVED} from '~/modules/pmo/summaryStatuses.constants'

const router = useRouter()
const route = useRoute()

const props = defineProps({
    'product': {
        type: [ Object ],
        required: true,
        default: null
    }
})

const showDialogModal = ref(false)
const questions = ref([
    {
        id: 'taste',
        variants: 5,
        title: 'Taste?',
    },
    {
        id: 'inconvenient',
        variants: 5,
        title: 'Packaging?',
    },
    {
        id: 'price',
        variants: 5,
        title: 'Price?',
    },
])

const answers = ref({
    price: null,
    taste: null,
    inconvenient: null,
})
const enableButtons = ref(false)

const emit = defineEmits(['clickProduct:product'])

watch(answers.value, (answers) => {
    enableButtons.value = _.every(answers)
})

async function clickLike() {
    if (props.product.liked === 1) {
        return
    }
    await store.dispatch('my-items/likeProduct', props.product.gtinFormats.gtin14)
    bus.$emit('show-sugar-reduction-report')
    bus.$emit('show-net-carb-reduction-report')
}

function clickDislike() {
    if (props.product.liked === -1) {
        return
    }

    showDialogModal.value = true
}

async function onClickYes() {
    await onClickNo()
}

async function onClickNo() {
    const product = _.clone(props.product)

    let data = {
        'gtin': props.product.gtinFormats.gtin14,
        'answers': answers.value
    }
    await store.dispatch('my-items/dislikeProduct', data)
    showDialogModal.value = false
    resetAnswers()
    openProductMoreChoices(product)

    return Promise.resolve()
}

function openProductMoreChoices(product) {
    const pmo_slug = store.getters['my-items/pmo_temp_category_slug']

    store.commit('my-items/clearPmoCatSlug')

    let query = {
        exclude: [product.gtin14],
    }
    if (route.query.store_id) {
        query.store_id = route.query.store_id
    }
    if (pmo_slug) {
        query.goto = 'my-items'
    }

    router.push({
        name: 'product.more-choices',
        params: {
            upc: product.original_gtin,
            pmo_category: route?.params?.pmo_category || pmo_slug,
        },
        query: query
    })
}

function resetAnswers() {
    answers.value = {
        price: null,
        taste: null,
        inconvenient: null,
    }
}

</script>

<style lang="scss" scoped>
.product-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__product {

    }
    &__btn {
        &:not(:last-child) {
            margin-right: 10px;
        }
        &-wrap {
            display: flex;
            align-items: center;
            margin-left: 10px;
        }
    }
}
</style>
