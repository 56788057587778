<template>
    <el-checkbox-group
        v-model="question.answer"
        @change="$emit('set', question._id, question.answer)"
    >
        <el-checkbox
            v-for="variant in question.variants"
            :key="variant._id"
            v-model="variant._id"
            :label="variant.value"
        >
            <span v-html="variant.value" />
        </el-checkbox>
    </el-checkbox-group>
</template>

<script>
export default {
    name: 'FormCheckbox',
    props: {
        question: {
            type: Object,
            required: true,
        }
    },
}
</script>
