<template>
    <div
        v-if="product.upc"
        class="product-block"
    >
        <div class="product-block__image">
            <img
                :src="productImageUrl"
                :alt="product.productTitle"
                class="product-block__image-i"
                @error="imageLoadError"
            >
        </div>
        <div
            class="product-block__info"
            @click="openProductDetails"
        >
            <p class="product-block__info-brand">
                {{ product.brand }}
            </p>
            <p class="product-block__info-title">
                {{ product.productTitle }}
            </p>
            <p
                v-if="product.productSize||product.lis_data.servingSize!==false"
                class="product-block__info-text"
            >
                Total size<span class="product-block__info-text-serving"> per item</span>: {{ roundUom(getProductSize().value, getProductSize().uom) }} {{ getProductSize().uom }}
            </p>
            <p
                v-if="product.lis_data.servingSize!==false"
                class="product-block__info-text"
            >
                Sugar<span class="product-block__info-text-serving-uom"> {{ getPerUnitOfMeasure(product.lis_data.totalWeightUom) }}</span>: <span class="product-block__info-text-value">{{ convertSize(product.lis_data.sugar, 'g', getUnits(product.lis_data.totalWeightUom)) }}&nbsp;{{ "g" }}</span>
            </p>
            <p
                v-if="product.lis_data.servingSize!==false"
                class="product-block__info-text"
            >
                Net Carb<span class="product-block__info-text-serving-uom"> {{ getPerUnitOfMeasure(product.lis_data.totalWeightUom) }}</span>: {{ convertSize(product.lis_data.totalNetCarb, 'g', getUnits(product.lis_data.totalWeightUom)) }}&nbsp;{{ "g" }}
            </p>
            <p
                v-if="product.lis_data.servingSize!==false"
                class="product-block__info-text product-block__info-text-sugar"
            >
                Total sugar<span class="product-block__info-text-serving"> per item</span>: {{ getTotalSugar().value }} {{ getTotalSugar().uom }}
            </p>
            <p
                v-if="insignificantAmountStatement"
                class="product-block__info-text"
            >
                Warning: {{ insignificantAmountStatement }}
            </p>
        </div>
    </div>
</template>

<script>
import UserMeasurementUnitsConverter from '~/plugins/UserMeasurementUnitsConverter.js'

export default {
    components: {
        // ProductNutrition
    },
    props: {
        product: {
            type: Object,
            default: () => {},
        },
        short: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            netCarbValue: 0,
            netCarbUom: '',
            imageUrl: '',
            productImageLoaded: true,
            insignificantAmountStatement: '',
        }
    },
    computed: {
        columnName() {
            return this.product?.factPanel?.nutrition ? 'nutrients' : 'supplements'
        },
        nutrition() {
            let nutr = this.product?.factPanel?.nutrition

            if (!nutr) {
                nutr = this.product?.factPanel?.supplement
            }
            return nutr
        },
        serving() {
            return this.nutrition?.serving
        },
        sugarColumn() {
            return this.getFactPanelColumn('Sugars') || this.getFactPanelColumn('Added Sugars')
        },
        totalCarbs() {
            return this.getFactPanelColumn('Total Carbohydrate')
        },
        fiber() {
            return this.getFactPanelColumn('Fiber')||this.getFactPanelColumn('Dietary Fiber')
        },
        netCarb() {
            let netCarbValue = 0
            let netCarbUom = ''

            if (this.totalCarbs) {
                netCarbValue = this.totalCarbs.value
                netCarbUom = this.totalCarbs.uom
            }
            if (this.fiber) {
                netCarbValue = netCarbValue - this.fiber.value
                if (netCarbValue < 0) {
                    netCarbValue = 0
                }
            }

            return {
                value: netCarbValue,
                uom: netCarbUom
            }
        },
        productImageUrl() {
            if (this.product && this.product.gtinFormats.gtin14 && this.product.imagesArray[0] && this.productImageLoaded) {
                return this.product.imagesArray[0]
                // return '/images/products/' + this.product.gtinFormats.gtin14 + '.jpg'
            } else {
                return '/images/ImageUnavailable2.png'
            }
        }
    },
    methods: {
        getFactPanelColumn(columnName) {
            let column = {
                value: 0,
                uom: 'g'
            }

            if (this.nutrition && this.nutrition.columns) {
                let amountPerServing = _.find(this.nutrition.columns, o => {
                    if (o.name === null) {
                        return false
                    }
                    return /serving/i.test(o.name)
                })

                if (!amountPerServing) {
                    amountPerServing = _.find(this.nutrition.columns, o => {
                        if (o.name === null) {
                            return false
                        }
                        return /packaged/i.test(o.name)
                    })
                }

                if (!amountPerServing &&  this.nutrition.columns.length > 0) {
                    amountPerServing = this.nutrition.columns[0]
                }

                if (amountPerServing){
                    column = _.find(amountPerServing[this.columnName], (o) => {
                        return (o.name.toLocaleLowerCase() === columnName.toLocaleLowerCase())
                    })
                } else {
                    this.insignificantAmountStatement = this.nutrition.insignificantAmountStatement
                }
            } else {
                this.insignificantAmountStatement = 'No nutrition information available'
            }

            return column
        },
        imageLoadError() {
            this.productImageLoaded = false
        },
        openProductDetails() {
            let query = {}
            if (this.$route.query.store_id) {
                query.store_id = this.$route.query.store_id
            }

            this.$router.push({
                name: 'product.show',
                params: {
                    upc: this.product.gtinFormats.gtin14,
                    pmo_category: this.$route?.params?.pmo_category,
                    mapped: this.product.mapped
                },
                query: query
            })
        },
        getPerUnitOfMeasure(uom) {
            return new UserMeasurementUnitsConverter().getPerUnitOfMeasure(uom, this.short)
        },
        getUnits(toUom) {
            return new UserMeasurementUnitsConverter().getUnits(toUom)
        },
        convertSize(value, fromUom, toUom) {
            let convertedValue = new UserMeasurementUnitsConverter().convertSize(value, fromUom, toUom)

            convertedValue =  Math.round(convertedValue*100)/100

            return convertedValue
            // return new UserMeasurementUnitsConverter().convertSize(value, fromUom, toUom)
        },
        convertSizeTotalToUser(value, fromUom) {
            return new UserMeasurementUnitsConverter().convertSizeTotalToUser(value, fromUom)
        },
        convertSizeTotal(value, fromUom, toUom) {
            return new UserMeasurementUnitsConverter().convertSizeTotal(value, fromUom, toUom)
        },
        getProductSize() {
            let size = this.product?.sizes?.[0]
            //size.netWeight = { 'value': 1, 'uom': 'lb''}
            let netWeightUom = size.netWeight.uom
            let netWeightValue = size.netWeight.value

            let uom = ''
            let value = 0
            if (size && netWeightUom) {
                uom = this.getUnits(size.netWeight.uom.toLocaleLowerCase())
                if (uom && netWeightValue) {
                    value = this.convertSizeTotalToUser(parseFloat(size.netWeight.value), size.netWeight.uom.toLocaleLowerCase())
                }
            }
            if (!value && this.product?.lis_data?.totalWeight) {
                uom = this.getUnits(this.product.lis_data.totalWeightUom.toLocaleLowerCase())
                let totalWeight = this.product?.lis_data?.totalWeight
                value = this.convertSizeTotalToUser(parseFloat(totalWeight),  this.getLisDataUom())
            }
            return {
                uom: uom,
                value: value
            }
        },
        getTotalSugar() {
            let productSizeInGramms = this.convertSizeTotal(this.getProductSize().value, this.getProductSize().uom, this.getLisDataUom())

            let totalSugar = productSizeInGramms * this.product.lis_data.sugar

            let totalSugarUom = 'g'
            let totalSugarValue = 0
            if (totalSugar) {
                totalSugarValue = Math.round(totalSugar)
            }
            return {
                value: totalSugarValue,
                uom: totalSugarUom
            }
        },
        roundUom(value, uom) {
            if (uom === 'g') {
                return Math.round(value*100)/100
            } else {
                return Math.round(value*10)/10
            }
        },
        getLisDataUom() {
            if (this.product.lis_data.isVolumeUom === true) {
                return 'ml'
            } else {
                return 'g'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.product-block {
    $block-name: &;
    display: flex;
    &__image {
        flex-shrink:0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        height: 150px;
        margin: 0 15px 0 0;
        border: 2px solid $light-gray;
        border-radius: 5px;
        overflow: hidden;
        background-color: $white;
        transition: 0.2s border-color ease-in-out;
        @media all and (min-width: 390px) {
            width: 170px;
            height: 170px;
            margin: 0 25px 0 0;
        }
        &-i {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__info {
        flex-grow: 1;
        &-title {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 600;
            line-height: 1.5;
            color: $dark;
        }
        &-brand {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 200;
            line-height: 1.5;
            color: $dark;
        }
        &-text {
            font-size: 15px;
            font-weight: 400;
            line-height: 1.6;
            color: $black;
            &-serving-uom {
                padding-left: 5px;
            }
            &-sugar {
                #{$block-name}__info-text-value {
                    color: $maroon;
                }
            }
            white-space: normal;
        }
        cursor: pointer;
    }
    &--vertical {
        display: block;
        #{$block-name} {
            &__image {
                margin: 0 0 10px;
            }
            &__info {
                cursor: pointer;
                flex-grow: unset;
                min-height: 78px;
                &-title,
                &-brand,
                &-text {
                    max-width: 150px;
                    margin-bottom: 5px;
                    overflow: hidden;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 1.3;
                    letter-spacing: 0.5px;
                    text-overflow: ellipsis;
                }
                &-title {
                    text-decoration: underline;
                    font-weight: 800;
                }
                &-text {
                    color: $dark;
                    &-serving {
                        display: none;
                    }
                    &-serving-uom {
                        font-size: 11px;
                        padding-left: 2px;
                    }
                    &-sugar {
                        #{$block-name}__info-text-value {
                            font-weight: 600;
                            color: $black;
                        }
                    }
                }
            }
        }
    }
}
</style>
