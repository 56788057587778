<template>
    <div class="main-content main-content--search-page">
        <div class="g-container">
            <div class="main-content__search-box">
                <div class="search-box">
                    <el-input
                        ref="search_field"
                        v-model="search"
                        name="search"
                        placeholder="Search by name or brand"
                        class="search-box__input"
                        clearable
                        autofocus
                        @clear="handleClear"
                        @keyup.enter="clickSearch"
                    />
                    <el-button
                        class="search-box__btn"
                        @click="clickSearch"
                    >
                        <i class="search-box__btn-icon fa fa-search" />
                    </el-button>
                </div>
                <div>
                    <el-radio-group
                        v-model="searchType"
                        size="small"
                    >
                        <el-radio-button label="By Product" />
                        <el-radio-button label="By Brand" />
                    </el-radio-group>
                </div>
            </div>
            <div
                v-loading="loading"
                class="main-content__products"
            >
                <div
                    v-if="products.length === 0 && emptySearchResult === false"
                    class="main-content__image"
                >
                    <div
                        class="main-content__image-wrap"
                    >
                        <img
                            src="/images/robots/robot_search.svg"
                            class="main-content__image-i"
                            alt=""
                        >
                    </div>
                </div>
                <div
                    v-if="emptySearchResult === true"
                    class="main-content__image"
                >
                    <div
                        v-if="!pmoSearch"
                        class="main-content__search-text"
                    >
                        Your search - <span class="main-content__search-query">{{ search }}</span> - did not match any products.
                    </div>
                    <div
                        v-else
                        v-loading="loadingPmoCategory"
                        class="main-content__search-text"
                    >
                        <div
                            v-if="pmoCategoryTitle"
                            v-html="pmoCategoryTitle"
                        />
                        <!-- <div v-else>
                            {{ not_fount_text }}
                        </div> -->
                    </div>
                    <div class="main-content__image-wrap">
                        <img
                            src="/images/robots/robot_oops.svg"
                            class="main-content__image-i"
                            alt=""
                        >
                    </div>
                </div>
                <div
                    v-if="products.length"
                    class="main-content__products-list"
                >
                    <div
                        disabled-v-infinite-scroll="loadMore"
                        class="products-list"
                        infinite-scroll-distance="5"
                        infinite-scroll-immediate="false"
                        style="overflow: auto"
                    >
                        <ProductPreviewBlock
                            v-for="product in products"
                            :key="product.upc"
                            :product="product"
                            class="products-list__item"
                        />
                    </div>
                    <div
                        v-if="last_page !== -1 && page < last_page"
                        class="main-content__load-more"
                    >
                        <el-button
                            :loading="loading"
                            type="primary"
                            @click="loadMore"
                        >
                            Load more
                        </el-button>
                    </div>
                    <div
                        v-else
                        v-if="last_page !== -1 && page >= last_page"
                        class="main-content__load-more"
                    >
                        <!-- <span>
                            No more products
                        </span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProductPreviewBlock from './ProductPreviewBlock'
import labelinsightApi from '~/modules/labelinsight/labelinsightApi'
import pmoApi from '~/modules/pmo/pmoApi'
import store from '~/store'
import bus from '~/includes/Event'

export default {
    components: {
        ProductPreviewBlock
    },
    data() {
        return {
            products: [],
            search: '',
            page: 1,
            last_page: -1,
            loading: false,
            emptySearchResult: false,
            store_id: '',
            pmoSearch: false,
            pmoCategoryTitle: '',
            // not_fount_text: 'Please select a different product as this product does not match the current category you are working on.',
            not_fount_text: 'Please select a different search criteria to find a product. This search criteria does not match the current category you are working on.',
            loadingPmoCategory: false,
            searchType: 'By Product',
            searchTypeValues: { 'By Product': 'product', 'By Brand': 'brand', 'By Category': 'category' },
        }
    },
    computed: {
        alreadySearched() {
            const user = store.getters['auth/user']
            return user.first_experience && (user.first_experience?.first_search ?? false)
        },
    },
    watch: {
        searchType() {
            this.clickSearch()
        },
    },
    mounted() {
        if (this.$route.query.search) {
            this.search = this.$route.query.search
        }
        if (this.$route.query.page) {
            this.page = this.$route.query.page
        }
        if (this.$route.query.store_id) {
            this.store_id = this.$route.query.store_id
        }
        if (this.$route.params.searchType) {
            this.searchType =  this.getSearchTypeLabelByValue(this.$route.params.searchType)
        }
        this.fetchPmoCategory()
        this.getData()
        this.getSubCategory()
        this.$refs.search_field.focus()
    },
    methods: {
        clickSearch() {
            let query = {
                search: this.search
            }
            if (this.store_id) {
                query.store_id = this.store_id
            }

            this.page = 1
            this.last_page = -1
            let params = {}
            if (this.$route.params.pmo_category) {
                params.pmo_category = this.$route.params.pmo_category
                this.pmoSearch = true
            }
            params.searchType = this.getSearchTypeValueByLabel(this.searchType)
            this.$router.push({name: 'product.search', query: query, params: params})
            // this.getData() TODO remove this or uncomment if bug
        },
        getData() {
            if (!this.search) {
                return
            }
            let params = {
                search: this.search,
                page: this.page,
                searchType: this.getSearchTypeValueByLabel(this.searchType),
                // store_id: this.store_id
            }

            if (this.$route.params.pmo_category) {
                params.pmo_category = this.$route.params.pmo_category
                this.pmoSearch = true
            }
            this.loading = true
            this.emptySearchResult = false

            labelinsightApi.search(params).then(response => {
                this.products = this.products.concat(response.data.data)
                this.last_page = response.data.last_page
                if(response.data.data.length === 0){
                    this.emptySearchResult = true
                }
                if (! this.alreadySearched) {
                    store.commit('auth/addFirstExperience', 'first_search')
                }
            }).finally(
                () => this.loading = false
            )
        },
        loadMore() {
            if (this.last_page === -1 || this.page < this.last_page) {
                this.page++
                this.getData()
            }
        },
        handleClear() {
            this.products = []
            this.$router.replace({query: null})
        },
        async getSubCategory() {
            if (this.$route.params.pmo_category) {
                const {data} = await pmoApi.get(this.$route.params.pmo_category)
                bus.$emit('set-title', data.title)
            }
        },
        fetchPmoCategory() {
            if (this.$route.params.pmo_category) {
                this.loadingPmoCategory = true
                pmoApi.productPmoCategoriesByGtin(this.search, this.getSearchTypeValueByLabel(this.searchType),).then(response => {
                    let responseData = response.data.nielsen

                    let message
                    if (responseData.length === 0) {
                        message = this.not_fount_text
                    } else if (responseData.length === 1) {
                        // Option 1: One-to-one mapping
                        const pmoCategory = responseData[0]
                        message = `Please scan this product in the <strong>${pmoCategory.type_name}: ${pmoCategory.title}</strong> Pantry Makeover Category for better results.`
                    } else {
                        // Option 2: One-to-many mapping
                        const categoryNames = responseData.map(c => '<strong>' + c.type_name + ': ' + c.title + '</strong>').join(' or ')
                        message = `Please scan this product in the ${categoryNames} Pantry Makeover Categories, whichever best fits the description for that product, for better results.`
                    }

                    this.pmoCategoryTitle = message
                })
                    .catch(error => {
                        this.pmoCategoryTitle = this.not_fount_text
                    })
                    .finally(() => {
                        this.loadingPmoCategory = false
                    })
            }
        },
        getSearchTypeValueByLabel(label) {
            return this.searchTypeValues[label]
        },
        getSearchTypeLabelByValue(value) {
            return Object.keys(this.searchTypeValues).find(key => this.searchTypeValues[key] === value)
        },
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.search-box {
    $block-name: &;
    position: relative;
    z-index: 1;
    &__btn {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        bottom: 0;
        height: 48px;
        border: unset;
        padding: 12px;
        background-color: $white-second;
        outline: none;
        &-icon {
            color: $gray;
            transition: 0.2s color ease-in-out;
        }
        &:hover {
            #{$block-name}__btn-icon {
                color: $indigo;
            }
            background-color: unset;
        }
    }
}

.main-content {
    &__load-more {
        font-size: 1.25rem;
        text-align: center;
        margin-top: 1rem;
        color: $gray;
        line-height: 1.5;
    }
}
</style>
