<template>
    <div class="testimonials dark first">
        <div class="container-holder">
            <Carousel
                :settings="settings"
            >
                <Slide
                    v-for="item in tstm_one"
                    :key="item.id"
                >
                    <div
                        class="carousel__item"
                    >
                        <figure>
                            <blockquote>
                                <p>{{ item.blockquote }}</p>
                            </blockquote>
                            <figcaption> {{ item.author }} <cite v-if="item.company"> {{ item.company }}</cite></figcaption>
                            <span class="rating">
                                <span class="rating__stars">
                                    <span
                                        class="fill"
                                        :style="'width: calc(100% / 5 * ' + item.rating +');'"
                                    />
                                </span>
                            </span>
                        </figure>
                    </div>
                </Slide>
                <template #addons>
                    <Navigation />
                </template>
            </Carousel>
            <p class="testimonials__text">
                Interested in bringing SeekingSimple into your organization?
            </p>
            <div class="button-holder">
                <a
                    class="lp__button lp__button--green testimonials__button"
                    href="/contacts"
                >
                    Contact Us
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Navigation, Slide } from 'vue3-carousel'
export default {
    name: 'LandingTestimonialsV1',
    components: {
        Carousel,
        Slide,
        Navigation
    },
    data() {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            // rating from 0 to 5
            tstm_one: [
                {
                    blockquote: '“I have completely changed my diet and this program has complimented my nutrition, so I can still enjoy many things I love without the guilt.”',
                    author: 'SeekingSimple Pantry Makeover Participant',
                    company: '',
                    rating: '5'
                },
                {
                    blockquote: '“I’m starting to feel better.  The better food choices are becoming a habit.”',
                    author: 'SeekingSimple Pantry Makeover Participant',
                    company: '',
                    rating: '5'
                },
            ]
        }
    }
}

</script>
