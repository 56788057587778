<template>
    <div
        v-if="product.upc"
        class="product-main-info"
    >
        <div class="product-main-info__image-wrap">
            <div class="product-main-info__image-title">
                {{ product.productTitle }}
            </div>
            <div class="product-main-info__image">
                <img
                    :src="productImageUrl"
                    :alt="product.productTitle"
                    class="product-main-info__image-i"
                    @error="imageLoadError"
                >
            </div>
        </div>
        <div class="product-main-info__buttons">
            <div class="product-main-info__buttons-main" />
            <div class="product-main-info__buttons-aside">
                <share-product
                    :product="product"
                    circle-btn
                />
            </div>
        </div>
        <div class="product-main-info__info">
            <p class="product-main-info__info-title">
                {{ product.productTitle }} - {{ product.productSize }}
            </p>
            <p
                v-if="product.productSize"
                class="product-main-info__info-text product-main-info__info-text-serving"
            >
                Total size per item: {{ roundUom(getProductSize().value, getProductSize().uom) }} {{ getProductSize().uom }}
            </p>
            <p
                v-if="sugarColumn&&product.lis_data.servingSize!==false"
                class="product-main-info__info-text"
            >
                Sugar {{ getPerUnitOfMeasure(product.lis_data.totalWeightUom) }}: <span class="product-block__info-text-value">{{ convertSize(product.lis_data.sugar, 'g', getUnits(product.lis_data.totalWeightUom)) }}&nbsp;{{ "g" }}</span>
            </p>
            <p
                v-if="netCarb&&product.lis_data.servingSize!==false"
                class="product-main-info__info-text"
            >
                Net Carb {{ getPerUnitOfMeasure(product.lis_data.totalWeightUom) }}: <span class="product-block__info-text-value">{{ convertSize(product.lis_data.totalNetCarb, 'g', getUnits(product.lis_data.totalWeightUom)) }}&nbsp;{{ "g" }}</span>
            </p>
            <p
                v-if="sugarColumn&&product.lis_data.servingSize!==false"
                class="product-main-info__info-text"
            >
                Total sugar per item: {{ getTotalSugar().value }} {{ getTotalSugar().uom }}
            </p>
            <p
                v-if="insignificantAmountStatement"
                class="product-main-info__info-text"
            >
                Warning: <span class="product-main-info__info-text-warning">{{ insignificantAmountStatement }}</span>
            </p>
        </div>
    </div>
</template>

<script>
import ShareProduct from './ShareProduct'
import UserMeasurementUnitsConverter from '~/plugins/UserMeasurementUnitsConverter.js'

export default {
    components: {
        ShareProduct
    },
    props: {
        product: {
            type: Object,
            default: () => { return {}},
        },
    },
    data() {
        return {
            netCarbValue: 0,
            netCarbUom: '',
            imageUrl: '',
            productImageLoaded: true,
            insignificantAmountStatement: '',
        }
    },
    computed: {
        columnName() {
            return this.product?.factPanel?.nutrition ? 'nutrients' : 'supplements'
        },
        nutrition() {
            let nutr = this.product?.factPanel?.nutrition

            if (!nutr) {
                nutr = this.product?.factPanel?.supplement
            }
            return nutr
        },
        serving() {
            return this.nutrition?.serving
        },
        sugarColumn() {
            return this.getFactPanelColumn('Sugars') || this.getFactPanelColumn('Added Sugars')
        },
        totalCarbs() {
            return this.getFactPanelColumn('Total Carbohydrate')
        },
        fiber() {
            return this.getFactPanelColumn('Fiber')||this.getFactPanelColumn('Dietary Fiber')
        },
        netCarb() {
            let netCarbValue = 0
            let netCarbUom = ''

            if (this.totalCarbs) {
                netCarbValue = this.totalCarbs.value
                netCarbUom = this.totalCarbs.uom
            }
            if (this.fiber) {
                netCarbValue = netCarbValue - this.fiber.value
                if (netCarbValue < 0) {
                    netCarbValue = 0
                }
            }

            return {
                value: netCarbValue,
                uom: netCarbUom
            }
        },
        productImageUrl() {
            if (this.product && this.product.gtinFormats.gtin14 && this.product.imagesArray[0] && this.productImageLoaded) {
                return this.product.imagesArray[0]
                // return '/images/products/' + this.product.gtinFormats.gtin14 + '.jpg'
            } else {
                return '/images/ImageUnavailable2.png'
            }
        }
    },
    methods: {
        getFactPanelColumn(columnName) {
            let column = {
                value: 0,
                uom: 'g'
            }

            if (this.nutrition && this.nutrition.columns) {
                let amountPerServing = _.find(this.nutrition.columns, o => {
                    if (o.name === null) {
                        return false
                    }
                    return /serving/i.test(o.name)
                })

                if (!amountPerServing) {
                    amountPerServing = _.find(this.nutrition.columns, o => {
                        if (o.name === null) {
                            return false
                        }
                        return /packaged/i.test(o.name)
                    })
                }
                if (!amountPerServing &&  this.nutrition.columns.length > 0) {
                    amountPerServing = this.nutrition.columns[0]
                }

                if (amountPerServing){
                    column = _.find(amountPerServing[this.columnName], (o) => {
                        return (o.name.toLocaleLowerCase() === columnName.toLocaleLowerCase())
                    })
                } else {
                    this.insignificantAmountStatement = this.nutrition.insignificantAmountStatement
                }
            } else {
                this.insignificantAmountStatement = 'No nutrition information available'
            }

            return column
        },
        imageLoadError() {
            this.productImageLoaded = false
        },
        getPerUnitOfMeasure(uom) {
            return new UserMeasurementUnitsConverter().getPerUnitOfMeasure(uom)
        },
        getUnits(toUom) {
            return new UserMeasurementUnitsConverter().getUnits(toUom)
        },
        convertSize(value, fromUom, toUom) {
            let convertedValue = new UserMeasurementUnitsConverter().convertSize(value, fromUom, toUom)

            convertedValue =  Math.round(convertedValue*100)/100

            return convertedValue
            // return new UserMeasurementUnitsConverter().convertSize(value, fromUom, toUom)
        },
        convertSizeTotalToUser(value, fromUom) {
            return new UserMeasurementUnitsConverter().convertSizeTotalToUser(value, fromUom)
        },
        convertSizeTotal(value, fromUom, toUom) {
            return new UserMeasurementUnitsConverter().convertSizeTotal(value, fromUom, toUom)
        },
        getProductSize() {
            let size = this.product?.sizes?.[0]
            //size.netWeight = { 'value': 1, 'uom': 'lb''}
            let netWeightUom = size.netWeight.uom
            let netWeightValue = size.netWeight.value

            let uom = ''
            let value = 0
            if (size && netWeightUom) {
                uom = this.getUnits(size.netWeight.uom.toLocaleLowerCase())
                if (uom && netWeightValue) {
                    value = this.convertSizeTotalToUser(parseFloat(size.netWeight.value), size.netWeight.uom.toLocaleLowerCase())
                }
            }
            if (!value && this.product?.lis_data?.totalWeight) {
                uom = this.getUnits(this.product.lis_data.totalWeightUom.toLocaleLowerCase())
                let totalWeight = this.product?.lis_data?.totalWeight
                value = this.convertSizeTotalToUser(parseFloat(totalWeight),  this.getLisDataUom())
            }
            return {
                uom: uom,
                value: value
            }
        },
        getTotalSugar() {
            let productSizeInGramms = this.convertSizeTotal(this.getProductSize().value, this.getProductSize().uom, this.getLisDataUom())

            let totalSugar = productSizeInGramms * this.product.lis_data.sugar

            let totalSugarUom = 'g'
            let totalSugarValue = 0
            if (totalSugar) {
                totalSugarValue = Math.round(totalSugar)
            }
            return {
                value: totalSugarValue,
                uom: totalSugarUom
            }
        },
        roundUom(value, uom) {
            if (uom === 'g') {
                return Math.round(value*100)/100
            } else {
                return Math.round(value*10)/10
            }
        },
        getLisDataUom() {
            if (this.product.lis_data.isVolumeUom === true) {
                return 'ml'
            } else {
                return 'g'
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.product-main-info {
    $block-name: &;
    &__image {
        position: absolute;
        z-index: 3;
        top: -25%;
        right: 0;
        bottom: -25%;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        &-title {
            position: absolute;
            z-index: 5;
            top: 16px;
            right: 20px;
            bottom: auto;
            left: 20px;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            font-weight: 700;
            line-height: 1.6;
            letter-spacing: 0.15px;
            white-space: nowrap;
            color: $white;
        }
        &-wrap {
            position: relative;
            z-index: 5;
            margin: 0 -15px 15px;
            overflow: hidden;
            border-bottom: 1px solid $light-gray;
            padding-bottom: 50%;
            @media all and (min-width: $m) {
                margin: 0 -30px 15px;
            }
            &:before {
                content: "";
                position: absolute;
                z-index: 4;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: linear-gradient(to top, rgba(0,0,0,0), rgba(0,0,0,100%));
            }
        }
        &-i {
            max-width: 100%;
            max-height: 100%;
        }
    }
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        &-main {
            display: flex;
            align-items: center;
        }
        &-aside {}
    }
    &__button {
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
    &__info {
        font-size: 15px;
        line-height: 1.6;
        color: $dark;
        &-text {
            &-serving {}
            &-value,
            &-warning {
                color: $maroon;
            }
            &-warning {
                font-weight: 600;
            }
        }
    }
}
</style>
