import Index from './components/Index'
import LiquidCandy from './components/matches/LiquidCandy'
import LiquidCandyKidsCamp from './components/matches/LiquidCandyKidsCamp'
import BrokeFast from './components/drag/BrokeFast'
import BrokeFastKidsCamp from './components/drag/BrokeFastKidsCamp'
import MealTime from './components/drag/MealTime'
import ConnedEMents from './components/word-search/ConnedEMents'
import SneakySnacks from './components/word-search/SneakySnacks'
import SneackySnacksKidsCamp from './components/matches/SneackySnacksKidsCamp'
import Child from '~/base/components/Child'
import DessertedGames from './components/matches/DessertedGames'

export default [
    {
        path: 'games',
        component: Child,
        name: 'games',
        meta: {
            auth: true,
            layout: 'Pmo'
        },
        children: [
            {
                path: '',
                name: 'games.index',
                component: Index,
            },
            // Matches
            {
                path: 'liquid-candy',
                name: 'games.liquid-candy',
                component: LiquidCandy,
            },
            {
                path: 'liquid-candy-kids-camp',
                name: 'games.liquid-candy-kids-camp',
                component: LiquidCandyKidsCamp,
            },
            {
                path: 'sneacky-snacks-kids-camp',
                name: 'games.sneacky-snacks-kids-camp',
                component: SneackySnacksKidsCamp,
            },
            {
                path: 'deserted',
                name: 'games.deserted',
                component: DessertedGames,
            },
            // Drag
            {
                path: 'broke-fast',
                name: 'games.broke-fast',
                component: BrokeFast,
            },
            {
                path: 'meal-time',
                name: 'games.meal-time',
                component: MealTime,
            },
            {
                path: 'broke-fast-kids-camp',
                name: 'games.broke-fast-kids-camp',
                component: BrokeFastKidsCamp,
            },
            // Word Search
            {
                path: 'conned-e-ments',
                name: 'games.conned-e-ments',
                component: ConnedEMents,
            },
            {
                path: 'sneaky-snacks',
                name: 'games.sneaky-snacks',
                component: SneakySnacks,
            },
        ]
    }
]
