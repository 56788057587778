// quiz

export const state = {
    showContinueBtn: true,
    customContinueBtnUrl: '',
    showQuestions: true,
    showDialog: false,
    dialogComponent: '',
    wasNope: false,
}

// getters
export const getters = {
    showContinueBtn: state => state.showContinueBtn,
    customContinueBtnUrl: state => state.customContinueBtnUrl,
    showDialog: state => state.showDialog,
    showQuestions: state => state.showQuestions,
    dialogComponent: state => state.dialogComponent,
    wasNope: state => state.wasNope,
}

// mutations
export const mutations = {
    hideContinueBtn (state) {
        state.showContinueBtn = false
    },
    riseContinueBtn (state) {
        state.showContinueBtn = true
    },
    hideQuestions (state) {
        state.showQuestions = false
    },
    riseQuestions (state) {
        state.showQuestions = true
    },
    hideDialog (state) {
        state.showDialog = false
    },
    riseDialog (state) {
        state.showDialog = true
    },
    riseWasNope (state) {
        state.wasNope = true
    },
    setDialogComponent (state, component) {
        state.dialogComponent = component
    },
    setCustomContinueBtnUrl (state, payload) {
        state.customContinueBtnUrl = payload
    },
}
