<template>
    <div
        v-loading="loading"
        class="main-content main-content--base-layout search-product"
    >
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div
                    v-if="product"
                    class="search-product__product"
                >
                    <ProductBlock :product="product" />
                    <div
                        v-if="outOfStore"
                        class="search-product__product-info"
                    >
                        Please note that this product is currently not sold in your selected store.
                    </div>
                </div>
                <div
                    v-if="productNotFound"
                    class="search-product__product"
                >
                    <div class="search-product__product-info" v-if="productNotFoundMessage">
                        <span class="warning">{{ productNotFoundMessage }}</span> 
                    </div>
                    <div class="search-product__product-info" v-else>
                        <span class="warning">Warning!</span> Product not found.
                    </div>
                </div>
            </div>
        </div>
        <RemoveReduceBlock
            v-if="product"
            :gtin14="product.gtinFormats.gtin14"
        />
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="search-product__alternatives-info">
                    <div
                        v-if="product"
                        class="search-product__alternatives-title"
                    >
                        <span class="search-product__alternatives-title-value">
                            <template v-if="alternatives.length > 0">
                                <div
                                    v-if="healthier"
                                    class="search-product-warning"
                                >
                                    <template v-if="greatChoice">Great Choice!</template>
                                    <template v-else>There are no healthier alternatives</template></div>
                                <div v-else>
                                    {{ alternatives.length }} alternatives found <i
                                        class="search-product__alternatives-title-info fa-solid fa-info-circle"
                                        @click="showDialog"
                                    />
                                </div>
                            </template>
                            <template v-if="noAlternatives">
                                No alternatives found
                            </template>
                        </span>
                    </div>
                </div>
                <div
                    v-if="alternativesFiltered.length > 0 && !healthier"
                    class="search-product__alternatives"
                >
                    <div class="search-product__alternative-products">
                        <div class="alternative-products">
                            <el-radio-group
                                id="checkbox-group-alternatives"
                                v-model="selectedRadio"
                                name="selected-alternatives"
                                class="alternative-products__list"
                            >
                                <div
                                    v-for="alternatives in alternativesFiltered"
                                    class="alternative-products__group"
                                >
                                <el-radio
                                    v-for="alternative in alternatives"
                                    :key="alternative.upc"
                                    :value="alternative.gtinFormats.gtin14"
                                    :label="alternative.gtinFormats.gtin14"
                                    class="alternative-products__list-item"
                                >
                                    <ProductBlock
                                        :product="alternative"
                                        :short="true"
                                        class="product-block--vertical"
                                    />
                                </el-radio>
                                </div>
                            </el-radio-group>
                        </div>
                    </div>
                </div>
                <div
                    v-else-if="noAlternatives"
                    class="search-product__no-alternatives"
                >
                    This is not a great item for your pantry.
                    If you remove or reduce this, you may make more progress on your goal for better {{ user.aspiration }}.
                    <div class="search-product__no-alternatives-robot">
                        <img
                            src="/images/robots/robot_search.svg"
                            alt=""
                        >
                    </div>
                </div>
                <div
                    v-if="products.length > 0 && !healthier"
                    class="search-product__selected-alternatives"
                >
                    <div class="search-product__alternative-products">
                        <div class="alternative-products">
                            <el-radio-group
                                id="checkbox-group-selected"
                                v-model="selectedRadio"
                                name="selected-alternatives"
                                class="alternative-products__list"
                            >
                                <el-radio
                                    v-for="alternative in products"
                                    :key="alternative.upc"
                                    :value="alternative.gtinFormats.gtin14"
                                    :label="alternative.gtinFormats.gtin14"
                                    class="alternative-products__list-item"
                                >
                                    <ProductBlock
                                        :product="alternative"
                                        :short="true"
                                        class="product-block--vertical"
                                    />
                                </el-radio>
                                <div
                                    v-if="excluded_product"
                                    class="search-product__excluded"
                                >
                                    <ProductBlock
                                        :product="excluded_product"
                                        :short="true"
                                        class="product-block--vertical"
                                    />
                                </div>
                            </el-radio-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <RobotDialog
                    v-if="showRobot"
                    :messages="[
                        'This is a good item for your pantry',
                        'It is one of the best in its category'
                    ]"
                    robot-image="/images/robots/robot_hands_up.svg"
                    centered
                    black-message-color
                    :show-bricks-animation="false"
                    :timeout-value="300"
                />
                <div class="search-product__buttons">
                    <div
                        v-if="!showMyItems"
                        class="text-center"
                    >
                        <el-button
                            v-if="product&&alternatives.length > 0"
                            class="continue-button"
                            type="primary"
                            round
                            :disabled="selected.length===0"
                            @click="saveRecommendationWithOriginal"
                        >
                            {{ saveBtnText }}
                        </el-button>
                    </div>
                    <template v-else>
                        <div
                            v-if="product&&alternatives.length > 0 && !healthier"
                            class="search-product__alternatives-info text-center"
                        >
                            <!-- <el-button
                                class="w-100"
                                type="primary"
                                round
                                :disabled="selected.length===0"
                                @click="saveMyItems"
                            >
                                {{ 'Save ' + (selected.length || '') + ' Recommendation' + (selected.length > 1 ? 's' : '') }}
                            </el-button> -->
                            <el-button
                                class="w-100"
                                type="primary"
                                round
                                :disabled="selected.length===0"
                                @click="saveMyItems"
                            >
                                Save Alternative
                            </el-button>
                        </div>
                        <div
                            v-if="healthier"
                            class="search-product__buttons-col"
                        >
                            <el-button
                                class="w-100"
                                type="primary"
                                round
                                @click="keepOriginalItem"
                            >
                                Add Original to My Items
                            </el-button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <el-dialog
            v-model="dialogVisible"
            :show-close="false"
            custom-class="el-dialog--dislike-product el-dialog--without-header"
        >
            <div
                class="el-dialog__body-text default-text"
                v-html="dialogText"
            />
            <template #footer>
                <span class="dialog-footer">
                    <el-button
                        type="primary"
                        class="el-button--small"
                        round
                        @click="dialogVisible = false"
                    >
                        GOT IT
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import ProductBlock from './ProductBlock'
import labelinsightApi from '~/modules/labelinsight/labelinsightApi'
import pmoApi from '~/modules/pmo/pmoApi'
import store from '~/store'
import bus from '~/includes/Event'
import RobotDialog from '~/modules/quiz/components/RobotDialog'
import RemoveReduceBlock from './RemoveReduceBlock'
import {STATUS_REPLACED} from '~/modules/pmo/summaryStatuses.constants'

export default {
    components: {
        RemoveReduceBlock,
        ProductBlock,
        RobotDialog
    },
    data() {
        return {
            product: null,
            excluded_product: null,
            products: [],
            alternatives: [],
            groupAlternatives: [],
            noAlternatives: false,
            selected: [],
            choose: [],
            loading: true,
            showMyItems: true,
            outOfStore: false,
            healthier: false,
            productNotFound: false,
            productNotFoundMessage: '',
            greatChoice: false,
            showRobot: false,
            dialogVisible: false,
            dialogText: 'These alternative recommendations were selected based on their lower amount of sugar and carbohydrates per serving. Please use caution when purchasing a product with a larger serving size as this may inadvertently lead to consuming more sugar!',
            selectedRadio: null,
        }
    },
    computed: {
        user() {
            return store.getters['auth/user']
        },
        alreadyFirstSaved() {
            return this.user.first_experience && (this.user.first_experience?.first_save_alternative ?? false)
        },
        saveBtnText() {
            const alternative_gtins = _.clone(this.selected)
            _.remove(alternative_gtins, (n) => n === this.product.gtinFormats.gtin14)
            const recommend_text = alternative_gtins.length
                ? (alternative_gtins.length + ' Recommendation' + (alternative_gtins.length > 1 ? 's' : ''))
                : 'Original Item'
            // return 'Save ' + recommend_text
            return 'Save Alternative'
        },
        alternativesFiltered() {
            let alternatives = this.alternatives
            let result = []
            alternatives =  alternatives.filter(o => ! this.choose.includes(o.gtinFormats.gtin14))
            for (let i = 0; i < alternatives.length; i += 5) {
                result.push(alternatives.slice(i, i + 5))
            }
            return result
        }
    },
    watch: {
        selected: function (val) {
            if (val.length > 0) {
                bus.$emit('setPmoItemStatus', STATUS_REPLACED)
            }
        },
        selectedRadio: function (val) {
            if (val) {
                this.selected = [val]
            }
        }
    },
    async mounted() {
        this.loading = true
        if (this.$route.query.exclude) {
            await this.fetchExcluded(this.$route.query.exclude)
        }
        await this.getData()
        if (this.$route.params.pmo_category) {
            await this.fetchProducts()
            this.showMyItems = false

            if (this.products.length) {
                this.choose = this.products.map(o => o.gtinFormats.gtin14)
                this.selected = _.clone(this.choose)
            }
        }
        if(this.selected.length>0) {
            this.selected.forEach((item) => {
                this.selectedRadio = item
            })
        }
        this.loading = false
    },
    methods: {
        async getData() {
            let params = this.$route.query

            if (this.$route.params.pmo_category) {
                params = {
                    ...params,
                    pmo_category: this.$route.params.pmo_category
                }
            }

            try {
                await this.fetchProductData(params)
                await this.fetchAlternativesData(params)
            }
            catch (error) {
                this.productNotFound = true
            }
        },
        async fetchProductData(params) {
            const {data} = await labelinsightApi.getProductByUpc(this.$route.params.upc, params).catch((error) => {
                this.productNotFound = true
                this.productNotFoundMessage = error.response.data.error||''
            })
            this.product = data.product
            this.outOfStore = data.out_of_store
            this.healthier = data.healthier
            this.greatChoice = data.great_choice
            this.productNotFound = false

            this.showRobot = this.greatChoice
        },
        async fetchAlternativesData(params) {
            const {data} = await labelinsightApi.getAlternativesByUpc(this.$route.params.upc, params)
            this.alternatives = data.alternatives||[]
            for (let i = 0; i < this.alternatives.length; i += 5) {
                this.groupAlternatives.push(this.alternatives.slice(i, i + 5))
            }
            if (data.alternatives.length === 0) {
                this.noAlternatives = true
            }
        },
        async fetchExcluded(upc) {
            const {data} = await labelinsightApi.getProductByUpc(upc)
            this.excluded_product = data.product
        },
        async fetchProducts() {
            const {data} = await pmoApi.getProductsBySubcategory(this.$route.params.pmo_category)
            if (this.product) {
                this.products = data.products.filter(o => o.original_gtin === this.product.gtinFormats.gtin14)
            }
        },
        saveRecommendationWithOriginal() {
            this.selected.push(this.product.gtinFormats.gtin14)
            this.saveSelected()
        },
        keepOriginalItem() {
            this.selected = [this.product.gtinFormats.gtin14]
            this.saveSelected()
        },
        saveMyItems() {
            this.saveSelected()
        },
        async saveSelected() {
            if (this.selected.length === 0) {
                return
            }
            const pmo_category = this.$route.params.pmo_category

            let selectedArray = this.selected
            if (!pmo_category) {
                // if pmo_category is not set, we need to save the product to my items
                await store.dispatch('my-items/addItemToList', {
                    gtins: selectedArray,
                    original_gtin: this.product.gtinFormats.gtin14
                })
                await this.$router.push({ name: 'my-items.shop', params: {} })
            } else {
                await pmoApi.addUpcsToShoppingList(pmo_category, {
                    gtins: selectedArray,
                    original_gtin: this.product.gtinFormats.gtin14
                })

                const alternative_gtins = _.clone(selectedArray)
                _.remove(alternative_gtins, (n) => n === this.product.gtinFormats.gtin14)

                if (alternative_gtins.length) {
                    await store.dispatch('my-items/addItemToList', {
                        gtins: alternative_gtins,
                        original_gtin: this.product.gtinFormats.gtin14
                    })
                }

                if (! this.alreadyFirstSaved) {
                    store.commit('auth/addFirstExperience', 'first_save_alternative')
                }

                if (this.$route.query.goto === 'my-items') {
                    await this.$router.push({ name: 'my-items.shop', params: {} })
                } else if ( this.$route.query.goto === 'summary') {
                    await this.$router.push({ name: 'pmo.summary', params: {pantry: this.$route.query.pantry} })
                } else {
                    await this.$router.push({ name: 'pmo.show', params: {pmo_category: pmo_category} })
                }
            }
        },
        showDialog() {
            this.dialogVisible = true
        },
    }
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.search-product {
    $block-name: &;
    $local-light-gray: #f0f0f0;
    background-color: $local-light-gray;
    &__product {
        //margin-bottom: 25px;
    }
    &__no-alternatives-robot {
        text-align: center;
        width: 100%;
        padding-top: 20px;
        img {
            max-width: 220px;
        }
    }
    &__alternatives, &__selected-alternatives {
        margin-bottom: 20px;
        &-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;
            // border-bottom: 1px solid $light-gray;
            padding-bottom: 10px;
        }
        &-title {
            flex-grow: 1;
            padding-right: 10px;
            &-value {
                font-weight: 600;
                color: $black;
            }
            &-info {
                cursor: pointer;
                &:hover {
                    transition: 0.2s color ease-in-out;
                    color: $indigo;
                }
            }
        }
        &-button {
            flex-shrink: 0;
        }
        &-products {
            border-top: 1px solid $light-gray;
            padding-top: 15px;
        }
    }
    &__excluded {
        .product-block {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                z-index: 5;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background-image: url("/images/cross.png");
                background-size: cover;
                background-position: center center;
                background-repeat: no-repeat;
                width: 170px;
                height: 170px;
            }
        }
    }
    &__buttons {
        padding: 20px 0;
        @media all and (min-width: $xs) {
            display: flex;
            margin: 0 -10px;
        }
        &-col {
            &:not(:last-child) {
                @media all and (max-width: $xs - 1) {
                    margin-bottom: 10px;
                }
            }
            @media all and (min-width: $xs) {
                width: 50%;
                padding: 0 10px;
            }
        }
    }
    .el-checkbox, .el-radio {
        height: unset;
        display: block;
        &-group {
            display: flex;
            flex-wrap: nowrap;
            align-items: unset;
        }
    }
    .search-product-warning {
        font-weight: 600;
    }
}
</style>
