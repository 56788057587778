<template>
    <section>
        <template v-if="page === currentPage">
            <div
                v-for="question in localQuestions"
                :key="question._id"
                class="quiz-question"
            >
                <div
                    class="default-text quiz-question__text"
                    v-html="question.text"
                />
                <div
                    v-if="question.additional_info && question.additional_info.top_image"
                    class="quiz-question__image quiz-question__image--centered"
                >
                    <div class="quiz-question__image-wrap">
                        <img
                            :src="question.additional_info.top_image"
                            class="quiz-question__image-i"
                            alt=""
                        >
                    </div>
                </div>
                <component
                    :is="getVariantComponent(question.type)"
                    :question="question"
                    class="el-radio-group-bordered"
                    :class="{'el-checkbox-group--column': question.type === 'checkbox'}"
                    @set="handleChange"
                />
            </div>
        </template>
        <QuizQuestions
            v-if="subQuestions.length"
            :questions="subQuestions"
            :page="nextPage"
            @done="done => $emit('done', done)"
            @paging="next_page => $emit('paging', next_page)"
        />
    </section>
</template>

<script>
import FormRadio from './inputs/FormRadio'
import FormRate from './inputs/FormRate'
import FormRadioBtn from './inputs/FormRadioBtn'
import FormCheckbox from './inputs/FormCheckbox'
import FormConfirm from './inputs/FormConfirm'
import quizApi from '~/modules/quiz/quizApi'
import loginForm from "~/modules/auth/components/LoginForm.vue";

export default {
    name: 'QuizQuestions',
    components: {
        FormRadio,
        FormRadioBtn,
        FormRate,
        FormCheckbox,
        FormConfirm,
    },
    props: {
        questions: {
            type: Array,
            required: true,
        },
        page: {
            type: Number,
            default: 1,
        }
    },
    data() {
        return {
            nextPage: this.page,
            subQuestions: [],
        }
    },
    computed: {
        currentPage() {
            return Number(this.$route.query?.page) || 1
        },
        localQuestions() {
            return this.questions.filter(o => o.page === this.page)
        },
        isAllAnswered() {
            let filteredQuestions = this.localQuestions.filter(o => o.type !== 'text')
            let flag = true
            _.forEach(filteredQuestions, o => {
                if (!o.answer) flag = false
            })

            return flag
        },
    },
    watch: {
        localQuestions(val) {
            this.subQuestions = []
        },
        $route (to, from) {
            if (this.page === this.currentPage && this.isAllAnswered) {
                this.$emit('done', true)
            }
        },
    },
    mounted() {
        this.preload()
    },
    methods: {
        preload() {
            this.nextPage = this.page + 1
            let nextPageQuestions = this.questions.filter(o => o.page >= this.nextPage)
            if (nextPageQuestions.length) {
                this.$emit('paging', this.nextPage)
                if (!this.subQuestions.length) {
                    this.subQuestions = nextPageQuestions
                }
            } else {
                this.nextPage = this.page
            }
        },
        getVariantComponent(type) {
            switch (type) {
            case this.$config.quizTypes.radio:
                return 'form-radio'
            case this.$config.quizTypes.radio_btn:
                return 'form-radio-btn'
            case this.$config.quizTypes.rate:
                return 'form-rate'
            case this.$config.quizTypes.checkbox:
                return 'form-checkbox'
            case this.$config.quizTypes.confirm:
                return 'form-confirm'
            }
        },
        handleChange(question_id, variant_id) {
            if (typeof variant_id === 'string') {
                this.setSubQuestions(variant_id, question_id)
            } else if (typeof variant_id === 'object' && variant_id.length) {
                if (this.page == 2) {
                    this.setAspirationStress()
                }
                this.$emit('done', true)
            } else {
                this.$emit('done', false)
            }
            this.$store.commit('quiz/hideDialog')
        },
        setSubQuestions(variant_id, question_id) {
            let question_index = _.findIndex(this.localQuestions, {_id: question_id})
            let variant = _.find(this.localQuestions[question_index].variants, {_id: variant_id})
            if (this.page == 1) {
                quizApi.saveAspiration(variant.addition)
            }
            if (variant.questions && variant.questions.length) {
                this.subQuestions = variant.questions.map(o => {
                    delete o.answer
                    return o
                })
                if (variant.questions[0].page > this.page) {
                    this.nextPage = this.page + 1
                    this.$emit('paging', this.nextPage)

                    this.$emit('done', true)
                } else {
                    this.$emit('done', false)
                }
            } else if (this.isAllAnswered) {
                this.subQuestions = []
                this.nextPage = this.page
                this.$emit('paging', this.nextPage)
                this.$emit('done', true)
            }
        },
        setAspirationStress() {
            const answers = _.toArray(this.localQuestions[0].answer)
            const variants = this.localQuestions[0].variants.filter(o => answers.includes(o.value))
            const var_types = variants.map(o => o.addition)
            this.subQuestions = this.questions.filter(q => var_types.includes(q.additional_info?.type))
            this.nextPage = this.page + 1
            this.$emit('paging', this.nextPage)
            this.$emit('done', true)
        }
    }
}
</script>
