<template>
    <header class="header">
        <div class="main-header">
            <div class="header__left-side">
                <div
                    class="header__menu"
                    @click="goBack"
                >
                    <i class="header__menu-icon fa-solid fa-arrow-left" />
                </div>
            </div>
            <div class="header__center">
                <header-title />
            </div>
            <div class="header__right-side">
                <StarCount
                    v-if="route.name!=='pmo.summary' && user.client_level && user.client_level.has_starts_points_system === true"
                    :star-count="user.points"
                />
                <PmoAddButton v-else-if="route.name ==='pmo.summary'" />
            </div>
        </div>
    </header>
</template>

<script setup>
import store from '~/store'
import {useRoute, useRouter} from 'vue-router'
import HeaderTitle from '~/base/components/HeaderTitle'
import StarCount from '~/base/components/star-count/StarCount'
import PmoAddButton from '~/modules/pmo/components/PmoAddButton'

const route = useRoute()
const router = useRouter()

const user = store.getters['auth/user']

const goBackRoutes = [
    'product.show',
    'pmo.welcome-game',
    'games',
]

function goBack() {
    // let previousRoute = router.options.history.state.back
    // const isPmoCategory = /\/pmo\/(\S+)$/gm.exec(previousRoute.toString())

    if (route.matched.some(route => goBackRoutes.includes(route.name))) {
        router.back()
    } else if (route.query.goto === 'summary') {
        router.push({
            name: 'pmo.summary',
            params: {pantry: route.query.pantry}
        })
    } else if (route.name === 'pmo.summary') {
        router.push({name: 'pmo'})
    } else if (route.params.pmo_category) {
        const route_name = route.name === 'pmo.show' ? 'pmo' : 'pmo.show'

        router.push({
            name: route_name,
            params: {
                pmo_category: route.params.pmo_category,
            },
        })
    } else {
        router.push({name: 'my-items.shop'})
    }
}
</script>
