<template>
    <div
        v-loading="loading"
        class="main-content main-content--base-layout main-content--no-offset"
    >
        <template v-if="sub_category">
            <div class="main-content__top-content-section pantry-category">
                <div class="pantry-category__video">
                    <span class="pantry-category__video-star-count">
                        <StarCount
                            v-if="false"
                            :star-count="5"
                            plus-symbol
                        />
                    </span>
                    <div
                        class="embedded-video"
                        v-html="sub_category.embedded"
                    />
                </div>
                <div class="g-container">
                    <InfoTabs
                        :sub-category="sub_category"
                        :video-link="videoLink"
                    />
                    <div class="pantry-category__content-section">
                        <ContentSection
                            :title="sub_category.title"
                            :text="sub_category.description"
                            :stars-number="10"
                        />
                    </div>
                    <div
                        v-if="isKidsCamp"
                        class="pantry-category__button-section"
                    >
                        <div class="buttons-section">
                            <div class="buttons-section__row">
                                <div class="buttons-section__col buttons-section__col--wide">
                                    <el-button
                                        type="primary"
                                        round
                                        class="w-100"
                                        @click="playKidsCampGame"
                                    >
                                        Play the Game
                                    </el-button>
                                </div>
                            </div>
                            <div class="buttons-section__row">
                                <div class="buttons-section__col buttons-section__col--wide">
                                    <el-button
                                        round
                                        class="w-100"
                                        @click="nextRoute"
                                    >
                                        Finish
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-else
                        class="pantry-category__button-section"
                    >
                        <div class="buttons-section">
                            <div class="buttons-section__row">
                                <div class="buttons-section__col">
                                    <el-button
                                        type="primary"
                                        round
                                        class="w-100"
                                        @click="router.push({ name: 'pmo.barcode-scanner', params: {pmo_category: sub_category.slug} })"
                                    >
                                        Scan Item
                                    </el-button>
                                </div>
                                <div class="buttons-section__col">
                                    <el-button
                                        type="primary"
                                        round
                                        class="w-100"
                                        @click="router.push({ name: 'product.search', params: {pmo_category: sub_category.slug} })"
                                    >
                                        Search
                                    </el-button>
                                </div>
                                <div
                                    v-if="isCompleted"
                                    class="buttons-section__col"
                                >
                                    <el-button
                                        v-if="next_slug === null"
                                        round
                                        class="w-100"
                                        @click="nextRoute"
                                    >
                                        Finish
                                    </el-button>
                                    <el-button
                                        v-else
                                        type="primary"
                                        round
                                        class="w-100"
                                        @click="nextRoute"
                                    >
                                        Next
                                    </el-button>
                                </div>
                            </div>
                            <div
                                v-if="!isCompleted"
                                class="buttons-section__row"
                            >
                                <div class="buttons-section__col buttons-section__col--wide">
                                    <el-button
                                        round
                                        class="w-100"
                                        @click.once="handleRobotMessage"
                                    >
                                        {{ btn_text }}
                                    </el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="originals.length"
                        class="pantry-category__alternatives-product"
                    >
                        <AlternativeProductsList
                            :products="originals"
                            :slug-category="sub_category.slug"
                            :is-originals="true"
                        />
                    </div>
                </div>
            </div>
            <div class="main-content__bottom-content-section">
                <div class="g-container">
                    <div class="pantry-category__page-slider">
                        <PmoPageSlider
                            :page="page"
                            :total="total"
                            :prev-slug="prev_slug"
                            :next-slug="next_slug"
                            :disable-next="!isCompleted"
                            :sub-category="sub_category"
                            @goNext="nextRoute"
                        />
                    </div>
                </div>
            </div>
        </template>
        <!--        <template v-if="showRobotMessage">-->
        <!--            <fullscreen-robot-message-->
        <!--                :messages="robotMessages"-->
        <!--                :robot-image="robotImage"-->
        <!--                :show-bricks-animation="true"-->
        <!--            />-->
        <!--        </template>-->
    </div>
</template>

<script setup>
import {ref, onMounted, getCurrentInstance, computed} from 'vue'
import PmoPageSlider from './PmoPageSlider'
import pmoApi from '~/modules/pmo/pmoApi'
import {useRoute, useRouter} from 'vue-router'
import bus from '~/includes/Event'
import ContentSection from '~/base/components/content-section/ContentSection'
import AlternativeProductsList from './AlternativeProductsList'
import InfoTabs from './InfoTabs'
import StarCount from '~/base/components/star-count/StarCount'
import FullscreenRobotMessage from '~/base/components/fullscreen-robot-message/FullscreenRobotMessage'
import userApi from '~/modules/profile/userApi'
import store from '~/store'

const router = useRouter()
const route = useRoute()
const { proxy } = getCurrentInstance()

const sub_category = ref()
const page = ref()
const total = ref()
const prev_slug = ref()
const next_slug = ref()
const btn_text = ref('We Don\'t Drink This')
const products = ref([])
const originals = ref([])
const videoLink = ref('')
const loading = ref(true)
const showRobotMessage = ref(false)
const robotMessages = ref([
    'That\'s fabulous! Choosing not to drink your calories is the best.',
])
const robotImage = '/images/robots/robot_waving.svg'

onMounted(async () => {
    bus.$emit('load-title', true)
    await fetchCategory()
    await fetchProducts()
    bus.$emit('set-title', proxy.$config.pmoTypes[sub_category.value.type].name)
    loading.value = false
})

const isKidsCamp = computed(() => !!sub_category.value.game_slug)

async function fetchCategory() {
    const {data} = await pmoApi.find(route.params.pmo_category)
    sub_category.value = data.data
    page.value = data.page
    total.value = data.total
    prev_slug.value = data.prev_slug
    next_slug.value = data.next_slug
    total.value = data.total
    btn_text.value = data.btn_text
    robotMessages.value = [data.robot_message]

    videoLink.value = getVideoLink()
}
async function fetchProducts() {
    const {data} = await pmoApi.getProductsBySubcategory(route.params.pmo_category)
    products.value = data.products
    originals.value = data.originals
}

async function nextRoute() {
    localStorage.removeItem('pmo_edit_products')

    if (next_slug.value) {
        if (products.value.length + originals.value.length > 0) {
            await userApi.setFirstExperience('pmo_sub_' + sub_category.value.slug + '_finished')
        }
        await router.push({ name: 'pmo.show', params: {pmo_category: next_slug.value} })
    } else if (userApi.hasFirstExperience('game_finished_' + proxy.$config.pmoTypes[sub_category.value.type].game_slug)) {
        await router.push({name: 'pmo'})
    } else {
        await router.push({name: 'pmo.welcome-game', params: {game_slug: proxy.$config.pmoTypes[sub_category.value.type].game_slug}})
    }
}

async function playKidsCampGame() {
    await router.push('/games/' + sub_category.value.game_slug)
}

const isCompleted = computed(() => products.value.length || originals.value.length || userApi.hasFirstExperience('pmo_sub_' + sub_category.value.slug + '_finished'))

function getVideoLink() {
    if (sub_category.value.url) {
        return sub_category.value.url
    }

    // if url is not set, fetch url from embedded code
    const match = /src="https:\/\/player\.vimeo\.com\/video\/(\d+)\?h=0e01e7ae8e"/gm.exec(sub_category.value.embedded)
    return 'https://vimeo.com/' + (match&&match[1])
}

function handleRobotMessage() {
    // showRobotMessage.value = true
    store.commit('global-robot/addMessage', {message: robotMessages.value[0]})
    userApi.setFirstExperience('pmo_sub_' + sub_category.value.slug + '_finished')
    setTimeout( ()=> {
        nextRoute()
    }, 5000)
}
</script>
