<template>
    <div
        v-if="quiz"
        class="main-content main-content--base-layout"
    >
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="questions-page__question-module">
                    <question-module-header
                        :image="quiz.image"
                        :name="quiz.name"
                        class="question-module--middle-size"
                    />
                </div>
                <template v-if="currentPage === 1 && nextPage === 1">
                    <div class="questions-page__question">
                        <div class="quiz-rate">
                            <p
                                class="default-text quiz-question__text"
                                v-html="quiz.questions[0].text"
                            />
                            <FormRate
                                :question="quiz.questions[0]"
                                @set="setNumberMembers"
                            />
                        </div>
                    </div>
                    <div class="questions-page__quantity-list questions-page__quantity-list--bordered-top">
                        <div class="quiz-quantity">
                            <p
                                class="default-text quiz-question__text quiz-question__text--light-gray"
                                :class="{'quiz-question__text--dark': step === 2}"
                                v-html="quiz.questions[1].text"
                            />
                            <template v-if="step === 2">
                                <div class="quiz-question__list">
                                    <PeopleType
                                        v-for="question in quiz.questions.slice(2, 6)"
                                        :key="question.id"
                                        :question="question"
                                        :answers="answers"
                                        class="quiz-question__list-item"
                                        @change="handleAnswers"
                                    />
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="questions-page__question questions-page__question--bordered-top">
                        <p
                            class="default-text quiz-question__text quiz-question__text--light-gray"
                            :class="{'quiz-question__text--dark': step === 2}"
                            v-html="quiz.questions[6].text"
                        />
                        <template v-if="step === 2">
                            <FormRadio
                                :question="quiz.questions[6]"
                                class="el-radio-group-bordered"
                                @set="handleFDM"
                            />
                        </template>
                    </div>
                    <el-form
                        v-if="majority_of_decisions === 'no_another_person_does'"
                        ref="formEmail"
                        :model="form"
                        :rules="rules"
                        hide-required-asterisk
                        @submit.prevent
                    >
                        <div class="quiz-question">
                            <div class="default-text quiz-question__text">
                                <p class="default-text default-text--offset-bottom">
                                    We would like to ask that person to be part of this journey. These tools are very helpful to the person deciding what foods to bring into your household as the Food Decision Maker.
                                </p>
                                <p class="default-text default-text--offset-bottom">
                                    When you invite them your accounts will be linked, and you can collaborate on food product changes with each of you having the necessary, critical information and tools.
                                </p>
                                <p class="default-text">
                                    Each of you will:
                                </p>
                                <ol>
                                    <li>Create a unique profile,</li>
                                    <li>Complete the learning modules, and</li>
                                    <li>Use My Items to track, shop and rate new products</li>
                                </ol>
                                <p class="default-text default-text--offset-bottom">
                                    The Food Decision Maker will complete the Pantry Makeover process finding healthier foods for your household. You will continue to track and evaluate how the new products are supporting your personal aspiration.
                                </p>
                                <div class="questions-page__question">
                                    <FormRadio
                                        :question="questionInfo"
                                        class="el-radio-group-bordered"
                                        @set="handleFDMEmail"
                                    />
                                </div>
                            </div>
                        </div>
                        <el-form-item
                            v-if="showEmailForm"
                            prop="email"
                            :rules="
                                [{
                                    required: true,
                                    message: 'Please input email address',
                                    trigger: 'blur',
                                },{
                                    type: 'email',
                                    message: 'Please input correct email address',
                                    trigger: ['blur', 'change'],
                                }]
                            "
                            :error="formErrors.get('email')"
                        >
                            <label class="el-form-item__label--floating-label">
                                <el-input
                                    v-model="form.email"
                                    placeholder=" "
                                    class="el-input--floating-label"
                                >
                                    <template #prefix>
                                        Email
                                    </template>
                                </el-input>
                            </label>
                        </el-form-item>
                    </el-form>
                </template>
            </div>
        </div>
        <div class="main-content__bottom-content-section">
            <div class="g-container">
                <div class="main-content__button-wrap">
                    <el-button
                        :disabled="! enabled_continue_button"
                        :loading="loading"
                        type="primary"
                        round
                        class="continue-button"
                        @click="handleContinue"
                    >
                        Continue
                    </el-button>
                </div>
            </div>
        </div>
        <fullscreen-robot-message
            v-if="showRobotMessage"
            :messages="robotMessage"
            :robot-image="'/images/robots/robot_smile.svg'"
        />
    </div>
</template>

<script>
import PeopleType from './PeopleType'
import FormRate from '../inputs/FormRate'
import FormRadio from '../inputs/FormRadio'
import quizApi from '~/modules/quiz/quizApi'
import {Errors} from '~/includes/Errors'
import bus from '~/includes/Event'
import QuestionModuleHeader from '~/modules/quiz/components/question-module-header/QuestionModuleHeader'
import FullscreenRobotMessage from '~/base/components/fullscreen-robot-message/FullscreenRobotMessage'
import router from "~/router";
import store from "~/store";

export default {
    name: 'QuizHouseholdShow',
    components: {FormRadio, FormRate, PeopleType, QuestionModuleHeader, FullscreenRobotMessage},
    data() {
        return {
            quiz: null,
            answers: {
                household_people_count: 0,
                household_adults_count: 0,
                household_teenagers_count: 0,
                household_pre_teens_count: 0,
                household_kids_toddlers_count: 0,
            },
            majority_of_decisions: '',
            form: {
                email: '',
            },
            rules: {
                email: [
                    { required: true, message: 'Please input email address', trigger: ['blur', 'change'] },
                    { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change']}
                ]
            },
            step: 1,
            currentPage: 1,
            nextPage: 1,
            loading: false,
            formErrors: new Errors(),
            is_valid_email: false,
            questionInfo: {
                _id: '123',
                answer: null,
                variants: [
                    {
                        value: 'Yes, <strong>invite the Food Decision Maker.</strong>',
                        _id: '124',
                        addition: 'no_another_person_does',
                    },
                    {
                        value: 'No thank you. <strong>I will do the entire process.</strong> ',
                        _id: '125',
                        addition: 'yes_i_do',
                    }
                ]
            },
            showEmailForm: null,
            showRobotMessage: false,
            robotMessage: ['Since you have little ones in the house, we will provide some learning content and games as an option to include them later in the process.']
        }
    },
    computed: {
        authUser() {
            return store.getters['auth/user']
        },
        enabled_continue_button() {
            let fdm_has_been_established =
                this.majority_of_decisions === 'yes_i_do'
                ||
                this.majority_of_decisions === 'no_another_person_does' && this.is_valid_email
                ||
                this.majority_of_decisions === 'no_another_person_does' && (this.show_email_form === false)
            return fdm_has_been_established && !this.loading
        },
        show_email_form() {
            return this.showEmailForm
        },
    },
    watch: {
        'form.email'() {
            this.$refs.formEmail?.validate().then(valid => {
                this.is_valid_email = valid
            }).catch(e => {
                this.is_valid_email = false
            })
        }
    },
    async created() {
        if (this.authUser.is_fdm && this.authUser.type === 'child') {
            this.$router.push({name: 'quiz.store-selector'})
        } else {
            await this.fetchSubQuiz()
            bus.$emit('set-title', this.quiz.name)
        }
    },
    methods: {
        async fetchSubQuiz() {
            const {data} = await quizApi.getSubQuiz(this.$config.householdQuizSlug)
            if (!!data) {
                this.quiz = data

                this.quiz.questions.forEach(question => {
                    let answerValue = 0
                    if ( question.answer === null) {
                        return
                    }
                    if (Number.isInteger(question.answer)) {
                        answerValue = question.answer||0
                    } else {
                        let variant = _.find(question.variants, {_id: question.answer})
                        answerValue = variant.value
                    }
                    this.answers[question.additional_info.slug] = answerValue
                    this.step = 2
                    this.majority_of_decisions = this.setMajorityOfDecisions(this.answers[question.additional_info.slug])
                })
            }
        },
        setNumberMembers(question_id, variant_id) {
            let variant = _.find(this.quiz.questions[0].variants, {_id: variant_id})
            this.answers.household_people_count = variant.value
            this.step = 2
        },
        handleAnswers(answers) {
            this.answers = answers
            let total = this.answers.household_adults_count + this.answers.household_teenagers_count + this.answers.household_pre_teens_count + this.answers.household_kids_toddlers_count

            if (this.answers.household_kids_toddlers_count > 0 && this.answers.household_people_count === total) {
                this.showRobot()
            }
        },
        handleFDM(question_id, variant_id) {
            let variant = _.find(this.quiz.questions[6].variants, {_id: variant_id})
            this.majority_of_decisions = variant.addition
            if (this.majority_of_decisions === 'no_another_person_does') {
                this.scrollTo()
            }
        },
        handleContinue() {
            let data
            if (this.form.email) {
                data = {email: this.form.email}
                this.loading = true
            } else {
                data = {}
            }
            quizApi.setFDM(data).then(async r => {
                quizApi.saveAnswers(this.quiz)
                this.currentPage++
                this.form.email = ''
                if (this.majority_of_decisions === 'no_another_person_does') {
                    await quizApi.setHouseholdDone()
                    this.$router.push({name: 'quiz.index'})
                } else {
                    this.$router.push({name: 'quiz.store-selector'})
                }
            }).catch(errors => {
                this.formErrors.record(errors.response.data.errors)
            }).finally(() => {
                this.loading = false
            })
        },
        setMajorityOfDecisions(value) {
            const values = {
                '<strong>Yes</strong>, I do': 'yes_i_do',
                '<strong>No</strong>, another person does': 'no_another_person_does'
            }
            return values[value]
        },
        handleFDMEmail(question_id, variant_id){
            this.showEmailForm = true
            let variant = _.find(this.questionInfo.variants, {_id: variant_id})
            this.showEmailForm = variant.addition === 'no_another_person_does'
            this.scrollTo()
        },
        scrollTo() {
            setTimeout(() => {
                document.querySelector('.continue-button').scrollIntoView({ behavior: 'smooth' })
            }, 200)
        },
        showRobot() {
            this.showRobotMessage = true
            setTimeout(() => {
                this.showRobotMessage = false
            }, 7000)
        },
    }
}
</script>
