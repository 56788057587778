<template>
    <div class="main-content main-content--base-layout">
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div class="personal-info fdm-person">
                    <div class="profile-info__form-section-title">
                        Food Decision Maker
                    </div>
                    <div
                        v-if="fdmUser"
                        class="fdm-user"
                    >
                        <div class="fdm-user__image">
                            <img
                                :src="avatarUrl"
                                alt="avatar"
                                class="fdm-user__image-i"
                                @error="imageLoadError"
                            >
                        </div>
                        <div class="fdm-user__email">
                            {{ fdmUser.email }}
                        </div>
                    </div>
                    <div
                        v-if="user.is_primary && !user.is_fdm"
                        class="lesson__btn-section"
                    >
                        <el-button
                            type="primary"
                            class="continue-button"
                            round
                            @click.prevent.stop="setMeFdm()"
                        >
                            Become the Food Decision Maker
                        </el-button>
                    </div>
                    <p
                        v-if="user.is_fdm"
                        class="profile-info__form-section-text"
                    >
                        You are currently the Food Decision Maker.
                        <template v-if="user.is_primary">
                            To delegate this role and have support from another household member please invite them to participate.
                        </template>
                    </p>
                </div>
            </div>
            <div class="g-container">
                <div class="personal-info">
                    <template v-if="user.is_primary">
                        <div class="profile-info__form-section-title">
                            Change the Food Decision Maker
                        </div>
                        <el-form
                            :model="form"
                            :rules="rules"
                            class="personal-info__form"
                            @submit.prevent.stop="onSubmit"
                        >
                            <div class="profile-info__form-section">
                                <el-form-item
                                    prop="email"
                                    :error="formErrors.get('email')"
                                    class="profile-info__form-section-item"
                                >
                                    <label class="el-form-item__label--floating-label">
                                        <el-input
                                            v-model="form.email"
                                            name="email"
                                            type="email"
                                            placeholder=" "
                                            class="el-input--floating-label"
                                        >
                                            <template #prefix>
                                                Email
                                            </template>
                                        </el-input>
                                    </label>
                                </el-form-item>
                                <div
                                    v-if="showSuccessSent"
                                    class="default-text"
                                >
                                    {{ successMessage }}
                                </div>
                            </div>
                        </el-form>
                        <div
                            v-if="false"
                            class="profile-info__form-section-text default-text"
                        >
                            <p>Please help us in achieving your goals by allowing us to <b>invite</b> the primary grocery decision maker.</p>
                            <p>When it comes to the food that makes it home for all to eat, <b>little details</b> about food ingredients matter a lot.</p>
                            <p>By providing simple tools and knowledge to those making purchase decisions, your <b>family can benefit</b> in so many ways.</p>
                        </div>
                    </template>
                </div>
            </div>
            <div
                v-if="user.is_primary"
                class="g-container"
            >
                <div class="main-content__buttons-wrap">
                    <div class="main-content__button-col">
                        <el-button
                            :loading="loading"
                            type="primary"
                            round
                            class="continue-button"
                            @click="onSubmit"
                        >
                            Re-assign Food Decision Maker
                        </el-button>
                    </div>
                    <div class="main-content__button-col">
                        <el-button
                            round
                            class="continue-button"
                            @click="onCancel"
                        >
                            Cancel
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {ref, computed, onMounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import store from '~/store'
import bus from '~/includes/Event'
import {Errors} from '~/includes/Errors'
import quizApi from '~/modules/quiz/quizApi'
import inviteApi from '~/modules/profile/inviteApi'
import {LEARNING_MODULE_STEP} from '~/modules/learning-modules/routes'

const router = useRouter()
const route = useRoute()
const user = computed(() => store.getters['auth/user'])
const fdmUser = ref(null)
const loading = ref(false)

const form = ref({
    email: '',
})
const rules = ref({
    email: [
        { required: true, message: 'Please enter your email', trigger: 'blur' },
        { type: 'email', message: 'Invalid email', trigger: ['change', 'blur'] }
    ],
})

const formErrors = ref(new Errors())
const showSuccessSent = ref(false)
const successMessage = ref('Invitation sent!')
const avatarUrl = computed(() => fdmUser.value.photo_url || '/images/ava-default.svg')

onMounted(
    () => {
        bus.$emit('set-title', 'Food Decision Maker')
        bus.$on('change-fdm', getFdmUserData)
        getFdmUserData()
    }
)

const imageLoadError = (e) => {
    e.target.src = '/images/ava-default.svg'
}

const onSubmit = (event) => {
    if (event) {
        event.preventDefault()
    }
    formErrors.value.record([])
    if(form.value.email) {
        loading.value = true
        quizApi.setFDM(form.value).then(response => {
            // ElMessage.success('Invitation sent successfully')
            form.value.email = ''
            if (response.data.is_new) {
                showSuccessSent.value = true
            }
            getFdmUserData()
            store.dispatch('auth/fetchUser')
        }).catch(err => {
            formErrors.value.record(err.response.data.errors)
        }).finally(() => loading.value = false)
    } else {
        formErrors.value.record({'email':['The email must be a valid email address.']})
    }
}

function onCancel() {
    router.push('/profile')
}

function setMeFdm() {
    inviteApi.setMeFdm().then(response => {
        store.dispatch('auth/fetchUser')
        getFdmUserData()
    })
}

function getFdmUserData() {
    inviteApi.childUsers().then(response => {
        if (response.data.parent.is_fdm) {
            fdmUser.value = response.data.parent
        } else {
            const userInvite = response.data.data.find(o => o.child_user?.is_fdm === true)
            if (userInvite) fdmUser.value = userInvite.child_user
        }
    })
}
</script>

<style lang="scss" scoped>
.continue-button {
    margin: 0 auto;
}
@media only screen and (max-width: 600px) {
    .main-content__buttons-wrap {
        display: block;
        .main-content__button-col {
            margin: 10px auto;
            width: auto;
        }
    }
}
.fdm-person {
    margin-bottom: 20px;
}
.fdm-user {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &__image {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden;
        &-i {
            object-fit: fill;
        }
    }
    &__email {
        flex-grow: 1;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
