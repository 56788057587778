<template>
    <div class="cart-product-block">
        <div class="cart-product-block__checkbox">
            <el-checkbox
                v-if="false"
                v-model="checked"
                class="el-checkbox--shop-product-block"
            />
        </div>
        <div class="cart-product-block__product cart-product-block__product--inline">
            <ProductPreviewBlock
                :key="product.upc"
                :product="product"
                no-border
                no-margin-bottom

                cart-product-block
            />
            <RateThumbs :product="product" />
        </div>
        <div
            v-if="false"
            class="cart-product-block__remove"
        >
            <el-popconfirm
                confirm-button-text="Yes"
                confirm-button-type="primary"
                cancel-button-text="No"
                cancel-button-type="default"
                :icon="InfoFilled"
                icon-color="#626AEF"
                title="Are you sure to Remove product from replaced?"
                popper-class="el-popper--cart-product-block"
                @confirm="clickDelete(product)"
            >
                <template #reference>
                    <div class="cart-product-block__remove-icon-wrap">
                        <i class="cart-product-block__remove-icon fa-solid fa-xmark" />
                    </div>
                </template>
            </el-popconfirm>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import ProductPreviewBlock from '~/modules/labelinsight/components/ProductPreviewBlock'
import RateThumbs from './rate-thumbs'
import store from '~/store'

const props = defineProps({
    'product': {
        type: [ Object, Array ],
        required: true,
        default: null
    }
})
const checked = ref(true)

// function clickDelete(product) {
//     store.dispatch('my-items/removeFromList', product.gtinFormats.gtin14)
// }
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.cart-product-block {
    $block-name: &;
    display: flex;
    align-items: center;
    &__checkbox {
        flex-shrink: 0;
    }
    &__product {
        flex-grow: 1;
        &--inline {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    &__remove {
        flex-shrink: 0;
        align-self: flex-start;
        cursor: pointer;
        &:hover {
            #{$block-name}__remove-icon {
                color: $green;
            }
        }
        &-icon {
            font-size: 22px;
            color: $gray;
            transition: 0.2s color ease-in-out;
            &-wrap {
                display: flex;
                justify-content: center;
                width: 32px;
                height: 32px;
            }
        }
    }
}
</style>
