<template>
    <div class="main-content main-content--offset-bottom">
        <div
            v-loading="loading"
            class="g-container"
        >
            <div class="main-content__text">
                Complete these <b>learning modules</b> as the first step in your
                pantry makeover. Each one just takes a couple of minutes
            </div>
            <div
                v-if="learning_modules.length !== 0"
                id="learning-modules"
                class="main-content__learning-modules"
            >
                <div
                    v-for="(module, index) in learning_modules"
                    :id="`module-${index + 1}`"
                    :key="module._id"
                    :ref="el => { divs[module._id] = el }"
                    class="main-content__learning-module"
                >
                    <LearningModuleItem
                        :module="module"
                        :is-active="module._id === activeModuleId"
                    />
                </div>
            </div>
            <SuccessFailRobot
                v-if="globalMessages.length === 0"
                @showFinalQuestion="handleFinishQuestion"
            />
        </div>
        <el-dialog
            v-model="showFinishQuestion"
            :show-close="false"
            :close-on-click-modal="false"
            custom-class="el-dialog--learning-module"
        >
            <FinishQuestion @clickCompleteSection="closeFinishQuestion" />
        </el-dialog>
    </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import store from '~/store'
import SuccessFailRobot from './success-fail-robot'
import learningModuleApi from '../learningModuleApi'
import LearningModuleItem from './LearningModuleItem'
import {useRouter} from 'vue-router'
import bus from '~/includes/Event'
import FinishQuestion from './FinishQuestion'
import userApi from '~/modules/profile/userApi'

const user = store.getters['auth/user']

const router = useRouter()

const globalMessages = computed(() => store.getters['global-robot/messages'])

const learning_modules = ref([])
const loading = ref(true)
const showFinishQuestion = ref(false)

onMounted( async () => {
    learningModuleApi.totalNotCompleted()
        .then(response => {
            store.commit('learning-modules/setCountUncompleted', response.data)
        })

    bus.$emit('set-title', 'Learning Modules')
    if (user.last_step.url !== '/learning-modules') {
        await store.dispatch('auth/fetchUser')
    }
    await fetchModules()
    await userApi.setFirstExperience('learning_modules_intro')

    if (!userApi.hasFirstExperience('showed_finish_question')) {
        const {data} = await learningModuleApi.completedModules()
        const completedModules = data
        const mandatoryModules = getMandatoryModules()
        // console.log('mandatoryModules', mandatoryModules)

        const mandatoryIds = mandatoryModules.map(obj => obj._id);
        const isShowFinishMandatoryMessage = mandatoryIds.every(id => completedModules.includes(id));

        const allModulesIds = learning_modules.value.map(obj => obj._id);
        const isShowFinishMessage = allModulesIds.every(id => completedModules.includes(id));
        // console.log('completedModules', completedModules)
        // console.log('isShowFinishMandatoryMessage', isShowFinishMandatoryMessage)

        if (isShowFinishMessage) {
            store.commit('shortcut_quiz/showFinalRobotDialog')
        } else if (isShowFinishMandatoryMessage && !userApi.hasFirstExperience('show_finish_mandatory_modules')) {
            await userApi.setFirstExperience('show_finish_mandatory_modules')
            store.commit('shortcut_quiz/showMandatoryFinishRobotDialog')
        }
    }
    scrollToActive()
})

async function fetchModules() {
    loading.value = true
    const {data} = await learningModuleApi.fetchModules()
    learning_modules.value = data?.data
    loading.value = false
}

const activeModuleId = computed(() => learning_modules.value.find(o => !o.completed)?._id)

const divs = ref([])

function scrollToActive() {
    if (activeModuleId.value) {
        divs.value[activeModuleId.value]?.scrollIntoView({ behavior: 'smooth' })
    }
}

function handleFinishQuestion() {
    showFinishQuestion.value = true
}

function closeFinishQuestion() {
    showFinishQuestion.value = false

    if (user.is_fdm) {
        router.push({name: 'intro.after-lm.pmo-preview'})
    } else {
        router.push({name: 'my-items.shop'})
    }
}

function getMandatoryModules() {
    let clientLevelIds = getClientLevelIds(user.client_level_parents[0])
    // clientLevelIds.push(user.client_level_id)

    return learning_modules.value.filter(module => {
        let is_mandatory = true

        if (module.is_mandatory[user.client_level_id] !== undefined) {
            is_mandatory = module.is_mandatory[user.client_level_id]

            return is_mandatory
        }

        for (let i = 0; i < clientLevelIds.length; i++) {
            if (module.is_mandatory[clientLevelIds[i]] !== undefined) {
                is_mandatory = module.is_mandatory[clientLevelIds[i]]
                break
            }
        }
        
        return is_mandatory
    })
}

function getClientLevelIds(node) {
    let ids = [];

    if (!node) {
        return ids;
    }
    ids.push(node._id);
    if (node.parent) {
        ids = ids.concat(getClientLevelIds(node.parent));
    }
    return ids;
}
</script>
