<template>
    <el-radio-group
        v-model="question.answer"
        @change="$emit('set', question._id, question.answer)"
    >
        <el-radio
            v-for="variant in question.variants"
            :key="variant._id"
            :label="variant._id"
            border
        >
            <span v-html="variant.value" />
        </el-radio>
    </el-radio-group>
</template>

<script>
export default {
    name: 'FormRadioBtn',
    props: {
        question: {
            type: Object,
            required: true,
        }
    },
}
</script>
