<template>
    <div
        class="info-block"
        :class="{'info-block--offset-top': offsetTop}"
    >
        <div class="container-holder">
            <div class="info-block__title-wrap">
                <div
                    class="info-block__title lp__title"
                    :class="{'info-block__title--blue-color': titleColorBlue}"
                >
                    {{ title }}
                </div>
                <div
                    v-if="subtitle"
                    class="info-block__subtitle"
                >
                    {{ subtitle }}
                </div>
            </div>
            <div
                v-if="!without_quote"
                class="info-block__quote"
                :class="{'info-block__quote--green-bg': quoteBgGreen}"
            >
                {{ quote }}
            </div>
            <div
                v-else
                class="info-block__quote"
                :class="{'info-block__quote--green-bg': quoteBgGreen}"
            >
                "I lost over 20 pounds, I lost 7 inches around my waist, my good cholesterol is up, my blood pressure is regulated, my glucose is normal. I FEEL AND LOOK FANTASTIC!" <span> - SeekingSimple Pantry Makeover Participant</span>

            </div>
        </div>
        <div class="info-block__image">
            <img
                :src="image"
                class="info-block__image-i"
                alt=""
            >
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    subtitle: {
        type: String,
        default: '',
    },
    quote: {
        type: String,
        default: '',
    },
    image: {
        type: String,
        default: '',
    },
    titleColorBlue: {
        type: Boolean,
        default: false
    },
    quoteBgGreen: {
        type: Boolean,
        default: false
    },
    offsetTop: {
        type: Boolean,
        default: false
    },
    without_quote: {
        type: Boolean,
        default: false
    }
})
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.info-block {
    padding-top: 50px;
    &--offset-top {
        padding-top: 100px;
    }
    .container-holder {
        position: relative;
        z-index: 3;
    }
    &__title {
        margin: 0;
        color: $green;
        &--blue-color {
            color: $blue;
        }
        &-wrap {
            max-width: 630px;
            margin: 0 0 35px auto;
            @media all and (min-width: $s) {
                margin: 0 0 60px auto;
            }
        }
    }
    &__subtitle {
        font-size: 22px;
        font-weight: 600;
        color: $green;
    }
    &__quote {
        max-width: 90%;
        margin: 0 auto;
        padding: 10px;
        font-size: 18px;
        color: $white;
        background-color: $blue;
        @media all and (min-width: $s) {
            max-width: 80%;
            font-size: 20px;
        }

        span {
            font-size: 14px !important;
        }

        &--green-bg {
            background-color: $green;
        }
    }
    &__image {
        position: relative;
        z-index: 2;
        margin: -15px 0 0;

        @media all and (min-width: $s) {
            margin: -40px 0 0;
        }
        &-i {
            max-height: 100%;
        }

        img {
            width: 100%;
        }
    }
}
</style>
