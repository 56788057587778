<template>
    <div class="finish-question">
        <h3 class="finish-question__title default-text default-text--bold">
            {{ mainTitle }}
        </h3>
        <p class="finish-question__text default-text">
            {{ mainText }}
        </p>
        <div class="finish-question__btn-wrap">
            <div class="finish-question__btn-col">
                <el-button
                    :disabled="answeredTrue"
                    type="primary"
                    round
                    class="w-100"
                    @click.prevent.stop="handleAnswer('true')"
                >
                    {{ positiveAnswerBtnTitle }}
                </el-button>
            </div>
            <div class="finish-question__btn-col">
                <el-button
                    :disabled="answeredFalse"
                    type="primary"
                    round
                    class="w-100"
                    @click.prevent.stop="handleAnswer('false')"
                >
                    {{ negativeAnswerBtnTitle }}
                </el-button>
            </div>
        </div>
        <div
            v-if="answeredTrue"
            class="finish-question__answer"
        >
            <h3 class="finish-question__title finish-question__title--center default-text default-text--bold">
                {{ correctAnswerTitle }}
            </h3>
            <div class="finish-question__img-wrap">
                <img
                    class="finish-question__img"
                    src="/images/robots/robot_hands_up.svg"
                    alt=""
                >
            </div>
        </div>
        <div
            v-if="answeredFalse"
            class="finish-question__answer"
        >
            <h3 class="finish-question__title default-text default-text--bold">
                {{ wrongAnswerTitle }}
            </h3>
            <p class="finish-question__text default-text">
                {{ wrongAnswerText }}
            </p>
            <div class="finish-question__img-wrap">
                <img
                    class="finish-question__img"
                    src="/images/robots/robot_glasses.svg"
                    alt=""
                >
            </div>
        </div>
        <el-button
            v-if="answeredTrue || answeredFalse"
            class="continue-button"
            type="primary"
            round
            @click.prevent.stop="complete"
        >
            {{ completeBtnTitle }}
        </el-button>
    </div>
</template>

<script setup>
import {computed, ref} from 'vue'
import baseApi from '~/base/baseApi'
import {MY_ITEMS, PMO_STEP} from '~/modules/pmo/routes'
import {useRouter} from 'vue-router'
import store from '~/store'
import inviteApi from '~/modules/profile/inviteApi'
import userApi from '~/modules/profile/userApi'
const emit = defineEmits()

const name = 'FinishQuestion'

const router = useRouter()
const user = store.getters['auth/user']
const answer = ref(null)
const mainTitle = ref('Check what you’ve learned')
const mainText = ref('Sugar and processed carbohydrates are handled by the liver, causing liver dysfunction and a broad range of negative health issues.')
const correctAnswerTitle = ref('Bravo!')
const wrongAnswerTitle = ref('Oops, that’s not right.')
const wrongAnswerText = ref('Taking the next steps to reduce sugar and carbs in your household will have a positive impact on your liver and your health.')
const completeBtnTitle = ref('Complete Section')
const positiveAnswerBtnTitle = ref('True')
const negativeAnswerBtnTitle = ref('False')

function handleAnswer(value) {
    answer.value = value
}

async function complete() {
    await userApi.setFirstExperience('showed_finish_question')
    if (!user.is_fdm) {
        await baseApi.newUserStep(MY_ITEMS)
    } else {
        await baseApi.newUserStep(PMO_STEP)
    }

    await store.dispatch('auth/fetchUser')
    emit('clickCompleteSection')
}

const answeredTrue = computed(() => answer.value === 'true')
const answeredFalse = computed(() => answer.value === 'false')

</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.finish-question {
    &__title {
        margin-bottom: 5px;
        word-break: break-word;
        &--center {
            text-align: center;
        }
    }
    &__text {
        margin-bottom: 15px;
        word-break: break-word;
    }
    &__btn {
        &-wrap {
            display: flex;
            margin: 0 -8px;
        }
        &-col {
            width: 50%;
            padding: 0 8px;
        }
    }
    &__answer {
        padding-top: 10px;
    }
    &__img {
        max-width: 100%;
        max-height: 100%;
        &-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 115px;
            height: 115px;
            margin: 0 auto 25px;
        }
    }
}
</style>
