export const state = {
    messages: [],
    points: 0,
    image: '/images/robots/robot_hand_up.svg'
}
// getters
export const getters = {
    messages: state => state.messages,
    points: state => state.points,
    type: state => state.type,
    image: state => state.image,
}

// mutations
export const mutations = {
    addMessage(state, payload) {
        state.messages.push(payload.message)
        if (payload.image) {
            state.image = payload.image
        }
    },
    clear(state) {
        state.messages = []
    },
    addPoints(state, payload) {
        state.points = payload.points
        state.type = payload.type
    },
    clearPoints(state) {
        state.points = 0
    }

}
