<template>
    <div class="profile-menu">
        <div
            v-for="(link, index) in list"
            :key="index"
            class="profile-menu__item"
            :class="{'profile-menu__item--bordered': link.title === 'Sugar Substitutes'}"
        >
            <template v-if="showMenuItem(link)">
                <profile-menu-link :item="link" />
            </template>
        </div>
    </div>
</template>

<script setup>
import {computed} from 'vue'
import store from '~/store'
import ProfileMenuLink from './ProfileMenuLink'

const name = 'ProfileMenu'
const user = computed(() => store.getters['auth/user'])
const props = defineProps({
    list: {
        type: Array,
        required: true,
    },
})

function showMenuItem (link) {
    if (link.primary === user.value.is_primary) return true
    if (link.fdm === user.value.is_fdm) return true
    return !link.fdm && !link.primary
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
$local-light-gray: #e0e0e0;
.profile-menu {
    $block-name: &;
    &__item {
        margin-bottom: 24px;
        line-height: 1;
        &:empty {
            display: none;
        }
        &--bordered,
        &:nth-child(2) {
            border-bottom: 1px solid $local-light-gray;
            padding-bottom: 24px;
        }
    }
}
</style>
