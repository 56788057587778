<template>
    <section
        id="robot_dialog"
        class="robot-notification"
        :class="{ 'robot-notification--centered': centered }"
    >
        <TransitionGroup
            name="message"
            tag="ul"
            mode="out-in"
            class="robot-notification__list-message"
        >
            <div
                v-for="(message, i) in messagesList"
                :key="i"
                class="robot-notification__message"
                :class="[
                    blackMessageColor ? 'robot-notification__message--black' : '',
                    listMessageCentered ? 'robot-notification__message--centered' : '',
                    !showBricksAnimation ? 'robot-notification__message--offset-bottom' : ''
                ]"
            >
                <div
                    :id="'message_' + i"
                    v-html="message"
                />
                <div
                    v-if="(buttonCanselText || buttonText) && i === messages.length - 1"
                    class="robot-notification__message-button-wrap"
                    :class="{'robot-notification__message-button-wrap--helpful-btn': helpfulBtnWhite}"
                >
                    <template v-if="buttonCanselText && helpfulBtnWhite">
                        <el-button
                            round
                            type="info"
                            class="robot-notification__message-button"
                            @click="clickCallbackCanselButton"
                        >
                            <i class="fa-solid fa-thumbs-up" />&nbsp; {{ buttonCanselText }}
                        </el-button>
                    </template>
                    <template v-else>
                        <el-button
                            v-if="buttonCanselText"
                            class="el-button--transparent robot-notification__message-button"
                            @click="clickCallbackCanselButton"
                        >
                            {{ buttonCanselText }}
                        </el-button>
                    </template>
                    <template v-if="buttonCanselText && helpfulBtnWhite">
                        <el-button
                            v-if="buttonText"
                            round
                            type="info"
                            class="robot-notification__message-button"
                            @click="clickCallbackButton"
                        >
                            <i class="fa-solid fa-thumbs-down" />&nbsp; {{ buttonText }}
                        </el-button>
                    </template>
                    <template v-else>
                        <el-button
                            v-if="buttonText"
                            class="el-button--transparent robot-notification__message-button"
                            @click="clickCallbackButton"
                        >
                            {{ buttonText }}
                        </el-button>
                    </template>
                </div>
            </div>
        </TransitionGroup>
        <div
            class="robot-notification__wrap-bricks"
            :class="{'robot-notification__wrap-bricks--hide': !showBricksAnimation}"
        >
            <div class="robot-notification__image-bricks">
                <div class="robot-notification__brick robot-notification__brick--1" />
                <div class="robot-notification__brick robot-notification__brick--2" />
                <div class="robot-notification__brick robot-notification__brick--3" />
                <div class="robot-notification__brick robot-notification__brick--4" />
                <div class="robot-notification__brick robot-notification__brick--5" />
                <div class="robot-notification__brick robot-notification__brick--6" />
                <div class="robot-notification__brick robot-notification__brick--7" />
            </div>
        </div>

        <div
            class="robot-notification__image"
        >
            <img
                ref="robotRef"
                :src="robotImage"
                class="robot-notification__image-i"
                alt=""
            >
        </div>
    </section>
</template>

<script>
import {TIME_FOR_SHOWING_LINE_S} from "~/base/constants/points.constants";

export default {
    name: 'RobotDialog',
    props: {
        messages: {
            type: Array,
            default: [],
        },
        robotImage: {
            type: String,
            default: '/images/robots/robot_hand_up.png',
        },
        buttonText: {
            type: String,
            default: null
        },
        buttonCanselText: {
            type: String,
            default: null
        },
        buttonCallback: {
            type: Function,
            default: null
        },
        buttonCanselCallback: {
            type: Function,
            default: null
        },
        showBricksAnimation: {
            type: Boolean,
            default: true
        },
        centered: {
            type: Boolean,
            default: false
        },
        blackMessageColor: {
            type: Boolean,
            default: false
        },
        timeoutValue: {
            type: Number,
            default: 2000
        },
        helpfulBtnWhite: {
            type: Boolean,
            default: false
        },
        listMessageCentered: {
            type: Boolean,
            default: false,
            description: 'Сenters the message list relative to the component container',
        }
    },
    data() {
        return {
            index: 0,
            showBricks: false,
            timeToShowingNextMessage: 0
        }
    },
    computed: {
        messagesList: ({ messages, index }) => messages.slice(0, index)
    },
    mounted() {
        this.showBricks = this.showBricksAnimation
        this.showRobot()
        let refreshIntervalId = setInterval(() => {
            setTimeout(async () => {
                this.index = Math.min(this.index + 1, this.messages.length)
                this.showBricks = false
            }, this.timeToShowingNextMessage)
            if (this.index + 1 > this.messages.length) {
                clearInterval(refreshIntervalId)
                this.$emit('finish')
            } else {
                this.showBricks = this.showBricksAnimation
            }
        }, this.timeoutValue)
    },
    methods: {
        showRobot() {
            setTimeout(() => {
                if (this.$refs.robotRef) {
                    this.$refs.robotRef.style.opacity = '1';
                    this.$refs.robotRef.style.visibility = 'visible';
                }
            },50)
        },
        async getMessageTime(index) {
            const id = 'message_' + index
            const messageBox = document.getElementById(id);
            if (messageBox) {
                const lineHeight = parseInt(window.getComputedStyle(messageBox).lineHeight);
                const messageHeight = messageBox.offsetHeight;
                const numberOfLines = Math.floor(messageHeight / lineHeight);
                return (numberOfLines * TIME_FOR_SHOWING_LINE_S)
            }

            return 0
        },
        clickCallbackButton: function() {
            if (this.buttonCallback !== 'undefined') {
                this.buttonCallback()
            }
        },
        clickCallbackCanselButton: function() {
            if (this.buttonCanselCallback !== 'undefined') {
                this.buttonCanselCallback()
            }
        }
    },
    watch: {
        'index': function(newValue, oldValue) {
            setTimeout(async () => {
                this.timeToShowingNextMessage = await this.getMessageTime(oldValue)
            })
        }
    }
}
</script>

<style lang="scss" >
    @import "resources/sass/_variables.scss";
    .robot-notification {
        $block-name: &;
        position: relative;
        &__list-message {
            position: relative;
            z-index: 11;

        }
        &__message {
            width: 300px;
            border-radius: 18px;
            padding: 10px 20px;
            text-align: left;
            background: $indigo;
            color: $white;
            overflow: hidden;
            &:not(:last-child){
                margin-bottom: 10px;
            }
            @media all and (min-width: $s) {
                width: 400px;
                margin-left: 100px;
                &:not(:last-child){
                    margin-bottom:10px;
                }
            }
            .stars-yellow {
                display: inline-flex;
                align-items: center;
                font-weight: 600;
                color: $yellow;
                .fa-star {
                    display: inline-block;
                    margin-top: -1px;
                    margin-left: 2px;
                    font-size: 14px;
                    font-weight: 600;
                    color: $yellow;
                }
            }
            &--black {
                font-size: 20px;
                font-weight: 600;
                color: $dark;
                background-color: transparent;
            }
            &--centered {
                margin: 0 auto 10px;
                @media all and (min-width: $s) {
                    margin: 0 auto 10px;
                }
            }
            &--offset-bottom {
                margin-bottom: 20px;
            }
            &-button {
                color: $white;
                background-color: transparent;
                &:hover,
                &:active,
                &:focus {
                    color: $white;
                    background-color: transparent;
                }
                &-wrap {
                    text-align: right;
                    &--helpful-btn {
                        margin: 10px -20px 0;
                        border-top: 1px solid $white;
                        padding-top: 10px;
                        text-align: center;
                    }
                }
                &.el-button--info {
                    background-color: $white;
                    span {
                        font-size: 17px;
                        font-weight: 500;
                        color: $indigo;
                        @media all and (min-width: $s) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        &__wrap-bricks {
            z-index: 1;
            @media all and (min-width: $s) {
                padding-left: 100px;
            }
            &--hide {
                display: none;
            }
        }
        &__image-bricks {
            position: relative;
            width: 300px;
            height: 40px;
            @media all and (min-width: $s) {
                width: 400px;
            }
        }
        &__image {
            position: relative;
            z-index: 2;
            width: 180px;
            height: 180px;
            overflow: hidden;
            text-align: center;
        }
        &__image-bricks,
        &__image {
            &-i {
                max-width: 100%;
                max-height: 100%;
                transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
                visibility: hidden;
                opacity: 0;
            }
        }
        &__brick {
            position: absolute;
            border-radius: 2px;
            background-color: $indigo;
            &--1 {
                left: 50%;
                width: 25px;
                height: 25px;
                opacity: 0;
                transform: translateX(-50%);
                animation: bricks-fly 0.75s linear 0.75s, 0.75s grow-up 1.59s;
                @media all and (min-width: $s){
                    animation: bricks-fly 0.75s linear 0.75s, 0.75s grow-up-desktop 1.59s;
                }
            }
            &--2 {
                left: 59%;
                width: 15px;
                height: 15px;
                opacity: 0;
                animation: 0.8s brick-fly 0.8s linear, 0.8s brick-2-fly 0.8s linear;
            }
            &--3 {
                left: 35%;
                width: 13px;
                height: 13px;
                opacity: 0;
                animation: 0.8s brick-fly 0.8s linear, 0.8s brick-2-fly 0.8s linear;
            }
            &--4 {
                left: 41%;
                width: 11px;
                height: 11px;
                opacity: 0;
                animation: 0.75s brick-fly 0.75s linear, 0.75s brick-2-fly 0.75s linear;
            }
            &--5 {
                left: 55%;
                width: 9px;
                height: 9px;
                opacity: 0;
                animation: 0.75s brick-fly 0.75s linear, 0.75s brick-2-fly 0.75s linear;
            }
            &--6 {
                left: 65%;
                width: 8px;
                height: 8px;
                opacity: 0;
                animation: 0.8s brick-fly 0.8s linear, 0.8s brick-2-fly 0.8s linear;
            }
            &--7 {
                left: 31%;
                width: 8px;
                height: 8px;
                opacity: 0;
                animation: 0.8s brick-fly 0.8s linear, 0.8s brick-2-fly 0.8s linear;
            }
        }
        &--centered {
            margin: 0 auto;
            #{$block-name} {
                &__message {
                    text-align: center;
                    margin: 0 auto;
                    &:not(:last-child){
                        margin: 0 auto 10px;
                    }
                }
                &__wrap-bricks {
                    padding: 0 50px;
                }
            }
            #{$block-name}__image {
                margin: 0 auto;
                text-align: center;
                &-bricks {
                    margin: 0 auto;
                }
            }
        }
    }

    @keyframes brick-fly {
        0% {
            top: 100%;
            opacity: 0;
            transform: rotate(0);
        }
        10% {
            opacity: 1;
        }
        90% {
            opacity: 1;
            box-shadow: 0 0 0 0 $indigo;
        }
        100% {
            box-shadow: 0 0 3px 3px $indigo;
            top: 0;
            opacity: 0;
        }
    }

    @keyframes bricks-fly {
        0% {
            top: 100%;
            transform: rotate(0),translateX(-50%);
            opacity: 0
        }
        50% {
            opacity: 1;
        }
        90% {
            opacity: 1;
        }
        100% {
            top: 0;
            opacity: 1;
            transform: rotate(180deg);
        }
    }
    @keyframes brick-2-fly {
        100% {
            transform: rotate(200deg);
        }
    }
    @keyframes brick-3-fly {
        100% {
            transform: rotate(270deg);
        }
    }
    @keyframes brick-4-fly {
        100% {
            transform: rotate(300deg);
        }
    }
    @keyframes brick-5-fly {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes grow-up {
        from {
            width: 25px;
            border-radius: 5px;
            opacity: 1;
        }
        to{
            width: 300px;
            border-radius: 18px;
            opacity: 1;
        }
    }

    @keyframes grow-up-desktop {
        from {
            width: 25px;
            border-radius: 5px;
            opacity: 1;
        }
        to{
            width: 400px;
            border-radius: 18px;
            opacity: 1;
        }
    }

    .message-enter-from {
        height: 0;
        opacity: 0;
        padding: 0 20px;
        transform: translateY(35px);
    }
    .message-enter-active {
        transition: 0.65s opacity ease-in, 0.65s height ease-in, 0.65s padding ease-in, 0.65s transform ease-in;
    }
    .message-enter-to {
        height: 70px;
        opacity: 1;
        padding: 10px 20px;
        transform: translateY(0);
    }

    .message-leave-from {
        height: 0;
        opacity: 1;
    }
    .message-leave-active {
        transition: 0.5s opacity linear, 0.5s height linear;
    }
    .message-leave-to {
        height: auto;
        opacity: 0;
    }
</style>
