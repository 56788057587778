<template>
    <div>
        <div class="works">
            <div class="container-holder">
                <h2 class="container__title works__title">
                    {{ title }}
                </h2>
                <ul class="works-list">
                    <li
                        v-for="item in items"
                        :key="item.text"
                        class="works-list__item"
                    >
                        {{ item.text }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            title: 'Our deep integration with grocers is key to changing the health risks of your population and driving your return on investment',
            items: [
                {
                    text: 'Changing the products purchased by households, and in doing so significantly reduces exposure to disease-causing ingredients'
                },
                {
                    text: 'Provides recommendations that are curated from local stores, providing low-friction access'
                },
                {
                    text: 'Leverages directed spend to drive the purchase of better foods, which is especially relevant for low-income, higher-risk households'
                },
                {
                    text: 'Drives the balance between best health outcome and household economics'
                },
                {
                    text: 'Encourages ongoing repurchase of the new brands through discounts and coupons'
                }
            ]
        }
    }
}

</script>
