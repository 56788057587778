<template>
    <el-button
        round
        class="share-shopping-list__btn"
        :class="small ? 'button-small' : ''"
        @click="clickShareBtn"
    >
        <span class="share-shopping-list__btn-icon el-button__icon">
            <inline-svg
                class="share-shopping-list__btn-icon-svg el-button__icon-svg"
                src="/images/svg-icons/share-icon.svg"
                fill="#00315D"
            />
        </span>
        Share
    </el-button>
    <el-dialog
        v-model="showShareDialog"
        title="Share the remaining to be purchased in My Items? "
        custom-class="share-shopping-list__dialog el-dialog--share-shopping-list"
    >
        <ul class="share-shopping-list__dialog-list">
            <li class="share-shopping-list__dialog-list-item">
                <a
                    :href="smsLink"
                    class="share-shopping-list__dialog-link"
                    @click="shareAction"
                >
                    SMS
                </a>
            </li>
            <li class="share-shopping-list__dialog-list-item">
                <a
                    :href="mailToLink"
                    class="share-shopping-list__dialog-link"
                    @click="shareAction"
                >
                    Email
                </a>
            </li>
        </ul>
    </el-dialog>
</template>

<script setup>
import { ref, computed } from 'vue'
import InlineSvg from 'vue-inline-svg'
import myItemApi from '~/modules/my-items/myItemApi'

const props = defineProps({
    'shopItems': {
        type: [ Object, Array ],
        required: true,
        default: null
    },
    'small': {
        type: Boolean,
        default: false
    }
})

const showShareDialog = ref(false)
const smsLink = ref('')
const mailToLink = ref('')


function clickShareBtn() {

    let shoppingListsShareProducts = props.shopItems

    let bodyText = encodeURIComponent(getShoppingListText(shoppingListsShareProducts))

    if (isIOSDevice()) {
        smsLink.value = 'sms:;body=' + bodyText
    } else {
        smsLink.value = 'sms:?body=' + bodyText
    }
    mailToLink.value = 'mailto:?body=' + bodyText + '&subject=Replaced%20products'

    showShareDialog.value = true
}

function shareAction () {
    myItemApi.firstSharedList()
}

function getShoppingListText(shopItems) {
    let text = ''

    shopItems.forEach((product) => {
        let title = [
            product.brand,
            product.subBrand,
            product.productTitle
        ].filter(function (el) {
            return el != null
        }).join(', ')
        text += title + '\r\n'
    })

    return text
}
function isIOSDevice(){
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
}
</script>

<style lang="scss" scoped>
@import "resources/sass/_variables.scss";
.button-small {
    height: 24px !important;
    font-size: 14px !important;
    padding: 8px 5px;

    .share-shopping-list__btn-icon-svg {
        width: 15px !important;
        height: 15px !important;
        margin-top: 2px !important;
        margin-right: -5px;
    }
}

.share-shopping-list {
    $local-light-gray: #f0f0f0;

    &__btn {
        border-color: $blue;
        color: $blue;
        background-color: $local-light-gray;
        transition: 0.2s box-shadow ease-in-out;
        &:hover {
            box-shadow: 0 0 6px rgb(0 0 0 / 20%);
        }
    }
    &__dialog {
        &-list {
            display: flex;
            align-items: center;
            list-style: none;
            margin: 0 -10px;
            padding: 0;
            &-item {
                padding: 0 10px;
            }
        }
        &-link {
            border: 1px solid $indigo;
            border-radius: 18px;
            padding: 6px 15px;
            font-size: 14px;
            font-weight: 600;
            text-decoration: none;
            color: $indigo;
            transition: 0.2s box-shadow ease-in-out;
            &:hover,
            &:active,
            &:focus {
                box-shadow: 0 0 6px rgba(0,0,0, 20%);
            }
        }
    }
}
</style>
