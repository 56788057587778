<template>
    <div
        v-loading="loading"
        class="main-content"
    >
        <div class="g-container main-content__summary">
            <div
                v-if="prods_first.length > 0"
                class="item-to-replace"
            >
                <h4 class="item-to-replace__title">
                    Item to Replace
                </h4>
                <el-table
                    :data="prods_first"
                    :row-class-name="tableRowClassName"
                    class="w-100 item-to-replace__table"
                >
                    <el-table-column
                        prop="name"
                        label="Item"
                        min-width="120"
                    >
                        <template #default="scope">
                            <div
                                v-if="(scope.row.approved == false)"
                            >
                                <span class="item-to-replace__item-under-review">
                                    {{ scope.row.name }}
                                </span>
                                <el-tooltip
                                    effect="dark"
                                    content="This product is under review"
                                    placement="top"
                                >
                                    <span class="item-to-replace__icon">
                                        <inline-svg
                                            class="item-to-replace__icon-svg"
                                            src="/images/svg-icons/info-icon.svg"
                                            fill="#757575"
                                        />
                                    </span>
                                </el-tooltip>
                            </div>

                            <span v-else>
                                {{ scope.row.name }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="sugar"
                        width="100"
                        class-name="sugar-number"
                    >
                        <template #header>
                            <div
                                class="table-header"
                                @click="onSort('sugar')"
                            >g/s
                                <inline-svg
                                    v-if="hasFiltered === 'sugar'"
                                    class="table-header__icon"
                                    src="/images/svg-icons/filter.svg"
                                />
                            </div>
                        </template>
                        <template #default="scope">
                            <div
                                v-if="(scope.row.status === STATUS_REDUCED || scope.row.status === STATUS_REPLACED || scope.row.status === STATUS_REMOVED)&&scope.row.reduced_sugar"
                                :style="scope.row.reduced_sugar ? 'text-align:left' : ''"
                            >
                                <span>
                                    {{ scope.row.sugar }} <sup style="width: max-content">{{ scope.row.reduced_sugar }}</sup>
                                </span>
                            </div>

                            <span v-else>
                                {{ scope.row.sugar }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="net_carbs"
                        width="100"
                        class-name="netcarb-number"
                    >
                        <template #header>
                            <div
                                class="table-header"
                                 @click="onSort('netCarbs')"
                            >g/nc
                                <inline-svg
                                    v-if="hasFiltered === 'netCarbs'"
                                    class="table-header__icon"
                                    src="/images/svg-icons/filter.svg"
                                />
                            </div>
                        </template>
                        <template #default="scope">
                            <div
                                :style="scope.row.reduced_net_carbs ? 'text-align:left' : ''"
                                v-if="(scope.row.status === STATUS_REDUCED || scope.row.status === STATUS_REPLACED || scope.row.status === STATUS_REMOVED)&&scope.row.reduced_sugar"
                            >
                                <span>
                                    {{ scope.row.net_carbs }} <sup style="width: max-content">{{ scope.row.reduced_net_carbs }}</sup>
                                </span>
                            </div>

                            <span v-else>
                                {{ scope.row.net_carbs }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="Action"
                        width="90"
                    >
                        <template #default="scope">
                            <template v-if="scope.row.approved">
                                <router-link
                                    v-if="!scope.row.status || scope.row.status === STATUS_EDITED"
                                    :to="{
                                        name: 'product.more-choices',
                                        params: {upc: scope.row.gtin14, pmo_category: scope.row.pmo_category},
                                        query: {goto: 'summary', pantry: route.params.pantry || 1}
                                    }"
                                >
                                    Replace
                                </router-link>
                                <router-link
                                    v-if="scope.row.status === STATUS_REPLACED"
                                    :to="{
                                        name: 'my-items.shop',
                                        query: {goto: 'summary', pantry: route.params.pantry || 1}
                                    }"
                                >
                                    My Items
                                </router-link>
                                <router-link
                                    v-if="scope.row.status === STATUS_REMOVED"
                                    :to="{
                                        name: 'product.more-choices',
                                        params: {
                                            upc: scope.row.gtin14,
                                            pmo_category: scope.row.pmo_category
                                        },
                                        query: {goto: 'summary', pantry: route.params.pantry || 1}
                                    }"
                                >
                                    Removed
                                </router-link>
                                <template v-if="scope.row.status === STATUS_REDUCED">
                                    Reduced
                                </template>
                            </template>
                            <template v-else>
                                <template v-if="scope.row.status === STATUS_REDUCED">
                                    Reduced
                                </template>
                                <template v-else>
                                    Replace
                                </template>
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div
                v-if="prods_other.length > 0"
                class="item-to-retain"
            >
                <h4 class="item-to-retain__title">
                    Item to Retain
                </h4>
                <el-table
                    :data="prods_other"
                    class="w-100 item-to-retain__table"
                >
                    <el-table-column
                        prop="name"
                        label="Item"
                        min-width="180"
                    >
                        <template #default="scope">
                            <div
                                v-if="(scope.row.approved == false)"
                            >
                                <span class="item-to-replace__item-under-review">
                                    {{ scope.row.name }}
                                </span>
                                <el-tooltip
                                    effect="dark"
                                    content="This product is under review"
                                    placement="top"
                                >
                                    <span class="item-to-replace__icon">
                                        <inline-svg
                                            class="item-to-replace__icon-svg"
                                            src="/images/svg-icons/info-icon.svg"
                                            fill="#757575"
                                        />
                                    </span>
                                </el-tooltip>
                            </div>

                            <span v-else>
                                {{ scope.row.name }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="sugar"
                        label="g/s"
                        min-width="70"
                        class-name="sugar-number"
                    />
                    <el-table-column
                        prop="net_carbs"
                        label="g/nc"
                        min-width="70"
                        class-name="netcarb-number"
                    />
                    <el-table-column
                        label="Action"
                        width="90"
                    >
                        <template #default>
                            Retain
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div
            v-if="prods_first.length > 0 || prods_other.length > 0 "
            class="main-content main-content__summary-fixed"
        >
            <div class="g-container">
                <PmoSummaryTotal
                    :total-sugar="total_sugar"
                    :total-reduced-sugar="total_reduced_sugar"
                    :total-net-carbs="total_net_carbs"
                    :total-reduced-net-carbs="total_reduced_net_carbs"
                />
                <div class="pantry-category__page-slider">
                    <PmoSummaryPageSlider
                        :page="pantry"
                    />
                </div>
            </div>
        </div>
        <PmoSummaryPopupDialog 
            v-if="showPmoSummaryPopupDialog" 
            :prods_first="prods_first"
            :prods_other="prods_other"
            :total_sugar="total_sugar"
            :total_net_carbs="total_net_carbs"
            :total_reduced_sugar="total_reduced_sugar"
            :total_reduced_net_carbs="total_reduced_net_carbs"
            :dialogVisible="showPmoSummaryPopupDialog"
        />
    </div>
</template>

<script setup>
import {computed, ref, onMounted, onBeforeMount, onUnmounted} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import pmoApi from '~/modules/pmo/pmoApi'
import bus from '~/includes/Event'
import store from '~/store'
import PmoSummaryPageSlider from './PmoSummaryPageSlider'
import {STATUS_REDUCED, STATUS_REPLACED, STATUS_REMOVED, STATUS_EDITED} from '~/modules/pmo/summaryStatuses.constants'
import PmoSummaryTotal from '~/modules/pmo/components/PmoSummaryTotal'
import PmoSummaryPopupDialog from './PmoSummaryPopupDialog'
import InlineSvg from 'vue-inline-svg'

const user = store.getters['auth/user']

const route = useRoute()
const router = useRouter()

const pantry = route.params.pantry || 1
const loading = ref(false)
const total_sugar = ref(0)
const total_net_carbs = ref(0)
const total_reduced_sugar = ref(0)
const total_reduced_net_carbs = ref(0)
const pantry_name = ref('')
const hasFiltered = ref('sugar')

const prods_first = ref([])
const prods_other = ref([])

const showPmoSummaryPopupDialog = ref(false)

onBeforeMount(() => {
    fetchProducts()
})

async function fetchProducts() {
    loading.value = true
    const {data} = await pmoApi.getSummaryProductsByPantry(pantry)
    prods_first.value = data.products_top_5
    prods_other.value = data.products_other
    total_sugar.value = data.total_sugar
    total_net_carbs.value = data.total_net_carbs
    total_reduced_sugar.value = data.total_reduced_sugar
    total_reduced_net_carbs.value = data.total_reduced_net_carbs
    pantry_name.value = data.pantry_name

    bus.$emit('set-title', pantry_name.value)
    loading.value = false

    showPmoSummaryPopupDialog.value = (data.products_top_5.length + data.products_other.length) !== 0

    if (data.products_top_5.length + data.products_other.length === 0) {
        await goToPmoCategory(pantry, true)
    }
}

onMounted(() => {
    bus.$on('clickPmoAddButton', clickPmoAddButton)
})

onUnmounted(() => {
    bus.$off('clickPmoAddButton', clickPmoAddButton)
})

function onSort(type) {
    hasFiltered.value = type
   if (type === 'netCarbs') {
       prods_first.value.sort((a, b) => b.net_carbs - a.net_carbs);
       prods_other.value.sort((a, b) => b.net_carbs - a.net_carbs);
   } else {
       prods_first.value.sort((a, b) => b.sugar - a.sugar);
       prods_other.value.sort((a, b) => b.sugar - a.sugar);
   }
}

function clickPmoAddButton() {
    goToPmoCategory(pantry)
}

async function goToPmoCategory(type, skip_goto_query = false) {
    const {data} = await pmoApi.findByType(type)

    if (skip_goto_query) {
        await router.push({
            name: 'pmo.show',
            params: {pmo_category: data.slug}
        })
    } else {
        await router.push({
            name: 'pmo.show',
            params: {pmo_category: data.slug},
            query: {goto: 'summary', pantry: type}
        })
    }
}

const tableRowClassName = ({row, rowIndex}) => {
    return row.status ? 'item-to-replace__done' : ''
}
</script>

<style lang="scss">
.item-to-replace, .item-to-retain  {
    margin-bottom: 35px;
    &__title {
        margin-left: 12px;
        font-weight: 600;
    }
    &__table {
        &.el-table td.el-table__cell:not(:last-child) {
            border-right: var(--el-table-border);
        }
    }
    &__item-under-review {
        color: #900101;
        font-weight: 600;
    }
    &__icon {
        margin-left: 5px;
        margin-bottom: 2px;

    }
    &__icon-svg {
        width: 16px;
        height: 16px;
    }

    th.el-table__cell > .cell {
        font-weight: 400;
        font-size: 13px;
    }

    .el-table__cell.sugar-number .cell {
        text-align: center;
        //@media (max-width: 480px) {
        //    text-align: start;
        //}
    }
    .el-table__cell.netcarb-number .cell {
        text-align: center;
        //@media (max-width: 480px) {
        //    text-align: start;
        //}
    }
}

.item-to-replace {
    &__table {
        color: #941A49;
    }
    &__done {
        color: black;
    }
    .el-table__cell {
        .cell {
            span {
                position: relative;
                sup {
                    position: absolute;
                    color: darkgray;
                    vertical-align: super;
                    top: -6px;
                    width: 3em;
                    text-align: left;
                }
            }
        }
    }
}

.main-content {
    &__summary {
        padding-bottom: 150px;
    }
    &__summary-fixed {
        position: fixed;
        width: 100%;
        bottom: 0;
        background: white;
        z-index: 3;
        box-shadow: 0 -5px 15px -10px #ccc;
    }
}
.icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
}

.table-header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &__icon {
        position: absolute;
        right: 20px;
        width: 16px;
        height: 16px;
        margin-left: 5px;
        color: #484baf;
    }
}
</style>
