<template>
    <div
        v-if="showSugarReductionReport || showNetCarbReductionReport"
        class="main-content main-content__summary-fixed sugar-reduction-report "
    >
        <div class="g-container">
            <el-button
                v-if="showSugarReductionReport"
                type="primary"
                round
                class="shop-category__btn"
                @click="router.push({name: 'sugar-reduction-report',})"
            >
                Sugar Reduction Report
            </el-button>

            <el-button
                v-if="showNetCarbReductionReport"
                type="primary"
                round
                class="shop-category__btn"
                @click="router.push({name: 'net-carbs-reduction-report',})"
            >
                Net Carbs Reduction Report
            </el-button>
        </div>
    </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import bus from '~/includes/Event'
import myItemApi from '~/modules/my-items/myItemApi'
import {useRoute, useRouter} from 'vue-router'

const router = useRouter()
const route = useRoute()

const showSugarReductionReport = ref(false)
const showNetCarbReductionReport = ref(false)


onMounted(() => {
    bus.$on('show-sugar-reduction-report', setShowSugarReductionReport)
    bus.$on('show-net-carb-reduction-report', setShowNetCarbReductionReport)
    setShowNetCarbReductionReport()
    setShowSugarReductionReport()
})

function setShowSugarReductionReport() {
    myItemApi.getSugarReductionReport().then(response => {
        showSugarReductionReport.value = response.data?.removed_sugar_num_of_items > 0
    })
}

function setShowNetCarbReductionReport() {
    myItemApi.getNetCarbsReductionReport().then(response => {
        showNetCarbReductionReport.value = response.data?.removed_net_carb_num_of_items > 0
    })
}
</script>
<style scoped lang="scss">
@import "resources/sass/_variables.scss";

.sugar-reduction-report {
    padding: 15px;
    text-align: center;
}
.shop-category {
    &__btn {
        width: 265px;
        @media all and (max-width: $s) {
            margin-left: 0 !important;
            margin-bottom: 10px;
        }
    }
}
</style>
