<template>
    <section class="main">
        <HeaderWithBack />
        <child-path />
    </section>
</template>

<script setup>
import ChildPath from '~/base/components/ChildPath'
import HeaderWithBack from '~/base/components/HeaderWithBack'
</script>
