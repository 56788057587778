<template>
    <div
        v-if="quizzes"
        class="main-content main-content--base-layout"
    >
        <div class="main-content__top-content-section">
            <div class="g-container">
                <div
                    v-if="quizzes[0].status !== 'done'"
                    class="main-content__text"
                >
                    Establishing a profile takes about 10 minutes and helps us understand what success might look like for you and how to best interact.
                </div>
                <div
                    v-loading="loading"
                    class="main-content__questions-group"
                >
                    <div
                        class="questions-group"
                        :class="{ 'finish': finishing }"
                    >
                        <div
                            v-for="quiz in quizzes"
                            :id="`quiz-page-${quiz.slug}`"
                            :key="quiz._id"
                            class="questions-group__item"
                        >
                            <div
                                class="question-module"
                                :class="quiz.status"
                                @click="quizRoute(quiz)"
                            >
                                <div class="question-module__image">
                                    <div class="question-module__image-wrap">
                                        <img
                                            :src="quiz.image"
                                            class="question-module__image-i"
                                            alt=""
                                        >
                                    </div>
                                    <span
                                        v-if="quiz.status === $config.quizStatuses.done"
                                        class="question-module__check-wrap"
                                    >
                                        <i class="fa-solid fa-circle-check question-module__check" />
                                    </span>
                                </div>
                                <div class="question-module__title">
                                    {{ quiz.name }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            id="bottom-content-section"
            class="main-content__bottom-content-section"
        >
            <div class="g-container">
                <div class="main-content__robot">
                    <RobotDialog
                        v-if="finishing && globalMessages.length === 0"
                        :messages="['Congratulations!  Off to the Learning Module.',]"
                        robot-image="/images/robots/robot_rocket.svg"
                    />
                </div>
                <div class="main-content__button-wrap">
                    <el-button
                        type="primary"
                        round
                        class="continue-button"
                        @click="handleContinue"
                        :disabled="disabledContinueButton"
                    >
                        Continue
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import RobotDialog from './RobotDialog'
import quizApi from '~/modules/quiz/quizApi'
import {ref, onMounted, getCurrentInstance, computed} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import baseApi from '~/base/baseApi'
import store from '~/store'
import {LEARNING_MODULE_STEP} from '~/modules/learning-modules/routes'
import userApi from '~/modules/profile/userApi'
import bus from '~/includes/Event'

const { proxy } = getCurrentInstance()
const route = useRoute()
const router = useRouter()

const quizzes = ref()
const loading = ref(true)

const finishing = ref(false)
const robotImage = '/images/robots/robot_smile.svg'

const globalMessages = computed(() => store.getters['global-robot/messages'])


onMounted(async () => {
    await fetchQuizzes()
    finishing.value =  ! _.find(quizzes.value, ['status', proxy.$config.quizStatuses.active])
    bus.$emit('set-title', 'Profile Builder')
})


async function fetchQuizzes() {
    loading.value = true
    const {data} = await quizApi.getQuizzes()
    if (!!data) quizzes.value = data
    loading.value = false
}

function quizRoute(quiz) {
    if (quiz.status !== proxy.$config.quizStatuses.active) {
        return
    }
    if (quiz.slug === proxy.$config.householdQuizSlug) {
        router.push({name: 'quiz.household'})
    } else {
        router.push({name: 'quiz.show', params: {slug: quiz.slug}})
    }
}

async function handleContinue() {
    const activeQuiz = _.find(quizzes.value, ['status', proxy.$config.quizStatuses.active])
    if (activeQuiz?.slug) {
        quizRoute(activeQuiz)
    } else {
        await handleUserStepOverview()
    }
}

async function handleUserStepOverview() {
    await baseApi.newUserStep(LEARNING_MODULE_STEP)
    await store.dispatch('auth/updateUserField', {
        last_step: {
            level: LEARNING_MODULE_STEP,
            name: 'Learning module',
            path: '/learning-modules',
        }
    })
    await router.push({ name: 'intro.after-profile.lm-preview' })
}


</script>
